import React, { useState, useEffect } from 'react';
import { getProcesses } from '../../firebase';
import './EstimateApp.css';

const ProcessSelection = ({ onSelect, selectedProcesses, processes }) => {
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);

  useEffect(() => {
    if (selectedProcesses.length >= 4) {
      setShowScrollIndicator(true);
      // 3초 후에 인디케이터 숨기기
      const timer = setTimeout(() => {
        setShowScrollIndicator(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [selectedProcesses.length]);

  const toggleProcess = (process) => {
    const updatedProcesses = selectedProcesses.includes(process)
      ? selectedProcesses.filter(p => p !== process)
      : [...selectedProcesses, process];
    onSelect(updatedProcesses);
  };

  return (
    <div className="process-selection">
      <div className="button-list">
        {processes.map(process => (
          <button
            key={process}
            onClick={() => toggleProcess(process)}
            className={`process-button ${selectedProcesses.includes(process) ? 'selected' : ''}`}
          >
            {process}
          </button>
        ))}
      </div>
      {showScrollIndicator && (
        <div className="scroll-indicator">
          <div className="arrow">↓</div>
          <p>아래로 스크롤하여 견적 확인하기</p>
        </div>
      )}
    </div>
  );
};

export default ProcessSelection;
