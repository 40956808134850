import { db } from '../../../firebase';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';

class AIService {
  static async getApiKeys() {
    try {
      const docRef = doc(db, 'config', 'apiKeys');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data();
      }
      throw new Error('API 키가 설정되지 않았습니다.');
    } catch (error) {
      throw error;
    }
  }

  static searchTemplatesWithKeyword(searchText, templates) {
    try {
      if (!searchText.trim() || !templates.length) {
        return templates;
      }

      const searchTerms = searchText.toLowerCase().split(/\s+/);
      
      const scoredTemplates = templates.map(template => {
        let score = 0;
        
        searchTerms.forEach(term => {
          if (template.userInput?.requirement?.toLowerCase().includes(term)) score += 3;
          if (template.userInput?.budget?.toLowerCase().includes(term)) score += 3;
          if (template.userInput?.keywords?.toLowerCase().includes(term)) score += 2;
          if (template.userInput?.specialNotes?.toLowerCase().includes(term)) score += 1;
          if (template.items) {
            template.items.forEach(item => {
              if (item.name.toLowerCase().includes(term)) score += 2;
            });
          }
        });

        return {
          ...template,
          score
        };
      });

      return scoredTemplates
        .filter(template => template.score > 0)
        .sort((a, b) => b.score - a.score);

    } catch (error) {
      return [];
    }
  }
}

export default AIService; 