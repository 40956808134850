import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc, getDocs, serverTimestamp, doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import './WindowSize.css';

const WindowSize = () => {
  const [measurementRows, setMeasurementRows] = useState([
    { location: '', windowType: '', width: '', height: '' },
    { location: '', windowType: '', width: '', height: '' },
    { location: '', windowType: '', width: '', height: '' },
    { location: '', windowType: '', width: '', height: '' },
    { location: '', windowType: '', width: '', height: '' }
  ]);
  
  const [memo, setMemo] = useState('');
  const [savedMeasurements, setSavedMeasurements] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [locationOptions, setLocationOptions] = useState([
    '거실',
    '안방',
    '작은방1',
    '작은방2',
    '주방',
    '화장실'
  ]);
  const [windowTypes, setWindowTypes] = useState([
    '이중창',
    '단창',
    '미서기창',
    '프로젝트창',
    '고정창',
    '터닝도어',
    '기타'
  ]);
  const [newLocation, setNewLocation] = useState('');
  const [newWindowType, setNewWindowType] = useState('');
  const [editMode, setEditMode] = useState('location'); // 'location' 또는 'type'
  const [savedSummary, setSavedSummary] = useState(null);

  // 설정 로드
  const loadSettings = async () => {
    try {
      const settingsDoc = await getDoc(doc(db, 'settings', 'windowSizeOptions'));
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        if (data.locations) setLocationOptions(data.locations);
        if (data.types) setWindowTypes(data.types);
      }
    } catch (error) {
      console.error('설정 로드 중 오류:', error);
    }
  };

  // 저장된 데이터 로드
  const loadSavedData = async () => {
    try {
      const docRef = doc(db, 'windowSizes', 'currentData');
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        setMeasurementRows(data.measurements);
        setMemo(data.memo || '');
        setSavedSummary(data.summary || null);
      }
    } catch (error) {
      console.error('데이터 로드 중 오류:', error);
    }
  };

  // 컴포넌트 마운트 시 데이터 로드
  useEffect(() => {
    loadSavedData();
    loadSettings();
  }, []);

  // 제목 클릭 처리
  const handleTitleClick = () => {
    setClickCount(prev => {
      const newCount = prev + 1;
      if (newCount === 4) {
        setShowModal(true);
        return 0;
      }
      return newCount;
    });
  };

  // 새 옵션 추가
  const handleAddOption = async () => {
    try {
      const value = editMode === 'location' ? newLocation : newWindowType;
      if (!value.trim()) return;

      const newOptions = editMode === 'location' 
        ? [...locationOptions, value]
        : [...windowTypes, value];

      const settingsRef = doc(db, 'settings', 'windowSizeOptions');
      await setDoc(settingsRef, {
        locations: editMode === 'location' ? newOptions : locationOptions,
        types: editMode === 'type' ? newOptions : windowTypes
      });

      if (editMode === 'location') {
        setLocationOptions(newOptions);
        setNewLocation('');
      } else {
        setWindowTypes(newOptions);
        setNewWindowType('');
      }
    } catch (error) {
      console.error('옵션 추가 중 오류:', error);
      alert('옵션 추가에 실패했습니다.');
    }
  };

  // 입력값 변경 처리
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newRows = [...measurementRows];
    newRows[index] = {
      ...newRows[index],
      [name]: value
    };
    setMeasurementRows(newRows);
  };

  // 메모 변경 처리
  const handleMemoChange = (e) => {
    setMemo(e.target.value);
  };

  // 새 행 추가
  const addNewRow = () => {
    setMeasurementRows([
      ...measurementRows,
      { location: '', windowType: '', width: '', height: '' }
    ]);
  };

  // 저장 처리
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const filledRows = measurementRows.filter(row => 
        row.location && row.windowType && row.width && row.height
      );

      if (filledRows.length === 0) {
        alert('최소 하나의 측정값을 입력해주세요.');
        return;
      }

      // 측정값 요약 텍스트 생성
      const summaryText = filledRows.map(row => 
        `${row.location} - ${row.windowType}\n크기: ${row.width}mm x ${row.height}mm`
      ).join('\n\n');

      // '메모:' 레이블 없이 메모 추가
      const fullSummary = memo ? `${summaryText}\n\n${memo}` : summaryText;

      // Firestore에 저장
      await setDoc(doc(db, 'windowSizes', 'currentData'), {
        measurements: measurementRows,
        memo: memo,
        summary: fullSummary,
        updatedAt: serverTimestamp()
      });

      setSavedSummary(fullSummary);
      alert('측정값이 저장���었습니다.');
    } catch (error) {
      console.error('저장 중 오류 발생:', error);
      alert('저장에 실패했습니다.');
    }
  };

  // 저장된 데이터 로드
  const loadMeasurements = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'windowSizes'));
      const data = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setSavedMeasurements(data);
    } catch (error) {
      console.error('데이터 로드 중 오류 발생:', error);
    }
  };

  // 공유 기능
  const handleShare = async () => {
    if (!savedSummary) return;

    if (navigator.share) {
      try {
        await navigator.share({
          title: '샷시 측정 정보',
          text: savedSummary
        });
      } catch (error) {
        console.error('공유 중 오류 발생:', error);
      }
    } else {
      alert('공유 기능이 지원되지 않는 브라우저입니다.');
    }
  };

  // 삭제 처리 함수 추가
  const handleDelete = async (measurementId) => {
    if (!window.confirm('이 측정값을 삭제하시겠습니까?')) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'windowSizes', measurementId));
      alert('삭제되었습니다.');
      loadMeasurements(); // 목록 새로고침
    } catch (error) {
      console.error('삭제 중 오류 발생:', error);
      alert('삭제에 실패했습니다.');
    }
  };

  // 옵션 삭제 함수 추가
  const handleDeleteOption = async (itemToDelete) => {
    if (!window.confirm(`"${itemToDelete}"를 삭제하시겠습니까?`)) {
      return;
    }

    try {
      const newOptions = editMode === 'location'
        ? locationOptions.filter(item => item !== itemToDelete)
        : windowTypes.filter(item => item !== itemToDelete);

      const settingsRef = doc(db, 'settings', 'windowSizeOptions');
      await setDoc(settingsRef, {
        locations: editMode === 'location' ? newOptions : locationOptions,
        types: editMode === 'type' ? newOptions : windowTypes
      });

      if (editMode === 'location') {
        setLocationOptions(newOptions);
      } else {
        setWindowTypes(newOptions);
      }
    } catch (error) {
      console.error('옵션 삭제 중 오류:', error);
      alert('삭제에 실패했습니다.');
    }
  };

  // 초기화 버튼 추가
  const handleReset = () => {
    if (window.confirm('모든 입력값을 초기화하시겠습니까?')) {
      setMeasurementRows([
        { location: '', windowType: '', width: '', height: '' },
        { location: '', windowType: '', width: '', height: '' },
        { location: '', windowType: '', width: '', height: '' },
        { location: '', windowType: '', width: '', height: '' },
        { location: '', windowType: '', width: '', height: '' }
      ]);
      setMemo('');
      setSavedSummary(null);
    }
  };

  // 행 삭제 함수 추가
  const handleRemoveRow = (indexToRemove) => {
    // 최소 1개의 행은 유지
    if (measurementRows.length <= 1) {
      alert('최소 1개의 행은 필요합니다.');
      return;
    }
    
    const newRows = measurementRows.filter((_, index) => index !== indexToRemove);
    setMeasurementRows(newRows);
  };

  return (
    <div className="window-size-container">
      <div className="window-size-content">
        <h1 className="window-size-title" onClick={handleTitleClick}>
          샷시 사이즈 보내기
        </h1>
        
        {/* 저장된 요약 표시 */}
        {savedSummary && (
          <div className="window-size-summary" onClick={handleShare}>
            <pre className="window-size-summary-text">
              {savedSummary}
            </pre>
            <div className="window-size-summary-hint">
              ↑ 터치하여 공유하기
            </div>
          </div>
        )}

        {/* 모달 */}
        {showModal && (
          <div className="window-size-modal">
            <div className="window-size-modal-content">
              <h2>설정</h2>
              <div className="window-size-modal-tabs">
                <button 
                  className={editMode === 'location' ? 'active' : ''} 
                  onClick={() => setEditMode('location')}
                >
                  위치 관리
                </button>
                <button 
                  className={editMode === 'type' ? 'active' : ''} 
                  onClick={() => setEditMode('type')}
                >
                  종류 관리
                </button>
              </div>

              <div className="window-size-modal-input">
                <input
                  type="text"
                  value={editMode === 'location' ? newLocation : newWindowType}
                  onChange={(e) => editMode === 'location' 
                    ? setNewLocation(e.target.value) 
                    : setNewWindowType(e.target.value)
                  }
                  placeholder={`새 ${editMode === 'location' ? '위치' : '종류'} 입력`}
                />
                <button onClick={handleAddOption}>추가</button>
              </div>

              <div className="window-size-modal-list">
                {(editMode === 'location' ? locationOptions : windowTypes).map((item, index) => (
                  <div key={index} className="window-size-modal-item">
                    <span>{item}</span>
                    <button 
                      onClick={() => handleDeleteOption(item)}
                      className="window-size-modal-delete-button"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>

              <button 
                className="window-size-modal-close" 
                onClick={() => setShowModal(false)}
              >
                닫기
              </button>
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit} className="window-size-form">
          {measurementRows.map((row, index) => (
            <div key={index} className="window-size-input-row">
              <select 
                name="location" 
                value={row.location}
                onChange={(e) => handleChange(index, e)}
                className="window-size-input-field"
              >
                <option value="">위치</option>
                {locationOptions.map(loc => (
                  <option key={loc} value={loc}>{loc}</option>
                ))}
              </select>

              <select 
                name="windowType" 
                value={row.windowType}
                onChange={(e) => handleChange(index, e)}
                className="window-size-input-field"
              >
                <option value="">종류</option>
                {windowTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>

              <input
                type="number"
                name="width"
                value={row.width}
                onChange={(e) => handleChange(index, e)}
                placeholder="가로"
                className="window-size-input-field"
              />

              <input
                type="number"
                name="height"
                value={row.height}
                onChange={(e) => handleChange(index, e)}
                placeholder="세로"
                className="window-size-input-field"
              />

              <button 
                type="button" 
                onClick={() => handleRemoveRow(index)}
                className="window-size-remove-button"
              >
                ×
              </button>
            </div>
          ))}

          <textarea
            value={memo}
            onChange={handleMemoChange}
            placeholder="전체 메모"
            className="window-size-memo"
            rows="3"
          />

          <div className="window-size-button-group">
            <button type="button" onClick={handleReset} className="window-size-reset-button">
              초기화
            </button>
            <button type="button" onClick={addNewRow} className="window-size-add-button">
              + 행 추가
            </button>
            <button type="submit" className="window-size-save-button">
              전체 저장
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WindowSize;
