import React, { useEffect } from 'react';
import './HiddenProcessList.css';

const HiddenProcessList = ({ 
  hiddenProcesses, 
  onToggle, 
  getProcessName,
  showModal,
  onShowModal,
  onCloseModal,
  onHiddenProcessesChange
}) => {
  const handleRestore = (processId) => {
    onToggle(processId);
    onCloseModal();
    
    if (onHiddenProcessesChange) {
      const updatedProcesses = { ...hiddenProcesses };
      delete updatedProcesses[processId];
      onHiddenProcessesChange(updatedProcesses);
    }
  };

  useEffect(() => {
    if (showModal) {
      const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          onCloseModal();
        }
      };

      window.addEventListener('keydown', handleKeyDown, true);
      return () => {
        window.removeEventListener('keydown', handleKeyDown, true);
      };
    }
  }, [showModal, onCloseModal]);

  return (
    <>
      <button 
        className="hidden-process-button"
        onClick={onShowModal}
      >
        숨긴 공정 보기
      </button>

      {showModal && (
        <div className="hidden-process-modal-overlay">
          <div className="hidden-process-modal-container">
            <div className="hidden-process-modal-header">
              <h3>숨긴 공정 목록</h3>
            </div>
            
            <div className="hidden-process-modal-body">
              <div className="hidden-process-list">
                {Object.entries(hiddenProcesses)
                  .filter(([_, status]) => status.hidden)
                  .map(([processId, status]) => (
                    <div key={processId} className="hidden-process-item">
                      <span className="hidden-process-name">
                        {getProcessName(processId)}
                      </span>
                      <span className="hidden-process-type">
                        {status.type === 'auto' ? '(자동)' : '(수동)'}
                      </span>
                      <button 
                        onClick={() => handleRestore(processId)}
                        className="hidden-process-restore"
                      >
                        복원
                      </button>
                    </div>
                  ))}
              </div>
            </div>
            
            <div className="hidden-process-modal-footer">
              <button 
                className="hidden-process-close"
                onClick={onCloseModal}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HiddenProcessList; 