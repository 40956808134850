import React, { useState, useEffect } from 'react';
import './TemplateAddEditModal.css';

const TemplateAddEditModal = ({ template, onSave, onClose, availableProcesses }) => {
  const [formData, setFormData] = useState({
    lineup: template?.lineup || 'refresh',
    size: template?.userInput?.size || '28',
    processName: template?.processName || ''
  });

  const lineups = [
    { value: 'refresh', label: '리프레쉬' },
    { value: 'harmony', label: '하모니' },
    { value: 'collection', label: '컬렉션' }
  ];

  const sizes = ['28', '33', '40', '48', '56'];

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey, true);
    return () => window.removeEventListener('keydown', handleEscKey, true);
  }, [onClose]);

  React.useEffect(() => {
    if (!template && availableProcesses.length > 1) {
      setFormData(prev => ({
        ...prev,
        processName: availableProcesses[1]
      }));
    }
  }, [template, availableProcesses]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <div className="template-add-edit-modal-overlay">
      <div className="template-add-edit-modal">
        <div className="template-add-edit-header">
          <h3 className="template-add-edit-title">
            {template ? '템플릿 수정' : '템플릿 추가'}
          </h3>
          <button className="template-add-edit-close" onClick={onClose}>×</button>
        </div>
        <div className="template-add-edit-content">
          <form onSubmit={handleSubmit} className="template-add-edit-form">
            <div className="template-add-edit-group">
              <label className="template-add-edit-label">라인업</label>
              <select
                className="template-add-edit-select"
                value={formData.lineup}
                onChange={(e) => setFormData({...formData, lineup: e.target.value})}
              >
                {lineups.map(lineup => (
                  <option key={lineup.value} value={lineup.value}>
                    {lineup.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="template-add-edit-group">
              <label className="template-add-edit-label">평수</label>
              <select
                className="template-add-edit-select"
                value={formData.size}
                onChange={(e) => setFormData({...formData, size: e.target.value})}
              >
                {sizes.map(size => (
                  <option key={size} value={size}>{size}평대</option>
                ))}
              </select>
            </div>
            <div className="template-add-edit-group">
              <label className="template-add-edit-label">공정명</label>
              <select
                className="template-add-edit-select"
                value={formData.processName}
                onChange={(e) => setFormData({...formData, processName: e.target.value})}
              >
                {availableProcesses.slice(1).map(process => (
                  <option key={process} value={process}>{process}</option>
                ))}
              </select>
            </div>
            <div className="template-add-edit-actions">
              <button type="submit" className="template-add-edit-save">
                {template ? '수정' : '추가'}
              </button>
              <button 
                type="button" 
                className="template-add-edit-cancel"
                onClick={onClose}
              >
                취소
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TemplateAddEditModal; 