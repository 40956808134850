import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import './EstimateApp.css';

const AreaSelection = ({ onSelect, selectedArea, onBack }) => {
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const loadAreas = async () => {
      try {
        const docSnap = await getDoc(doc(db, 'settings', 'areas'));
        if (docSnap.exists()) {
          const areaList = docSnap.data().list || getDefaultAreas();
          setAreas(Array.isArray(areaList) ? areaList : getDefaultAreas());
        } else {
          setAreas(getDefaultAreas());
        }
      } catch (error) {
        console.error('평수 목록 로드 중 오류:', error);
        setAreas(getDefaultAreas());
      } finally {
        setLoading(false);
      }
    };

    loadAreas();
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        onBack();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onBack]);

  const getDefaultAreas = () => {
    return ['20평대', '30평대', '40평대', '50평대'];
  };

  const extractNumber = (areaString) => {
    if (typeof areaString !== 'string') return null;
    const match = areaString.match(/\d+/);
    return match ? parseInt(match[0]) : null;
  };

  const handleAreaSelect = (area) => {
    const areaNumber = extractNumber(area);
    if (areaNumber) {
      onSelect(areaNumber);
    }
  };

  if (loading) {
    return <div className="area-selection loading">로딩중...</div>;
  }

  return (
    <div className="area-selection">
      <div className="button-list">
        {areas.map(area => {
          const areaNumber = extractNumber(area);
          if (!areaNumber) return null;
          
          return (
            <button 
              key={area} 
              onClick={() => handleAreaSelect(area)}
              className={`area-button ${selectedArea === areaNumber ? 'selected' : ''}`}
            >
              {area}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default AreaSelection;
