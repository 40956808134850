import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DraggableText from './DraggableText';
import FontSelector from './FontSelector';
import TextLayerManager from './TextLayerManager';
import { saveThumbnailImage, saveTextLayers, getThumbnails } from '../../firebase';
import ThumbnailList from './ThumbnailList';

const ThumbnailEditor = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [textLayers, setTextLayers] = useState([]);
  const [selectedLayerId, setSelectedLayerId] = useState(null);
  const [fontSize, setFontSize] = useState(24);
  const [textColor, setTextColor] = useState('#ffffff');
  const [textAlign, setTextAlign] = useState('center');
  const [showDraggableText, setShowDraggableText] = useState(true);
  const [selectedFont, setSelectedFont] = useState('Pretendard');
  const [textSpacing, setTextSpacing] = useState(0);
  const [thumbnails, setThumbnails] = useState([]);
  
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);
  const canvasContainerRef = useRef(null);

  // 모바일 여부를 확인하는 state 추가
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isControlPanelOpen, setIsControlPanelOpen] = useState(true);

  // 이미지 원본 크기 저장
  const [originalSize, setOriginalSize] = useState({ width: 0, height: 0 });
  
  // 드래그 상태 관리를 위한 state 추가
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const loadThumbnails = async () => {
      const loadedThumbnails = await getThumbnails();
      setThumbnails(loadedThumbnails);
    };
    
    loadThumbnails();
  }, []);

  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleImageFile(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleImageFile = (file) => {
    if (file && file.type.match('image.*')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = e.target.result;
        img.onload = () => {
          setImage(img);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    handleImageFile(file);
  };

  const drawCanvas = (canvas, img, scale = 1, shouldDrawText = true) => {
    if (!canvas || !img) return;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // 캔버스 크기로 캔버스 설정
    canvas.width = img.width;
    canvas.height = img.height;
    
    // 이미지 그리기
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    
    if (shouldDrawText) {
      textLayers.forEach(layer => {
        ctx.save();
        
        // 텍스트 설정 - 원본 크기 기준
        const fontSize = layer.fontSize;
        ctx.font = `${fontSize}px ${layer.font}`;
        ctx.fillStyle = layer.color;
        ctx.textAlign = layer.align;
        
        // 텍스트 위치 계산 - 원본 크기 기준
        let xPos;
        const yPos = (layer.position.y / 100) * canvas.height;
        
        switch (layer.align) {
          case 'left':
            xPos = canvas.width * 0.1;
            break;
          case 'right':
            xPos = canvas.width * 0.9;
            break;
          default:
            xPos = (layer.position.x / 100) * canvas.width;
        }
        
        ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
        ctx.shadowBlur = 4;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        
        ctx.fillText(layer.text, xPos, yPos);
        ctx.restore();
      });
    }
  };

  // 편집용 캔버스 업데이트
  useEffect(() => {
    if (!image || !canvasRef.current) return;
    
    const updateCanvas = () => {
      console.log('캔버스 업데이트:', {
        이미지: !!image,
        텍스트레이어: textLayers.length
      });
      
      const ctx = canvasRef.current.getContext('2d');
      
      // 캔버스 크기 설정
      canvasRef.current.width = image.width;
      canvasRef.current.height = image.height;
      
      // 이미지 그리기
      ctx.drawImage(image, 0, 0, canvasRef.current.width, canvasRef.current.height);
      
      // 텍스트 레이어 그리기
      textLayers.forEach(layer => {
        ctx.save();
        
        const fontSize = layer.fontSize;
        ctx.font = `${fontSize}px ${layer.font}`;
        ctx.fillStyle = layer.color;
        ctx.textAlign = layer.align;
        
        let xPos;
        const yPos = (layer.position.y / 100) * canvasRef.current.height;
        
        switch (layer.align) {
          case 'left':
            xPos = canvasRef.current.width * 0.1;
            break;
          case 'right':
            xPos = canvasRef.current.width * 0.9;
            break;
          default:
            xPos = (layer.position.x / 100) * canvasRef.current.width;
        }
        
        ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
        ctx.shadowBlur = 4;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        
        ctx.fillText(layer.text, xPos, yPos);
        ctx.restore();
      });
    };

    // requestAnimationFrame 사용하여 렌더링 최적화
    const frameId = requestAnimationFrame(updateCanvas);
    return () => cancelAnimationFrame(frameId);
  }, [image, textLayers]); // 의존성 배열 최소화

  const handleSaveToStorage = async () => {
    console.log('=== 저장 시작 ===', {
      현재_텍스트레이어: textLayers
    });

    if (!canvasRef.current || !image) return;
    
    try {
      // 이미지 데이터 준비
      const maxSize = 1024 * 1024;
      let imageDataUrl = canvasRef.current.toDataURL('image/jpeg', 0.9);
      
      // 이미지 크기가 너무 크면 품질을 조정
      let quality = 0.9;
      while (imageDataUrl.length > maxSize && quality > 0.5) {
        quality -= 0.1;
        imageDataUrl = canvasRef.current.toDataURL('image/jpeg', quality);
      }

      const timestamp = Date.now().toString();
      
      // 텍스트 레이어 데이터 준비 - 기존 ID 유지하면서 필요한 속성만 저장
      const currentTextLayers = textLayers.map(layer => ({
        id: layer.id,
        text: layer.text,
        position: layer.position,
        fontSize: layer.fontSize,
        color: layer.color,
        align: layer.align,
        font: layer.font,
        textSpacing: layer.textSpacing
      }));
      
      console.log('저장할 텍스트 레이어:', {
        개수: currentTextLayers.length,
        레이어목록: currentTextLayers
      });
      
      // Firebase에 저장
      await saveTextLayers(timestamp, currentTextLayers, imageDataUrl);
      
      // 썸네일 목록 업데이트
      const loadedThumbnails = await getThumbnails();
      setThumbnails(loadedThumbnails);
      
      console.log('=== 저장 완료 ===');
      alert('이미지와 텍스트 설정이 성공적으로 저장되었습니다!');
    } catch (error) {
      console.error('저장 실패:', error);
      alert(`저장에 실패했습니다: ${error.message}`);
    }
  };

  const handleDownload = () => {
    if (!image) return;
    
    // 임시 캔버스 생성
    const downloadCanvas = document.createElement('canvas');
    downloadCanvas.width = image.width;
    downloadCanvas.height = image.height;
    
    // 원본 크기로 그리기
    drawCanvas(downloadCanvas, image, 1, true);
    
    // 이미지 품질과 크기 최적화
    const maxSize = 1024 * 1024; // 1MB
    let quality = 1.0;
    let imageDataUrl;
    
    // JPEG 형식으로 변환 (더 작은 파일 크기)
    do {
      imageDataUrl = downloadCanvas.toDataURL('image/jpeg', quality);
      quality -= 0.1;
    } while (imageDataUrl.length > maxSize && quality > 0.5); // 최소 품질은 0.5로 유지
    
    // 다운로드
    const link = document.createElement('a');
    link.download = `thumbnail_${Date.now()}.jpg`;  // 확장자를 jpg로 변경
    link.href = imageDataUrl;
    link.click();
  };

  const handleDragStart = () => {
    setTextAlign('center');
  };

  // 선택된 레이어 찾기 helper 함수
  const selectedLayer = textLayers.find(layer => layer.id === selectedLayerId);

  // 텍스트 레이어 추가
  const handleAddTextLayer = () => {
    const newLayer = {
      id: Date.now(),
      text: '새로운 텍스트',
      position: { x: 50, y: 50 },
      fontSize: 24,
      color: '#ffffff',
      align: 'center',
      font: selectedFont,
      textSpacing: 0,
    };
    console.log('새로운 텍스트 레이어 추가:', newLayer);
    setTextLayers([...textLayers, newLayer]);
    setSelectedLayerId(newLayer.id);
    console.log('텍스트 레이어 추가 및 선택 완료');
  };

  // 텍스트 레이어 삭제
  const handleDeleteTextLayer = (id) => {
    setTextLayers(textLayers.filter(layer => layer.id !== id));
    if (selectedLayerId === id) {
      setSelectedLayerId(null);
    }
  };

  // 레이어 속성 업데이트
  const updateLayerProperty = (property, value) => {
    if (!selectedLayerId) {
      console.warn('선택된 레이어가 없습니다');
      return;
    }
    
    console.log('레이어 속성 업데이트:', {
      레이어ID: selectedLayerId,
      속성: property,
      값: value
    });
    
    setTextLayers(layers => layers.map(layer => 
      layer.id === selectedLayerId 
        ? { ...layer, [property]: value }
        : layer
    ));
  };

  // 썸네일 불러오기 함수
  const handleLoadThumbnail = async (thumbnail) => {
    console.log('=== 썸네일 로드 시작 ===', {
      썸네일ID: thumbnail.id
    });

    try {
      // 먼저 모든 상태 초기화
      setSelectedLayerId(null);
      setIsDragging(false);
      setDragStart({ x: 0, y: 0 });
      
      // 이미지 로드
      const img = new Image();
      img.crossOrigin = "anonymous";
      
      await new Promise((resolve, reject) => {
        img.onload = () => {
          setImage(img);
          resolve();
        };
        img.onerror = reject;
        img.src = thumbnail.imageUrl;
      });

      // 텍스트 레이어 설정 - 선택되지 않은 상태로 초기화
      if (thumbnail.textLayers && Array.isArray(thumbnail.textLayers)) {
        const restoredLayers = thumbnail.textLayers.map(layer => ({
          ...layer,
          id: layer.id,
          isSelected: false  // 초기에는 선택되지 않은 상태
        }));
        
        console.log('텍스트 레이어 복원:', {
          개수: restoredLayers.length,
          레이어목록: restoredLayers
        });
        
        setTextLayers(restoredLayers);
      } else {
        setTextLayers([]);
      }
      
      console.log('=== 썸네일 로드 완료 ===');

    } catch (error) {
      console.error('썸네일 로드 오류:', error);
      alert('썸네일을 불러오는데 실패했습니다. 다시 시도해주세요.');
    }
  };

  // 이미지 로드 시 원본 크기 저장
  const handleImageLoad = (img) => {
    setOriginalSize({
      width: img.width,
      height: img.height
    });
    setImage(img);
  };
  
  // 모든 좌표 계산을 원본 이미지 크기 기준으로 처리
  const convertToImageCoordinates = (screenX, screenY) => {
    if (!canvasRef.current || !originalSize.width) return { x: screenX, y: screenY };
    
    const scale = canvasRef.current.width / originalSize.width;
    return {
      x: screenX / scale,
      y: screenY / scale
    };
  };

  // 마우스 이벤트 핸들러 수정
  const handleCanvasMouseDown = (e) => {
    if (!canvasRef.current) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    
    // 클릭한 위치에 있는 텍스트 레이어 찾기
    const clickedLayer = textLayers.find(layer => {
      const layerX = layer.position.x;
      const layerY = layer.position.y;
      
      // 클릭 영역 계산 (텍스트 주변 20px)
      const clickArea = 20;
      return Math.abs(x - layerX) < clickArea && Math.abs(y - layerY) < clickArea;
    });
    
    if (clickedLayer) {
      setSelectedLayerId(clickedLayer.id);
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  const handleCanvasMouseMove = (e) => {
    if (!isDragging || !selectedLayerId || !canvasRef.current) return;
    
    const rect = canvasRef.current.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    
    setTextLayers(layers => layers.map(layer => {
      if (layer.id === selectedLayerId) {
        return {
          ...layer,
          position: {
            x: Math.max(0, Math.min(100, x)),
            y: Math.max(0, Math.min(100, y))
          }
        };
      }
      return layer;
    }));
  };

  const handleCanvasMouseUp = () => {
    setIsDragging(false);
  };

  // canvas 엘리먼트에 이벤트 리스너 추가
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    canvas.addEventListener('mousemove', handleCanvasMouseMove);
    canvas.addEventListener('mouseup', handleCanvasMouseUp);
    canvas.addEventListener('mouseleave', handleCanvasMouseUp);
    
    return () => {
      canvas.removeEventListener('mousemove', handleCanvasMouseMove);
      canvas.removeEventListener('mouseup', handleCanvasMouseUp);
      canvas.removeEventListener('mouseleave', handleCanvasMouseUp);
    };
  }, [isDragging, selectedLayerId, dragStart]);

  return (
    <div style={{ 
      padding: isMobile ? '10px' : '20px',
      maxWidth: '1200px',
      margin: '0 auto',
      fontFamily: 'Pretendard'
    }}>
      <div style={{
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: isMobile ? '10px' : '20px'
      }}>
        <div>
          <div
            ref={canvasContainerRef}
            data-canvas-container
            style={{
              position: 'relative',
              border: '2px dashed #ccc',
              borderRadius: '5px',
              padding: isMobile ? '10px' : '20px',
              textAlign: 'center',
              marginBottom: '10px',
              minHeight: isMobile ? '200px' : '300px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              maxWidth: '100%',
              overflowX: 'hidden'
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            {!image ? (
              <>
                <p>이미지를 드래그하여 놓거나 클릭하여 선택하세요</p>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileSelect}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                />
                <button
                  onClick={() => fileInputRef.current.click()}
                  style={{
                    padding: '10px 20px',
                    background: '#946A6A',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    fontFamily: 'Pretendard'
                  }}
                >
                  이미지 선택
                </button>
              </>
            ) : (
              <>
                <div style={{ 
                  position: 'relative',
                  width: '100%',
                  maxWidth: '800px',
                  margin: '0 auto',
                  aspectRatio: `${image.width} / ${image.height}`,
                  backgroundColor: '#000',
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  overflow: 'hidden'
                }}>
                  <canvas
                    ref={canvasRef}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      cursor: isDragging ? 'grabbing' : (selectedLayerId ? 'grab' : 'default')
                    }}
                    onMouseDown={handleCanvasMouseDown}
                    onMouseMove={handleCanvasMouseMove}
                    onMouseUp={handleCanvasMouseUp}
                    onMouseLeave={handleCanvasMouseUp}
                  />
                </div>

                <div style={{
                  textAlign: 'center',
                  marginTop: '10px',
                  color: '#666',
                  fontSize: '14px'
                }}>
                  원본 크기: {image.width} x {image.height}px
                </div>
              </>
            )}
          </div>
          
          {image && (
            <div style={{ 
              display: 'flex', 
              gap: '10px', 
              marginTop: '10px',
              flexDirection: isMobile ? 'column' : 'row'
            }}>
              <button
                onClick={handleSaveToStorage}
                style={{
                  padding: '10px 20px',
                  background: '#946A6A',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  flex: 1,
                  fontFamily: 'Pretendard',
                  width: '100%'
                }}
              >
                저장하기
              </button>
              <button
                onClick={handleDownload}
                style={{
                  padding: '10px 20px',
                  background: '#6A6A94',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  flex: 1,
                  fontFamily: 'Pretendard',
                  width: '100%'
                }}
              >
                다운로드
              </button>
            </div>
          )}
        </div>

        <div style={{ 
          padding: '20px', 
          background: '#f5f5f5', 
          borderRadius: '5px',
          width: '100%',
          maxWidth: '100%',
          boxSizing: 'border-box'
        }}>
          <ThumbnailList 
            thumbnails={thumbnails}
            onThumbnailLoad={handleLoadThumbnail}
            onThumbnailsUpdate={async () => {
              const loadedThumbnails = await getThumbnails();
              setThumbnails(loadedThumbnails);
            }}
          />

          {image && (
            <>
              {isMobile ? (
                <div>
                  <button
                    onClick={() => setIsControlPanelOpen(!isControlPanelOpen)}
                    style={{
                      width: '100%',
                      padding: '10px',
                      background: '#946A6A',
                      color: 'white',
                      border: 'none',
                      borderRadius: '5px',
                      marginBottom: '10px',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontFamily: 'Pretendard'
                    }}
                  >
                    <span>텍스트 편집</span>
                    <span>{isControlPanelOpen ? '▼' : '▲'}</span>
                  </button>

                  {isControlPanelOpen && (
                    <div style={{ 
                      padding: '15px', 
                      background: '#fff', 
                      borderRadius: '5px',
                      marginBottom: '10px'
                    }}>
                      <TextLayerManager
                        textLayers={textLayers}
                        onLayerSelect={setSelectedLayerId}
                        onLayerDelete={handleDeleteTextLayer}
                        onLayerAdd={handleAddTextLayer}
                      />

                      {selectedLayer && (
                        <>
                          <div style={{ marginBottom: '15px' }}>
                            <label>텍스트 내용:</label>
                            <input
                              type="text"
                              value={selectedLayer.text}
                              onChange={(e) => updateLayerProperty('text', e.target.value)}
                              style={{ 
                                width: '100%', 
                                padding: '5px', 
                                marginTop: '5px',
                                fontFamily: 'Pretendard'
                              }}
                            />
                          </div>

                          <div style={{ marginBottom: '15px' }}>
                            <label>텍스트 정렬:</label>
                            <div style={{ 
                              display: 'flex', 
                              gap: '10px',
                              marginTop: '5px'
                            }}>
                              <button
                                onClick={() => updateLayerProperty('align', 'left')}
                                style={{
                                  padding: '5px 10px',
                                  background: selectedLayer.align === 'left' ? '#946A6A' : '#ddd',
                                  color: selectedLayer.align === 'left' ? 'white' : 'black',
                                  border: 'none',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                  flex: 1,
                                  fontFamily: 'Pretendard'
                                }}
                              >
                                왼쪽
                              </button>
                              <button
                                onClick={() => updateLayerProperty('align', 'center')}
                                style={{
                                  padding: '5px 10px',
                                  background: selectedLayer.align === 'center' ? '#946A6A' : '#ddd',
                                  color: selectedLayer.align === 'center' ? 'white' : 'black',
                                  border: 'none',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                  flex: 1,
                                  fontFamily: 'Pretendard'
                                }}
                              >
                                가운데
                              </button>
                              <button
                                onClick={() => updateLayerProperty('align', 'right')}
                                style={{
                                  padding: '5px 10px',
                                  background: selectedLayer.align === 'right' ? '#946A6A' : '#ddd',
                                  color: selectedLayer.align === 'right' ? 'white' : 'black',
                                  border: 'none',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                  flex: 1,
                                  fontFamily: 'Pretendard'
                                }}
                              >
                                오른쪽
                              </button>
                            </div>
                          </div>

                          <div style={{ marginBottom: '15px' }}>
                            <label>가로 간격 ({selectedLayer.textSpacing}px):</label>
                            <input
                              type="range"
                              min="-50"
                              max="50"
                              value={selectedLayer.textSpacing}
                              onChange={(e) => updateLayerProperty('textSpacing', parseInt(e.target.value))}
                              style={{ width: '100%' }}
                            />
                          </div>

                          <div style={{ marginBottom: '15px' }}>
                            <label>글자 크기 ({selectedLayer.fontSize}px):</label>
                            <input
                              type="range"
                              min="12"
                              max="500"
                              value={selectedLayer.fontSize}
                              onChange={(e) => updateLayerProperty('fontSize', parseInt(e.target.value))}
                              style={{ width: '100%' }}
                            />
                          </div>

                          <div style={{ marginBottom: '15px' }}>
                            <label>글자 색상:</label>
                            <input
                              type="color"
                              value={selectedLayer.color}
                              onChange={(e) => updateLayerProperty('color', e.target.value)}
                              style={{ width: '100%', height: '40px', padding: '0' }}
                            />
                          </div>

                          <FontSelector 
                            selectedFont={selectedLayer.font}
                            onFontChange={(font) => updateLayerProperty('font', font)}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <TextLayerManager
                    textLayers={textLayers}
                    onLayerSelect={setSelectedLayerId}
                    onLayerDelete={handleDeleteTextLayer}
                    onLayerAdd={handleAddTextLayer}
                  />

                  {selectedLayer && (
                    <>
                      <div style={{ marginBottom: '15px' }}>
                        <label>텍스트 내용:</label>
                        <input
                          type="text"
                          value={selectedLayer.text}
                          onChange={(e) => updateLayerProperty('text', e.target.value)}
                          style={{ 
                            width: '100%', 
                            padding: '5px', 
                            marginTop: '5px',
                            fontFamily: 'Pretendard'
                          }}
                        />
                      </div>

                      <div style={{ marginBottom: '15px' }}>
                        <label>텍스트 정렬:</label>
                        <div style={{ 
                          display: 'flex', 
                          gap: '10px',
                          marginTop: '5px'
                        }}>
                          <button
                            onClick={() => updateLayerProperty('align', 'left')}
                            style={{
                              padding: '5px 10px',
                              background: selectedLayer.align === 'left' ? '#946A6A' : '#ddd',
                              color: selectedLayer.align === 'left' ? 'white' : 'black',
                              border: 'none',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              flex: 1,
                              fontFamily: 'Pretendard'
                            }}
                          >
                            왼쪽
                          </button>
                          <button
                            onClick={() => updateLayerProperty('align', 'center')}
                            style={{
                              padding: '5px 10px',
                              background: selectedLayer.align === 'center' ? '#946A6A' : '#ddd',
                              color: selectedLayer.align === 'center' ? 'white' : 'black',
                              border: 'none',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              flex: 1,
                              fontFamily: 'Pretendard'
                            }}
                          >
                            가운데
                          </button>
                          <button
                            onClick={() => updateLayerProperty('align', 'right')}
                            style={{
                              padding: '5px 10px',
                              background: selectedLayer.align === 'right' ? '#946A6A' : '#ddd',
                              color: selectedLayer.align === 'right' ? 'white' : 'black',
                              border: 'none',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              flex: 1,
                              fontFamily: 'Pretendard'
                            }}
                          >
                            오른쪽
                          </button>
                        </div>
                      </div>

                      <div style={{ marginBottom: '15px' }}>
                        <label>가로 간격 ({selectedLayer.textSpacing}px):</label>
                        <input
                          type="range"
                          min="-50"
                          max="50"
                          value={selectedLayer.textSpacing}
                          onChange={(e) => updateLayerProperty('textSpacing', parseInt(e.target.value))}
                          style={{ width: '100%' }}
                        />
                      </div>

                      <div style={{ marginBottom: '15px' }}>
                        <label>글자 크기 ({selectedLayer.fontSize}px):</label>
                        <input
                          type="range"
                          min="12"
                          max="500"
                          value={selectedLayer.fontSize}
                          onChange={(e) => updateLayerProperty('fontSize', parseInt(e.target.value))}
                          style={{ width: '100%' }}
                        />
                      </div>

                      <div style={{ marginBottom: '15px' }}>
                        <label>글자 색상:</label>
                        <input
                          type="color"
                          value={selectedLayer.color}
                          onChange={(e) => updateLayerProperty('color', e.target.value)}
                          style={{ width: '100%', height: '40px', padding: '0' }}
                        />
                      </div>

                      <FontSelector 
                        selectedFont={selectedLayer.font}
                        onFontChange={(font) => updateLayerProperty('font', font)}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThumbnailEditor; 