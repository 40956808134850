import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>서비스 이용약관</h1>
      <p>
        본 약관은 AMARE DESIGN(이하 "회사")이 제공하는 서비스의 이용 조건과 절차, 회사와 회원 간의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.
      </p>
      {/* 여기에 더 자세한 서비스 이용약관 내용을 추가하세요 */}
      <Link to="/">홈으로 돌아가기</Link>
    </div>
  );
};

export default TermsOfService;
