import React, { useState } from 'react';
import './MaterialModal.css';

const MaterialModal = ({ onClose, onSave }) => {
  const [location, setLocation] = useState('');
  const [materialType, setMaterialType] = useState('');
  const [materialName, setMaterialName] = useState('');
  const [customLocation, setCustomLocation] = useState('');
  const [isCustomLocation, setIsCustomLocation] = useState(false);

  const locations = ['거실', '주방', '침실1', '침실2', '욕실', '직접입력'];
  const materialTypes = ['벽지', '마루', '타일', '도어', '몰딩', '시트필름'];
  const materialNames = {
    '벽지': ['실크', '합지', '방염', '직접입력'],
    '마루': ['강마루', '원목마루', '직접입력'],
    '타일': ['포세린', '세라믹', '모자이크', '직접입력'],
    '도어': ['ABS도어', '시트도어', '직접입력'],
    '몰딩': ['천장몰딩', '걸레받이', '직접입력'],
    '시트필름': ['일반필름', '방염필름', '직접입력']
  };

  const handleSave = () => {
    const materialData = {
      location: isCustomLocation ? customLocation : location,
      materialType,
      materialName,
      createdAt: new Date()
    };
    onSave(materialData);
    onClose();
  };

  const handleLocationChange = (value) => {
    if (value === '직접입력') {
      setIsCustomLocation(true);
      setLocation('');
    } else {
      setIsCustomLocation(false);
      setLocation(value);
    }
  };

  return (
    <div className="material-modal-overlay" onClick={onClose}>
      <div className="material-modal-content" onClick={e => e.stopPropagation()}>
        <h2>자재 표시</h2>
        
        <div className="material-input-group">
          <label>위치</label>
          {isCustomLocation ? (
            <input
              type="text"
              value={customLocation}
              onChange={(e) => setCustomLocation(e.target.value)}
              placeholder="위치를 입력하세요"
            />
          ) : (
            <select
              value={location}
              onChange={(e) => handleLocationChange(e.target.value)}
            >
              <option value="">선택하세요</option>
              {locations.map(loc => (
                <option key={loc} value={loc}>{loc}</option>
              ))}
            </select>
          )}
        </div>

        <div className="material-input-group">
          <label>자재 종류</label>
          <select
            value={materialType}
            onChange={(e) => setMaterialType(e.target.value)}
          >
            <option value="">선택하세요</option>
            {materialTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        </div>

        <div className="material-input-group">
          <label>자재 이름</label>
          <select
            value={materialName}
            onChange={(e) => setMaterialName(e.target.value)}
            disabled={!materialType}
          >
            <option value="">선택하세요</option>
            {materialType && materialNames[materialType]?.map(name => (
              <option key={name} value={name}>{name}</option>
            ))}
          </select>
        </div>

        <div className="material-modal-buttons">
          <button onClick={handleSave} disabled={!location || !materialType || !materialName}>
            저장
          </button>
          <button onClick={onClose}>취소</button>
        </div>
      </div>
    </div>
  );
};

export default MaterialModal; 