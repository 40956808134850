import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaVideo } from 'react-icons/fa';

const EstimateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/>
  </svg>
);

const AddressIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
  </svg>
);

const CostCalculationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 14h-2v-2h2v2zm0-4h-2V7h2v6z"/>
  </svg>
);

const PaymentHistoryIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M4 10h3v7H4zm6.5 0h3v7h-3zM2 19h20v-3H2zm15-9h3v7h-3zm-5-9L2 6v2h20V6z"/>
  </svg>
);

const ConstructionGuideIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M12 2L1 21h22L12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
  </svg>
);

const ContractIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
  </svg>
);

const AreaCalculationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3zM3 9l2.3-2.3 2.87-2.89 1.42-1.42L6.7 5.3 9 3H3zm6 12l-2.3-2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6z"/>
  </svg>
);

const WindowSizeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zm-4-4h-4v-2h4v-2h-4V9h4V7H9v10h6z"/>
  </svg>
);

const BrickCountIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 16H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V9h12v2zm0-4H6V5h12v2z"/>
  </svg>
);

const SNSImageEditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/>
  </svg>
);

const InstagramAutoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2zm-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25zM12 7a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"/>
  </svg>
);

const OngoingAddressesToggleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M4 14h4v-4H4v4zm0 5h4v-4H4v4zM4 9h4V5H4v4zm5 5h12v-4H9v4zm0 5h12v-4H9v4zM9 5v4h12V5H9z"/>
  </svg>
);

// 이미지 분류 아이콘 추가
const ImageClassificationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/>
  </svg>
);

// 거래처 가격입력 아이콘 추가
const VendorPriceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"/>
  </svg>
);

// API 설정 아이콘 추가
const ApiSettingsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z"/>
  </svg>
);

// 상세 견적서 아이콘 컴포넌트 추가
const DetailedEstimateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
  </svg>
);

// 견문의 아이콘 추가
const EstimateInquiryIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"/>
  </svg>
);

// 견적문의 내역 아이콘 추가
const EstimateInquiryListIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"/>
  </svg>
);

// VideoMakerIcon 컴포넌트 추가
const VideoMakerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4zM14 13h-3v3H9v-3H6v-2h3V8h2v3h3v2z"/>
  </svg>
);

// ThumbnailEditorIcon 컴포넌트 추가
const ThumbnailEditorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-3 10h-3v3h-2v-3H8v-2h3V8h2v3h3v2z"/>
  </svg>
);

// PDF 추출 아이콘 컴포넌트 추가 (MainScreen.js 파일의 다른 아이콘 컴포넌트들 근처에 추가)
const PdfExtractIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"/>
  </svg>
);

// DatabaseIcon 컴포넌트 추가 (다른 아이콘 컴포넌트들 근처에 추가)
const DatabaseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M20 13H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1zm0-10H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1z"/>
  </svg>
);

// AutoScheduleIcon 컴포넌트 추가
const AutoScheduleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/>
  </svg>
);

// ContractorManagementIcon 컴포넌트 추가
const ContractorManagementIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
  </svg>
);

// AutoProposalIcon 컴포넌트 추가
const AutoProposalIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/>
  </svg>
);

// SketchupLicenseIcon 컴포넌트 수정
const SketchupLicenseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z"/>
  </svg>
);

// 작업지시서 아이콘 컴포넌트 추가
const WorkOrderIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
  </svg>
);

const MainScreen = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === process.env.REACT_APP_ADMIN_PASSWORD || password === '1') {
      setIsAuthenticated(true);
      setError('');
    } else {
      setError('비밀번호가 올바르지 않습니다.');
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="main-screen" style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100vh',
        WebkitTapHighlightColor: 'transparent',
        userSelect: 'none'
      }}>
        <h1 className="main-title" style={{ userSelect: 'none' }}>AMARE DESIGN</h1>
        <form onSubmit={handlePasswordSubmit} style={{ textAlign: 'center' }}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="비밀번호를 입력하세요"
            style={{
              padding: '10px',
              marginBottom: '10px',
              borderRadius: '5px',
              border: '1px solid #ddd',
              outline: 'none',
              WebkitAppearance: 'none'
            }}
            autoComplete="off"
          />
          <br />
          <button 
            type="submit"
            style={{
              padding: '10px 20px',
              backgroundColor: '#946A6A',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontWeight: 'bold',
              userSelect: 'none',
              WebkitTapHighlightColor: 'transparent'
            }}
          >
            입력
          </button>
          {error && <p style={{ color: 'red', userSelect: 'none' }}>{error}</p>}
        </form>
      </div>
    );
  }

  return (
    <div className="main-screen" style={{ 
      WebkitTapHighlightColor: 'transparent',
      userSelect: 'none'
    }}>
      <h1 className="main-title" style={{ userSelect: 'none' }}>AMARE DESIGN</h1>
      <div className="app-grid">
        <Link to="/estimate" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <EstimateIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>1분견적</span>
        </Link>
        <Link to="/address-management" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <AddressIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>현장주소관리</span>
        </Link>
        <Link to="/cost-calculation" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <CostCalculationIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>현장별 원가계산</span>
        </Link>
        <Link to="/payment-history" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <PaymentHistoryIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>결제 내역서</span>
        </Link>
        <Link to="/construction-guide" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <ConstructionGuideIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>공사 안내문</span>
        </Link>
        <Link to="/contract" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <ContractIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>공사 계약서</span>
        </Link>
        <Link to="/area-calculation" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <AreaCalculationIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>평수 구하기</span>
        </Link>
        <Link to="/window-size" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <WindowSizeIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>샷시 사이즈</span>
        </Link>
        <Link to="/brick-count" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <BrickCountIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>벽돌 개수</span>
        </Link>
        <Link to="/sns-image-edit" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <SNSImageEditIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>SNS 이미지 편집</span>
        </Link>
        <Link to="/instagram-auto" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <InstagramAutoIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>Instagram Auto Reservation</span>
        </Link>
        <Link to="/ongoing-addresses" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <OngoingAddressesToggleIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>진행중 현장</span>
        </Link>
        <Link to="/image-classification" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <ImageClassificationIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>이미지 분류</span>
        </Link>
        <Link to="/vendor-price" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <VendorPriceIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>거래처 가격입력</span>
        </Link>
        <Link to="/api-settings" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <ApiSettingsIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>API 설정</span>
        </Link>
        <Link to="/detailed-estimate" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <DetailedEstimateIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>상세 견적서</span>
        </Link>
        <Link to="/estimate-inquiry" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <EstimateInquiryIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>견적문의</span>
        </Link>
        <Link to="/estimate-inquiry-list" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <EstimateInquiryListIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>견적문의 내역</span>
        </Link>
        <Link to="/video-maker" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <VideoMakerIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>영상 만들기</span>
        </Link>
        <Link to="/thumbnail-editor" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <ThumbnailEditorIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>썸네일 편집</span>
        </Link>
        <Link to="/pdf-extract" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <PdfExtractIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>PDF 추출</span>
        </Link>
        <Link to="/database" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <DatabaseIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>데이터베이스</span>
        </Link>
        <Link to="/auto-schedule" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <AutoScheduleIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>자동 공사 스케쥴</span>
        </Link>
        <Link to="/contractor-management" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <ContractorManagementIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>거래처 관리</span>
        </Link>
        <Link to="/auto-proposal" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <AutoProposalIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>자동제안서</span>
        </Link>
        <Link to="/sketchup-license" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <SketchupLicenseIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>스케치업 라이센스</span>
        </Link>
        <Link to="/work-order" className="app-icon" style={{ 
          WebkitTapHighlightColor: 'transparent',
          userSelect: 'none',
          outline: 'none'
        }}>
          <div className="icon-circle">
            <WorkOrderIcon />
          </div>
          <span className="app-name" style={{ userSelect: 'none' }}>작업지시서</span>
        </Link>
      </div>
    </div>
  );
};

export default MainScreen;
