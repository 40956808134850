import React from 'react';
import './InterimSave.css';
import VersionManager from './VersionManager';

const InterimSave = ({
  processData,
  selectedAddress,
  currentFilters,
  notes,
  currentVersion,
  hiddenProcesses
}) => {
  const handleInterimSave = async () => {
    try {
      console.group('🔄 중간 저장 시도');
      console.log('선택된 주소:', selectedAddress);
      console.log('현재 버전:', currentVersion);
      
      // 숨긴 공정 상태 로깅 강화
      console.group('📋 숨긴 공정 상태 상세 로깅');
      if (hiddenProcesses) {
        console.log('숨긴 공정 상태 존재:', true);
        console.log('숨긴 공정 데이터:', JSON.stringify(hiddenProcesses, null, 2));
        console.log('숨긴 공정 ID 목록:', Object.keys(hiddenProcesses));
        console.log('숨긴 공정 개수:', Object.keys(hiddenProcesses).length);
        Object.entries(hiddenProcesses).forEach(([processId, status]) => {
          console.log(`공정 ID ${processId}:`, {
            hidden: status.hidden,
            type: status.type
          });
        });
      } else {
        console.log('숨긴 공정 상태 없음');
      }
      console.groupEnd();

      if (!selectedAddress || !currentVersion) {
        console.log('❌ 주소 또는 현재 버전 정보 없음');
        console.groupEnd();
        alert('주소와 현재 버전 정보가 필요합니다.');
        return;
      }

      // 현재 프로세스 순서 수집
      const currentProcessOrder = processData.reduce((acc, process) => {
        acc[process.id] = process.items.map(item => item.uniqueId);
        return acc;
      }, {});

      // 필터 데이터 구조 변환
      const filters = {
        size: currentFilters.selectedSize,
        grade: currentFilters.selectedGrade,
        extra: currentFilters.selectedExtra,
        window: currentFilters.selectedWindow,
        painting: currentFilters.selectedPainting,
        furniture: currentFilters.selectedFurniture,
        ac: currentFilters.selectedAC,
        design: currentFilters.selectedDesign,
        floor_tile: currentFilters.selectedFloorTile
      };

      // 업데이트할 버전 데이터 생성
      const versionData = {
        ...currentVersion,
        data: {
          filters: filters,
          notes: notes,
          processData: processData,
          processOrder: currentProcessOrder
        },
        hiddenProcesses: hiddenProcesses || {}
      };
      
      // 저장할 데이터 로깅
      console.group('💾 저장할 버전 데이터 상세');
      console.log('전체 데이터 구조:', {
        ...versionData,
        hiddenProcesses: JSON.stringify(versionData.hiddenProcesses, null, 2)
      });
      console.log('숨긴 공정 데이터 (저장 전):', versionData.hiddenProcesses);
      console.groupEnd();

      // 중간 저장 실행
      console.log('VersionManager.saveInterimVersion 호출...');
      const savedVersion = await VersionManager.saveInterimVersion(selectedAddress, versionData);
      
      // 저장 결과 확인
      console.group('🎉 중간 저장 완료');
      console.log('저장된 버전:', savedVersion);
      console.log('저장된 숨긴 공정 데이터:', savedVersion.hiddenProcesses);
      console.groupEnd();
      
      alert('중간 저장이 완료되었습니다.');
    } catch (error) {
      console.error('❌ 중간 저장 중 오류:', error);
      console.error('오류 상세:', {
        name: error.name,
        message: error.message,
        stack: error.stack
      });
      console.groupEnd();
      alert(`중간 저장 중 오류가 발생했습니다: ${error.message}`);
    }
  };

  return (
    <button
      className="interim-save-button"
      onClick={handleInterimSave}
      disabled={!selectedAddress || !currentVersion}
    >
      중간 저장
    </button>
  );
};

export default InterimSave; 