import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, updateDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import './ProcessManagementModal.css';

const ProcessManagementModal = ({ isOpen, onClose, processes, setProcesses, scheduleInputs, setScheduleInputs }) => {
  const [newProcessName, setNewProcessName] = useState('');
  const [error, setError] = useState('');
  const [editingProcess, setEditingProcess] = useState(null);
  const [editingName, setEditingName] = useState('');
  const [editingNumber, setEditingNumber] = useState('');

  // Firebase 스케줄 데이터 업데이트
  const updateProcessesInFirebase = async () => {
    try {
      console.log('=== Firebase 업데이트 시작 ===');
      console.log('현재 processes:', processes);
      
      const schedulesRef = collection(db, 'schedules');
      const schedulesSnapshot = await getDocs(schedulesRef);
      
      // 공정 목록 저장
      const processesRef = doc(db, 'settings', 'processes');
      const processList = Object.keys(processes);
      console.log('저장할 공정 목록:', processList);

      await setDoc(processesRef, {
        processList: processList,
        updatedAt: new Date()
      });
      
      console.log('processes 저장 완료');
      
      // 스케줄 데이터 업데이트
      const updatePromises = schedulesSnapshot.docs.map(async (doc) => {
        const scheduleData = doc.data();
        if (!scheduleData || !scheduleData.processDays) {
          console.log('스케줄 데이터 또는 processDays가 없음:', doc.id);
          return;
        }

        const newProcessDays = {};
        processList.forEach(key => {
          if (scheduleData.processDays[key]) {
            newProcessDays[key] = scheduleData.processDays[key];
          }
        });
        
        await updateDoc(doc.ref, {
          processDays: newProcessDays
        });
      });

      await Promise.all(updatePromises.filter(Boolean));
      console.log('=== Firebase 업데이트 완료 ===');
    } catch (error) {
      console.error('Firebase 업데이트 실패:', error);
      setError('데이터 저장 중 오류가 발생했습니다.');
    }
  };

  // 컴포넌트 마운트 시 공정 목록 불러오기
  useEffect(() => {
    const loadProcesses = async () => {
      try {
        console.log('공정 목록 불러오기 시작');
        const processesRef = doc(db, 'settings', 'processes');
        const processesDoc = await getDoc(processesRef);
        
        if (processesDoc.exists() && Object.keys(processes).length === 0) {  // processes가 비어있을 때만 로드
          const savedProcessList = processesDoc.data().processList;
          console.log('저장된 공정 목록:', savedProcessList);
          
          // processes 객체 재정렬
          const sortedProcesses = savedProcessList.reduce((acc, key) => {
            if (processes[key]) {
              acc[key] = processes[key];
            }
            return acc;
          }, {});
          
          // 기존 processes에 있지만 savedProcessList에 없는 항목들 추가
          Object.keys(processes).forEach(key => {
            if (!sortedProcesses[key]) {
              sortedProcesses[key] = processes[key];
            }
          });
          
          console.log('정렬된 processes:', sortedProcesses);
          setProcesses(sortedProcesses);

          // scheduleInputs도 업데이트
          setScheduleInputs(prev =>
            prev.map(input => ({
              ...input,
              processDays: Object.keys(sortedProcesses).reduce((acc, key) => {
                acc[key] = input.processDays[key] || sortedProcesses[key];
                return acc;
              }, {})
            }))
          );
        }
      } catch (error) {
        console.error('공정 목록 불러오기 실패:', error);
      }
    };

    if (isOpen) {
      loadProcesses();
    }
  }, []); // isOpen 의존성 제거

  // 공정 순서 변경 함수
  const moveProcess = async (processName, direction) => {
    console.log('=== moveProcess 시작 ===');
    console.log('현재 processes:', processes);
    console.log('이동할 공정:', processName);
    console.log('이동 방향:', direction);

    const processList = Object.keys(processes);
    const currentIndex = processList.indexOf(processName);
    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

    console.log('현재 인덱스:', currentIndex);
    console.log('새 인덱스:', newIndex);

    if (newIndex < 0 || newIndex >= processList.length) return;

    try {
      // 순서 변경
      const newProcessList = [...processList];
      [newProcessList[currentIndex], newProcessList[newIndex]] = 
      [newProcessList[newIndex], newProcessList[currentIndex]];

      console.log('변경된 공정 목록:', newProcessList);

      // 새로운 순서로 processes 객체 재생성
      const newProcesses = {};
      newProcessList.forEach(key => {
        newProcesses[key] = processes[key];
      });

      console.log('새로운 processes:', newProcesses);

      // Firebase 업데이트
      const processesRef = doc(db, 'settings', 'processes');
      await setDoc(processesRef, {
        processList: newProcessList,
        updatedAt: new Date()
      });

      // 스케줄 데이터 업데이트
      const schedulesRef = collection(db, 'schedules');
      const schedulesSnapshot = await getDocs(schedulesRef);
      
      const updatePromises = schedulesSnapshot.docs.map(async (doc) => {
        const scheduleData = doc.data();
        if (!scheduleData || !scheduleData.processDays) {
          console.log('스케줄 데이터 또는 processDays가 없음:', doc.id);
          return;
        }

        const newProcessDays = {};
        newProcessList.forEach(key => {
          if (scheduleData.processDays[key]) {
            newProcessDays[key] = scheduleData.processDays[key];
          }
        });
        
        await updateDoc(doc.ref, {
          processDays: newProcessDays
        });
      });

      await Promise.all(updatePromises.filter(Boolean));

      // Firebase 업데이트가 완료된 후 상태 업데이트
      setProcesses(newProcesses);

      // scheduleInputs 업데이트
      setScheduleInputs(prev =>
        prev.map(input => {
          if (!input.processDays) return input;
          
          const newProcessDays = {};
          newProcessList.forEach(key => {
            if (input.processDays[key]) {
              newProcessDays[key] = input.processDays[key];
            }
          });

          return {
            ...input,
            processDays: newProcessDays
          };
        })
      );

      console.log('=== moveProcess 완료 ===');
    } catch (error) {
      console.error('공정 순서 변경 실패:', error);
      setError('공정 순서 변경 중 오류가 발생했습니다.');
    }
  };

  // 공정명 수정 시작
  const startEditing = (processName, index) => {
    setEditingProcess(processName);
    setEditingName(processName);
    setEditingNumber(String(index + 1));
    setError('');
  };

  // 공정명 수정 취소
  const cancelEditing = () => {
    setEditingProcess(null);
    setEditingName('');
    setEditingNumber('');
    setError('');
  };

  // 공정명 수정 저장
  const saveEditing = async () => {
    if (!editingName.trim()) {
      setError('공정명을 입력해주세요.');
      return;
    }

    if (editingName !== editingProcess && processes[editingName]) {
      setError('이미 존재하는 공정명입니다.');
      return;
    }

    const newNumber = parseInt(editingNumber);
    if (isNaN(newNumber) || newNumber < 1 || newNumber > Object.keys(processes).length) {
      setError('올바른 공정 번호를 입력해주세요.');
      return;
    }

    try {
      // processes 객체의 키 배열 가져오기
      const processList = Object.keys(processes);
      const oldIndex = processList.indexOf(editingProcess);
      const newIndex = newNumber - 1;

      // 순서 변경을 위해 배열 재정렬
      const reorderedList = [...processList];
      
      // 이전 위치의 항목 제거
      reorderedList.splice(oldIndex, 1);
      
      // 새로운 위치에 삽입
      reorderedList.splice(newIndex, 0, editingProcess);

      // 이름 변경이 있는 경우 처리
      let newProcesses = {};
      if (editingName !== editingProcess) {
        // 새로운 processes 객체 생성
        reorderedList.forEach(name => {
          if (name === editingProcess) {
            newProcesses[editingName] = processes[editingProcess];
          } else {
            newProcesses[name] = processes[name];
          }
        });
      } else {
        // 순서만 변경하는 경우
        reorderedList.forEach(name => {
          newProcesses[name] = processes[name];
        });
      }

      // Firebase 업데이트
      try {
        console.log('=== Firebase 업데이트 시작 ===');
        const processesRef = doc(db, 'settings', 'processes');
        const finalProcessList = Object.keys(newProcesses);
        
        await setDoc(processesRef, {
          processList: finalProcessList,
          updatedAt: new Date()
        });
        
        // 스케줄 데이터 업데이트
        const schedulesRef = collection(db, 'schedules');
        const schedulesSnapshot = await getDocs(schedulesRef);
        
        const updatePromises = schedulesSnapshot.docs.map(async (doc) => {
          const scheduleData = doc.data();
          if (!scheduleData || !scheduleData.processDays) {
            return;
          }

          const newProcessDays = {};
          finalProcessList.forEach(key => {
            const originalKey = key === editingName ? editingProcess : key;
            if (scheduleData.processDays[originalKey]) {
              newProcessDays[key] = scheduleData.processDays[originalKey];
            }
          });
          
          await updateDoc(doc.ref, {
            processDays: newProcessDays
          });
        });

        await Promise.all(updatePromises.filter(Boolean));
        console.log('=== Firebase 업데이트 완료 ===');
      } catch (error) {
        console.error('Firebase 업데이트 실패:', error);
        throw error;
      }

      // 상태 업데이트
      setProcesses(newProcesses);

      // scheduleInputs 업데이트
      setScheduleInputs(prev =>
        prev.map(input => {
          const newProcessDays = {};
          Object.keys(newProcesses).forEach(key => {
            const originalKey = key === editingName ? editingProcess : key;
            if (input.processDays[originalKey]) {
              newProcessDays[key] = input.processDays[originalKey];
            }
          });
          return {
            ...input,
            processDays: newProcessDays
          };
        })
      );

      setEditingProcess(null);
      setEditingName('');
      setEditingNumber('');
      setError('');
    } catch (error) {
      console.error('공정 수정 실패:', error);
      setError('공정 수정 중 오류가 발생했습니다.');
    }
  };

  // 모든 스케줄에서 특정 공정 제거
  const removeProcessFromAllSchedules = async (processName) => {
    try {
      const schedulesRef = collection(db, 'schedules');
      const schedulesSnapshot = await getDocs(schedulesRef);
      
      const updatePromises = schedulesSnapshot.docs.map(async (doc) => {
        const scheduleData = doc.data();
        const { [processName]: removed, ...remainingProcesses } = scheduleData.processDays;
        
        await updateDoc(doc.ref, {
          processDays: remainingProcesses
        });
      });

      await Promise.all(updatePromises);
    } catch (error) {
      console.error('공정 제거 실패:', error);
      setError('공정 제거 중 오류가 발생했습니다.');
    }
  };

  // 공정 추가
  const addProcess = async () => {
    if (!newProcessName.trim()) {
      setError('공정명을 입력해주세요.');
      return;
    }

    if (processes[newProcessName]) {
      setError('이미 존재하는 공정명입니다.');
      return;
    }

    try {
      // 새로운 processes 객체 생성
      const newProcesses = {
        ...processes,
        [newProcessName]: { days: '1', include: true }
      };

      // processes 상태 업데이트
      setProcesses(newProcesses);
      
      // scheduleInputs 업데이트
      setScheduleInputs(prev => 
        prev.map(input => ({
          ...input,
          processDays: {
            ...input.processDays,
            [newProcessName]: { days: '1', include: true }
          }
        }))
      );
      
      // Firebase 직접 업데이트
      const processesRef = doc(db, 'settings', 'processes');
      const newProcessList = Object.keys(newProcesses);
      
      await setDoc(processesRef, {
        processList: newProcessList,
        updatedAt: new Date()
      });

      // 스케줄 데이터 업데이트
      const schedulesRef = collection(db, 'schedules');
      const schedulesSnapshot = await getDocs(schedulesRef);
      
      const updatePromises = schedulesSnapshot.docs.map(async (doc) => {
        const scheduleData = doc.data();
        if (!scheduleData || !scheduleData.processDays) {
          return;
        }

        const newProcessDays = {
          ...scheduleData.processDays,
          [newProcessName]: { days: '1', include: true }
        };
        
        await updateDoc(doc.ref, {
          processDays: newProcessDays
        });
      });

      await Promise.all(updatePromises.filter(Boolean));
      
      setNewProcessName('');
      setError('');
    } catch (error) {
      console.error('공정 추가 실패:', error);
      setError('공정 추가 중 오류가 발생했습니다.');
    }
  };

  // 공정 삭제
  const deleteProcess = async (processName) => {
    if (Object.keys(processes).length <= 1) {
      setError('최소 1개의 공정은 유지해야 합니다.');
      return;
    }

    try {
      // 새로운 processes 객체 생성
      const newProcesses = { ...processes };
      delete newProcesses[processName];

      // processes 상태 업데이트
      setProcesses(newProcesses);
      
      // scheduleInputs 업데이트
      setScheduleInputs(prev =>
        prev.map(input => {
          const newProcessDays = { ...input.processDays };
          delete newProcessDays[processName];
          return {
            ...input,
            processDays: newProcessDays
          };
        })
      );

      // Firebase 직접 업데이트
      const processesRef = doc(db, 'settings', 'processes');
      const newProcessList = Object.keys(newProcesses);
      
      await setDoc(processesRef, {
        processList: newProcessList,
        updatedAt: new Date()
      });

      // 스케줄 데이터 업데이트
      const schedulesRef = collection(db, 'schedules');
      const schedulesSnapshot = await getDocs(schedulesRef);
      
      const updatePromises = schedulesSnapshot.docs.map(async (doc) => {
        const scheduleData = doc.data();
        if (!scheduleData || !scheduleData.processDays) {
          return;
        }

        const newProcessDays = { ...scheduleData.processDays };
        delete newProcessDays[processName];
        
        await updateDoc(doc.ref, {
          processDays: newProcessDays
        });
      });

      await Promise.all(updatePromises.filter(Boolean));
      
      setError('');
    } catch (error) {
      console.error('공정 삭제 실패:', error);
      setError('공정 삭제 중 오류가 발생했습니다.');
    }
  };

  // Enter 키 처리
  const handleKeyPress = (e, type) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (type === 'add') {
        addProcess();
      } else if (type === 'edit') {
        saveEditing();
      }
    } else if (e.key === 'Escape') {
      if (type === 'edit') {
        cancelEditing();
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="process-modal-overlay">
      <div className="process-modal" onClick={e => e.stopPropagation()}>
        <div className="process-modal-header">
          <h3>공정 관리</h3>
          <button className="close-button" onClick={onClose}>×</button>
        </div>

        <div className="process-modal-content">
          <div className="add-process-section">
            <input
              type="text"
              value={newProcessName}
              onChange={(e) => setNewProcessName(e.target.value)}
              onKeyDown={(e) => handleKeyPress(e, 'add')}
              placeholder="새 공정명 입력"
              className="process-input"
            />
            <button 
              className="add-process-button"
              onClick={addProcess}
            >
              추가
            </button>
          </div>

          {error && <div className="error-message">{error}</div>}

          <div className="process-list">
            {Object.keys(processes)
              .map((processName, index, array) => (
                <div key={processName} className="process-item">
                  {editingProcess === processName ? (
                    <div className="process-edit-container">
                      <input
                        type="number"
                        value={editingNumber}
                        onChange={(e) => setEditingNumber(e.target.value)}
                        onKeyDown={(e) => handleKeyPress(e, 'edit')}
                        className="process-number-input"
                        min="1"
                        max={array.length}
                      />
                      <input
                        type="text"
                        value={editingName}
                        onChange={(e) => setEditingName(e.target.value)}
                        onKeyDown={(e) => handleKeyPress(e, 'edit')}
                        className="process-edit-input"
                        autoFocus
                      />
                      <div className="process-edit-buttons">
                        <button 
                          className="process-edit-save"
                          onClick={saveEditing}
                        >
                          ✓
                        </button>
                        <button 
                          className="process-edit-cancel"
                          onClick={cancelEditing}
                        >
                          ×
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <span 
                        className="process-number"
                        onClick={() => startEditing(processName, index)}
                      >
                        {index + 1}
                      </span>
                      <span 
                        className="process-name"
                        onClick={() => startEditing(processName, index)}
                      >
                        {processName}
                      </span>
                      <button 
                        className="delete-process-button"
                        onClick={() => deleteProcess(processName)}
                      >
                        ×
                      </button>
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessManagementModal; 