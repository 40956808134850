import React, { useState, useEffect } from 'react';
import MobileVersionManager from './MobileVersionManager';
import './MobileSelectVersionModal.css';

const MobileSelectVersionModal = ({ selectedAddress, onSelectVersion, onNewVersion, onClose }) => {
  const [versions, setVersions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newVersionName, setNewVersionName] = useState('');
  const [editingVersion, setEditingVersion] = useState(null);
  const [editedVersionName, setEditedVersionName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    loadVersions();
  }, [selectedAddress]);

  const loadVersions = async () => {
    try {
      setLoading(true);
      const versionList = await MobileVersionManager.getVersionList(selectedAddress.description);
      setVersions(versionList);
    } catch (error) {
      console.error('버전 목록 로드 중 오류:', error);
      setError('버전 목록을 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateVersion = async () => {
    if (!newVersionName.trim()) {
      setError('버전 이름을 입력해주세요.');
      return;
    }

    try {
      await onNewVersion(newVersionName.trim());
      setNewVersionName('');
      setError('');
    } catch (error) {
      console.error('새 버전 생성 중 오류:', error);
      setError('새 버전 생성에 실패했습니다.');
    }
  };

  const handleDeleteVersion = async (version) => {
    if (!window.confirm('이 버전을 삭제하시겠습니까?')) {
      return;
    }

    try {
      await MobileVersionManager.deleteVersion(selectedAddress.description, version.versionName);
      await loadVersions();
    } catch (error) {
      console.error('버전 삭제 중 오류:', error);
      setError('버전 삭제에 실패했습니다.');
    }
  };

  const handleStartEditVersion = (version) => {
    setEditingVersion(version);
    setEditedVersionName(version.versionName);
  };

  const handleSaveEditVersion = async () => {
    if (!editedVersionName.trim()) {
      setError('버전 이름을 입력해주세요.');
      return;
    }

    try {
      await MobileVersionManager.updateVersionName(
        selectedAddress.description,
        editingVersion.versionName,
        editedVersionName.trim()
      );
      setEditingVersion(null);
      setEditedVersionName('');
      setError('');
      await loadVersions();
    } catch (error) {
      console.error('버전 이름 수정 중 오류:', error);
      setError('버전 이름 수정에 실패했습니다.');
    }
  };

  return (
    <div className="msvm">
      <div className="msvm__overlay" onClick={onClose} />
      <div className="msvm__modal">
        <div className="msvm__header">
          <h2 className="msvm__title">버전 선택</h2>
          <button className="msvm__close" onClick={onClose}>&times;</button>
        </div>

        <div className="msvm__content">
          {error && <div className="msvm__error">{error}</div>}

          <div className="msvm__new-version">
            <input
              type="text"
              className="msvm__input"
              placeholder="새 버전 이름"
              value={newVersionName}
              onChange={(e) => setNewVersionName(e.target.value)}
            />
            <button
              className="msvm__button msvm__button--create"
              onClick={handleCreateVersion}
            >
              새 버전 생성
            </button>
          </div>

          <div className="msvm__version-list">
            {loading ? (
              <div className="msvm__loading">버전 목록을 불러오는 중...</div>
            ) : versions.length === 0 ? (
              <div className="msvm__empty">저장된 버전이 없습니다.</div>
            ) : (
              versions.map((version) => (
                <div key={version.versionName} className="msvm__version-item">
                  {editingVersion === version ? (
                    <div className="msvm__version-edit">
                      <input
                        type="text"
                        className="msvm__input"
                        value={editedVersionName}
                        onChange={(e) => setEditedVersionName(e.target.value)}
                      />
                      <div className="msvm__edit-buttons">
                        <button
                          className="msvm__button msvm__button--save"
                          onClick={handleSaveEditVersion}
                        >
                          저장
                        </button>
                        <button
                          className="msvm__button msvm__button--cancel"
                          onClick={() => setEditingVersion(null)}
                        >
                          취소
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="msvm__version-info">
                      <div className="msvm__version-details">
                        <span className="msvm__version-name">{version.versionName}</span>
                        <span className="msvm__version-date">
                          {new Date(version.lastModified).toLocaleString()}
                        </span>
                      </div>
                      <div className="msvm__version-buttons">
                        <button
                          className="msvm__button"
                          onClick={() => onSelectVersion(version)}
                        >
                          선택
                        </button>
                        <button
                          className="msvm__button msvm__button--edit"
                          onClick={() => handleStartEditVersion(version)}
                        >
                          수정
                        </button>
                        <button
                          className="msvm__button msvm__button--delete"
                          onClick={() => handleDeleteVersion(version)}
                        >
                          삭제
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSelectVersionModal; 