import React, { useState } from 'react';
import { deleteThumbnail } from '../../firebase';

const ThumbnailList = ({ thumbnails, onThumbnailLoad, onThumbnailsUpdate }) => {
  const [loadingStates, setLoadingStates] = useState({});

  const formatDate = (timestamp) => {
    // timestamp가 숫자(밀리초)인 경우
    if (typeof timestamp === 'number') {
      return new Date(timestamp).toLocaleDateString('ko-KR');
    }
    
    // timestamp가 Firebase Timestamp 객체인 경우
    if (timestamp && typeof timestamp.toDate === 'function') {
      return timestamp.toDate().toLocaleDateString('ko-KR');
    }
    
    // timestamp가 문자열인 경우
    if (typeof timestamp === 'string') {
      return new Date(parseInt(timestamp)).toLocaleDateString('ko-KR');
    }
    
    return '날짜 없음';
  };

  const handleImageLoad = async (thumbnail) => {
    try {
      setLoadingStates(prev => ({ ...prev, [thumbnail.id]: true }));
      
      // 이미지 프리로드
      const img = new Image();
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = thumbnail.imageUrl;
      });
      
      onThumbnailLoad(thumbnail);
    } catch (error) {
      console.error('썸네일 이미지 로드 실패:', error);
      alert('썸네일 로드에 실패했습니다. 다시 시도해주세요.');
    } finally {
      setLoadingStates(prev => ({ ...prev, [thumbnail.id]: false }));
    }
  };

  const handleDelete = async (e, thumbnailId) => {
    e.stopPropagation();
    
    if (window.confirm('이 썸네일을 삭제하시겠습니까?')) {
      try {
        await deleteThumbnail(thumbnailId);
        onThumbnailsUpdate();
        alert('썸네일이 삭제되었습니다.');
      } catch (error) {
        console.error('썸네일 삭제 중 오류 발생:', error);
        alert('썸네일 삭제에 실패했습니다.');
      }
    }
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <h3>저장된 썸네일</h3>
      <div style={{ 
        maxHeight: '200px', 
        overflowY: 'auto',
        border: '1px solid #ddd',
        borderRadius: '5px'
      }}>
        {thumbnails.map(thumbnail => (
          <div
            key={thumbnail.id}
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
              display: 'flex',
              alignItems: 'flex-start',
              gap: '10px',
              cursor: loadingStates[thumbnail.id] ? 'wait' : 'pointer',
              background: 'white',
              opacity: loadingStates[thumbnail.id] ? 0.7 : 1
            }}
            onClick={() => !loadingStates[thumbnail.id] && handleImageLoad(thumbnail)}
          >
            <div style={{ 
              position: 'relative',
              width: '50px',
              height: '50px',
              flexShrink: 0
            }}>
              <img 
                src={thumbnail.imageUrl} 
                alt="썸네일" 
                style={{ 
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'cover',
                  borderRadius: '5px'
                }} 
              />
              {loadingStates[thumbnail.id] && (
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '5px'
                }}>
                  <div style={{
                    width: '20px',
                    height: '20px',
                    border: '2px solid #946A6A',
                    borderTop: '2px solid transparent',
                    borderRadius: '50%',
                    animation: 'spin 1s linear infinite'
                  }} />
                </div>
              )}
            </div>
            <div style={{ 
              flex: 1,
              overflow: 'hidden'
            }}>
              <div style={{
                fontSize: '13px',
                color: '#666',
                overflow: 'hidden'
              }}>
                {thumbnail.textLayers.map((layer, index) => (
                  <div 
                    key={index}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginBottom: '2px'
                    }}
                  >
                    {layer.text || '(텍스트 없음)'}
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={(e) => handleDelete(e, thumbnail.id)}
              disabled={loadingStates[thumbnail.id]}
              style={{
                padding: '5px 10px',
                background: loadingStates[thumbnail.id] ? '#ccc' : '#ff4444',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: loadingStates[thumbnail.id] ? 'not-allowed' : 'pointer',
                fontFamily: 'Pretendard',
                whiteSpace: 'nowrap',
                width: 'auto',
                flexShrink: 0,
                fontSize: '14px'
              }}
            >
              삭제
            </button>
          </div>
        ))}
      </div>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default ThumbnailList; 