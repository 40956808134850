import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, writeBatch } from 'firebase/firestore';
import { db } from '../../firebase';
import './AreaManagement.css';

const AreaManagement = ({ onMessage }) => {
  const [areas, setAreas] = useState([]);
  const [newArea, setNewArea] = useState('');
  const [editingAreaIndex, setEditingAreaIndex] = useState(null);
  const [editedAreaName, setEditedAreaName] = useState('');

  useEffect(() => {
    loadAreas();
  }, []);

  const loadAreas = async () => {
    try {
      const docSnap = await getDoc(doc(db, 'settings', 'areas'));
      if (docSnap.exists()) {
        setAreas(docSnap.data().list || getDefaultAreas());
      } else {
        setAreas(getDefaultAreas());
      }
    } catch (error) {
      console.error('평수 목록 로드 중 오류:', error);
      setAreas(getDefaultAreas());
    }
  };

  const getDefaultAreas = () => {
    return ['20평대', '30평대', '40평대', '50평대'];
  };

  const handleAddArea = async () => {
    if (newArea.trim() && !areas.includes(newArea.trim())) {
      try {
        const updatedAreas = [...areas, newArea.trim()];
        await saveAreasData(updatedAreas);
        setAreas(updatedAreas);
        setNewArea('');
        onMessage('평수가 추가되었습니다.');
      } catch (error) {
        console.error('평수 추가 중 오류:', error);
        onMessage('평수 추가 중 오류가 발생했습니다.', 'error');
      }
    }
  };

  const handleDeleteArea = async (areaToDelete) => {
    if (window.confirm(`${areaToDelete}를 삭제하시겠습니까?\n관련된 모든 가격 데이터도 함께 삭제됩니다.`)) {
      try {
        const updatedAreas = areas.filter(area => area !== areaToDelete);
        await saveAreasData(updatedAreas);
        setAreas(updatedAreas);
        onMessage(`${areaToDelete}가 삭제되었습니다.`);
      } catch (error) {
        console.error('평수 삭제 중 오류:', error);
        onMessage('평수 삭제 중 오류가 발생했습니다.', 'error');
      }
    }
  };

  const handleAreaEditComplete = async (index) => {
    if (editedAreaName.trim() && editedAreaName !== areas[index]) {
      try {
        const updatedAreas = [...areas];
        const oldArea = areas[index];
        updatedAreas[index] = editedAreaName.trim();
        
        await updateAreaName(oldArea, editedAreaName.trim(), updatedAreas);
        setAreas(updatedAreas);
        onMessage('평수가 수정되었습니다.');
      } catch (error) {
        console.error('평수 수정 중 오류:', error);
        onMessage('평수 수정 중 오류가 발생했습니다.', 'error');
      }
    }
    setEditingAreaIndex(null);
    setEditedAreaName('');
  };

  const handleMoveArea = async (index, direction) => {
    try {
      const newIndex = index + direction;
      if (newIndex < 0 || newIndex >= areas.length) return;

      const updatedAreas = [...areas];
      const [movedArea] = updatedAreas.splice(index, 1);
      updatedAreas.splice(newIndex, 0, movedArea);

      await saveAreasData(updatedAreas);
      setAreas(updatedAreas);
      onMessage('평수 순서가 변경되었습니다.');
    } catch (error) {
      console.error('평수 순서 변경 중 오류:', error);
      onMessage('평수 순서 변경 중 오류가 발생했습니다.', 'error');
    }
  };

  const saveAreasData = async (newAreas) => {
    const batch = writeBatch(db);
    
    // areas 문서 업데이트
    const areasRef = doc(db, 'settings', 'areas');
    batch.set(areasRef, { list: newAreas });

    // lineupPrices 문서 업데이트
    const lineupPricesRef = doc(db, 'lineupPrices', 'default');
    const lineupPricesDoc = await getDoc(lineupPricesRef);
    if (lineupPricesDoc.exists()) {
      const currentPrices = lineupPricesDoc.data();
      const lineups = ['refresh', 'harmony', 'collection'];
      
      // 새로운 가격 데이터 구조 생성
      const newPrices = {};
      lineups.forEach(lineup => {
        newPrices[lineup] = {};
        // 새로운 평수 목록에 있는 평수만 유지
        newAreas.forEach(area => {
          // 기존 데이터가 있으면 유지, 없으면 빈 객체로 초기화
          newPrices[lineup][area] = currentPrices[lineup]?.[area] || {};
        });
      });
      
      batch.set(lineupPricesRef, newPrices);
    }

    // 모든 변경사항을 한 번에 저장
    await batch.commit();
  };

  const updateAreaName = async (oldArea, newArea, updatedAreas) => {
    const batch = writeBatch(db);
    
    // areas 문서 업데이트
    const areasRef = doc(db, 'settings', 'areas');
    batch.set(areasRef, { list: updatedAreas });

    // areaPrices 문서 업데이트
    const areaPricesRef = doc(db, 'settings', 'areaPrices');
    const areaPricesDoc = await getDoc(areaPricesRef);
    const currentPrices = areaPricesDoc.exists() ? areaPricesDoc.data() : {};
    
    const newPrices = { ...currentPrices };
    if (newPrices[oldArea]) {
      newPrices[newArea] = newPrices[oldArea];
      delete newPrices[oldArea];
    }
    
    batch.set(areaPricesRef, newPrices);

    await batch.commit();
  };

  return (
    <div className="content-card">
      <h2 className="card-title">평수 관리</h2>
      <div className="process-management">
        <div className="input-group">
          <input
            type="text"
            value={newArea}
            onChange={(e) => setNewArea(e.target.value)}
            placeholder="새 평수 입력 (예: 60평대)"
            className="admin-input"
          />
          <button onClick={handleAddArea} className="area-add-button">
            평수 추가
          </button>
        </div>
        <ul className="admin-process-list">
          {areas.map((area, index) => (
            <li key={area} className="admin-process-item">
              {editingAreaIndex === index ? (
                <input
                  type="text"
                  value={editedAreaName}
                  onChange={(e) => setEditedAreaName(e.target.value)}
                  onBlur={() => handleAreaEditComplete(index)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleAreaEditComplete(index);
                    if (e.key === 'Escape') {
                      setEditingAreaIndex(null);
                      setEditedAreaName('');
                    }
                  }}
                  autoFocus
                  className="admin-input"
                />
              ) : (
                <span onDoubleClick={() => {
                  setEditingAreaIndex(index);
                  setEditedAreaName(area);
                }}>
                  {area}
                </span>
              )}
              <div className="process-controls">
                {index > 0 && (
                  <button onClick={() => handleMoveArea(index, -1)} className="control-button">↑</button>
                )}
                {index < areas.length - 1 && (
                  <button onClick={() => handleMoveArea(index, 1)} className="control-button">↓</button>
                )}
                <button 
                  onClick={() => handleDeleteArea(area)} 
                  className="control-button delete"
                >
                  삭제
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AreaManagement; 