import React from 'react';
import { FaLeaf, FaHeart, FaCrown } from 'react-icons/fa';

const LineupSelector = ({ selectedLineup, onLineupChange }) => {
  const lineups = [
    {
      id: 'refresh',
      name: '리프레쉬',
      icon: <FaLeaf />,
      color: '#7CB342'
    },
    {
      id: 'harmony',
      name: '하모니',
      icon: <FaHeart />,
      color: '#946A6A'
    },
    {
      id: 'collection',
      name: '컬렉션',
      icon: <FaCrown />,
      color: '#5D4037'
    }
  ];

  return (
    <div className="lineup-selector">
      {lineups.map(lineup => (
        <button
          key={lineup.id}
          className={`lineup-button ${selectedLineup === lineup.id ? 'active' : ''}`}
          onClick={() => onLineupChange(lineup.id)}
          style={{ '--lineup-color': lineup.color }}
        >
          {lineup.icon}
          <span>{lineup.name}</span>
        </button>
      ))}
    </div>
  );
};

export default LineupSelector; 