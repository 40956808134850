// PDF 관련 유틸리티 함수들이 들어갈 파일 
export const filterProcessesForPDF = (processes, hiddenProcesses) => {
  return processes.filter(process => {
    const hasNonZeroTotal = process.total > 0;
    const isVisible = !hiddenProcesses[process.id]?.hidden;
    return hasNonZeroTotal && isVisible;
  });
};

export const formatNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const calculateTotal = (processes) => {
  return processes.reduce((total, process) => total + process.total, 0);
};

export const getProcessName = (processId) => {
    const processNames = {
      design: '디자인',
      demolition: '철거폐기물',
      window: '창호금속',
      plumbing: '배관 공사',
      carpentry: '목공사',
      door: '문공사',
      tile: '타일 공사',
      film: '필름공사',
      floor_protection: '바닥 보양 공사',
      wood_flooring: '바닥재 공사',
      floor_tile: '바닥 타일 공사',
      painting: '도장공사',
      wallpaper: '도배공사',
      bathroom: '욕실시공',
      hardware: '하드웨어, 악세서리',
      electrical: '전기공사',
      furniture: '가구공사',
      silicon: '실리콘 공사',
      ac: '에어컨공사',
      supervision_utilities: '현장감리/공과잡비',
      misc_additional: '기타잡비/별도공사'
    };
    return processNames[processId] || processId;
  };