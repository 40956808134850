import React, { useState } from 'react';
import axios from 'axios';
import './ImageClassification.css';

// axios 설정
axios.defaults.baseURL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:8080'  // 로컬 개발 환경
  : 'https://image-classification-project-4m9c.onrender.com';  // 프로덕션 환경
axios.defaults.timeout = 600000; // 10분
axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
axios.defaults.withCredentials = false;

const ImageClassification = () => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [imageURLs, setImageURLs] = useState([]);
  const [currentBatch, setCurrentBatch] = useState(1);
  const [totalBatches, setTotalBatches] = useState(1);

  // 파일을 20MB 단위로 나누는 함수
  const splitFilesIntoBatches = (files) => {
    const MAX_BATCH_SIZE = 20 * 1024 * 1024; // 20MB
    let currentBatchSize = 0;
    let currentBatch = [];
    const batches = [];

    Array.from(files).forEach(file => {
      if (currentBatchSize + file.size > MAX_BATCH_SIZE) {
        batches.push(currentBatch);
        currentBatch = [file];
        currentBatchSize = file.size;
      } else {
        currentBatch.push(file);
        currentBatchSize += file.size;
      }
    });

    if (currentBatch.length > 0) {
      batches.push(currentBatch);
    }

    return batches;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFiles(droppedFiles);
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    handleFiles(selectedFiles);
  };

  const handleFiles = (files) => {
    // 개별 파일 크기 체크 (4MB)
    const MAX_FILE_SIZE = 4 * 1024 * 1024;
    const oversizedFiles = Array.from(files).filter(file => file.size > MAX_FILE_SIZE);
    if (oversizedFiles.length > 0) {
      setMessage('각 파일은 4MB를 초과할 수 없습니다.');
      return;
    }

    const urls = Array.from(files).map(file => URL.createObjectURL(file));
    setImageURLs(prev => {
      prev.forEach(url => URL.revokeObjectURL(url));
      return urls;
    });
    setFiles(files);

    // 전체 크기 계산 및 표시
    const totalSize = Array.from(files).reduce((sum, file) => sum + file.size, 0);
    const totalSizeMB = (totalSize / (1024 * 1024)).toFixed(2);
    const batches = splitFilesIntoBatches(files);
    setTotalBatches(batches.length);
    
    if (batches.length > 1) {
      setMessage(`총 파일 크기: ${totalSizeMB}MB (${batches.length}개의 배치로 나누어 처리됩니다)`);
    } else {
      setMessage(`총 파일 크기: ${totalSizeMB}MB`);
    }
  };

  const processBatch = async (batch, batchNumber) => {
    const formData = new FormData();
    batch.forEach(file => formData.append('images', file));

    try {
        console.log('Sending request to:', axios.defaults.baseURL + '/classify');
        
        const response = await axios.post('/classify', formData, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            validateStatus: function (status) {
                return status >= 200 && status < 500; // 502 에러도 처리
            },
            onUploadProgress: (progressEvent) => {
                console.log('Upload Progress:', progressEvent.loaded / progressEvent.total * 100, '%');
            }
        });

        if (response.status === 502) {
            console.error('Server error (502)');
            setMessage('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            return false;
        }

        console.log('Response received:', response);
        
        // ZIP 파일 다운로드
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `classified_images_batch${batchNumber}.zip`;
        link.click();
        window.URL.revokeObjectURL(url);

        return true;
    } catch (error) {
        console.error('Error details:', {
            message: error.message,
            response: error.response,
            request: error.request,
            config: error.config
        });
        setMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!files.length) return;

    setIsLoading(true);
    const batches = splitFilesIntoBatches(files);
    setTotalBatches(batches.length);

    for (let i = 0; i < batches.length; i++) {
      setCurrentBatch(i + 1);
      setMessage(`배치 ${i + 1}/${batches.length} 처리 중...`);
      
      const success = await processBatch(batches[i], i + 1);
      
      if (!success) {
        setMessage('처리 중 오류가 발생했습니다.');
        setIsLoading(false);
        return;
      }

      // 마지막 배치가 아니면 5초 대기
      if (i < batches.length - 1) {
        setMessage(`배치 ${i + 1} 완료. 5초 후 다음 배치를 시작합니다...`);
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    }

    setMessage(`모든 배치 처리 완료! (총 ${batches.length}개의 ZIP 파일이 다운로드되었습니다)`);
    setIsLoading(false);
  };

  return (
    <div className="image-classification">
      <h1>이미지 분류 프로그램</h1>
      <div className="upload-section">
        <div 
          className="drop-area"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          <p>이미지를 드래그하거나 클릭하여 업로드하세요</p>
          <p className="file-size-info">
            * 각 파일은 4MB 이하여야 합니다<br/>
            * 전체 크기가 20MB를 초과하는 경우 자동으로 나누어 처리됩니다
          </p>
          <label className="file-input-label">
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              accept="image/*"
              style={{ display: 'none' }}
            />
            파일 선택
          </label>
        </div>
        <div className="image-preview-grid">
          {imageURLs.map((url, index) => (
            <div key={index} className="image-preview-item">
              <img src={url} alt={`Preview ${index}`} />
              <button 
                className="delete-button" 
                onClick={() => {
                  URL.revokeObjectURL(url);
                  setImageURLs(prev => prev.filter((_, i) => i !== index));
                  setFiles(prev => Array.from(prev).filter((_, i) => i !== index));
                }}
              >
                ×
              </button>
            </div>
          ))}
        </div>
      </div>
      <button 
        onClick={handleSubmit}
        disabled={isLoading || !files.length}
      >
        {isLoading ? `처리중... (${currentBatch}/${totalBatches})` : '분류 시작'}
      </button>
      {message && <p className="message">{message}</p>}
      {isLoading && (
        <div className="progress-bar-container">
          <div 
            className="progress-bar" 
            style={{width: `${(currentBatch / totalBatches) * 100}%`}}
          ></div>
        </div>
      )}
    </div>
  );
};

export default ImageClassification;
