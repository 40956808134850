import React, { useEffect, useState, useRef } from 'react';
import { db } from '../../firebase';
import { collection, doc, getDoc, addDoc } from 'firebase/firestore';
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import './EstimateApp.css';
import { FaPhone, FaComments, FaFileAlt } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { generateEstimateId } from '../../utils/estimateUtils';
import { trackMetaEvent } from '../../utils/metaPixel';

// PDF 스타일 정의
const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: '#ffffff'
  },
  header: {
    marginBottom: 20,
    textAlign: 'center'
  },
  title: {
    fontSize: 24,
    marginBottom: 10
  },
  // ... 기존 스타일 유지
});

// PDF 문서 컴포넌트
const EstimatePdfDocument = ({ selectedProcesses, userInfo }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>AMARE DESIGN</Text>
      </View>
      {/* 나머지 PDF 내용 */}
    </Page>
  </Document>
);

// 라인업 이름 매핑 객체 수정 (깨진 주석 수정)
const lineupNames = {
  'refresh': '리프레쉬',
  'harmony': '하모니',
  'collection': '컬렉션'
};

// 공정명 매핑 객체
const processMapping = {
  '제작 가구(싱크대, 붙박이)': '제작 가구\n(싱크대,붙박이)',
  '철거 및 폐기물 처리': '철거\n폐기물',
  '베란다 탄성코트': '탄성코트',
  // 필요한 다른 공정명 매핑 추가
};

const constructionProcess = [
  {
    step: '현장방문 및 실측',
    description: '정확한 견적 산출을 위해 전문가가 직접 방문하여 실측을 진행합니다.'
  },
  {
    step: '디자인 및 설계',
    description: '고객님의 공간에 맞는 맞춤형 디자인과 도면 작업을 2주~2개월간 진행합니다.'
  },
  {
    step: '공사 시작',
    description: '철거, 전기, 설비 등 기초공사부터 시작합니다.'
  },
  {
    step: '공사 마감',
    description: '제작가구 설치, 도배 및 코킹 작업으로 모든 공정을 정성스럽게 마무리합니다.'
  },
  {
    step: '최종 점검',
    description: '모든 공정이 마무리된 후 전체적인 하자 여부를 점검합니다.'
  },
  {
    step: '공사 완료',
    description: '회사와 고객 상호 동의 후 최종 공사 완료 처리됩니다.'
  },
  {
    step: '고객 점검 기간',
    description: '공간 사용 중 발견되는 불편사항이나 개선점을 꼼꼼히 확인하여 보완해 드립니다.'
  }
];

const EstimateResult = React.memo(({ 
  selectedProcesses, 
  selectedArea, 
  userInfo, 
  onGoHome, 
  isModalView,
  prices: initialPrices,
  totalPrice: initialTotal,
  estimateId: propEstimateId,
  selectedLineup
}) => {
  const [prices, setPrices] = useState(initialPrices || {});
  const [totalPrice, setTotalPrice] = useState(initialTotal || 0);
  const [orderedProcesses, setOrderedProcesses] = useState([]);
  const [isMessageBtnHighlighted, setIsMessageBtnHighlighted] = useState(false);
  const [currentEstimateId, setCurrentEstimateId] = useState(propEstimateId);
  const [descriptions, setDescriptions] = useState({});
  const [template, setTemplate] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // URL에서 견적서 ID 추출 및 상태 업데이트
  useEffect(() => {
    const path = location.pathname;
    const match = path.match(/\/estimate\/(.+)$/);
    if (match) {
      setCurrentEstimateId(match[1]);
    } else if (propEstimateId) {
      setCurrentEstimateId(propEstimateId);
    }
  }, [location.pathname, propEstimateId]);

  // 공정 순서 로드 및 정렬
  useEffect(() => {
    const loadProcessOrder = async () => {
      try {
        const processesDoc = await getDoc(doc(db, 'oneClickProcesses', 'default'));
        
        if (processesDoc.exists() && processesDoc.data().processList?.length > 0) {
          const orderedProcessList = processesDoc.data().processList;
          
          // 선택된 공정들을 관리자가 설정한 순서대로 정렬하되,
          // processList에 없는 공정도 포함시킴
          const ordered = [];
          
          // 먼저 processList의 순서대로 공정을 추가
          orderedProcessList.forEach(process => {
            if (selectedProcesses.includes(process)) {
              ordered.push(process);
            }
          });
          
          // processList에 없는 선택된 공정들을 마지막에 추가
          selectedProcesses.forEach(process => {
            if (!ordered.includes(process)) {
              ordered.push(process);
            }
          });
          
          setOrderedProcesses(ordered);
        } else {
          // processList가 없는 경우 선택된 공정 그대로 사용
          setOrderedProcesses([...selectedProcesses]);
        }
      } catch (error) {
        console.error('공정 순서 로드 중 오류:', error);
        // 오류 발생 시 선택된 공정 그대로 사용
        setOrderedProcesses([...selectedProcesses]);
      }
    };

    if (selectedProcesses.length > 0) {
      loadProcessOrder();
    } else {
      setOrderedProcesses([]);
    }
  }, [selectedProcesses]);

  // 가격과 설명 데이터 로드
  useEffect(() => {
    const loadPricesAndDescriptions = async () => {
      try {
        // 라인업별 가격 데이터 로드
        const pricesDoc = await getDoc(doc(db, 'lineupPrices', 'default'));
        if (pricesDoc.exists()) {
          const lineupPrices = pricesDoc.data()?.[selectedLineup]?.[`${selectedArea}평대`] || {};
          setPrices(lineupPrices);
          
          // 공정 가격 합계 계산
          const processTotal = selectedProcesses.reduce((sum, process) => {
            const price = lineupPrices[process];
            if (typeof price === 'string') {
              return sum + (parseInt(price.replace(/,/g, '')) || 0);
            }
            if (typeof price === 'number') {
              return sum + price;
            }
            return sum;
          }, 0);
          
          // 공과잡비 3% 계산 및 총액 설정
          const miscFee = Math.round(processTotal * 0.03);
          setTotalPrice(processTotal + miscFee);
        }

        // 라인업별 공정 설명 데이터 로드
        const descriptionsDoc = await getDoc(doc(db, 'lineupDescriptions', 'default'));
        if (descriptionsDoc.exists()) {
          const lineupDescriptions = descriptionsDoc.data()?.[selectedLineup] || {};
          setDescriptions(lineupDescriptions);
        }
      } catch (error) {
        console.error('가격/설명 데이터 로드 중 오류:', error);
      }
    };

    if (selectedLineup && selectedArea) {
      loadPricesAndDescriptions();
    }
  }, [selectedLineup, selectedArea, selectedProcesses]);

  // 주기적으로 애니메이션 실행
  useEffect(() => {
    const interval = setInterval(() => {
      setIsMessageBtnHighlighted(prev => !prev);
    }, 2000); // 2초마다 토글

    return () => clearInterval(interval);
  }, []);

  // 템플릿 데이터 로드
  useEffect(() => {
    const loadTemplate = async () => {
      try {
        const docRef = doc(db, 'emailTemplates', '1mintemplates');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setTemplate(docSnap.data());
        }
      } catch (error) {
        // console.error('템플릿 로딩 중 오류:', error);
      }
    };
    
    loadTemplate();
  }, []);

  // 설명 메시지 생성 함수
  const getDescriptionMessage = () => {
    const defaultMessage = `안녕하세요. ${userInfo?.name || ''}님,\n`;
    return defaultMessage + (template?.estimateDescription || '');
  };

  const formatPrice = (price) => {
    if (!price) return '0';
    return parseInt(price).toLocaleString();
  };

  const getProcessPrice = (process) => {
    const price = prices[process];
    
    // 가격이 없는 경우
    if (!price) {
      return <span className="price-value">미정</span>;
    }
    
    // 가격이 문자열이고 숫자가 아닌 경우 (예: "방문실측필요")
    if (typeof price === 'string' && isNaN(parseInt(price.replace(/,/g, '')))) {
      return <span className="price-value">{price}</span>;
    }
    
    // 숫자인 경우 포맷하여 "원" 추가
    const formattedPrice = typeof price === 'number' ? 
      price.toLocaleString() : 
      parseInt(price.replace(/,/g, '')).toLocaleString();
      
    return <span className="price-value">{formattedPrice}원</span>;
  };

  // formatProcessName을 useMemo로 메모이제이션
  const formatProcessName = React.useMemo(() => (process) => {
    return processMapping[process] || process;
  }, []);

  const handlePhoneCall = () => {
    trackMetaEvent('Contact', { method: 'phone' });
    window.location.href = 'tel:01086944078';
  };

  const handleSMS = () => {
    trackMetaEvent('Contact', { method: 'sms' });
    window.location.href = 'sms:01086944078';
  };

  const handleEstimateInquiry = () => {
    if (currentEstimateId) {
      trackMetaEvent('Contact', { 
        method: 'inquiry',
        estimateId: currentEstimateId 
      });
      navigate(`/estimate-inquiry/${currentEstimateId}`);
    } else {
      alert('견적서 ID를 찾을 수 없습니다. 페이지를 새로고침해주세요.');
    }
  };

  // 처음으로 돌아가기 버튼 핸들러 수정
  const handleGoHome = () => {
    navigate('/', { replace: true });
    if (onGoHome) {
      onGoHome();
    }
  };

  return (
    <div className="estimate-result">
      {!isModalView && (
        <div className="button-container">
          <button className="go-home-button" onClick={handleGoHome}>
            처음으로 돌아가기
          </button>
          <div className="action-buttons">
            <button className="circle-button" onClick={handleEstimateInquiry}>
              <FaFileAlt className="button-icon" />
              <span>견적문의</span>
            </button>
            <button className="circle-button" onClick={handlePhoneCall}>
              <FaPhone className="button-icon" />
              <span>전화</span>
            </button>
            <button 
              className={`circle-button sms-button ${isMessageBtnHighlighted ? 'active' : ''}`} 
              onClick={handleSMS}
            >
              <FaComments className="button-icon" />
              <span>문자</span>
            </button>
          </div>

          <div className="estimate-description">
            <p>{getDescriptionMessage()}</p>
          </div>
        </div>
      )}
      
      {!isModalView && (
        <div className="title-container">
          <h1 className="main-title">AMARE DESIGN</h1>
        </div>
      )}
      
      <div className="estimate-header">
        <h3 className="sub-title">
          {userInfo?.name}님의 {selectedArea}평 견적서
          {selectedLineup && `(${lineupNames[selectedLineup]})`}
        </h3>
        <p className="estimate-date">{new Date().toLocaleDateString()}</p>
      </div>

      <div className="process-list">
        <div className="process-header">
          <span>공정</span>
          <span>금액</span>
        </div>
        
        {orderedProcesses.map((process, index) => (
          <React.Fragment key={index}>
            <div className="process-item">
              <span className="process-name" style={{ whiteSpace: 'pre-line', fontWeight: 'bold' }}>
                {formatProcessName(process)}
              </span>
              {getProcessPrice(process)}
            </div>
            {descriptions[process] && descriptions[process].trim() !== '' && (
              <div className="process-description">
                {descriptions[process]}
              </div>
            )}
          </React.Fragment>
        ))}

        {/* 기타경비 추가 */}
        <div className="process-item">
          <span className="process-name" style={{ fontWeight: 'bold' }}>기타경비</span>
          <span className="price-value">180,000원</span>
        </div>
        <div className="process-description">
          엘레베이터보양
          {'\n'}베란다 탄성 코트(별도)
          {'\n'}입주청소(별도)
          {'\n'}공적업무(별도)
        </div>

        {/* 공과잡비 추가 */}
        <div className="process-item special">
          <span className="process-name" style={{ fontWeight: 'bold' }}>공과잡비</span>
          <span className="price-value">
            {formatPrice(Math.round(totalPrice * (3/103)))}원
          </span>
        </div>
      </div>

      <div className="total-price">
        <span className="total-label">총 견적 금액</span>
        <span className="total-value">{formatPrice(totalPrice)}원</span>
      </div>

      <div className={isModalView ? "estimate-modal-footer" : "estimate-footer"}>
        <p className={isModalView ? "estimate-modal-note" : "note"} style={{ whiteSpace: 'pre-line' }}>
          {'※ 상기 금액은 예상 견적가이며\n현장 상황에 따라 변동될 수 있는 점\n양해 부탁드립니다.\n\n다만, 최대한 변동 없이 진행될 수 있도록\n최선을 다하겠습니다 😊'}
        </p>
      </div>

      <div className="construction-process-section">
        <h3 className="process-title">아마레 공사 프로세스</h3>
        <div className="timeline">
          {constructionProcess.map((process, index) => (
            <div key={index} className="timeline-item">
              <div className="timeline-number">{index + 1}</div>
              <div className="timeline-content">
                <div className="timeline-step">{process.step}</div>
                <p className="timeline-description">{process.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* 비용 지급 방식 섹션 수정 */}
      <div className="payment-process-section">
        <h3 className="process-title">비용 지급 방식</h3>
        <div className="payment-info">
          <div className="payment-item">
            <span className="payment-step">계약금</span>
            <span className="payment-arrow">↓</span>
            <span className="payment-step">중도금 (3~5회)</span>
            <span className="payment-arrow">↓</span>
            <span className="payment-step">잔금</span>
          </div>
          <p className="payment-description">
            공정률에 따라 분할 납부되며, 상호 동의 후 진행됩니다.
          </p>
        </div>
      </div>
    </div>
  );
});

// displayName 추가
EstimateResult.displayName = 'EstimateResult';

export default EstimateResult;
