import React from 'react';
import './VideoMaker.css';

const VideoMaker = () => {
  return (
    <div className="video-maker">
      <h1>영상 만들기</h1>
      {/* 추후 구현 예정 */}
    </div>
  );
};

export default VideoMaker; 