import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc, collection, query, orderBy, getDocs } from 'firebase/firestore';
import './ContractorManagement.css';

const ContractorManagement = () => {
  const [selectedProcess, setSelectedProcess] = useState('');
  const [contractors, setContractors] = useState({});
  const [processes, setProcesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingContractor, setEditingContractor] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newContractor, setNewContractor] = useState({
    name: '',
    phone: '',
    processName: ''
  });

  // 공정 목록 로드
  useEffect(() => {
    const loadProcesses = async () => {
      try {
        console.log('[ContractorManagement] 공정 목록 로딩 시작');
        const processesRef = doc(db, 'settings', 'processes');
        const processesDoc = await getDoc(processesRef);
        
        if (processesDoc.exists()) {
          const processList = processesDoc.data().processList || [];
          const processesList = processList.map((name, index) => ({
            id: String(index),
            name,
            order: index
          }));
          console.log('[ContractorManagement] 로드된 공정 목록:', processesList);
          setProcesses(processesList);

          // 첫 로드 시 '전체 보기' 선택
          setSelectedProcess('전체 보기');
        } else {
          console.log('[ContractorManagement] 공정 목록이 없습니다.');
          setProcesses([]);
          setSelectedProcess('전체 보기');
        }
      } catch (error) {
        console.error('[ContractorManagement] 공정 목록 로딩 실패:', error);
        setError('공정 목록을 불러오는데 실패했습니다.');
      }
    };

    loadProcesses();
  }, []);

  // 거래처 데이터 로드
  useEffect(() => {
    const loadContractors = async () => {
      if (!selectedProcess) return;

      try {
        console.log('[ContractorManagement] 거래처 데이터 로딩 시작:', selectedProcess);
        setLoading(true);
        
        if (selectedProcess === '전체 보기') {
          const contractorsData = {};
          for (const process of processes) {
            console.log('[ContractorManagement] 공정별 거래처 조회:', process.name);
            const processRef = doc(db, 'contractors', process.name);
            const processDoc = await getDoc(processRef);
            
            if (processDoc.exists()) {
              contractorsData[process.name] = processDoc.data().contractors || [];
            } else {
              contractorsData[process.name] = [];
            }
          }
          console.log('[ContractorManagement] 전체 거래처 데이터:', contractorsData);
          setContractors(contractorsData);
        } else {
          const processRef = doc(db, 'contractors', selectedProcess);
          const processDoc = await getDoc(processRef);
          
          if (processDoc.exists()) {
            const contractorsData = {
              [selectedProcess]: processDoc.data().contractors || []
            };
            console.log('[ContractorManagement] 선택된 공정의 거래처 데이터:', contractorsData);
            setContractors(contractorsData);
          } else {
            console.log('[ContractorManagement] 거래처 데이터 없음. 빈 배열로 초기화');
            setContractors({ [selectedProcess]: [] });
          }
        }
      } catch (error) {
        console.error('[ContractorManagement] 거래처 데이터 로딩 실패:', error);
        setError('거래처 데이터를 불러오는데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    loadContractors();
  }, [selectedProcess, processes]);

  // 거래처 추가
  const addContractor = async (processName, contractor) => {
    try {
      console.log('[ContractorManagement] 거래처 추가 시작:', { processName, contractor });
      const processRef = doc(db, 'contractors', processName);
      const processDoc = await getDoc(processRef);
      
      let currentContractors = [];
      if (processDoc.exists()) {
        currentContractors = processDoc.data().contractors || [];
      }
      
      await setDoc(processRef, {
        contractors: [...currentContractors, contractor]
      });
      
      setContractors(prev => ({
        ...prev,
        [processName]: [...(prev[processName] || []), contractor]
      }));
      console.log('[ContractorManagement] 거래처 추가 완료');
    } catch (error) {
      console.error('[ContractorManagement] 거래처 추가 실패:', error);
      throw new Error('거래처 추가에 실패했습니다.');
    }
  };

  // 거래처 수정
  const updateContractor = async (processName, index, updatedContractor) => {
    try {
      console.log('[ContractorManagement] 거래처 수정 시작:', { processName, index, updatedContractor });
      const processRef = doc(db, 'contractors', processName);
      const processDoc = await getDoc(processRef);
      
      if (!processDoc.exists()) {
        throw new Error('해당 공정의 거래처 데이터가 없습니다.');
      }
      
      const currentContractors = processDoc.data().contractors || [];
      currentContractors[index] = updatedContractor;
      
      await setDoc(processRef, {
        contractors: currentContractors
      });
      
      setContractors(prev => ({
        ...prev,
        [processName]: currentContractors
      }));
      console.log('[ContractorManagement] 거래처 수정 완료');
    } catch (error) {
      console.error('[ContractorManagement] 거래처 수정 실패:', error);
      throw new Error('거래처 수정에 실패했습니다.');
    }
  };

  // 거래처 삭제
  const deleteContractor = async (processName, index) => {
    try {
      console.log('[ContractorManagement] 거래처 삭제 시작:', { processName, index });
      const processRef = doc(db, 'contractors', processName);
      const processDoc = await getDoc(processRef);
      
      if (!processDoc.exists()) {
        throw new Error('해당 공정의 거래처 데이터가 없습니다.');
      }
      
      const currentContractors = processDoc.data().contractors || [];
      currentContractors.splice(index, 1);
      
      await setDoc(processRef, {
        contractors: currentContractors
      });
      
      setContractors(prev => ({
        ...prev,
        [processName]: currentContractors
      }));
      console.log('[ContractorManagement] 거래처 삭제 완료');
    } catch (error) {
      console.error('[ContractorManagement] 거래처 삭제 실패:', error);
      throw new Error('거래처 삭제에 실패했습니다.');
    }
  };

  // 전체 거래처 목록 가져오기
  const getAllContractors = () => {
    return Object.entries(contractors).reduce((acc, [process, contractorList]) => {
      return [...acc, ...contractorList.map(contractor => ({
        ...contractor,
        process
      }))];
    }, []);
  };

  // 새 거래처 추가
  const handleAddContractor = async () => {
    const targetProcess = selectedProcess === '전체 보기' ? newContractor.processName : selectedProcess;
    
    if (!targetProcess || !newContractor.name || !newContractor.phone) {
      alert('모든 필드를 입력해주세요.');
      return;
    }

    try {
      await addContractor(targetProcess, {
        name: newContractor.name,
        phone: newContractor.phone,
        createdAt: new Date()
      });
      setNewContractor({ name: '', phone: '', processName: '' });
      alert('거래처가 추가되었습니다.');
    } catch (error) {
      alert(error.message);
    }
  };

  // 수정 모달 열기
  const handleEdit = (contractor, index) => {
    const processName = selectedProcess === '전체 보기' ? contractor.process : selectedProcess;
    const processContractors = contractors[processName] || [];
    const realIndex = selectedProcess === '전체 보기' 
      ? processContractors.findIndex(c => c.name === contractor.name && c.phone === contractor.phone)
      : index;
      
    setEditingContractor({...contractor, realIndex});
    setEditingIndex(realIndex);
    setShowEditModal(true);
  };

  // 수정 저장
  const handleSaveEdit = async () => {
    if (!editingContractor.name || !editingContractor.phone) {
      alert('모든 필드를 입력해주세요.');
      return;
    }

    try {
      const processName = selectedProcess === '전체 보기' ? editingContractor.process : selectedProcess;
      const { realIndex, ...contractorData } = editingContractor;
      await updateContractor(processName, editingIndex, contractorData);
      setShowEditModal(false);
      setEditingContractor(null);
      setEditingIndex(null);
      alert('거래처 정보가 수정되었습니다.');
    } catch (error) {
      alert(error.message);
    }
  };

  // 삭제 처리
  const handleDelete = async (contractor, index) => {
    if (!window.confirm('정말 이 거래처를 삭제하시겠습니까?')) {
      return;
    }

    try {
      const processName = selectedProcess === '전체 보기' ? contractor.process : selectedProcess;
      const processContractors = contractors[processName] || [];
      const realIndex = selectedProcess === '전체 보기'
        ? processContractors.findIndex(c => c.name === contractor.name && c.phone === contractor.phone)
        : index;

      await deleteContractor(processName, realIndex);
      alert('거래처가 삭제되었습니다.');
    } catch (error) {
      alert(error.message);
    }
  };

  if (loading) {
    return <div className="cm-loading">데이터를 불러오는 중...</div>;
  }

  if (error) {
    return <div className="cm-error">{error}</div>;
  }

  // 표시할 거래처 목록
  const displayContractors = selectedProcess === '전체 보기' 
    ? getAllContractors()
    : contractors[selectedProcess] || [];

  return (
    <div className="cm-container">
      <header className="cm-header">
        <h1 className="cm-header__title">거래처 관리</h1>
      </header>
      
      <div className="cm-select">
        <select 
          className="cm-select__dropdown"
          value={selectedProcess} 
          onChange={(e) => setSelectedProcess(e.target.value)}
        >
          <option value="">공정을 선택하세요</option>
          <option value="전체 보기">전체 보기</option>
          {processes.map(process => (
            <option key={process.id} value={process.name}>{process.name}</option>
          ))}
        </select>
      </div>

      {selectedProcess && (
        <div className="cm-form">
          <div className="cm-form__row">
            {selectedProcess === '전체 보기' && (
              <select
                className="cm-form__input"
                value={newContractor.processName}
                onChange={(e) => setNewContractor({...newContractor, processName: e.target.value})}
              >
                <option value="">공정 선택</option>
                {processes.map(process => (
                  <option key={process.id} value={process.name}>{process.name}</option>
                ))}
              </select>
            )}
            <input
              className="cm-form__input"
              type="text"
              placeholder="거래처명"
              value={newContractor.name}
              onChange={(e) => setNewContractor({...newContractor, name: e.target.value})}
            />
            <input
              className="cm-form__input"
              type="text"
              placeholder="전화번호"
              value={newContractor.phone}
              onChange={(e) => setNewContractor({...newContractor, phone: e.target.value})}
            />
            <button 
              className="cm-button cm-form__button"
              onClick={handleAddContractor}
            >
              추가
            </button>
          </div>
        </div>
      )}

      {selectedProcess && (
        <div className="cm-table">
          <table>
            <thead>
              <tr className="cm-table__header">
                <th className="cm-table__header-cell cm-table__header-cell--no">No.</th>
                {selectedProcess === '전체 보기' && (
                  <th className="cm-table__header-cell">공정</th>
                )}
                <th className="cm-table__header-cell">거래처명</th>
                <th className="cm-table__header-cell">연락처</th>
                <th className="cm-table__header-cell cm-table__header-cell--actions">관리</th>
              </tr>
            </thead>
            <tbody>
              {displayContractors.map((contractor, index) => (
                <tr key={index} className="cm-table__row">
                  <td className="cm-table__cell cm-table__cell--no">{index + 1}</td>
                  {selectedProcess === '전체 보기' && (
                    <td className="cm-table__cell">{contractor.process}</td>
                  )}
                  <td className="cm-table__cell">{contractor.name}</td>
                  <td className="cm-table__cell">{contractor.phone}</td>
                  <td className="cm-table__cell cm-table__cell--actions">
                    <button 
                      className="cm-button cm-button--edit"
                      onClick={() => handleEdit(contractor, index)}
                    >
                      수정
                    </button>
                    <button 
                      className="cm-button cm-button--delete"
                      onClick={() => handleDelete(contractor, index)}
                    >
                      삭제
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showEditModal && (
        <div className="cm-modal">
          <div className="cm-modal__content">
            <h3 className="cm-modal__title">거래처 정보 수정</h3>
            <input
              className="cm-modal__input"
              type="text"
              placeholder="거래처명"
              value={editingContractor?.name || ''}
              onChange={(e) => setEditingContractor({
                ...editingContractor,
                name: e.target.value
              })}
            />
            <input
              className="cm-modal__input"
              type="text"
              placeholder="전화번호"
              value={editingContractor?.phone || ''}
              onChange={(e) => setEditingContractor({
                ...editingContractor,
                phone: e.target.value
              })}
            />
            <div className="cm-modal__buttons">
              <button 
                className="cm-button cm-modal__button"
                onClick={handleSaveEdit}
              >
                저장
              </button>
              <button 
                className="cm-button cm-modal__button"
                onClick={() => {
                  setShowEditModal(false);
                  setEditingContractor(null);
                  setEditingIndex(null);
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractorManagement; 