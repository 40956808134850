import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import './EmailPdfModal.css';
import { sendEstimateEmail } from '../services/emailService';

const EmailPdfModal = ({ 
  selectedAddress, 
  processData, 
  notes, 
  hiddenProcesses,
  onClose
}) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [signature, setSignature] = useState(null);

  // 템플릿 불러오기
  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'emailTemplates'));
        const loadedTemplates = querySnapshot.docs
          .filter(doc => doc.id !== '1mintemplates')
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
        setTemplates(loadedTemplates);
        
        // 1mintemplates에서 서명 정보 가져오기
        const signatureDoc = await getDoc(doc(db, 'emailTemplates', '1mintemplates'));
        if (signatureDoc.exists()) {
          setSignature(signatureDoc.data().signature);
        }
      } catch (error) {
        console.error('템플릿/서명 로딩 오류:', error);
      }
    };
    loadTemplates();
  }, []);

  // 템플릿 저장
  const handleSaveTemplate = async () => {
    try {
      const templateData = {
        name: templateName,
        subject,
        content,
        createdAt: new Date().toISOString()
      };

      const docRef = await addDoc(collection(db, 'emailTemplates'), templateData);
      setTemplates([...templates, { ...templateData, id: docRef.id }]);
      setShowTemplateForm(false);
      setTemplateName('');
      alert('템플릿이 저장되었습니다.');
    } catch (error) {
      console.error('템플릿 저장 오류:', error);
      alert('템플릿 저장에 실패했습니다.');
    }
  };

  const handleTemplateSelect = (template) => {
    console.log('템플릿 선택 전:', {
      currentSubject: subject,
      currentContent: content
    });

    setSelectedTemplate(template);
    if (template) {
      setSubject(template.subject);
      setContent(template.content);

      console.log('템플릿 선택 후:', {
        newSubject: template.subject,
        newContent: template.content
      });
    }
  };

  // 서명 정보를 HTML로 변환하는 함수
  const createSignatureHtml = (signature) => {
    if (!signature) return '';
    
    const signatureItems = [];
    if (signature.representative) signatureItems.push(`대표: ${signature.representative}`);
    if (signature.address) signatureItems.push(`주소: ${signature.address}`);
    if (signature.phone) signatureItems.push(`연락처: ${signature.phone}`);
    if (signature.instagram) signatureItems.push(`인스타그램: ${signature.instagram}`);
    if (signature.website) signatureItems.push(`웹사이트: ${signature.website}`);
    if (signature.blog) signatureItems.push(`블로그: ${signature.blog}`);

    if (signatureItems.length === 0) return '';

    return `\n\n------------------\n${signatureItems.join('\n')}`;
  };

  // handleSubmit 함수 수정
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('유효한 이메일 주소를 입력해주세요.');
      return;
    }

    if (!selectedTemplate) {
      setError('이메일 템플릿을 선택해주세요.');
      const templateSelect = document.querySelector('.template-section select');
      if (templateSelect) {
        templateSelect.scrollIntoView({ behavior: 'smooth', block: 'center' });
        templateSelect.focus();
      }
      return;
    }

    setLoading(true);
    setError('');
    
    try {
      // 서명이 포함된 content 생성
      const contentWithSignature = `${content}\n${createSignatureHtml(signature)}`;
      
      // 이메일 전송 서비스 호출
      await sendEstimateEmail({
        email,
        subject,
        content: contentWithSignature,
        selectedAddress,
        processData,
        notes,
        hiddenProcesses
      });

      setSuccessMessage('견적서가 이메일로 전송되었습니다.');
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      setError(error.message || '이메일 전송 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  // 템플릿 수정 함수 수정
  const handleEditTemplate = async () => {
    if (!selectedTemplate) return;
    
    try {
      const updatedTemplate = {
        name: selectedTemplate.name, // 기존 이름 유지
        subject,
        content,
        updatedAt: new Date().toISOString()
      };

      await updateDoc(doc(db, 'emailTemplates', selectedTemplate.id), updatedTemplate);

      const updatedTemplates = templates.map(t => 
        t.id === selectedTemplate.id ? 
        { ...t, ...updatedTemplate } : 
        t
      );
      
      setTemplates(updatedTemplates);
      setSelectedTemplate({ ...selectedTemplate, ...updatedTemplate });
      setEditMode(false);
      alert('템플릿이 수정되었습니다.');
    } catch (error) {
      console.error('템플릿 수정 오류:', error);
      alert('템플릿 수정에 실패했습니다.');
    }
  };

  // 템플릿 삭제 함수
  const handleDeleteTemplate = async (templateId) => {
    if (!templateId || templateId === '1mintemplates' || !window.confirm('이 템플릿을 삭제하시겠습니까?')) return;
    
    try {
      await deleteDoc(doc(db, 'emailTemplates', templateId));
      const updatedTemplates = templates.filter(t => t.id !== templateId);
      setTemplates(updatedTemplates);
      
      if (selectedTemplate?.id === templateId) {
        setSelectedTemplate(null);
        setSubject('');
        setContent('');
      }
      
      alert('템플릿이 삭제되었습니다.');
    } catch (error) {
      console.error('템플릿 삭제 오류:', error);
      alert('템플릿 삭제에 실패했습니다.');
    }
  };

  // 수정 모드 시작 함수 수정
  const startEditMode = (template) => {
    setEditMode(true);
    setSubject(template.subject);
    setContent(template.content);
  };

  // 새 템플릿 폼 표시/숨김 함수 수정
  const toggleTemplateForm = () => {
    setShowTemplateForm(!showTemplateForm);
    if (!showTemplateForm) {
      // 새 템플릿 폼을 열 때 입력 필드 초기화
      setTemplateName('');
      setSubject('');
      setContent('');
      setSelectedTemplate(null);
    }
  };

  return (
    <div className="email-pdf-modal-overlay">
      <div className="email-pdf-modal">
        <h2 className="email-pdf-modal-title">견적서 이메일 전송</h2>
        <form onSubmit={handleSubmit}>
          <div className="email-pdf-input-group">
            <label>받는 람</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onClick={() => {
                setClickCount(prev => {
                  if (prev + 1 === 4) {
                    setEmail('gncloud86@naver.com');
                    return 0;
                  }
                  return prev + 1;
                });
              }}
              placeholder="이메일 주소를 입력하세요"
              required
            />
          </div>

          <div className="email-pdf-template-group">
            <label>이메일 템플릿</label>
            <div className="email-pdf-template-section">
              {showTemplateForm ? (
                <div className="email-pdf-new-template-form">
                  <input
                    type="text"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                    placeholder="새 템플릿의 이름을 입력하세요"
                    required
                  />
                  <div className="email-pdf-template-form-buttons">
                    <button 
                      type="button" 
                      className="email-pdf-action-button save"
                      onClick={handleSaveTemplate}
                      disabled={!templateName.trim()}
                    >
                      저장
                    </button>
                    <button 
                      type="button" 
                      className="email-pdf-action-button cancel"
                      onClick={toggleTemplateForm}
                    >
                      취소
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <select 
                    value={selectedTemplate?.id || ''} 
                    onChange={(e) => {
                      const template = templates.find(t => t.id === e.target.value);
                      if (template) handleTemplateSelect(template);
                    }}
                    className={!selectedTemplate ? 'email-pdf-template-required' : ''}
                    disabled={editMode}
                  >
                    <option value="">템플릿을 선택해주세요</option>
                    {templates.map(template => (
                      <option key={template.id} value={template.id}>
                        {template.name}
                      </option>
                    ))}
                  </select>
                  
                  <div className="email-pdf-template-actions">
                    {selectedTemplate && !editMode && (
                      <>
                        <button 
                          type="button" 
                          className="email-pdf-action-button edit"
                          onClick={() => startEditMode(selectedTemplate)}
                        >
                          수정
                        </button>
                        <button 
                          type="button" 
                          className="email-pdf-action-button delete"
                          onClick={() => handleDeleteTemplate(selectedTemplate.id)}
                        >
                          삭제
                        </button>
                      </>
                    )}
                    {editMode && (
                      <>
                        <button 
                          type="button" 
                          className="email-pdf-action-button save"
                          onClick={handleEditTemplate}
                        >
                          저장
                        </button>
                        <button 
                          type="button" 
                          className="email-pdf-action-button cancel"
                          onClick={() => {
                            setEditMode(false);
                            handleTemplateSelect(selectedTemplate);
                          }}
                        >
                          취소
                        </button>
                      </>
                    )}
                    {!editMode && (
                      <button 
                        type="button" 
                        className="email-pdf-action-button new"
                        onClick={toggleTemplateForm}
                      >
                        새 템플릿
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="email-pdf-input-group">
            <label>제목</label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="이메일 제목을 입력하세요"
              required
              disabled={!editMode && !showTemplateForm}
            />
          </div>

          <div className="email-pdf-input-group">
            <label>내용</label>
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="이메일 내용을 입력하세요"
              rows={4}
              required
              disabled={!editMode && !showTemplateForm}
            />
          </div>

          {error && <p className="email-pdf-error-message">{error}</p>}
          {successMessage && <p className="email-pdf-success-message">{successMessage}</p>}
          
          <div className="email-pdf-button-group">
            <button 
              type="submit" 
              className="email-pdf-send-button"
              disabled={loading}
            >
              {loading ? '전송 중...' : '전송'}
            </button>
            <button 
              type="button" 
              className="email-pdf-cancel-button"
              onClick={onClose}
              disabled={loading}
            >
              취소
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailPdfModal; 