import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import LineupSelector from './LineupSelector';
import './ProcessDescriptionManagement.css';

const ProcessDescriptionManagement = ({ onMessage }) => {
  const [descriptions, setDescriptions] = useState({
    refresh: {},
    harmony: {},
    collection: {}
  });
  const [processes, setProcesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLineup, setSelectedLineup] = useState('refresh');

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      // 공정 목록을 oneClickProcesses에서 로드
      const processesDoc = await getDoc(doc(db, 'oneClickProcesses', 'default'));
      if (processesDoc.exists()) {
        setProcesses(processesDoc.data().processList || []);
      }

      // 라인업별 공정 설명 로드
      const descriptionsDoc = await getDoc(doc(db, 'lineupDescriptions', 'default'));
      if (descriptionsDoc.exists()) {
        setDescriptions(descriptionsDoc.data() || {
          refresh: {},
          harmony: {},
          collection: {}
        });
      }
    } catch (error) {
      console.error('데이터 로드 중 오류:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDescriptionChange = (process, value) => {
    setDescriptions(prev => ({
      ...prev,
      [selectedLineup]: {
        ...prev[selectedLineup],
        [process]: value
      }
    }));
  };

  const handleSave = async () => {
    try {
      await setDoc(doc(db, 'lineupDescriptions', 'default'), descriptions);
      onMessage(`${selectedLineup} 라인업의 설명이 저장되었습니다.`);
    } catch (error) {
      console.error('설명 저장 중 오류:', error);
      onMessage('설명 저장 중 오류가 발생했습니다.');
    }
  };

  if (loading) {
    return <div className="loading">로딩 중...</div>;
  }

  return (
    <div className="content-card">
      <h2 className="card-title">공정 설명 관리</h2>
      <LineupSelector 
        selectedLineup={selectedLineup}
        onLineupChange={setSelectedLineup}
      />
      <div className="description-list">
        {processes.map(process => (
          <div key={process} className="description-item">
            <div className="process-name">{process}</div>
            <div className="description-input-wrapper">
              <textarea
                value={descriptions[selectedLineup][process] || ''}
                onChange={(e) => handleDescriptionChange(process, e.target.value)}
                placeholder={`${process} 공정 설명을 입력하세요`}
                className="description-input"
              />
            </div>
          </div>
        ))}
      </div>
      <button onClick={handleSave} className="save-button">
        {selectedLineup} 라인업 설명 저장
      </button>
    </div>
  );
};

export default ProcessDescriptionManagement; 