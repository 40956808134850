import React, { useState } from 'react';
import './AutoProposal.css';

const FirstPage = () => (
  <div className="auto-proposal-page auto-proposal-first-page">
    <div className="auto-proposal-sidebar">
      <div className="auto-proposal-logo-wrapper">
        <img 
          src="/images/amarelogo.png" 
          alt="AMARE DESIGN" 
          className="auto-proposal-logo"
        />
      </div>
      <div className="auto-proposal-side-text">proposal</div>
    </div>
    <div className="auto-proposal-main-content">
      <div className="auto-proposal-company-info">
        <div>
          <div className="auto-proposal-company-name-en">
            AMARE DESIGN
          </div>
          <div className="auto-proposal-company-name">
            아마레디자인
          </div>
        </div>
        <div className="auto-proposal-contact-section">
          <div className="auto-proposal-contact-title">contact</div>
          <div className="auto-proposal-contact-info">
            www.amaredesign.kr<br />
            Instagram - amare._.design
          </div>
        </div>
      </div>
    </div>
  </div>
);

const SecondPage = () => (
  <div className="auto-proposal-page">
    <div className="auto-proposal-sidebar">
      <div className="auto-proposal-side-text">proposal</div>
    </div>
    <div className="auto-proposal-main-content">
      <div className="auto-proposal-text-content">
        <h1 className="auto-proposal-title">
          차별화된 가치를 만드는<br />
          아마레디자인의 약속
        </h1>
        <ul className="auto-proposal-list">
          <li className="auto-proposal-list-item">
            <span className="auto-proposal-list-number">01</span>
            <span>고객의 사업적 성공이 우리를 성장시킨다.</span>
          </li>
          <li className="auto-proposal-list-item">
            <span className="auto-proposal-list-number">02</span>
            <span>사회에 기여할 때 기업의 존재 이유가 완성된다.</span>
          </li>
          <li className="auto-proposal-list-item">
            <span className="auto-proposal-list-number">03</span>
            <span>기업 가치를 만드는 유일한 길은 정도(正道) 다.</span>
          </li>
        </ul>
      </div>
      <div className="auto-proposal-image-container">
        <img 
          src="/path-to-your-image.jpg" 
          alt="아마레디자인 비전" 
          className="auto-proposal-image"
        />
      </div>
    </div>
  </div>
);

const AutoProposal = () => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className="auto-proposal-container">
      {currentPage === 1 ? <FirstPage /> : <SecondPage />}
    </div>
  );
};

export default AutoProposal; 