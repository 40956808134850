import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, deleteDoc, doc, orderBy } from 'firebase/firestore';
import { db } from '../../../firebase';
import './SavedItemsModal.css';

const SavedItemsModal = ({ processId, onSelect, onClose }) => {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  useEffect(() => {
    loadItems();
  }, [processId]);

  const loadItems = async () => {
    try {
      setLoading(true);
      console.group('📂 저장된 품목 로드');
      console.log('Process ID:', processId);
      
      const q = query(
        collection(db, 'savedItems'),
        where('processId', '==', processId),
        orderBy('createdAt', 'desc')
      );
      
      const snapshot = await getDocs(q);
      console.log('조회된 데이터:', snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      
      const loadedItems = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setItems(loadedItems);
      console.log('✅ 로드 완료');
      console.groupEnd();
    } catch (error) {
      console.error('❌ 저장된 품목 로드 중 오류:', error);
      console.groupEnd();
    } finally {
      setLoading(false);
    }
  };

  const filteredItems = items.filter(item => {
    const searchLower = searchTerm.toLowerCase();
    
    // 평수 검색 처리
    const pyungMatch = searchTerm.match(/(\d+)평/);
    if (pyungMatch) {
      const startPyung = parseInt(pyungMatch[1]);
      const endPyung = startPyung + 9;
      const itemPyungMatch = item.memo?.match(/(\d+)평/) || item.name.match(/(\d+)평/);
      
      if (itemPyungMatch) {
        const itemPyung = parseInt(itemPyungMatch[1]);
        return itemPyung >= startPyung && itemPyung <= endPyung;
      }
      return false;
    }

    // 일반 검색 (품목명 + 메모)
    return (
      item.name.toLowerCase().includes(searchLower) ||
      (item.memo && item.memo.toLowerCase().includes(searchLower))
    );
  });

  const handleDelete = async (e, itemId) => {
    e.stopPropagation(); // 클릭 이벤트 전파 방지
    
    if (window.confirm('이 품목을 삭제하시겠습니까?')) {
      try {
        setDeletingId(itemId);
        await deleteDoc(doc(db, 'savedItems', itemId));
        setItems(prevItems => prevItems.filter(item => item.id !== itemId));
        alert('품목이 삭제되었습니다.');
      } catch (error) {
        console.error('품목 삭제 중 오류:', error);
        alert('삭제 중 오류가 발생했습니다.');
      } finally {
        setDeletingId(null);
      }
    }
  };

  return (
    <div className="saved-items-modal-overlay">
      <div className="saved-items-modal">
        <h3>저장된 품목 목록</h3>
        
        <div className="search-box">
          <input
            type="text"
            placeholder="품목명 검색..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="items-list">
          {loading ? (
            <div className="loading">로딩 중...</div>
          ) : filteredItems.length > 0 ? (
            filteredItems.map(item => (
              <div 
                key={item.id} 
                className={`item-row ${deletingId === item.id ? 'deleting' : ''}`}
              >
                <div className="item-content" onClick={() => onSelect(item)}>
                  <div className="item-name">{item.name}</div>
                  <div className="item-unit">{item.unit}</div>
                  <div className="item-price">{item.unitPrice.toLocaleString()}원</div>
                  {item.memo && <div className="item-memo">{item.memo}</div>}
                </div>
                <button 
                  className="delete-button"
                  onClick={(e) => handleDelete(e, item.id)}
                  disabled={deletingId === item.id}
                >
                  ×
                </button>
              </div>
            ))
          ) : (
            <div className="no-items">
              {searchTerm ? '검색 결과가 없습니다.' : '저장된 품목이 없습니다.'}
            </div>
          )}
        </div>

        <div className="modal-buttons">
          <button onClick={onClose}>닫기</button>
        </div>
      </div>
    </div>
  );
};

export default SavedItemsModal; 