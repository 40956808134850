import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { saveWorkOrderPage, updateWorkOrderPage, fetchWorkOrderPages, deleteWorkOrderPage } from './firebaseService';
import MaterialModal from './MaterialModal';
import LayoutModal from './LayoutModal';
import PageControls from './PageControls';
import PageMinimap from './PageMinimap';
import PdfControls from './PdfControls';
import BulkImageModal from './BulkImageModal';
import './WorkOrder.css';

const WorkOrder = () => {
  const [memo, setMemo] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([{ imageFile: null, memo: '', workType: '' }]);
  const [showWorkTypeModal, setShowWorkTypeModal] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isAlternateLogo, setIsAlternateLogo] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState('');
  const [pageIds, setPageIds] = useState([]);  // Firestore 문서 ID 저장
  const [showMaterialModal, setShowMaterialModal] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [showLayoutModal, setShowLayoutModal] = useState(false);
  const [selectedLayout, setSelectedLayout] = useState('template1');
  const [selectedWorkType, setSelectedWorkType] = useState('');
  const [labels, setLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [isDraggingLabel, setIsDraggingLabel] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [selectedLabelId, setSelectedLabelId] = useState(null);
  const [isLabelDragEnded, setIsLabelDragEnded] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [labelToDelete, setLabelToDelete] = useState(null);
  const [editingPageIndex, setEditingPageIndex] = useState(null);
  const pageRefs = useRef({});
  const [showBulkImageModal, setShowBulkImageModal] = useState(false);

  const workTypes = [
    '도배',
    '바닥',
    '목공',
    '필름',
    '제작가구',
    '에어컨'
  ];

  useEffect(() => {
    // 주소 목록 가져오기
    const fetchAddresses = async () => {
      try {
        const addressesCollection = collection(db, 'addresses');
        const q = query(addressesCollection, orderBy('createdAt', 'desc'));
        const addressesSnapshot = await getDocs(q);
        const addressesList = addressesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setAddresses(addressesList);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses();
  }, []);

  const handleImageUpload = async (file, pageIndex) => {
    if (!file || !selectedAddressId || !pageIds[pageIndex]) return;

    const reader = new FileReader();
    reader.onloadend = async () => {
      try {
        const newPages = [...pages];
        const oldImageUrl = newPages[pageIndex].imageFile;
        
        newPages[pageIndex] = {
          ...newPages[pageIndex],
          imageFile: reader.result
        };
        setPages(newPages);

        const response = await updateWorkOrderPage(
          selectedAddressId, 
          pageIds[pageIndex], 
          {
            ...newPages[pageIndex],
            oldImageUrl
          }
        );

        if (response?.imageUrl) {
          newPages[pageIndex] = {
            ...newPages[pageIndex],
            imageFile: response.imageUrl
          };
          setPages(newPages);
        }
      } catch (error) {
        alert('이미지 업로드 중 오류가 발생했습니다.');
      }
    };
    reader.readAsDataURL(file);
  };

  const handleMemoChange = async (value, pageIndex) => {
    if (!selectedAddressId || !pageIds[pageIndex]) return;

    const newPages = [...pages];
    newPages[pageIndex] = {
      ...newPages[pageIndex],
      memo: value
    };
    setPages(newPages);

    try {
      await updateWorkOrderPage(selectedAddressId, pageIds[pageIndex], newPages[pageIndex]);
    } catch (error) {
      alert('메모 업데이트 중 오류가 발생했습니다.');
    }
  };

  const handleAddressSelect = async (e) => {
    const selectedOption = addresses.find(addr => addr.description === e.target.value);
    setSelectedAddress(e.target.value);
    setSelectedAddressId(selectedOption?.id || '');

    if (selectedOption?.id) {
      try {
        const workOrderPages = await fetchWorkOrderPages(selectedOption.id);
        
        if (workOrderPages.length > 0) {
          const formattedPages = workOrderPages.map(page => ({
            imageFile: page.imageUrl || null,
            memo: page.memo || '',
            workType: page.workType,
            labels: page.labels || [],
            layoutType: page.layoutType
          }));
          
          setPages(formattedPages);
          setPageIds(workOrderPages.map(page => page.id));
          
          if (formattedPages[0]?.labels) {
            setLabels(formattedPages[0].labels);
          }
        } else {
          setPages([{ imageFile: null, memo: '', workType: '', labels: [] }]);
          setPageIds([]);
          setLabels([]);
        }
      } catch (error) {
        alert('작업지시서를 불러오는 중 오류가 발생했습니다.');
      }
    } else {
      setPages([{ imageFile: null, memo: '', workType: '', labels: [] }]);
      setPageIds([]);
      setLabels([]);
    }
  };

  const handleWorkTypeSelect = async (workType) => {
    if (editingPageIndex !== null) {
      // 기존 페이지의 작업 종류 수정
      try {
        const newPages = [...pages];
        newPages[editingPageIndex] = {
          ...newPages[editingPageIndex],
          workType
        };
        setPages(newPages);

        await updateWorkOrderPage(
          selectedAddressId,
          pageIds[editingPageIndex],
          newPages[editingPageIndex]
        );

        setShowWorkTypeModal(false);
        setEditingPageIndex(null);
      } catch (error) {
        alert('작업 종류 수정 중 오류가 발생했습니다.');
      }
      return;
    }

    setSelectedWorkType(workType);
    setShowLayoutModal(true);
  };

  const handleWorkTypeClick = (pageIndex) => {
    setEditingPageIndex(pageIndex);
    setShowWorkTypeModal(true);
  };

  const handleLayoutSelect = async (layoutType) => {
    setSelectedLayout(layoutType);
    setShowLayoutModal(false);
    
    if (!selectedAddressId) return;

    try {
      if (pages.length === 1 && !pages[0].workType) {
        const newPage = { 
          imageFile: null, 
          memo: '', 
          workType: selectedWorkType,
          layoutType 
        };
        const docId = await saveWorkOrderPage(selectedAddressId, newPage);
        setPages([newPage]);
        setPageIds([docId]);
      } else {
        const newPage = { 
          imageFile: null, 
          memo: '', 
          workType: selectedWorkType,
          layoutType 
        };
        const docId = await saveWorkOrderPage(selectedAddressId, newPage);
        setPages([...pages, newPage]);
        setPageIds([...pageIds, docId]);
      }
      setShowWorkTypeModal(false);
    } catch (error) {
      alert('페이지 저장 중 오류가 발생했습니다.');
    }
  };

  const addNewPage = () => {
    setPages([...pages, { imageFile: null, memo: '', workType: '' }]);
    setCurrentPage(pages.length + 1);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e, pageIndex) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      handleImageUpload(file, pageIndex);
    }
  };

  const handleLogoClick = () => {
    setIsAlternateLogo(!isAlternateLogo);
  };

  const handleDeletePage = async (pageIndex) => {
    if (!selectedAddressId || !pageIds[pageIndex]) return;

    if (window.confirm('이 페이지를 삭제하시겠습니까?')) {
      try {
        const pageToDelete = pages[pageIndex];
        const imagesToDelete = [];
        
        // 메인 이미지가 있는 경우
        if (pageToDelete.imageFile && typeof pageToDelete.imageFile === 'string' && !pageToDelete.imageFile.startsWith('data:')) {
          imagesToDelete.push(pageToDelete.imageFile);
        }
        
        // 2D 평면도가 있는 경우
        if (pageToDelete.floorPlanFile && typeof pageToDelete.floorPlanFile === 'string' && !pageToDelete.floorPlanFile.startsWith('data:')) {
          imagesToDelete.push(pageToDelete.floorPlanFile);
        }

        await deleteWorkOrderPage(
          selectedAddressId, 
          pageIds[pageIndex],
          imagesToDelete
        );

        const newPages = pages.filter((_, index) => index !== pageIndex);
        const newPageIds = pageIds.filter((_, index) => index !== pageIndex);
        
        setPages(newPages);
        setPageIds(newPageIds);
      } catch (error) {
        console.error('페이지 삭제 중 오류:', error);
        alert('페이지 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleImageDelete = async (pageIndex) => {
    if (!selectedAddressId || !pageIds[pageIndex]) return;

    if (window.confirm('이미지를 삭제하시겠습니까?')) {
      try {
        const newPages = [...pages];
        const currentPage = newPages[pageIndex];
        
        let imageUrl = null;
        if (typeof currentPage.imageFile === 'string' && !currentPage.imageFile.startsWith('data:')) {
          imageUrl = currentPage.imageFile;
        }
        
        newPages[pageIndex] = {
          ...currentPage,
          imageFile: null,
          imageUrl: null
        };
        
        setPages(newPages);

        if (imageUrl) {
          await updateWorkOrderPage(
            selectedAddressId,
            pageIds[pageIndex],
            {
              ...newPages[pageIndex],
              imageUrl: null,
              oldImageUrl: imageUrl,
              deleteImage: true  // 이미지 삭제 플래그 추가
            }
          );
        }
      } catch (error) {
        console.error('이미지 삭제 중 오류:', error);
        alert('이미지 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleFloorPlanUpload = async (file, pageIndex) => {
    if (!file || !selectedAddressId || !pageIds[pageIndex]) return;

    const reader = new FileReader();
    reader.onloadend = async () => {
      try {
        const newPages = [...pages];
        const oldFloorPlanUrl = newPages[pageIndex].floorPlanFile;
        
        newPages[pageIndex] = {
          ...newPages[pageIndex],
          floorPlanFile: reader.result
        };
        setPages(newPages);

        const response = await updateWorkOrderPage(
          selectedAddressId, 
          pageIds[pageIndex], 
          {
            ...newPages[pageIndex],
            oldFloorPlanUrl,
            isFloorPlan: true
          }
        );

        if (response?.floorPlanUrl) {
          newPages[pageIndex] = {
            ...newPages[pageIndex],
            floorPlanFile: response.floorPlanUrl
          };
          setPages(newPages);
        }
      } catch (error) {
        alert('2D 평면도 업로드 중 오류가 발생했습니다.');
      }
    };
    reader.readAsDataURL(file);
  };

  const handleFloorPlanDrop = (e, pageIndex) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      handleFloorPlanUpload(file, pageIndex);
    }
  };

  const handleFloorPlanDelete = async (pageIndex) => {
    if (!selectedAddressId || !pageIds[pageIndex]) return;

    if (window.confirm('2D 평면도를 삭제하시겠습니까?')) {
      try {
        const newPages = [...pages];
        const currentPage = newPages[pageIndex];
        
        let floorPlanUrl = null;
        if (typeof currentPage.floorPlanFile === 'string' && !currentPage.floorPlanFile.startsWith('data:')) {
          floorPlanUrl = currentPage.floorPlanFile;
        }
        
        newPages[pageIndex] = {
          ...currentPage,
          floorPlanFile: null,
          floorPlanUrl: null
        };
        
        setPages(newPages);

        if (floorPlanUrl) {
          await updateWorkOrderPage(
            selectedAddressId,
            pageIds[pageIndex],
            {
              ...newPages[pageIndex],
              floorPlanUrl: null,
              oldFloorPlanUrl: floorPlanUrl,
              isFloorPlan: true,
              deleteImage: true  // 이미지 삭제 플래그 추가
            }
          );
        }
      } catch (error) {
        console.error('2D 평면도 삭제 중 오류:', error);
        alert('2D 평면도 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleEditClick = (e, labelId) => {
    e.stopPropagation();
    setSelectedLabelId(labelId);
    setShowMaterialModal(true);
  };

  const handleMaterialSave = (materialData) => {
    if (selectedLabelId) {
      const pageIndex = currentPageIndex;
      const currentLabels = pages[pageIndex].labels || [];
      const newLabels = currentLabels.map(label => {
        if (label.id === selectedLabelId) {
          return { ...label, materialInfo: materialData };
        }
        return label;
      });

      const newPages = [...pages];
      newPages[pageIndex] = {
        ...newPages[pageIndex],
        labels: newLabels
      };
      setPages(newPages);
      setLabels(newLabels);
      updatePageLabels(pageIndex, newLabels);
    }
    setShowMaterialModal(false);
    setSelectedLabelId(null);
  };

  const handleLabelDragStart = (e, labelId) => {
    e.stopPropagation();
    setSelectedLabel(labelId);
    setIsDraggingLabel(true);
    setIsLabelDragEnded(false);
  };

  const handleLabelDragEnd = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isDraggingLabel) {
      setSelectedLabel(null);
      setIsDraggingLabel(false);
      setIsLabelDragEnded(true);
      setTimeout(() => {
        setIsLabelDragEnded(false);
      }, 500);
    }
  };

  const handleImageClick = (e, pageIndex) => {
    if (isLabelDragEnded) return;
    
    if (!pages[pageIndex]?.imageFile || isDraggingLabel || selectedLabel !== null) return;

    if (e.target.closest('.image-label')) return;

    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    const currentLabels = pages[pageIndex].labels || [];
    const newLabel = {
      id: Date.now(),
      position: { x, y },
      number: (currentLabels.length + 1),
      materialInfo: null
    };

    const newLabels = [...currentLabels, newLabel];
    
    const newPages = [...pages];
    newPages[pageIndex] = {
      ...newPages[pageIndex],
      labels: newLabels
    };
    setPages(newPages);
    setLabels(newLabels);
    updatePageLabels(pageIndex, newLabels);
  };

  const handleLabelDrag = (e, pageIndex) => {
    if (!isDraggingLabel || selectedLabel === null) return;
    e.stopPropagation();
    e.preventDefault();

    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    const currentLabels = pages[pageIndex].labels || [];
    const newLabels = currentLabels.map(label => {
      if (label.id === selectedLabel) {
        return { ...label, position: { x, y } };
      }
      return label;
    });

    const newPages = [...pages];
    newPages[pageIndex] = {
      ...newPages[pageIndex],
      labels: newLabels
    };
    setPages(newPages);
    setLabels(newLabels);
    updatePageLabels(pageIndex, newLabels);
  };

  const updatePageLabels = async (pageIndex, newLabels) => {
    if (!selectedAddressId || !pageIds[pageIndex]) return;

    try {
      const newPages = [...pages];
      newPages[pageIndex] = {
        ...newPages[pageIndex],
        labels: newLabels
      };
      setPages(newPages);

      await updateWorkOrderPage(
        selectedAddressId,
        pageIds[pageIndex],
        {
          ...newPages[pageIndex],
          labels: newLabels
        }
      );
    } catch (error) {
      alert('라벨 업데이트 중 오류가 발생했습니다.');
    }
  };

  const handleLabelDoubleClick = (e, labelId) => {
    e.stopPropagation();
    setLabelToDelete(labelId);
    setShowDeleteModal(true);
  };

  const handleDeleteLabel = async () => {
    if (!labelToDelete || currentPageIndex === null || !pages[currentPageIndex]) return;

    try {
      const pageIndex = currentPageIndex;
      const currentLabels = pages[pageIndex]?.labels || [];
      const newLabels = currentLabels.filter(label => label.id !== labelToDelete);
      
      // 라벨 번호 재정렬
      const updatedLabels = newLabels.map((label, index) => ({
        ...label,
        number: index + 1
      }));

      const newPages = [...pages];
      newPages[pageIndex] = {
        ...newPages[pageIndex],
        labels: updatedLabels
      };
      
      setPages(newPages);
      setLabels(updatedLabels);
      await updatePageLabels(pageIndex, updatedLabels);
      setShowDeleteModal(false);
      setLabelToDelete(null);
    } catch (error) {
      console.error('라벨 삭제 중 오류:', error);
      alert('라벨 삭제 중 오류가 발생했습니다.');
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (showDeleteModal && e.code === 'Space') {
        e.preventDefault();
        handleDeleteLabel();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [showDeleteModal, labelToDelete]);

  const renderImageLabels = (pageIndex) => {
    const currentLabels = pages[pageIndex]?.labels || [];
    if (currentLabels.length === 0) return null;

    return (
      <div className="label-container">
        {currentLabels.map((label) => (
          <div
            key={label.id}
            className={`image-label ${selectedLabel === label.id ? 'dragging' : ''} ${label.materialInfo ? 'has-material' : ''}`}
            style={{
              left: `${label.position.x}%`,
              top: `${label.position.y}%`,
              transform: 'translate(-50%, -50%)'
            }}
            onMouseDown={(e) => handleLabelDragStart(e, label.id)}
            onMouseUp={handleLabelDragEnd}
            onDoubleClick={(e) => handleLabelDoubleClick(e, label.id)}
          >
            {label.number}
            <div 
              className="edit-icon"
              onClick={(e) => handleEditClick(e, label.id)}
            >
              ✎
            </div>
            {label.materialInfo && (
              <div className="label-info">
                <div className="label-info-content">
                  <div className="label-info-item">
                    <span className="label-info-title">위치:</span>
                    <span className="label-info-value">{label.materialInfo.location}</span>
                  </div>
                  <div className="label-info-item">
                    <span className="label-info-title">종류:</span>
                    <span className="label-info-value">{label.materialInfo.materialType}</span>
                  </div>
                  <div className="label-info-item">
                    <span className="label-info-title">이름:</span>
                    <span className="label-info-value">{label.materialInfo.materialName}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderCoverPage = () => (
    <div className="work-order-container">
      <div className="cover-page">
        <div className="logo-section">
          <div className="logo-text-container" onClick={handleLogoClick}>
            {isAlternateLogo ? (
              <>
                <div className="logo-text">BESPOKE</div>
                <div className="logo-text">SPACE</div>
              </>
            ) : (
              <>
                <div className="logo-text">AMARE</div>
                <div className="logo-text">DESIGN</div>
              </>
            )}
          </div>
          <div className="logo-subtext">작업지시서</div>
        </div>
        <div className="address-select-section">
          <select 
            className="address-select"
            value={selectedAddress}
            onChange={handleAddressSelect}
          >
            <option value="">현장 주소를 선택하세요</option>
            {addresses.map(address => (
              <option key={address.id} value={address.description}>
                {address.description}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );

  const WorkTypeModal = () => (
    <div className="worktype-modal-overlay" onClick={() => setShowWorkTypeModal(false)}>
      <div className="worktype-modal-content" onClick={e => e.stopPropagation()}>
        <h2 className="worktype-modal-title">작업 종류 선택</h2>
        <div className="worktype-modal-grid">
          {workTypes.map((type) => (
            <button
              key={type}
              className="worktype-modal-button"
              onClick={() => handleWorkTypeSelect(type)}
            >
              {type}
            </button>
          ))}
        </div>
      </div>
    </div>
  );

  const renderWorkOrderPage = (pageIndex) => {
    const page = pages[pageIndex];
    const isTemplate2 = page.layoutType === 'template2';
    const pageNumber = pages.filter(p => p.workType).findIndex(p => p === page) + 1;
    const totalPages = pages.filter(p => p.workType).length;
    const currentLabels = page.labels || [];
    const materialsWithLabels = currentLabels
      .filter(label => label.materialInfo)
      .map(label => ({
        ...label.materialInfo,
        labelNumber: label.number
      }));

    return (
      <div className="work-order-container">
        <div className="work-order-header">
          <div className="work-order-title">
            작업지시서 {page?.workType && (
              <>
                : <span 
                    className="work-type-text" 
                    onClick={() => handleWorkTypeClick(pageIndex)}
                  >
                    {page.workType}</span>
              </>
            )}
            <span className="page-number-indicator">(page {pageNumber}/{totalPages})</span>
          </div>
          <div>
            <div className="site-address">{selectedAddress}</div>
            <div className="warning-text">*현장 상황에 따라 변동 될 수 있습니다.</div>
          </div>
        </div>

        <hr className="divider" />

        <div className={`work-order-content ${isTemplate2 ? 'template2' : ''}`}>
          {isTemplate2 ? (
            <>
              <div className="left-section">
                <div className="blueprint-section">
                  {renderBlueprintSection(pageIndex)}
                </div>
                <div className="memo-section">
                  <textarea
                    className="memo-content"
                    value={page?.memo || ''}
                    onChange={(e) => handleMemoChange(e.target.value, pageIndex)}
                    placeholder="작업 지시 사항을 입력하세요..."
                  />
                </div>
              </div>
              <div className="right-section">
                <div className="materials-container">
                  <div className="materials-title">자재 목록</div>
                  {materialsWithLabels.length > 0 ? (
                    <div className="materials-list">
                      {materialsWithLabels.map((material, idx) => (
                        <div key={idx} className="material-item">
                          <div className="material-header">
                            <span className="material-label-number">[{material.labelNumber}]</span>
                            <div className="material-info">
                              {material.location} / {material.materialType} / {material.materialName}
                            </div>
                            <div className="material-controls">
                              <button 
                                onClick={(e) => handleEditClick(e, material.labelId)}
                                title="자재 정보 수정"
                              >
                                ✎
                              </button>
                              <button 
                                className="delete-button"
                                onClick={(e) => handleLabelDoubleClick(e, material.labelId)}
                                title="라벨 삭제"
                              >
                                ×
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div style={{ color: '#666', fontSize: '13px', textAlign: 'center', marginTop: '10px' }}>
                      등록된 자재가 없습니다
                    </div>
                  )}
                </div>
                <div className="floor-plan-section">
                  <div className="floor-plan-title">2D 평면도</div>
                  {renderFloorPlanSection(pageIndex)}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="blueprint-section">
                {renderBlueprintSection(pageIndex)}
              </div>
              <div className="right-section">
                <div className="materials-container">
                  <div className="materials-title">자재 목록</div>
                  {materialsWithLabels.length > 0 ? (
                    <div className="materials-list">
                      {materialsWithLabels.map((material, idx) => (
                        <div key={idx} className="material-item">
                          <div className="material-header">
                            <span className="material-label-number">[{material.labelNumber}]</span>
                            <div className="material-info">
                              {material.location} / {material.materialType} / {material.materialName}
                            </div>
                            <div className="material-controls">
                              <button 
                                onClick={(e) => handleEditClick(e, material.labelId)}
                                title="자재 정보 수정"
                              >
                                ✎
                              </button>
                              <button 
                                className="delete-button"
                                onClick={(e) => handleLabelDoubleClick(e, material.labelId)}
                                title="라벨 삭제"
                              >
                                ×
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div style={{ color: '#666', fontSize: '13px', textAlign: 'center', marginTop: '10px' }}>
                      등록된 자재가 없습니다
                    </div>
                  )}
                </div>
                <div className="memo-section">
                  <textarea
                    className="memo-content"
                    value={page?.memo || ''}
                    onChange={(e) => handleMemoChange(e.target.value, pageIndex)}
                    placeholder="작업 지시 사항을 입력하세요..."
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderBlueprintSection = (pageIndex) => (
    <div 
      className={`blueprint-upload-area ${isDragging ? 'dragging' : ''}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={(e) => handleDrop(e, pageIndex)}
    >
      <input 
        type="file" 
        accept="image/*" 
        onChange={(e) => handleImageUpload(e.target.files[0], pageIndex)}
        style={{ display: 'none' }}
        id={`blueprint-upload-${pageIndex}`}
      />
      {pages[pageIndex]?.imageFile ? (
        <div 
          className="blueprint-image-container"
          onClick={(e) => handleImageClick(e, pageIndex)}
          onMouseMove={(e) => handleLabelDrag(e, pageIndex)}
          onMouseUp={handleLabelDragEnd}
          onMouseLeave={handleLabelDragEnd}
        >
          <img 
            src={pages[pageIndex].imageFile} 
            alt="도면" 
            className="blueprint-image"
            draggable={false}
          />
          {renderImageLabels(pageIndex)}
          <button 
            className="image-delete-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleImageDelete(pageIndex);
            }}
          >
            ×
          </button>
          <div className={`image-overlay ${isDragging ? 'dragging' : ''}`}>
            <div className="overlay-text">
              {isDragging ? '새 이미지를 여기에 놓으세요' : ''}
            </div>
          </div>
        </div>
      ) : (
        <label 
          htmlFor={`blueprint-upload-${pageIndex}`}
          className="blueprint-upload-label"
        >
          <div className="upload-icon">
            <i className="fas fa-cloud-upload-alt"></i>
          </div>
          <div className="upload-text">
            {isDragging ? 
              '이미지를 여기에 놓으세요' : 
              '도면 이미지를 드래그하거나 클릭하여 업로드하세요'}
          </div>
        </label>
      )}
    </div>
  );

  const renderFloorPlanSection = (pageIndex) => (
    <div 
      className="floor-plan-upload-area"
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={(e) => handleFloorPlanDrop(e, pageIndex)}
    >
      <input 
        type="file" 
        accept="image/*" 
        onChange={(e) => handleFloorPlanUpload(e.target.files[0], pageIndex)}
        style={{ display: 'none' }}
        id={`floor-plan-upload-${pageIndex}`}
      />
      {pages[pageIndex]?.floorPlanFile ? (
        <div className="floor-plan-image-container">
          <img 
            src={pages[pageIndex].floorPlanFile} 
            alt="2D 평면도" 
            className="floor-plan-image"
          />
          <button 
            className="image-delete-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleFloorPlanDelete(pageIndex);
            }}
          >
            ×
          </button>
        </div>
      ) : (
        <label 
          htmlFor={`floor-plan-upload-${pageIndex}`}
          className="floor-plan-upload-label"
        >
          <div className="upload-icon">
            <i className="fas fa-cloud-upload-alt"></i>
          </div>
          <div className="upload-text">
            2D 평면도를 업로드하세요
          </div>
        </label>
      )}
    </div>
  );

  const handleDuplicatePage = async (pageIndex) => {
    if (!selectedAddressId || !pageIds[pageIndex]) return;

    try {
      const pageToDuplicate = pages[pageIndex];
      const newPage = {
        ...pageToDuplicate,
        imageFile: null,
        floorPlanFile: null,
        memo: pageToDuplicate.memo,
        workType: pageToDuplicate.workType,
        layoutType: pageToDuplicate.layoutType
      };

      const docId = await saveWorkOrderPage(selectedAddressId, newPage);
      setPages([...pages, newPage]);
      setPageIds([...pageIds, docId]);
    } catch (error) {
      alert('페이지 복제 중 오류가 발생했습니다.');
    }
  };

  const handlePageSelect = (index) => {
    setCurrentPageIndex(index);
    const pageElement = pageRefs.current[index];
    if (pageElement) {
      pageElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const handlePageReorder = async (sourceIndex, targetIndex) => {
    if (!selectedAddress) return;

    try {
      const newPages = [...pages];
      const newPageIds = [...pageIds];
      
      // 페이지 순서 변경
      const [movedPage] = newPages.splice(sourceIndex, 1);
      const [movedPageId] = newPageIds.splice(sourceIndex, 1);
      
      newPages.splice(targetIndex, 0, movedPage);
      newPageIds.splice(targetIndex, 0, movedPageId);

      setPages(newPages);
      setPageIds(newPageIds);
      
      // 현재 페이지 인덱스 업데이트
      if (currentPageIndex === sourceIndex) {
        setCurrentPageIndex(targetIndex);
      } else if (
        currentPageIndex > sourceIndex && 
        currentPageIndex <= targetIndex
      ) {
        setCurrentPageIndex(currentPageIndex - 1);
      } else if (
        currentPageIndex < sourceIndex && 
        currentPageIndex >= targetIndex
      ) {
        setCurrentPageIndex(currentPageIndex + 1);
      }

      // Firebase 순서 업데이트 로직은 여기에 추가
      // 현재는 로컬 상태만 업데이트

    } catch (error) {
      alert('페이지 순서 변경 중 오류가 발생했습니다.');
    }
  };

  const handleBulkImageUpload = async (images) => {
    try {
      const newPages = [];
      const newPageIds = [];

      for (const image of images) {
        const reader = new FileReader();
        
        // 각 이미지를 비동기적으로 처리하기 위한 Promise 생성
        const processImage = new Promise((resolve) => {
          reader.onloadend = async () => {
            try {
              const newPage = {
                imageFile: reader.result,
                memo: '',
                workType: image.workType,
                layoutType: 'template1',
                labels: []
              };

              const docId = await saveWorkOrderPage(selectedAddressId, newPage);
              resolve({ page: newPage, id: docId });
            } catch (error) {
              console.error('이미지 처리 중 오류:', error);
              resolve(null);
            }
          };
          reader.readAsDataURL(image.file);
        });

        const result = await processImage;
        if (result) {
          newPages.push(result.page);
          newPageIds.push(result.id);
        }
      }

      setPages(prev => [...prev, ...newPages]);
      setPageIds(prev => [...prev, ...newPageIds]);
      setShowBulkImageModal(false);
    } catch (error) {
      alert('이미지 일괄 업로드 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="pages-wrapper">
      <div className={`pages-container ${pages.length > 1 ? 'has-multiple-pages' : ''}`}>
        {renderCoverPage()}
        {selectedAddress && pages.map((page, index) => (
          page.workType && (
            <div 
              key={index}
              ref={el => pageRefs.current[index] = el}
              className={`page-wrapper ${currentPageIndex === index ? 'active' : ''}`}
            >
              {renderWorkOrderPage(index)}
            </div>
          )
        ))}
      </div>
      {selectedAddress && (
        <>
          <PageControls
            onAddWorkType={() => setShowWorkTypeModal(true)}
            onAddMaterial={() => setShowMaterialModal(true)}
            onDelete={() => handleDeletePage(currentPageIndex)}
            onDuplicate={() => handleDuplicatePage(currentPageIndex)}
            onBulkImageUpload={() => setShowBulkImageModal(true)}
            hasPages={pages.some(page => page.workType)}
          />
          {pages.some(page => page.workType) && (
            <>
              <PdfControls
                currentPage={currentPageIndex}
                pages={pages.filter(page => page.workType)}
                pageRefs={pageRefs}
              />
              <PageMinimap
                pages={pages.filter(page => page.workType)}
                currentPage={currentPageIndex}
                onPageSelect={handlePageSelect}
                onPageReorder={handlePageReorder}
              />
            </>
          )}
        </>
      )}
      {showWorkTypeModal && <WorkTypeModal />}
      {showLayoutModal && <LayoutModal onSelect={handleLayoutSelect} onClose={() => setShowLayoutModal(false)} />}
      {showMaterialModal && (
        <MaterialModal
          onClose={() => setShowMaterialModal(false)}
          onSave={handleMaterialSave}
        />
      )}
      {showBulkImageModal && (
        <BulkImageModal
          onClose={() => setShowBulkImageModal(false)}
          onUpload={handleBulkImageUpload}
          workTypes={workTypes}
        />
      )}
      {showDeleteModal && (
        <div className="delete-modal-overlay" onClick={() => setShowDeleteModal(false)}>
          <div className="delete-modal-content" onClick={e => e.stopPropagation()}>
            <h3>라벨 삭제</h3>
            <p>이 라벨을 삭제하시겠습니까?</p>
            <p className="delete-modal-hint">(스페이스바를 누르면 삭제됩니다)</p>
            <div className="delete-modal-buttons">
              <button onClick={handleDeleteLabel} className="delete-confirm-button">삭제</button>
              <button onClick={() => setShowDeleteModal(false)} className="delete-cancel-button">취소</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkOrder; 