import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import LineupSelector from './LineupSelector';
import './PriceManagement.css';
import { FaFileExport, FaFileUpload } from 'react-icons/fa';

const PriceManagement = ({ onMessage }) => {
  const [area, setArea] = useState('');
  const [prices, setPrices] = useState({
    refresh: {},
    harmony: {},
    collection: {}
  });
  const [processes, setProcesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLineup, setSelectedLineup] = useState('refresh');
  const [areas, setAreas] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    loadData();
    loadPrices();
  }, []);

  useEffect(() => {
    if (area) {
      loadPrices();
    }
  }, [area, selectedLineup]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, 'lineupPrices', 'default'),
      (doc) => {
        if (doc.exists()) {
          const loadedPrices = doc.data();
          setPrices(prevPrices => ({
            ...prevPrices,
            ...loadedPrices
          }));
        }
      },
      (error) => {
        console.error('가격 데이터 실시간 업데이트 중 오류:', error);
      }
    );

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const loadAreas = async () => {
      try {
        const areasDoc = await getDoc(doc(db, 'settings', 'areas'));
        if (areasDoc.exists()) {
          const areasList = areasDoc.data().list || [];
          setAreas(areasList);
          
          if (areasList.length > 0) {
            setArea(areasList[0]);
          }
        }
      } catch (error) {
        console.error('평수 목록 로드 중 오류:', error);
      }
    };

    loadAreas();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const processesDoc = await getDoc(doc(db, 'oneClickProcesses', 'default'));
      if (processesDoc.exists()) {
        setProcesses(processesDoc.data().processList || []);
      }
    } catch (error) {
      console.error('데이터 로드 중 오류:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadPrices = async () => {
    try {
      const pricesDoc = await getDoc(doc(db, 'lineupPrices', 'default'));
      if (pricesDoc.exists()) {
        const loadedPrices = pricesDoc.data();
        setPrices(prevPrices => ({
          ...prevPrices,
          ...loadedPrices
        }));
      }
    } catch (error) {
      console.error('가격 데이터 로드 중 오류:', error);
      onMessage('가격 데이터 로드 중 오류가 발생했습니다.');
    }
  };

  const handlePriceChange = (process, value) => {
    // 숫자, 쉼표, 한글 허용
    const formattedValue = value.replace(/[^\d,가-힣\s]/g, '');
    
    setPrices(prev => ({
      ...prev,
      [selectedLineup]: {
        ...prev[selectedLineup],
        [area]: {
          ...prev[selectedLineup][area],
          [process]: formattedValue
        }
      }
    }));
  };

  const formatNumber = (value) => {
    if (!value && value !== 0) return '';
    
    // value를 문자열로 변환
    const stringValue = String(value);
    
    // 숫자인 경우에만 쉼표 포맷팅 적용
    if (!isNaN(stringValue.replace(/,/g, ''))) {
      const number = stringValue.replace(/,/g, '');
      return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    // 한글 등 텍스트는 그대로 반환
    return stringValue;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // 가격 데이터 저장 전 처리
      const processedPrices = {};
      Object.keys(prices).forEach(lineup => {
        processedPrices[lineup] = {};
        Object.keys(prices[lineup]).forEach(area => {
          processedPrices[lineup][area] = {};
          Object.keys(prices[lineup][area] || {}).forEach(process => {
            const value = prices[lineup][area][process];
            
            // value가 null이나 undefined인 경우 처리
            if (!value && value !== 0) {
              processedPrices[lineup][area][process] = '';
              return;
            }

            // 문자열로 변환 후 처리
            const stringValue = String(value);
            
            // 숫자인 경우에만 parseInt 적용
            if (!isNaN(stringValue.replace(/,/g, ''))) {
              processedPrices[lineup][area][process] = parseInt(stringValue.replace(/,/g, '')) || 0;
            } else {
              // 텍스트는 그대로 저장
              processedPrices[lineup][area][process] = stringValue;
            }
          });
        });
      });

      await setDoc(doc(db, 'lineupPrices', 'default'), processedPrices);
      onMessage(`${selectedLineup} 라인업의 가격이 저장되었습니다.`);
    } catch (error) {
      console.error('가격 저장 중 오류:', error);
      onMessage('가격 저장 중 오류가 발생했습니다.');
    }
  };

  // CSV 내보내기 함수
  const handleExportCSV = () => {
    try {
      let csvContent = "라인업,평수,공정,가격\n";
      
      Object.entries(prices).forEach(([lineup, lineupData]) => {
        Object.entries(lineupData).forEach(([area, areaData]) => {
          Object.entries(areaData).forEach(([process, price]) => {
            const row = [
              lineup,
              area,
              process,
              price.toString().replace(/,/g, '')
            ];
            csvContent += row.join(",") + "\n";
          });
        });
      });

      const blob = new Blob(["\ufeff" + csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      
      const today = new Date().toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).replace(/\./g, '').replace(/ /g, '');
      
      link.setAttribute('href', url);
      link.setAttribute('download', `아마레_가격표_${today}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      onMessage('CSV 파일이 다운로드되었습니다.');
    } catch (error) {
      console.error('CSV 내보내기 중 오류:', error);
      onMessage('CSV 내보내기 중 오류가 발생했습니다.');
    }
  };

  // CSV 불러오기 함수
  const handleImportCSV = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const text = e.target.result;
        const rows = text.split('\n');
        const headers = rows[0].split(',');
        
        // 새로운 가격 데이터 객체 초기화
        const newPrices = {
          refresh: {},
          harmony: {},
          collection: {}
        };

        // CSV 데이터 파싱
        for (let i = 1; i < rows.length; i++) {
          if (!rows[i].trim()) continue;
          
          const columns = rows[i].split(',');
          const lineup = columns[0];
          const area = columns[1];
          const process = columns[2];
          const price = columns[3];

          if (!newPrices[lineup][area]) {
            newPrices[lineup][area] = {};
          }
          newPrices[lineup][area][process] = price;
        }

        // Firestore에 저장
        await setDoc(doc(db, 'lineupPrices', 'default'), newPrices);
        setPrices(newPrices);
        onMessage('가격 데이터가 공적으로 업로드되었습니다.');
        
      } catch (error) {
        console.error('CSV 불러오기 중 오류:', error);
        onMessage('CSV 불러오기 중 오류가 발생했습니다.');
      }
    };
    reader.readAsText(file);
  };

  if (loading) {
    return <div className="loading">로딩 중...</div>;
  }

  return (
    <div className="content-card">
      <h2 className="card-title">가격 관리</h2>
      
      <div className="csv-controls">
        <button onClick={handleExportCSV} className="csv-button">
          CSV 내보내기
        </button>
        <button onClick={() => fileInputRef.current.click()} className="csv-button">
          CSV 불러오기
        </button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImportCSV}
          accept=".csv"
          style={{ display: 'none' }}
        />
      </div>

      <LineupSelector 
        selectedLineup={selectedLineup}
        onLineupChange={setSelectedLineup}
      />
      <div className="select-wrapper">
        <select 
          className="admin-select" 
          value={area} 
          onChange={(e) => setArea(e.target.value)}
        >
          {areas.map(a => (
            <option key={a} value={a}>{a}</option>
          ))}
        </select>
      </div>
      <form onSubmit={handleSubmit} className="price-form">
        {processes.map(process => (
          <div key={process} className="price-input-group">
            <label>{process}</label>
            <div className="input-wrapper">
              <input
                type="text"
                value={formatNumber(prices[selectedLineup]?.[area]?.[process] || '')}
                onChange={(e) => handlePriceChange(process, e.target.value)}
                placeholder="가격 입력"
                className="price-input"
              />
            </div>
          </div>
        ))}
        <button type="submit" className="submit-button">
          {selectedLineup} 라인업 가격 저장
        </button>
      </form>
    </div>
  );
};

export default PriceManagement; 