import { db, storage } from '../../firebase';
import { 
  collection, 
  addDoc, 
  updateDoc,
  doc,
  serverTimestamp,
  getDocs,
  orderBy,
  query,
  deleteDoc
} from 'firebase/firestore';
import { 
  ref, 
  uploadBytes, 
  getDownloadURL,
  deleteObject,
  uploadString
} from 'firebase/storage';

// 이미지를 Firebase Storage에 업로드하고 URL을 반환하는 함수
export const uploadImage = async (file, addressId, workType) => {
  if (!file) return null;

  try {
    const timestamp = new Date().getTime();
    const path = `addresses/${addressId}/workOrders/${timestamp}_${workType}.jpg`;
    const storageRef = ref(storage, path);
    const snapshot = await uploadBytes(storageRef, file);
    const imageUrl = await getDownloadURL(snapshot.ref);
    return imageUrl;
  } catch (error) {
    throw error;
  }
};

// base64 이미지를 File 객체로 변환하는 함수
export const base64ToFile = (base64String, filename) => {
  const arr = base64String.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

// 작업지시서 페이지를 Firestore에 저장하는 함수
export const saveWorkOrderPage = async (addressId, pageData) => {
  try {
    const workOrderRef = collection(db, 'addresses', addressId, 'workOrders');
    const docRef = await addDoc(workOrderRef, {
      memo: pageData.memo || '',
      workType: pageData.workType || '',
      layoutType: pageData.layoutType || 'template1',
      labels: pageData.labels || [],
      createdAt: new Date()
    });

    if (pageData.imageFile) {
      if (pageData.imageFile.startsWith('data:')) {
        const imageRef = ref(storage, `workOrders/${addressId}/${docRef.id}/image`);
        await uploadString(imageRef, pageData.imageFile, 'data_url');
        const imageUrl = await getDownloadURL(imageRef);
        await updateDoc(docRef, { imageUrl });
      }
    }

    return docRef.id;
  } catch (error) {
    console.error('Error saving work order page:', error);
    throw error;
  }
};

// Storage에서 이미지를 삭제하는 함수
export const deleteStorageImage = async (imageUrl) => {
  if (!imageUrl) return;

  try {
    const imageRef = ref(storage, imageUrl);
    await deleteObject(imageRef);
  } catch (error) {
    throw error;
  }
};

// 작업지시서 페이지를 업데이트하는 함수
export const updateWorkOrderPage = async (addressId, pageId, pageData) => {
  try {
    const pageRef = doc(db, 'addresses', addressId, 'workOrders', pageId);
    const updateData = {
      memo: pageData.memo || '',
      workType: pageData.workType || '',
      layoutType: pageData.layoutType || 'template1',
      labels: pageData.labels || [],
      updatedAt: new Date()
    };

    // 이미지 처리
    if (pageData.imageFile) {
      if (pageData.imageFile.startsWith('data:')) {
        const imageRef = ref(storage, `workOrders/${addressId}/${pageId}/image`);
        await uploadString(imageRef, pageData.imageFile, 'data_url');
        const imageUrl = await getDownloadURL(imageRef);
        updateData.imageUrl = imageUrl;
      }
    }

    // 이전 이미지 삭제
    if (pageData.oldImageUrl) {
      try {
        const oldImageRef = ref(storage, pageData.oldImageUrl);
        await deleteObject(oldImageRef);
      } catch (error) {
        console.error('Error deleting old image:', error);
      }
    }

    await updateDoc(pageRef, updateData);

    return { ...updateData, imageUrl: updateData.imageUrl };
  } catch (error) {
    console.error('Error updating work order page:', error);
    throw error;
  }
};

// 주소에 해당하는 작업지시서 페이지들을 불러오는 함수
export const fetchWorkOrderPages = async (addressId) => {
  try {
    const workOrderRef = collection(db, 'addresses', addressId, 'workOrders');
    const snapshot = await getDocs(workOrderRef);
    
    const pages = snapshot.docs.map(doc => ({
      id: doc.id,
      imageUrl: doc.data().imageUrl || null,
      memo: doc.data().memo || '',
      workType: doc.data().workType || '',
      layoutType: doc.data().layoutType || 'template1',
      labels: doc.data().labels || [],
      createdAt: doc.data().createdAt
    }));

    // createdAt 기준으로 정렬
    return pages.sort((a, b) => {
      if (!a.createdAt || !b.createdAt) return 0;
      return a.createdAt.seconds - b.createdAt.seconds;
    });
  } catch (error) {
    console.error('Error fetching work order pages:', error);
    throw error;
  }
};

// 작업지시서 페이지를 삭제하는 함수
export const deleteWorkOrderPage = async (addressId, pageId, imagesToDelete) => {
  try {
    // 이미지 삭제
    if (Array.isArray(imagesToDelete) && imagesToDelete.length > 0) {
      for (const imageUrl of imagesToDelete) {
        if (imageUrl && !imageUrl.startsWith('data:')) {
          try {
            const imageRef = ref(storage, imageUrl);
            await deleteObject(imageRef);
          } catch (error) {
            console.error('이미지 삭제 중 오류:', error);
          }
        }
      }
    }

    // 문서 삭제
    const pageRef = doc(db, 'addresses', addressId, 'workOrders', pageId);
    await deleteDoc(pageRef);
  } catch (error) {
    console.error('작업지시서 페이지 삭제 중 오류:', error);
    throw error;
  }
}; 