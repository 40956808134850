import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, query, deleteDoc, doc, addDoc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './DatabaseManager.css';
import { FaFileExport } from 'react-icons/fa';
import TemplateTable from './components/TemplateTable';
import TemplateDetailModal from './components/TemplateDetailModal';
import TemplateAddEditModal from './components/TemplateAddEditModal';

const DatabaseManager = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedLineup, setSelectedLineup] = useState('전체');
  const [selectedSize, setSelectedSize] = useState('전체');
  const [selectedProcess, setSelectedProcess] = useState('전체');
  const [availableSizes, setAvailableSizes] = useState(['전체']);
  const [availableProcesses, setAvailableProcesses] = useState(['전체']);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);

  const lineups = ['전체', 'refresh', 'harmony', 'collection'];

  // 평수 배열 수정
  const sizes = ['전체', '28평대', '33평대', '40평대', '48평대', '56평대'];

  useEffect(() => {
    const templatesRef = collection(db, 'ai-templates');
    
    // Realtime Listener 설정
    const unsubscribe = onSnapshot(query(templatesRef), (snapshot) => {
      const templatesData = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          lineup: data.userInput?.specialNotes?.includes('리프레쉬') ? 'refresh' :
                  data.userInput?.specialNotes?.includes('하모니') ? 'harmony' :
                  data.userInput?.specialNotes?.includes('컬렉션') ? 'collection' :
                  'refresh'
        };
      }).sort((a, b) => {
        // 라인업 순서 정의
        const lineupOrder = {
          'refresh': 1,    // 리프레쉬를 첫번째로
          'harmony': 2,    // 하모니를 두번째로
          'collection': 3  // 컬렉션을 세번째로
        };
        
        // 먼저 라인업으로 정렬
        const lineupCompare = lineupOrder[a.lineup] - lineupOrder[b.lineup];
        
        // 라인업이 같은 경우 평수로 정렬
        if (lineupCompare === 0) {
          const sizeA = parseInt(a.userInput?.size) || 0;
          const sizeB = parseInt(b.userInput?.size) || 0;
          return sizeA - sizeB;
        }
        
        return lineupCompare;
      });

      // 사용 가능한 평수와 공정 목록 추출
      const sizes = new Set(['전체']);
      const processes = new Set(['전체']);
      templatesData.forEach(template => {
        if (template.userInput?.size) {
          sizes.add(template.userInput.size);
        }
        if (template.processName) {
          processes.add(template.processName);
        }
      });

      setAvailableSizes(Array.from(sizes).sort((a, b) => {
        if (a === '전체') return -1;
        if (b === '전체') return 1;
        return a.localeCompare(b);
      }));

      setAvailableProcesses(Array.from(processes).sort((a, b) => {
        if (a === '전체') return -1;
        if (b === '전체') return 1;
        return a.localeCompare(b);
      }));

      setTemplates(templatesData);
    }, (error) => {
      console.error('템플릿 로드 중 오류:', error);
    });

    // 컴포넌트 언마운트 시 리스너 해제
    return () => unsubscribe();
  }, []);

  const handleDeleteTemplate = async (templateId) => {
    if (window.confirm('정말로 이 템플릿을 삭제하시겠습니까?')) {
      try {
        await deleteDoc(doc(db, 'ai-templates', templateId));
        // Realtime Listener가 자동으로 UI를 업데이트하므로
        // setTemplates는 더 이상 필요하지 않습니다.
      } catch (error) {
        console.error('템플릿 삭제 중 오류:', error);
        alert('템플릿 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleCloneTemplate = async (template) => {
    try {
      // id를 제외한 나머지 데이터를 그로 복사
      const { id, ...templateData } = template;
      
      // 새로운 문서 생성 (원본 데이터를 그대로 사용)
      const docRef = await addDoc(collection(db, 'ai-templates'), templateData);
      console.log('Template cloned with ID: ', docRef.id);
      
    } catch (error) {
      console.error('템플릿 복제 중 오류:', error);
      alert('템플릿 복제 중 오류가 발생했습니다.');
    }
  };

  const filteredTemplates = templates.filter(template => {
    const matchLineup = selectedLineup === '전체' || template.lineup === selectedLineup;
    const matchSize = selectedSize === '전체' || 
      template.userInput?.size === selectedSize.replace('평대', '');
    const matchProcess = selectedProcess === '전체' || template.processName === selectedProcess;
    return matchLineup && matchSize && matchProcess;
  });

  const exportToCSV = () => {
    const headers = ['라인업', '평수', '공정명', '요구사항', '키워드', '특이사항', '아이템목록', '총액'];
    const csvData = filteredTemplates.map(template => [
      template.lineup || '',
      template.userInput?.size || '',
      template.processName || '',
      template.userInput?.requirement || '',
      template.userInput?.keywords || '',
      template.userInput?.specialNotes || '',
      template.items?.map(item => `${item.name}(${item.quantity}${item.unit})`).join(', ') || '',
      template.items?.reduce((sum, item) => sum + (Number(item.quantity) * Number(item.unitPrice)), 0).toLocaleString() + '원'
    ]);

    const csvContent = [headers, ...csvData].map(row => row.join(',')).join('\n');
    const blob = new Blob(['\uFEFF' + csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `템플릿_데이터_${new Date().toLocaleDateString()}.csv`;
    link.click();
  };

  const handleAddTemplate = () => {
    setEditingTemplate(null);
    setIsAddEditModalOpen(true);
  };

  const handleEditTemplate = (template) => {
    setEditingTemplate(template);
    setIsAddEditModalOpen(true);
  };

  const handleSaveTemplate = async (formData) => {
    try {
      if (editingTemplate) {
        // 템플릿 수정
        const updatedTemplate = {
          ...editingTemplate,  // 기��� 템플릿 데이터 보존
          lineup: formData.lineup,
          processName: formData.processName,
          userInput: {
            ...editingTemplate.userInput,  // 기존 userInput 데이터 보존
            size: formData.size,
            specialNotes: formData.lineup === 'refresh' ? '리프레쉬' :
                         formData.lineup === 'harmony' ? '하모니' :
                         formData.lineup === 'collection' ? '컬렉션' : ''
          }
        };

        await updateDoc(doc(db, 'ai-templates', editingTemplate.id), {
          lineup: updatedTemplate.lineup,
          processName: updatedTemplate.processName,
          userInput: updatedTemplate.userInput
        });
      } else {
        // 새 템플릿 추가
        await addDoc(collection(db, 'ai-templates'), {
          lineup: formData.lineup,
          processName: formData.processName,
          userInput: {
            size: formData.size,
            specialNotes: formData.lineup === 'refresh' ? '리프레쉬' :
                         formData.lineup === 'harmony' ? '하모니' :
                         formData.lineup === 'collection' ? '컬렉션' : ''
          },
          items: []
        });
      }
      setIsAddEditModalOpen(false);
    } catch (error) {
      console.error('템플릿 저장 중 오류:', error);
      alert('템플릿 저장 중 오류가 발생했습니다.');
    }
  };

  const handleOneMinEstimate = async (template) => {
    try {
      // 1. 템플릿의 평수 정보 확인 및 변환
      const templateSize = template.userInput?.size;
      const areaRange = `${templateSize}평대`;
      
      if (!templateSize) {
        alert('템플릿에 평수 정보가 없습니다.');
        return;
      }

      // 2. 템플릿 아이템들의 총 가격 계산
      const totalPrice = template.items.reduce((sum, item) => {
        return sum + (parseInt(item.quantity) * parseInt(item.unitPrice));
      }, 0);

      // 3. process-mappings에서 매핑 정보 가져오기
      const mappingDoc = await getDoc(doc(db, 'process-mappings', 'default'));
      if (!mappingDoc.exists()) {
        alert('공정 매핑 정보가 없습니다.');
        return;
      }

      const mappings = mappingDoc.data();
      const oneMinProcess = mappings[template.processId];
      
      if (!oneMinProcess) {
        alert('이 공정에 대한 1분견적이 없습니다.');
        return;
      }

      // 4. lineupPrices 문서 가져오기
      const lineupPricesDoc = await getDoc(doc(db, 'lineupPrices', 'default'));
      const lineupPrices = lineupPricesDoc.exists() ? lineupPricesDoc.data() : {};

      // 5. 해당 라인업의 가격 데이터 업데이트
      const updatedPrices = {
        ...lineupPrices,
        [template.lineup]: {
          ...lineupPrices[template.lineup],
          [areaRange]: {
            ...lineupPrices[template.lineup]?.[areaRange],
            [oneMinProcess]: totalPrice.toString()
          }
        }
      };

      // 6. Firestore에 저장
      await setDoc(doc(db, 'lineupPrices', 'default'), updatedPrices);

      const lineupNames = {
        'refresh': '리프레쉬',
        'harmony': '하모니',
        'collection': '컬렉션'
      };

      alert(`${lineupNames[template.lineup]} 라인업 ${areaRange}의 ${oneMinProcess} 공정 가격이 ${totalPrice.toLocaleString()}원으로 업데이트되었습니다.`);

    } catch (error) {
      console.error('1분견적 적용 중 오류:', error);
      alert('1분견적 적용 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="database-manager">
      <div className="database-header">
        <h2>데이터베이스 관리</h2>
        <div className="header-controls">
          <div className="filters">
            <select 
              value={selectedLineup} 
              onChange={(e) => setSelectedLineup(e.target.value)}
            >
              {lineups.map(lineup => (
                <option key={lineup} value={lineup}>
                  {lineup === 'refresh' ? '리프레쉬' : 
                   lineup === 'harmony' ? '하모니' : 
                   lineup === 'collection' ? '컬렉션' : lineup}
                </option>
              ))}
            </select>
            <select 
              value={selectedSize} 
              onChange={(e) => setSelectedSize(e.target.value)}
            >
              {sizes.map(size => (
                <option key={size} value={size}>{size}</option>
              ))}
            </select>
            <select 
              value={selectedProcess}
              onChange={(e) => setSelectedProcess(e.target.value)}
            >
              {availableProcesses.map(process => (
                <option key={process} value={process}>{process}</option>
              ))}
            </select>
          </div>
          <div className="action-buttons">
            <button onClick={handleAddTemplate} className="add-button">
              템플릿 추가
            </button>
            <button onClick={exportToCSV} className="export-button">
              <FaFileExport /> 내보내기
            </button>
          </div>
        </div>
      </div>

      <div className="database-content">
        <TemplateTable 
          templates={filteredTemplates}
          onTemplateClick={setSelectedTemplate}
          onDeleteTemplate={handleDeleteTemplate}
          onCloneTemplate={handleCloneTemplate}
          onEditTemplate={handleEditTemplate}
          onOneMinEstimate={handleOneMinEstimate}
        />
      </div>

      {selectedTemplate && (
        <TemplateDetailModal
          template={selectedTemplate}
          onClose={() => setSelectedTemplate(null)}
        />
      )}

      {isAddEditModalOpen && (
        <TemplateAddEditModal
          template={editingTemplate}
          onSave={handleSaveTemplate}
          onClose={() => setIsAddEditModalOpen(false)}
          availableProcesses={availableProcesses}
        />
      )}
    </div>
  );
};

export default DatabaseManager; 