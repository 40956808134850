import React from 'react';
import './TemplateItemsModal.css';

const TemplateItemsModal = ({ items, onClose }) => {
  const handleOverlayClick = (e) => {
    if (e.target.className === 'template-items-modal__overlay') {
      onClose();
    }
  };

  return (
    <div className="template-items-modal__overlay" onClick={handleOverlayClick}>
      <div className="template-items-modal__container">
        <div className="template-items-modal__header">
          <h3>상세 품목 목록</h3>
          <button 
            className="template-items-modal__close-btn"
            onClick={onClose}
          >
            ✕
          </button>
        </div>
        
        <div className="template-items-modal__content">
          <div className="template-items-modal__table">
            <div className="template-items-modal__table-header">
              <div className="template-items-modal__col name">품목명</div>
              <div className="template-items-modal__col quantity">수량</div>
              <div className="template-items-modal__col price">단가</div>
            </div>
            <div className="template-items-modal__table-body">
              {items.map((item, index) => (
                <div key={index} className="template-items-modal__table-row">
                  <div className="template-items-modal__col name">{item.name}</div>
                  <div className="template-items-modal__col quantity">
                    {item.quantity} {item.unit}
                  </div>
                  <div className="template-items-modal__col price">
                    {Number(item.unitPrice).toLocaleString()}원
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className="template-items-modal__footer">
            <div className="template-items-modal__total">
              <span>총 금액:</span>
              <span className="template-items-modal__total-price">
                {items.reduce((sum, item) => 
                  sum + (Number(item.quantity) * Number(item.unitPrice)), 0
                ).toLocaleString()}원
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateItemsModal; 