import React from 'react';
import './AIPromptInput.css';

const AIPromptInput = ({ value, onChange }) => {
  const handleSizeChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '');
    if (numericValue === '' || parseInt(numericValue) <= 100) {
      handleChange('size', numericValue);
    }
  };

  const handleChange = (field, newValue) => {
    onChange({
      ...value,
      [field]: newValue
    });
  };

  return (
    <div className="ai-prompt-input">
      <div className="input-group">
        <label>요구사항</label>
        <textarea
          value={value.requirement}
          onChange={(e) => handleChange('requirement', e.target.value)}
          placeholder="작업 범위와 선호하는 자재 등을 입력해주세요."
        />
      </div>

      <div className="input-group">
        <label>평수</label>
        <div className="size-input-wrapper">
          <input
            type="text"
            value={value.size || ''}
            onChange={handleSizeChange}
            placeholder="숫자만 입력 (예: 32)"
            className="size-input"
          />
          <span className="size-unit">평</span>
        </div>
      </div>

      <div className="input-group">
        <label>키워드</label>
        <input
          type="text"
          value={value.keywords}
          onChange={(e) => handleChange('keywords', e.target.value)}
          placeholder="주요 키워드를 쉼표로 구분하여 입력해주세요."
        />
      </div>

      <div className="input-group">
        <label>특이사항</label>
        <textarea
          value={value.specialNotes}
          onChange={(e) => handleChange('specialNotes', e.target.value)}
          placeholder="작업 시 고려해야 할 특이사항을 입력해주세요."
        />
      </div>
    </div>
  );
};

export default AIPromptInput; 