import React, { useState, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import './TemplateDetailModal.css';

const EditItemModal = ({ item, onSave, onClose }) => {
  const [editedItem, setEditedItem] = useState({ ...item });

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey, true);
    return () => window.removeEventListener('keydown', handleEscKey, true);
  }, [onClose]);

  return (
    <div className="template-item-edit-modal-overlay">
      <div className="template-item-edit-modal">
        <div className="template-items-modal-header">
          <h3 className="template-items-modal-title">아이템 수정</h3>
          <button className="template-items-modal-close" onClick={onClose}>×</button>
        </div>
        <div className="template-item-edit-form">
          <div className="template-item-form-group">
            <label className="template-item-form-label">품목</label>
            <input
              className="template-item-form-input"
              type="text"
              value={editedItem.name}
              onChange={e => setEditedItem({...editedItem, name: e.target.value})}
            />
          </div>
          <div className="template-item-form-group">
            <label className="template-item-form-label">수량</label>
            <input
              className="template-item-form-input"
              type="number"
              value={editedItem.quantity}
              onChange={e => setEditedItem({...editedItem, quantity: e.target.value})}
            />
          </div>
          <div className="template-item-form-group">
            <label className="template-item-form-label">단위</label>
            <input
              className="template-item-form-input"
              type="text"
              value={editedItem.unit}
              onChange={e => setEditedItem({...editedItem, unit: e.target.value})}
            />
          </div>
          <div className="template-item-form-group">
            <label className="template-item-form-label">단가</label>
            <input
              className="template-item-form-input"
              type="number"
              value={editedItem.unitPrice}
              onChange={e => setEditedItem({...editedItem, unitPrice: e.target.value})}
            />
          </div>
          <div className="template-item-form-group">
            <label className="template-item-form-label">비고</label>
            <input
              className="template-item-form-input"
              type="text"
              value={editedItem.note || ''}
              onChange={e => setEditedItem({...editedItem, note: e.target.value})}
            />
          </div>
          <div className="template-item-form-actions">
            <button className="template-item-save-btn" onClick={() => onSave(editedItem)}>저장</button>
            <button className="template-item-cancel-btn" onClick={onClose}>취소</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const TemplateDetailModal = ({ template, onClose }) => {
  const [editingItem, setEditingItem] = useState(null);
  const [isAddingItem, setIsAddingItem] = useState(false);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        if (editingItem) {
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey, true);
    return () => window.removeEventListener('keydown', handleEscKey, true);
  }, [onClose, editingItem]);

  const calculateTotal = (items) => {
    return items?.reduce((sum, item) => 
      sum + (Number(item.quantity) * Number(item.unitPrice)), 0
    ) || 0;
  };

  const handleEditItem = async (updatedItem) => {
    try {
      const updatedItems = template.items.map((item, idx) => 
        idx === editingItem.index ? updatedItem : item
      );

      await updateDoc(doc(db, 'ai-templates', template.id), {
        items: updatedItems
      });

      // 템플릿 객체 업데이트
      template.items = updatedItems;
      
      setEditingItem(null);
    } catch (error) {
      console.error('아이템 수정 중 오류:', error);
      alert('수정 중 오류가 발생했습니다.');
    }
  };

  const handleDeleteItem = async (index) => {
    if (window.confirm('정말로 이 아이템을 삭제하시겠습니까?')) {
      try {
        // 해당 인덱스의 아이템을 제외한 새로운 배열 생성
        const updatedItems = [...template.items];
        updatedItems.splice(index, 1);
        
        // Firestore 문서 업데이트
        await updateDoc(doc(db, 'ai-templates', template.id), {
          items: updatedItems
        });

        // 로컬 템플릿 객체 업데이트
        template.items = updatedItems;
        
      } catch (error) {
        console.error('아이템 삭제 중 오류:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleAddItem = async (newItem) => {
    try {
      const updatedItems = [...template.items, newItem];
      
      await updateDoc(doc(db, 'ai-templates', template.id), {
        items: updatedItems
      });

      // 템플릿 객체 업데이트
      template.items = updatedItems;
      
      setIsAddingItem(false);
    } catch (error) {
      console.error('아이템 추가 중 오류:', error);
      alert('추가 중 오류가 발생했습니다.');
    }
  };

  const getLineupName = (lineup) => {
    switch(lineup) {
      case 'refresh': return '리프레쉬';
      case 'harmony': return '하모니';
      case 'collection': return '컬렉션';
      default: return lineup;
    }
  };

  return (
    <div className="template-items-modal-overlay">
      <div className="template-items-modal">
        <div className="template-items-modal-header">
          <h3 className="template-items-modal-title">{template.processName} 아이템 목록</h3>
          <button className="template-items-modal-close" onClick={onClose}>×</button>
        </div>

        <div className="template-summary">
          <span>{getLineupName(template.lineup)}</span>
          <span className="divider">|</span>
          <span>{template.userInput?.size}평대</span>
          <span className="divider">|</span>
          <span>{template.processName}</span>
        </div>
        
        <div className="template-items-table-container">
          <div className="template-items-actions">
            <button 
              className="template-items-add-btn"
              onClick={() => setIsAddingItem(true)}
            >
              아이템 추가
            </button>
          </div>
          <table className="template-items-table">
            <thead>
              <tr>
                <th>품목</th>
                <th>수량</th>
                <th>단위</th>
                <th>단가</th>
                <th>금액</th>
                <th>비고</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {template.items?.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.unit}</td>
                  <td>{Number(item.unitPrice).toLocaleString()}원</td>
                  <td>{(Number(item.quantity) * Number(item.unitPrice)).toLocaleString()}원</td>
                  <td>{item.note || '-'}</td>
                  <td>
                    <div className="template-item-actions">
                      <button 
                        className="template-item-edit-btn"
                        onClick={() => setEditingItem({ ...item, index })}
                      >
                        수정
                      </button>
                      <button 
                        className="template-item-delete-btn"
                        onClick={() => handleDeleteItem(index)}
                      >
                        삭제
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
              <tr className="template-items-total-row">
                <td colSpan="4" className="template-items-total-label">총 합계</td>
                <td className="template-items-total-amount" colSpan="3">
                  {calculateTotal(template.items).toLocaleString()}원
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {editingItem && (
        <EditItemModal
          item={editingItem}
          onSave={handleEditItem}
          onClose={() => setEditingItem(null)}
        />
      )}

      {isAddingItem && (
        <EditItemModal
          item={{ name: '', quantity: '', unit: '', unitPrice: '', note: '' }}
          onSave={handleAddItem}
          onClose={() => setIsAddingItem(false)}
        />
      )}
    </div>
  );
};

export default TemplateDetailModal; 