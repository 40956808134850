import React, { useState, useEffect } from 'react';
import './ConstructionGuide.css';
import ConstructionGuidePDF from './ConstructionGuidePDF';
import { db } from '../firebase';
import { collection, getDocs, query, orderBy, doc, getDoc } from 'firebase/firestore';

const ConstructionGuide = () => {
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [constructionPeriod, setConstructionPeriod] = useState('');
  const [processDates, setProcessDates] = useState(new Map());
  const [hideUndecided, setHideUndecided] = useState(true);

  useEffect(() => {
    fetchAddresses();
  }, []);

  useEffect(() => {
    if (selectedAddress) {
      fetchConstructionPeriod();
    }
  }, [selectedAddress]);

  const fetchConstructionPeriod = async () => {
    try {
      console.log('=== 공사 기간 조회 시작 ===');
      console.log('선택된 주소:', selectedAddress);

      const scheduleDoc = await getDoc(doc(db, 'schedules', selectedAddress));
      console.log('스케줄 문서 존재 여부:', scheduleDoc.exists());

      if (scheduleDoc.exists()) {
        const scheduleData = scheduleDoc.data();
        const events = scheduleData.events || [];
        console.log('이벤트 데이터:', events);

        // 각 공정별 날짜 매핑
        const processDateMap = new Map();
        events.forEach(event => {
          console.log('처리 중인 이벤트:', event);
          const processes = event.title.split('\n').slice(1).map(p => p.replace('> ', ''));
          console.log('추출된 공정들:', processes);
          
          processes.forEach(process => {
            processDateMap.set(process, {
              start: new Date(event.start),
              end: new Date(new Date(event.end).getTime() - 24*60*60*1000)
            });
          });
        });

        console.log('공정별 날짜 매핑:', Object.fromEntries(processDateMap));
        setProcessDates(processDateMap);

        // 전체 공사 기간 계산
        if (events.length > 0) {
          console.log('이벤트 시작일들:', events.map(e => e.start));
          console.log('이벤트 종료일들:', events.map(e => e.end));
          
          const startDate = new Date(Math.min(...events.map(e => new Date(e.start))));
          const endDate = new Date(Math.max(...events.map(e => new Date(e.end))));
          endDate.setDate(endDate.getDate() - 1); // end date 보정
          
          console.log('계산된 시작일:', startDate);
          console.log('계산된 종료일:', endDate);
          
          const period = `${formatDate(startDate)} ~ ${formatDate(endDate)}`;
          console.log('최종 공사 기간:', period);
          setConstructionPeriod(period);
        } else {
          console.log('이벤트가 없음');
          setConstructionPeriod('미정');
        }
      } else {
        console.log('스케줄 문서가 존재하지 않음');
        setConstructionPeriod('미정');
      }
    } catch (error) {
      console.error('=== 공사 기간 조회 실패 ===');
      console.error('에러 상세:', error);
      console.error('에러 스택:', error.stack);
    }
  };

  const formatDate = (date) => {
    try {
      console.log('formatDate 입력값:', date);
      if (!(date instanceof Date)) {
        console.log('Date 객체가 아님, 변환 시도');
        date = new Date(date);
      }
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const result = `${year}-${month}-${day}`;
      console.log('formatDate 결과:', result);
      return result;
    } catch (error) {
      console.error('formatDate 에러:', error);
      console.error('문제의 date 값:', date);
      return '날짜 오류';
    }
  };

  const fetchAddresses = async () => {
    const addressesCollection = collection(db, 'addresses');
    const q = query(addressesCollection, orderBy('createdAt', 'desc'));
    const addressesSnapshot = await getDocs(q);
    const addressesList = addressesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setAddresses(addressesList);
  };

  return (
    <div className="cg-wrapper">
      <div className="cg-container">
        <div className="cg-paper">
          <div className="cg-header">
            <h1 className="cg-title">공사 안내문</h1>
          </div>
          
          <div className="cg-content">
            <div className="cg-greeting">
              <p>주민 여러분 안녕하십니까.</p>
            </div>

            <div className="cg-company-info">
              <select 
                className="cg-address-select"
                value={selectedAddress}
                onChange={(e) => setSelectedAddress(e.target.value)}
              >
                <option value="">주소를 선택해주세요</option>
                {addresses.map((address) => (
                  <option key={address.id} value={address.description}>
                    {address.description}
                  </option>
                ))}
              </select>
            </div>

            <div className="cg-notice">
              <p>내부인테리어 공사를 진행하게 되었습니다.</p>
              <p>공사기간 동안 소음과 분진이 발생할 수 있어 입주민 여러분들의 양해를 구합니다.</p>
              <p>입주민분들께서 불편하지 않도록 철저히 감독하여 신속히 공사를 마무리하도록 최선을 다하겠습니다.</p>
            </div>

            <div className="cg-construction-info">
              <div className="cg-info-item">
                <span className="cg-label">공사 내용</span>
                <span className="cg-value">전체 리모델링</span>
              </div>
              <div className="cg-info-item">
                <span className="cg-label">작업 기간</span>
                <span className="cg-value">{constructionPeriod || '~'}</span>
              </div>
              <div className="cg-info-item">
                <span className="cg-label">작업 시간</span>
                <span className="cg-value">오전 9시 ~ 오후 5시</span>
              </div>
            </div>

            <div className="cg-contact">
              <p>기타 불편사항이나 문의사항이 있으시면 연락 바랍니다.</p>
              <div className="cg-contact-info">
                <a href="tel:010-8694-4078" className="cg-phone">010-8694-4078</a>
              </div>
              <span className="cg-company-name">(아마레디자인)</span>
            </div>

            <div className="cg-schedule">
              <h3 className="cg-schedule-title">공사 일정표</h3>
              <div className="cg-table">
                <div className="cg-table-header">
                  <span>공정</span>
                  <span>공사기간</span>
                  <span>소음강도</span>
                </div>
                {[
                  { work: '철거 공사', period: '요일', noise: '매우 심함' },
                  { work: '샷시 공사', period: '요일', noise: '매우 심함' },
                  { work: '설비 공사', period: '요일', noise: '매우 심함' },
                  { work: '전기 공사', period: '요일', noise: '중간(간헐적)' },
                  { work: '목 공사', period: '요일', noise: '중간' },
                  { work: '타일 공사', period: '요일', noise: '중간' },
                  { work: '필름 공사', period: '요일', noise: '약함' },
                  { work: '마루 공사', period: '요일', noise: '중간' },
                  { work: '제작가구 공사', period: '요일', noise: '중간' },
                  { work: '도배 공사', period: '요일', noise: '약함' },
                  { work: '입주 청소', period: '요일', noise: '약함' }
                ].filter(item => {
                  if (hideUndecided) {
                    return processDates.get(item.work) !== undefined;
                  }
                  return true;
                }).map((item, index, filteredArray) => (
                  <div key={index} className={`cg-table-row ${index === filteredArray.length - 1 ? 'last-row' : ''}`}>
                    <span>{item.work}</span>
                    <span>
                      {processDates.get(item.work) 
                        ? (() => {
                            const start = formatDate(new Date(processDates.get(item.work).start));
                            const end = formatDate(new Date(processDates.get(item.work).end));
                            return start === end ? start : `${start} ~ ${end}`;
                          })()
                        : '미정'}
                    </span>
                    <span>{item.noise}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        
        <div className="cg-download-section">
          <button 
            className="cg-toggle-button"
            onClick={() => setHideUndecided(!hideUndecided)}
          >
            {hideUndecided ? '미정 표시' : '미정 숨기기'}
          </button>
          <ConstructionGuidePDF 
            selectedAddress={selectedAddress}
            constructionPeriod={constructionPeriod}
            processDates={processDates}
            hideUndecided={hideUndecided}
          />
        </div>
      </div>
    </div>
  );
};

export default ConstructionGuide;
