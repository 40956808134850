import React, { useState, useEffect } from 'react';
import { doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import Contract from './Contract';
import AddressSelect from './AddressSelect';
import ContractPDF from './ContractPDF';
import './ContractSettings.css';

const ContractSettings = () => {
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [hasSashWork, setHasSashWork] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [contractRef, setContractRef] = useState(null);
  const [contractData, setContractData] = useState(null);

  // 선택된 주소의 샷시 공사 여부 실시간 구독
  useEffect(() => {
    if (!selectedAddressId) return;

    const unsubscribe = onSnapshot(
      doc(db, 'addresses', selectedAddressId),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setHasSashWork(data.hasSashWork || false);
        }
      },
      (error) => {
        console.error("Error fetching sash work status:", error);
      }
    );

    return () => unsubscribe();
  }, [selectedAddressId]);

  const handleAddressSelect = (address) => {
    setSelectedAddressId(address?.id || null);
    setSaveMessage('');
  };

  const handleSashWorkToggle = async (e) => {
    const newValue = e.target.checked;
    setHasSashWork(newValue);
    
    if (selectedAddressId) {
      try {
        await updateDoc(doc(db, 'addresses', selectedAddressId), {
          hasSashWork: newValue,
          lastModified: new Date()
        });
      } catch (error) {
        console.error("Error updating sash work status:", error);
        setHasSashWork(!newValue);
      }
    }
  };

  const handleSaveClick = async () => {
    if (!selectedAddressId) return;
    
    setIsSaving(true);
    setSaveMessage('');

    try {
      await updateDoc(doc(db, 'addresses', selectedAddressId), {
        lastModified: new Date(),
        lastSaved: new Date()
      });
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        setIsSaving(false);
      }, 1000);
    } catch (error) {
      console.error("Error saving contract:", error);
      setSaveMessage('저장 중 오류가 발생했습니다.');
      setIsSaving(false);
    }
  };

  return (
    <div className="settings-wrapper">
      <div className="settings-panel">
        <div className="settings-content">
          <div className="settings-row">
            <div className="settings-item">
              <AddressSelect onAddressSelect={handleAddressSelect} />
            </div>
            <div className="settings-item">
              <label>샷시 공사:</label>
              <div className="toggle-switch">
                <input
                  type="checkbox"
                  id="sash-toggle"
                  checked={hasSashWork}
                  onChange={handleSashWorkToggle}
                />
                <label htmlFor="sash-toggle">
                  <span className="toggle-label">{hasSashWork ? '포함' : '미포함'}</span>
                </label>
              </div>
            </div>
          </div>
          {selectedAddressId && (
            <div className="save-section">
              <div className="button-group">
                <button 
                  className="save-button" 
                  onClick={handleSaveClick}
                  disabled={isSaving}
                >
                  {isSaving ? '저장 중...' : '계약서 저장'}
                </button>
                <ContractPDF 
                  contractData={contractData}
                  hasSashWork={hasSashWork}
                />
              </div>
              {saveMessage && (
                <span className={`save-message ${saveMessage.includes('오류') ? 'error' : 'success'}`}>
                  {saveMessage}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      
      {selectedAddressId && (
        <Contract 
          addressId={selectedAddressId}
          hasSashWork={hasSashWork}
          showSaveButtons={true}
          onContractDataChange={setContractData}
          onContractRefChange={setContractRef}
        />
      )}

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>저장되었습니다</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractSettings; 