import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import MainScreen from './components/MainScreen';
import EstimateProcess from './components/1min-estimate/EstimateProcess';
import AdminPage from './components/1min-estimate/AdminPage';
import AddressManagement from './components/AddressManagement';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import InstagramAuto from './components/InstagramAuto';
import SNSImageEdit from './components/SNSImageEdit';
import PaymentHistory from './components/PaymentHistory';
import AreaCalculation from './components/AreaCalculation';
import ConstructionGuide from './components/ConstructionGuide';
import ContractSettings from './components/ContractSettings';
import OngoingAddressesToggle from './components/OngoingAddressesToggle';
import ImageClassification from './components/ImageClassification';
import VendorPrice from './components/VendorPrice';
import ApiSettings from './components/ApiSettings';
import DetailedEstimate from './components/DetailedEstimate';
import EstimateInquiry from './components/1min-estimate/EstimateInquiry';
import EstimateInquiryList from './components/1min-estimate/EstimateInquiryList';
import VideoMaker from './components/VideoMaker';
import ThumbnailEditor from './components/thumbnail-editor/ThumbnailEditor';
import ReactPixel from 'react-facebook-pixel';
import PdfExtract from './components/PdfExtract';
import DatabaseManager from './components/database-management/DatabaseManager';
import WindowSize from './components/WindowSize';
import AutoSchedule from './components/AutoSchedule';
import ContractorManagement from './components/ContractorManagement';
import MobileEstimate from './components/mobile-estimate/MobileEstimate';
import AutoProposal from './components/AutoProposal/AutoProposal';
import SketchupLicense from './components/SketchupLicense/SketchupLicense';
import SketchupLicenseAdmin from './components/SketchupLicense/SketchupLicenseAdmin';
import WorkOrder from './components/WorkOrder/WorkOrder';

const AppWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        const modalElements = document.querySelectorAll('.mobile-add-item-modal, .mobile-edit-item-modal, .mobile-saved-items-modal, .mobile-memo-modal');
        if (modalElements.length > 0) {
          return;
        }

        if (location.pathname === '/vendor-price' || 
            location.pathname === '/mobile-estimate') {
          return;
        }
        if (!location.pathname.includes('/estimate')) {
          navigate(-1);
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [navigate, location]);

  useEffect(() => {
    const excludePaths = ['/estimate'];
    if (!excludePaths.some(path => location.pathname.startsWith(path))) {
      ReactPixel.pageView();
    }
  }, [location]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<MainScreen />} />
        <Route path="/mobile-estimate" element={<MobileEstimate />} />
        <Route path="/estimate" element={<EstimateProcess />} />
        <Route path="/estimate/:id" element={<EstimateProcess />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/address-management" element={<AddressManagement />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/instagram-auto" element={<InstagramAuto />} />
        <Route path="/sns-image-edit" element={<SNSImageEdit />} />
        <Route path="/payment-history" element={<PaymentHistory />} />
        <Route path="/area-calculation" element={<AreaCalculation />} />
        <Route path="/construction-guide" element={<ConstructionGuide />} />
        <Route path="/contract" element={<ContractSettings />} />
        <Route path="/ongoing-addresses" element={<OngoingAddressesToggle />} />
        <Route path="/image-classification" element={<ImageClassification />} />
        <Route path="/vendor-price" element={<VendorPrice />} />
        <Route path="/api-settings" element={<ApiSettings />} />
        <Route path="/detailed-estimate/*" element={<DetailedEstimate />} />
        <Route path="/estimate-inquiry" element={<EstimateInquiry />} />
        <Route path="/estimate-inquiry/:id" element={<EstimateInquiry />} />
        <Route path="/estimate-inquiry-list" element={<EstimateInquiryList />} />
        <Route path="/video-maker" element={<VideoMaker />} />
        <Route path="/thumbnail-editor" element={<ThumbnailEditor />} />
        <Route path="/pdf-extract" element={<PdfExtract />} />
        <Route path="/database" element={<DatabaseManager />} />
        <Route path="/window-size" element={<WindowSize />} />
        <Route path="/auto-schedule" element={<AutoSchedule />} />
        <Route path="/contractor-management" element={<ContractorManagement />} />
        <Route path="/auto-proposal" element={<AutoProposal />} />
        <Route path="/sketchup-license" element={<SketchupLicense />} />
        <Route path="/sketchup-license-admin" element={<SketchupLicenseAdmin />} />
        <Route path="/work-order" element={<WorkOrder />} />
      </Routes>
    </div>
  );
};

function App() {
  return (
    <BrowserRouter future={{ 
      v7_startTransition: true,
      v7_relativeSplatPath: true 
    }}>
      <AppWrapper />
    </BrowserRouter>
  );
}

export default App;
