import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, collection, query, orderBy, getDocs } from 'firebase/firestore';
import './MessageModal.css';

const MessageModal = ({ isOpen, onClose, address, events }) => {
  const [contractorsByProcess, setContractorsByProcess] = useState({});
  const [selectedContractors, setSelectedContractors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scheduleData, setScheduleData] = useState(null);
  const [processes, setProcesses] = useState([]);

  // 전체 선택 상태 확인
  const isAllSelected = () => {
    const allContractors = Object.values(contractorsByProcess).flat();
    return allContractors.length > 0 && 
           allContractors.every(contractor =>
             selectedContractors.some(c => 
               c.name === contractor.name && 
               c.phone === contractor.phone
             )
           );
  };

  // 전체 선택/해제 토글
  const toggleSelectAll = () => {
    if (isAllSelected()) {
      setSelectedContractors([]);
    } else {
      const allContractors = Object.values(contractorsByProcess).flat();
      const uniqueContractors = allContractors.filter((contractor, index, self) =>
        index === self.findIndex(c => c.name === contractor.name && c.phone === contractor.phone)
      );
      setSelectedContractors(uniqueContractors);
    }
  };

  // 공정 목록 로드
  useEffect(() => {
    const loadProcesses = async () => {
      try {
        console.log('1. 공정 목록 로딩 시작');
        const processesRef = doc(db, 'settings', 'processes');
        const processesDoc = await getDoc(processesRef);
        
        if (processesDoc.exists()) {
          const processesList = processesDoc.data().list || [];
          console.log('2. 로드된 공정 목록:', processesList);
          setProcesses(processesList);
        } else {
          console.log('2. 공정 목록 없음');
          setProcesses([]);
        }
      } catch (error) {
        console.log('2. 공정 목록 로딩 실패:', error.message);
        setProcesses([]);
      }
    };

    if (isOpen) {
      loadProcesses();
    }
  }, [isOpen]);

  // 해당 주소의 이벤트만 필터링
  const addressEvents = events.filter(event => {
    const [eventAddress] = event.title.split('\n');
    return eventAddress === address;
  });

  // 공정별 일정 데이터 생성
  const processSchedules = addressEvents.reduce((acc, event) => {
    const [_, ...eventProcesses] = event.title.split('\n');
    const startDate = new Date(event.start);
    const endDate = new Date(event.end);
    endDate.setDate(endDate.getDate() - 1);

    eventProcesses.forEach(process => {
      const processName = process.replace('> ', '').trim();
      if (!processName) return;
      
      if (!acc[processName]) {
        acc[processName] = [];
      }
      acc[processName].push({
        start: startDate,
        end: endDate
      });
    });
    return acc;
  }, {});

  // 공정별 메시지 생성 함수
  const generateProcessMessage = (process, dates) => {
    const dateRanges = dates.map(date => {
      const startStr = formatDate(date.start);
      const endStr = formatDate(date.end);
      return startStr === endStr ? startStr : `${startStr} ~ ${endStr}`;
    });

    return `[${process} 일정 추가]
${dateRanges.join('\n')}

주소: ${address}`;
  };

  // 안드로이드 앱으로 전달할 데이터 생성
  const generateMessageData = () => {
    const processMessages = Object.entries(processSchedules).map(([process, dates]) => {
      const recipients = selectedContractors.filter(contractor => 
        contractorsByProcess[process]?.some(c => 
          c.name === contractor.name && c.phone === contractor.phone
        )
      );

      return {
        process,
        dates: dates.map(date => ({
          start: formatDate(date.start),
          end: formatDate(date.end)
        })),
        recipients: recipients.map(r => ({
          name: r.name,
          phone: r.phone
        })),
        messageText: generateProcessMessage(process, dates)
      };
    }).filter(pm => pm.recipients.length > 0);

    return {
      address,
      processMessages
    };
  };

  // 메시지 전송 처리
  const handleSendMessage = () => {
    if (selectedContractors.length === 0) {
      alert('최소 한 명의 거래처를 선택해주세요.');
      return;
    }

    const messageData = {
      ...generateMessageData(),
      openMessageTab: true
    };

    // 데스크톱 환경에서는 클립보드 복사만 실행
    if (window.innerWidth >= 768) {
      try {
        const firstMessage = messageData.processMessages[0]?.messageText || '';
        navigator.clipboard.writeText(firstMessage);
        alert('클립보드에 복사되었습니다!');
      } catch (err) {
        console.error('클립보드 복사 실패:', err);
        alert('클립보드 복사에 실패했습니다.');
      }
      return;
    }

    // 딥링크 실행
    const deepLinkUrl = `amare://message?data=${encodeURIComponent(JSON.stringify(messageData))}`;
    window.location.href = deepLinkUrl;
  };

  // 데이터 로드
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!isOpen || !address) {
        console.log('4. 로딩 중단: 모달이 닫혀있거나 주소가 없음');
        setLoading(false);
        return;
      }

      console.log('3. 데이터 로딩 시작:', {
        주소: address,
        이벤트수: events.length,
        필터링된이벤트수: addressEvents.length,
        공정목록: Object.keys(processSchedules)
      });

      try {
        // schedules 컬렉션에서 일정 데이터 로드
        const scheduleRef = doc(db, 'schedules', address);
        const scheduleDoc = await getDoc(scheduleRef);
        
        if (scheduleDoc.exists() && isMounted) {
          console.log('5. 스케줄 데이터 로드 성공');
          setScheduleData(scheduleDoc.data());
        } else {
          console.log('5. 스케줄 데이터 없음');
        }

        // contractors 컬렉션에서 거래처 데이터 로드
        const contractorsData = {};
        const processNames = Object.keys(processSchedules);
        
        if (processNames.length === 0) {
          console.log('6. 해당 주소의 공정이 없음');
          if (isMounted) {
            setContractorsByProcess({});
            setLoading(false);
          }
          return;
        }

        console.log('6. 거래처 데이터 로딩 시작:', processNames);
        
        for (const processName of processNames) {
          if (!isMounted) break;
          
          const processRef = doc(db, 'contractors', processName);
          const processDoc = await getDoc(processRef);
          
          if (processDoc.exists()) {
            const data = processDoc.data();
            contractorsData[processName] = data.contractors || [];
          } else {
            contractorsData[processName] = [];
          }
        }
        
        if (isMounted) {
          console.log('7. 거래처 데이터 로딩 완료:', 
            Object.entries(contractorsData).map(([process, contractors]) => 
              `${process}: ${contractors.length}명`
            )
          );
          setContractorsByProcess(contractorsData);
        }
      } catch (error) {
        console.log('8. 데이터 로딩 실패:', error.message);
      } finally {
        if (isMounted) {
          console.log('9. 로딩 완료');
          setLoading(false);
        }
      }
    };

    setLoading(true);
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [isOpen, address]);

  const toggleContractor = (contractor) => {
    setSelectedContractors(prev => {
      const exists = prev.some(c => 
        c.name === contractor.name && 
        c.phone === contractor.phone
      );

      if (exists) {
        return prev.filter(c => 
          !(c.name === contractor.name && 
            c.phone === contractor.phone)
        );
      } else {
        return [...prev, contractor];
      }
    });
  };

  // 날짜 포맷 함수
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // 공정별 모든 거래처 선택/해제
  const toggleProcess = (processName) => {
    const contractors = contractorsByProcess[processName] || [];
    const isCurrentlySelected = isProcessSelected(processName);
    
    if (!isCurrentlySelected) {
      const newContractors = contractors.filter(contractor => 
        !selectedContractors.some(c => 
          c.name === contractor.name && c.phone === contractor.phone
        )
      );
      setSelectedContractors(prev => [...prev, ...newContractors]);
    } else {
      setSelectedContractors(prev => 
        prev.filter(c => 
          !contractors.some(pc => 
            pc.name === c.name && pc.phone === c.phone
          )
        )
      );
    }
  };

  // 공정의 모든 거래처가 선택되었는지 확인
  const isProcessSelected = (processName) => {
    const contractors = contractorsByProcess[processName] || [];
    return contractors.every(contractor =>
      selectedContractors.some(c => 
        c.name === contractor.name && 
        c.phone === contractor.phone
      )
    );
  };

  if (!isOpen) return null;

  console.log('[MessageModal] 렌더링:', {
    address,
    events: events.length,
    addressEvents: addressEvents.length,
    processSchedules,
    contractorsByProcess,
    selectedContractors,
    scheduleData
  });

  return (
    <div className="msg-modal">
      <div className="msg-modal__content">
        <div className="msg-modal__header">
          <h2 className="msg-modal__title">문자 메시지 보내기</h2>
          <h3 className="msg-modal__subtitle">{address}</h3>
        </div>
        
        <div className="msg-modal__events">
          {loading ? (
            <div className="msg-modal__loading">데이터를 불러오는 중...</div>
          ) : (
            <>
              <label className="msg-modal__select-all">
                <input
                  type="checkbox"
                  className="msg-modal__checkbox"
                  checked={isAllSelected()}
                  onChange={toggleSelectAll}
                />
                <span className="msg-modal__select-all-text">
                  전체 선택
                </span>
              </label>
              {Object.entries(processSchedules).map(([process, dates], index) => (
                <div key={index} className="msg-modal__event-item">
                  <div className="msg-modal__process-header">
                    <label className="msg-modal__process-checkbox">
                      <input
                        type="checkbox"
                        className="msg-modal__checkbox"
                        checked={isProcessSelected(process)}
                        onChange={() => toggleProcess(process)}
                      />
                      <span className="msg-modal__process-name">
                        {process}
                      </span>
                    </label>
                    <div className="msg-modal__dates">
                      {dates.map((date, idx) => (
                        <div key={idx} className="msg-modal__date">
                          {formatDate(date.start) === formatDate(date.end) 
                            ? formatDate(date.start)
                            : `${formatDate(date.start)} ~ ${formatDate(date.end)}`}
                        </div>
                      ))}
                    </div>
                  </div>
                  {contractorsByProcess[process] && (
                    <div className="msg-modal__contractors">
                      {contractorsByProcess[process].map((contractor, cIndex) => (
                        <label key={cIndex} className="msg-modal__contractor">
                          <input
                            type="checkbox"
                            className="msg-modal__checkbox"
                            checked={selectedContractors.some(c => 
                              c.name === contractor.name && 
                              c.phone === contractor.phone
                            )}
                            onChange={() => toggleContractor(contractor)}
                          />
                          <span className="msg-modal__contractor-name">
                            {contractor.name}
                          </span>
                          <span className="msg-modal__contractor-phone">
                            {contractor.phone}
                          </span>
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
        </div>

        <div className="msg-modal__buttons">
          <button 
            className="msg-modal__send-button"
            onClick={handleSendMessage}
            disabled={selectedContractors.length === 0}
          >
            보내기 ({selectedContractors.length}명)
          </button>
          <button 
            className="msg-modal__close-button"
            onClick={onClose}
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageModal; 