import React, { useState, useEffect } from 'react';
import './SelectVersionModal.css';
import VersionManager from './VersionManager';
import { FaTrash, FaEdit, FaCheck, FaTimes } from 'react-icons/fa';

const SelectVersionModal = ({ 
  selectedAddress,
  onSelectVersion, 
  onNewVersion, 
  onClose 
}) => {
  const [versions, setVersions] = useState([]);
  const [newVersionName, setNewVersionName] = useState('');
  const [loading, setLoading] = useState(true);
  const [editingVersion, setEditingVersion] = useState(null);
  const [editingName, setEditingName] = useState('');

  useEffect(() => {
    loadVersions();
  }, [selectedAddress]);

  const loadVersions = async () => {
    try {
      setLoading(true);
      const versionList = await VersionManager.getVersionList(selectedAddress);
      setVersions(versionList);
    } catch (error) {
      console.error('버전 목록 로드 중 오류:', error);
      alert('버전 목록을 불러오는 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleVersionSelect = async (version) => {
    try {
      if (!version) return;
      onSelectVersion(version);
    } catch (error) {
      console.error('버전 선택 중 오류:', error);
      alert('버전을 불러오는 중 오류가 발생했습니다.');
    }
  };

  const handleNewVersion = () => {
    if (!newVersionName.trim()) {
      alert('버전 이름을 입력해주세요.');
      return;
    }
    onNewVersion(newVersionName.trim());
  };

  const handleDeleteVersion = async (version, event) => {
    event.stopPropagation();
    
    try {
      if (window.confirm('이 버전을 삭제하시겠습니까?')) {
        await VersionManager.deleteVersion(selectedAddress, version.versionName);
        await loadVersions();
      }
    } catch (error) {
      console.error('버전 삭제 중 오류:', error);
      alert('버전 삭제 중 오류가 발생했습니다.');
    }
  };

  const startEditing = (version, event) => {
    event.stopPropagation();
    setEditingVersion(version);
    setEditingName(version.versionName);
  };

  const cancelEditing = (event) => {
    event.stopPropagation();
    setEditingVersion(null);
    setEditingName('');
  };

  const handleEditVersion = async (version, event) => {
    event.stopPropagation();
    
    try {
      if (!editingName.trim()) {
        alert('버전 이름을 입력해주세요.');
        return;
      }

      await VersionManager.updateVersionName(selectedAddress, version.versionName, editingName.trim());
      setEditingVersion(null);
      setEditingName('');
      await loadVersions();
    } catch (error) {
      console.error('버전 이름 수정 중 오류:', error);
      alert('버전 이름을 수정하는 중 오류가 발생했습니다.');
    }
  };

  const renderContent = () => {
    if (loading) {
      return <div className="loading">버전 목록을 불러오는 중...</div>;
    }

    return (
      <>
        <div className="new-version-section">
          <input
            type="text"
            value={newVersionName}
            onChange={(e) => setNewVersionName(e.target.value)}
            placeholder="새 버전 이름 입력"
            className="new-version-input"
          />
          <button 
            onClick={handleNewVersion}
            className="btn btn-primary"
          >
            새 버전 생성
          </button>
        </div>

        <div className="version-list">
          {versions.length > 0 ? (
            versions.map((version) => (
              <div 
                key={version.versionId} 
                className="version-item"
                onClick={() => handleVersionSelect(version)}
              >
                <div className="version-info">
                  {editingVersion === version ? (
                    <input
                      type="text"
                      value={editingName}
                      onChange={(e) => setEditingName(e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                      className="version-edit-input"
                      autoFocus
                    />
                  ) : (
                    <>
                      <span className="version-name">{version.versionName}</span>
                      <span className="version-date">
                        {new Date(version.lastModified || version.createdAt).toLocaleString()}
                      </span>
                    </>
                  )}
                </div>
                <div className="version-actions">
                  {editingVersion === version ? (
                    <>
                      <button 
                        className="version-btn version-save-btn"
                        onClick={(e) => handleEditVersion(version, e)}
                        title="저장"
                      >
                        <FaCheck />
                      </button>
                      <button 
                        className="version-btn version-cancel-btn"
                        onClick={cancelEditing}
                        title="취소"
                      >
                        <FaTimes />
                      </button>
                    </>
                  ) : (
                    <>
                      <button 
                        className="version-btn version-edit-btn"
                        onClick={(e) => startEditing(version, e)}
                        title="이름 수정"
                      >
                        <FaEdit />
                      </button>
                      <button 
                        className="version-btn version-delete-btn"
                        onClick={(e) => handleDeleteVersion(version, e)}
                        title="버전 삭제"
                      >
                        <FaTrash />
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="no-versions">저장된 버전이 없습니다.</div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="select-version-modal-overlay">
      <div className="select-version-modal">
        <div className="modal-header">
          <h2>버전 선택</h2>
        </div>
        
        <div className="modal-body">
          {renderContent()}
        </div>

        <div className="modal-footer">
          <button 
            className="btn btn-secondary" 
            onClick={onClose}
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectVersionModal; 