import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import './SketchupLicense.css';

const SketchupLicense = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [clickCount, setClickCount] = useState(0);

  // 추가 기능 목록 정의
  const additionalFeatures = [
    { id: 'feature1', name: '기능 1', description: '추가 기능 1 설명' },
    { id: 'feature2', name: '기능 2', description: '추가 기능 2 설명' },
    { id: 'feature3', name: '기능 3', description: '추가 기능 3 설명' },
    { id: 'feature4', name: '기능 4', description: '추가 기능 4 설명' }
  ];

  // 사용자 정보 불러오기
  const fetchUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, 'sketchup_ruby_users', userId));
      if (userDoc.exists()) {
        setUserData(userDoc.data());
      }
    } catch (err) {
      console.error('사용자 정보 조회 실패:', err);
    }
  };

  // 라이센스 키 생성 함수
  const generateLicenseKey = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const segments = 4;
    const segmentLength = 4;
    let key = '';
    
    for (let i = 0; i < segments; i++) {
      for (let j = 0; j < segmentLength; j++) {
        key += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      if (i < segments - 1) key += '-';
    }
    
    return key;
  };

  // 라이센스 구매 처리
  const handlePurchaseLicense = async () => {
    try {
      setLoading(true);
      const user = auth.currentUser;
      
      if (!user) {
        throw new Error('로그인이 필요합니다.');
      }

      if (!userData) {
        throw new Error('사용자 정보를 불러오는 중입니다. 잠시 후 다시 시도해주세요.');
      }

      if (userData.license) {
        throw new Error('이미 라이센스를 보유하고 있습니다.');
      }

      // 새 라이센스 생성
      const licenseKey = generateLicenseKey();
      const now = new Date();

      const licenseData = {
        licenseKey,
        issuedAt: now.toISOString(),
        features: ['basic'],
        status: 'active',
        userId: user.uid,
        userEmail: userData.email,
        userName: userData.name,
        userPhone: userData.phone
      };

      const updatedUserData = {
        ...userData,
        license: licenseData
      };

      // Firestore에 사용자 정보 저장
      await setDoc(doc(db, 'sketchup_ruby_users', user.uid), updatedUserData);
      
      // Firestore에 라이센스 정보 저장
      await setDoc(doc(db, 'sketchup_ruby_licenses', licenseKey), licenseData);
      
      // 상태 업데이트
      setUserData(updatedUserData);
      alert('라이센스가 성공적으로 발급되었습니다!');
    } catch (err) {
      console.error('라이센스 구매 실패:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // 추가 기능 구매 처리
  const handlePurchaseFeature = async (featureId) => {
    try {
      setLoading(true);
      const user = auth.currentUser;
      
      if (!user || !userData.license) {
        throw new Error('라이센스가 필요합니다.');
      }

      // 이미 구매한 기능인지 확인
      if (userData.license.features.includes(featureId)) {
        throw new Error('이미 구매한 기능입니다.');
      }

      const updatedLicenseData = {
        ...userData.license,
        features: [...userData.license.features, featureId]
      };

      const updatedUserData = {
        ...userData,
        license: updatedLicenseData
      };

      // Firestore에 사용자 정보 저장
      await setDoc(doc(db, 'sketchup_ruby_users', user.uid), updatedUserData);
      
      // Firestore에 라이센스 정보 업데이트
      await setDoc(doc(db, 'sketchup_ruby_licenses', userData.license.licenseKey), updatedLicenseData);
      
      // 상태 업데이트
      setUserData(updatedUserData);
      alert('기능이 성공적으로 추가되었습니다!');
    } catch (err) {
      console.error('기능 구매 실패:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // 로그아웃 처리
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUserData(null);
    } catch (err) {
      console.error('로그아웃 실패:', err);
      setError(err.message);
    }
  };

  // 로그인 상태 변경 시 정보 조회
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        fetchUserData(user.uid);
      } else {
        setUserData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (isLogin) {
        // 로그인 처리
        await signInWithEmailAndPassword(auth, email, password);
      } else {
        // 회원가입 처리
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        
        // Firestore에 사용자 정보 저장
        await setDoc(doc(db, 'sketchup_ruby_users', userCredential.user.uid), {
          email,
          name,
          phone,
          createdAt: new Date().toISOString()
        });
      }
    } catch (err) {
      console.error('Error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTitleClick = () => {
    setClickCount(prev => prev + 1);
  };

  // 라이센스 키 복사 함수
  const handleCopyLicenseKey = async (licenseKey) => {
    try {
      await navigator.clipboard.writeText(licenseKey);
      alert('라이센스 키가 클립보드에 복사되었습니다.');
    } catch (err) {
      console.error('복사 실패:', err);
      alert('라이센스 키 복사에 실패했습니다.');
    }
  };

  // 라이센스 정보 표시 컴포넌트
  const LicenseInfo = ({ license }) => {
    return (
      <div className="license-info">
        <h3>라이센스 정보</h3>
        <div className="license-key-container">
          <p className="license-key-text">라이센스 키: {license.licenseKey}</p>
          <button 
            onClick={() => handleCopyLicenseKey(license.licenseKey)}
            className="copy-button"
          >
            복사
          </button>
        </div>
        <p>발급일: {new Date(license.issuedAt).toLocaleDateString()}</p>
        <p>타입: 기본 라이센스</p>
        <p className="license-status active">
          상태: 활성화
        </p>
        <div className="features-info">
          <h4>사용 가능한 기능</h4>
          <ul>
            <li>기본 기능</li>
            {license.features
              .filter(f => f !== 'basic')
              .map(feature => {
                const featureInfo = additionalFeatures.find(f => f.id === feature);
                return featureInfo && (
                  <li key={feature}>{featureInfo.name}</li>
                );
              })}
          </ul>
        </div>

        {/* 추가 기능 구매 섹션 */}
        <div className="additional-features">
          <h4>추가 구매 가능한 기능</h4>
          <div className="features-grid">
            {additionalFeatures.map(feature => {
              const isOwned = license.features.includes(feature.id);
              return (
                <div key={feature.id} className="feature-item">
                  <h5>{feature.name}</h5>
                  <p>{feature.description}</p>
                  <button
                    onClick={() => handlePurchaseFeature(feature.id)}
                    disabled={loading || isOwned}
                    className={`feature-button ${isOwned ? 'owned' : ''}`}
                  >
                    {isOwned ? '구매완료' : '구매하기'}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  // 인증된 사용자 화면
  if (auth.currentUser) {
    return (
      <div className="sketchup-license-container">
        <div className="auth-form-container">
          <h1 onClick={handleTitleClick} style={{ cursor: 'pointer' }}>스케치업 루비</h1>
          {clickCount >= 5 && (
            <Link to="/sketchup-license-admin" className="admin-link">
              라이센스 관리 페이지
            </Link>
          )}
          
          {/* 사용자 정보 표시 */}
          {userData && (
            <div className="user-info">
              <p>이름: {userData.name}</p>
              <p>이메일: {userData.email}</p>
              <p>연락처: {userData.phone}</p>
            </div>
          )}

          {userData?.license ? (
            <LicenseInfo license={userData.license} />
          ) : (
            <>
              <h2>라이센스 구매</h2>
              <button 
                onClick={handlePurchaseLicense} 
                disabled={loading}
                className="purchase-button"
              >
                {loading ? '처리중...' : '라이센스 구매하기'}
              </button>
            </>
          )}
          
          {error && <div className="error-message">{error}</div>}
          
          {/* 로그아웃 버튼 */}
          <button onClick={handleLogout} className="logout-button">
            로그아웃
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="sketchup-license-container">
      <div className="auth-form-container">
        <h1 onClick={handleTitleClick} style={{ cursor: 'pointer' }}>스케치업 루비</h1>
        {clickCount >= 5 && (
          <Link to="/sketchup-license-admin" className="admin-link">
            라이센스 관리 페이지
          </Link>
        )}
        <h2>{isLogin ? '로그인' : '회원가입'}</h2>
        
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>이메일</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="이메일을 입력하세요"
            />
          </div>

          <div className="form-group">
            <label>비밀번호</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="비밀번호를 입력하세요"
            />
          </div>

          {!isLogin && (
            <>
              <div className="form-group">
                <label>이름</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  placeholder="이름을 입력하세요"
                />
              </div>

              <div className="form-group">
                <label>연락처</label>
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  placeholder="연락처를 입력하세요"
                />
              </div>
            </>
          )}

          {error && <div className="error-message">{error}</div>}

          <button type="submit" disabled={loading}>
            {loading ? '처리중...' : (isLogin ? '로그인' : '회원가입')}
          </button>
        </form>

        <div className="auth-switch">
          <button onClick={() => setIsLogin(!isLogin)}>
            {isLogin ? '회원가입하기' : '로그인하기'}
          </button>
        </div>

        <div className="brand-logo">
          Interior One Click
        </div>
      </div>
    </div>
  );
};

export default SketchupLicense; 