import { db } from '../../firebase';
import { collection, doc, getDoc, setDoc, getDocs, deleteDoc, query, orderBy } from 'firebase/firestore';

class MobileVersionManager {
  static async saveVersion(address, version) {
    try {
      const versionRef = doc(db, 'estimateVersions', `${address}_${version.versionName}`);
      await setDoc(versionRef, {
        ...version,
        createdAt: new Date().toISOString(),
        lastModified: new Date().toISOString()
      });
      return true;
    } catch (error) {
      console.error('버전 저장 중 오류:', error);
      throw error;
    }
  }

  static async saveInterimVersion(address, version) {
    try {
      const versionRef = doc(db, 'estimateVersions', `${address}_${version.versionName}`);
      await setDoc(versionRef, {
        ...version,
        lastModified: new Date().toISOString()
      }, { merge: true });
      return true;
    } catch (error) {
      console.error('중간 저장 중 오류:', error);
      throw error;
    }
  }

  static async loadVersion(address, versionName) {
    try {
      const versionRef = doc(db, 'estimateVersions', `${address}_${versionName}`);
      const versionDoc = await getDoc(versionRef);
      
      if (versionDoc.exists()) {
        return versionDoc.data();
      }
      return null;
    } catch (error) {
      console.error('버전 로드 중 오류:', error);
      throw error;
    }
  }

  static async getVersionList(address) {
    try {
      const versionsRef = collection(db, 'estimateVersions');
      const q = query(versionsRef, orderBy('createdAt', 'desc'));
      const versionsSnapshot = await getDocs(q);
      
      const versions = [];
      versionsSnapshot.forEach(doc => {
        const version = doc.data();
        if (version.address === address) {
          versions.push(version);
        }
      });
      
      return versions;
    } catch (error) {
      console.error('버전 목록 로드 중 오류:', error);
      throw error;
    }
  }

  static async deleteVersion(address, versionName) {
    try {
      const versionRef = doc(db, 'estimateVersions', `${address}_${versionName}`);
      await deleteDoc(versionRef);
      return true;
    } catch (error) {
      console.error('버전 삭제 중 오류:', error);
      throw error;
    }
  }

  static async updateVersionName(address, oldVersionName, newVersionName) {
    try {
      const oldVersionRef = doc(db, 'estimateVersions', `${address}_${oldVersionName}`);
      const versionDoc = await getDoc(oldVersionRef);
      
      if (!versionDoc.exists()) {
        throw new Error('버전을 찾을 수 없습니다.');
      }

      const versionData = versionDoc.data();
      await this.deleteVersion(address, oldVersionName);
      await this.saveVersion(address, {
        ...versionData,
        versionName: newVersionName
      });
      
      return true;
    } catch (error) {
      console.error('버전 이름 수정 중 오류:', error);
      throw error;
    }
  }
}

export default MobileVersionManager; 