import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './EstimateApp.css';
import { trackMetaEvent } from '../../utils/metaPixel';

const LandingPage = ({ onStart }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (location.state?.returnPath) {
      const path = location.state.returnPath;
      const params = location.state.returnParams;
      const targetPath = params ? path.replace(':id', params.id) : path;
      navigate(targetPath, { replace: true });
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        handleBackButton();
      }
    };

    const handlePopState = () => {
      handleBackButton();
    };

    window.addEventListener('popstate', handlePopState);
    window.addEventListener('keydown', handleKeyPress);

    if (location.state?.returnPath) {
      window.history.pushState(location.state, '', window.location.pathname);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [location.state]);

  const [clickCount, setClickCount] = useState(0);
  const [showAdminLink, setShowAdminLink] = useState(false);

  const handleBrandClick = () => {
    const newClickCount = clickCount + 1;
    setClickCount(newClickCount);
    
    if (newClickCount === 3) {
      setShowAdminLink(true);
      setTimeout(() => {
        setShowAdminLink(false);
      }, 5000);
      setClickCount(0);
    }
  };

  const handleStartClick = () => {
    trackMetaEvent('바로견적내기');
    onStart();
  };

  return (
    <div className="landing-page">
      <div className="content-container">
        <div className="landing-content">
          <div>
            <h1 className="brand" onClick={handleBrandClick} style={{ marginTop: 0 }}>AMARE DESIGN</h1>
            {showAdminLink && (
              <Link to="/admin" className="admin-link">
                관리자 페이지
              </Link>
            )}
          </div>
          <p className="slogan">
            시간 절약😊 손쉬운 인테리어!<br />
            30초만에 끝내는 간편 견적<br /><br /><br />
            <span className="highlight-text">
              맞춤 견적서를 이메일로 즉시 발송!
            </span><br /><br /><br />
            쉽고 빠르게, <br />
            인테리어의 시작을 함께하세요.
          </p>
          <button className="start-button" onClick={handleStartClick}>
            바로 견적 내기
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
