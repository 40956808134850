import React from 'react';
import './LayoutModal.css';

const LayoutModal = ({ onClose, onSelect }) => {
  return (
    <div className="layout-modal-overlay" onClick={onClose}>
      <div className="layout-modal-content" onClick={e => e.stopPropagation()}>
        <h2 className="layout-modal-title">레이아웃 선택</h2>
        <div className="layout-options">
          <div 
            className="layout-option"
            onClick={() => onSelect('template1')}
          >
            <div className="layout-preview template1-preview">
              <div className="preview-main"></div>
              <div className="preview-side">
                <div className="preview-materials"></div>
                <div className="preview-memo"></div>
              </div>
            </div>
            <div className="layout-label">기본 템플릿</div>
          </div>
          
          <div 
            className="layout-option"
            onClick={() => onSelect('template2')}
          >
            <div className="layout-preview template2-preview">
              <div className="preview-main">
                <div className="preview-blueprint"></div>
                <div className="preview-memo"></div>
              </div>
              <div className="preview-side">
                <div className="preview-materials"></div>
                <div className="preview-floor-plan"></div>
              </div>
            </div>
            <div className="layout-label">2D 평면도 템플릿</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutModal; 