import React, { useState, useCallback } from 'react';
import './BulkImageModal.css';

const BulkImageModal = ({ onClose, onUpload, workTypes }) => {
  const [images, setImages] = useState([]);
  const [selectedWorkType, setSelectedWorkType] = useState('');
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files).filter(file => 
      file.type.startsWith('image/')
    );

    if (files.length > 0) {
      const newImages = files.map(file => ({
        file,
        preview: URL.createObjectURL(file),
        workType: selectedWorkType
      }));
      setImages(prev => [...prev, ...newImages]);
    }
  }, [selectedWorkType]);

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files).filter(file => 
      file.type.startsWith('image/')
    );

    if (files.length > 0) {
      const newImages = files.map(file => ({
        file,
        preview: URL.createObjectURL(file),
        workType: selectedWorkType
      }));
      setImages(prev => [...prev, ...newImages]);
    }
  };

  const handleWorkTypeChange = (workType) => {
    setSelectedWorkType(workType);
    // 선택된 작업 종류를 모든 이미지에 적용
    setImages(prev => prev.map(img => ({
      ...img,
      workType
    })));
  };

  const handleUpload = () => {
    if (images.length === 0) {
      alert('업로드할 이미지를 선택해주세요.');
      return;
    }
    if (!selectedWorkType) {
      alert('작업 종류를 선택해주세요.');
      return;
    }
    onUpload(images);
    onClose();
  };

  const handleRemoveImage = (index) => {
    setImages(prev => {
      const newImages = [...prev];
      URL.revokeObjectURL(newImages[index].preview);
      newImages.splice(index, 1);
      return newImages;
    });
  };

  return (
    <div className="bulk-image-modal-overlay" onClick={onClose}>
      <div className="bulk-image-modal-content" onClick={e => e.stopPropagation()}>
        <h2>이미지 일괄 업로드</h2>
        
        <div className="work-type-selection">
          <h3>작업 종류 선택</h3>
          <div className="work-type-grid">
            {workTypes.map((type) => (
              <button
                key={type}
                className={`work-type-button ${selectedWorkType === type ? 'selected' : ''}`}
                onClick={() => handleWorkTypeChange(type)}
              >
                {type}
              </button>
            ))}
          </div>
        </div>

        <div
          className={`drop-zone ${isDragging ? 'dragging' : ''}`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            id="file-input"
          />
          <label htmlFor="file-input" className="drop-zone-label">
            {isDragging ? 
              '이미지를 여기에 놓으세요' : 
              '이미지를 드래그하거나 클릭하여 업로드하세요'}
          </label>
        </div>

        {images.length > 0 && (
          <div className="image-preview-grid">
            {images.map((image, index) => (
              <div key={index} className="image-preview-item">
                <img src={image.preview} alt={`Preview ${index + 1}`} />
                <button
                  className="remove-image-button"
                  onClick={() => handleRemoveImage(index)}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="modal-buttons">
          <button 
            className="upload-button"
            onClick={handleUpload}
            disabled={images.length === 0 || !selectedWorkType}
          >
            업로드
          </button>
          <button className="cancel-button" onClick={onClose}>
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkImageModal; 