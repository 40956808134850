import React, { useState, useEffect, useMemo, useRef } from 'react';
import { doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import './Contract.css';

const Contract = ({ addressId, hasSashWork = false, onContractDataChange, onContractRefChange }) => {
  const contractRef = useRef(null);
  const [contractData, setContractData] = useState({
    area: '',
    startDate: '',
    endDate: '',
    clientName: '',
    clientAddress: '',
    clientId: '',
    totalAmount: '',
    description: '',
    contractDate: new Date().toISOString().split('T')[0]
  });

  // ref와 data를 부모 컴포넌트로 전달
  useEffect(() => {
    if (onContractRefChange) {
      onContractRefChange(contractRef);
    }
  }, [contractRef, onContractRefChange]);

  useEffect(() => {
    if (onContractDataChange) {
      onContractDataChange(contractData);
    }
  }, [contractData, onContractDataChange]);

  // Firebase 실시간 구독
  useEffect(() => {
    if (!addressId) return;

    const unsubscribe = onSnapshot(
      doc(db, 'addresses', addressId),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setContractData(prev => ({
            ...prev,
            area: data.area || '',
            startDate: data.startDate || '',
            endDate: data.endDate || '',
            clientName: data.clientName || '',
            clientAddress: data.clientAddress || '',
            clientId: data.clientId || '',
            totalAmount: data.totalAmount || '',
            description: data.description || ''
          }));
        }
      },
      (error) => {
        console.error("Error fetching contract data:", error);
      }
    );

    return () => unsubscribe();
  }, [addressId]);

  // Firebase 업데이트 함수
  const updateField = async (field, value) => {
    if (!addressId) return;

    try {
      await updateDoc(doc(db, 'addresses', addressId), {
        [field]: value,
        lastModified: new Date()
      });
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('ko-KR').format(amount) + '원';
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (value === '') {
      setContractData(prev => ({ ...prev, totalAmount: '' }));
      updateField('totalAmount', '');
    } else {
      const formattedValue = formatCurrency(parseInt(value));
      setContractData(prev => ({ ...prev, totalAmount: formattedValue }));
      updateField('totalAmount', formattedValue);
    }
  };

  // 3차 금액 자동 계산
  const calculateThirdPayment = useMemo(() => {
    const total = parseInt(contractData.totalAmount?.replace(/[^0-9]/g, '') || '0');
    const firstPayment = 1000000;
    const sashPayment = hasSashWork ? 7000000 : 0;
    const secondPayment = 5000000;
    const fifthPayment = 3000000;

    const thirdPayment = total - firstPayment - sashPayment - secondPayment - fifthPayment;
    return thirdPayment > 0 ? thirdPayment : 0;
  }, [contractData.totalAmount, hasSashWork]);

  return (
    <div className="contract-container" ref={contractRef}>
      {/* 첫 번째 페이지 */}
      <div className="contract-page-1">
        <div className="contract-header">
          <h1>공사 계약서</h1>
        </div>

        <div className="contract-body">
          <div className="contract-section project-info">
            <div className="info-row">
              <div className="info-field">
                <label>공사명:</label>
                <div className="project-name">
                  <input 
                    type="text" 
                    value={contractData.description} 
                    className="address-input" 
                    readOnly 
                  />
                  <div className="area-input-wrapper">
                    <input 
                      type="text" 
                      value={contractData.area}
                      onChange={(e) => {
                        setContractData(prev => ({ ...prev, area: e.target.value }));
                        updateField('area', e.target.value);
                      }}
                      className="area-input" 
                      placeholder="평수"
                    />
                    평
                  </div>
                </div>
              </div>
            </div>
            <div className="info-row">
              <div className="info-field">
                <label>공사기간:</label>
                <div className="project-period">
                  <div className="period-input">
                    <span>착공일:</span>
                    <input 
                      type="date" 
                      className="date-input"
                      value={contractData.startDate}
                      onChange={(e) => {
                        setContractData(prev => ({ ...prev, startDate: e.target.value }));
                        updateField('startDate', e.target.value);
                      }}
                    />
                  </div>
                  <div className="period-input">
                    <span>준공일:</span>
                    <input 
                      type="date" 
                      className="date-input"
                      value={contractData.endDate}
                      onChange={(e) => {
                        setContractData(prev => ({ ...prev, endDate: e.target.value }));
                        updateField('endDate', e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contract-section">
            <h2>계약 당사자</h2>
            <div className="party-info">
              <div className="party client">
                <h3>발주자 (갑)</h3>
                <div className="info-field">
                  <label>성명:</label>
                  <input 
                    type="text" 
                    value={contractData.clientName}
                    onChange={(e) => {
                      setContractData(prev => ({ ...prev, clientName: e.target.value }));
                      updateField('clientName', e.target.value);
                    }}
                    placeholder="성명을 입력하세요" 
                  />
                </div>
                <div className="info-field">
                  <label>주소:</label>
                  <input 
                    type="text" 
                    value={contractData.clientAddress}
                    onChange={(e) => {
                      setContractData(prev => ({ ...prev, clientAddress: e.target.value }));
                      updateField('clientAddress', e.target.value);
                    }}
                    placeholder="주소를 입력하세요" 
                  />
                </div>
                <div className="info-field">
                  <label>주민등록번호:</label>
                  <input 
                    type="text" 
                    value={contractData.clientId}
                    onChange={(e) => {
                      setContractData(prev => ({ ...prev, clientId: e.target.value }));
                      updateField('clientId', e.target.value);
                    }}
                    placeholder="주민등록번호를 입력하세요" 
                  />
                </div>
              </div>

              <div className="party contractor">
                <h3>수급자 (을)</h3>
                <div className="info-field">
                  <label>상호:</label>
                  <input type="text" value="아마레디자인" readOnly />
                </div>
                <div className="info-field">
                  <label>주소:</label>
                  <input type="text" value="수성구 들안로 215" readOnly />
                </div>
                <div className="info-field">
                  <label>대표자:</label>
                  <input type="text" value="권 경 대" readOnly />
                </div>
              </div>
            </div>
          </div>

          <div className="contract-section">
            <h2>계약 내용</h2>
            <div className="contract-terms">
              <div className="term-item">
                <p>• "갑"은 전항의 공사대금지급과 관련하여 정당한 사유 없이 지급을 지연하여 서는 안된다.</p>
              </div>
              <div className="term-item">
                <p>• 미지급으로 생기는 문제의 책임은 "갑"에게 있으며, "을"은 합당한 권리를 요구 할 수 있다.</p>
              </div>
              <div className="term-item">
                <p>• 공사완공 후 공사금액을 D/C할시 하자보수이행을 인정하지 않는다.</p>
              </div>
              <div className="term-item">
                <p>• 모든 공정 완료 및 입주 후에는 2주의 테스트 기간을 둘 수 있으며 서로의 완전한 합의 하에 잔금을 지급한다.</p>
              </div>
            </div>
          </div>

          <div className="contract-section">
            <h2>계약 금액</h2>
            <div className="payment-info">
              <div className="info-field">
                <label>총 공사금액:</label>
                <input 
                  type="text" 
                  value={contractData.totalAmount}
                  onChange={handleAmountChange}
                  placeholder="금액을 입력하세요" 
                />
              </div>
              <div className="payment-schedule">
                <h4>지불 방식</h4>
                <div className="schedule-item">
                  <label>1차 계약금</label>
                  <span className="amount">{formatCurrency(1000000)}</span>
                </div>
                {hasSashWork && (
                  <div className="schedule-item">
                    <label>1.5차 샷시 제작 선수금</label>
                    <span className="amount">{formatCurrency(7000000)}</span>
                  </div>
                )}
                <div className="schedule-item">
                  <label>2차 착수금 (공사 시작)</label>
                  <span className="amount">{formatCurrency(5000000)}</span>
                </div>
                <div className="schedule-item">
                  <label>3차 목공사부터</label>
                  <span className="amount">
                    {contractData.totalAmount ? formatCurrency(calculateThirdPayment) : '여러번의 중도금(제작가구 선급금포함)'}
                  </span>
                </div>
                <div className="schedule-item">
                  <label>4차 중도금</label>
                  <span className="amount">약속한 중도급 완납</span>
                </div>
                <div className="schedule-item">
                  <label>5차 완공 후</label>
                  <span className="amount">{formatCurrency(3000000)}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="contract-footer">
            <div className="date">
              {contractData.contractDate.split('-')[0]}년 {contractData.contractDate.split('-')[1]}월 {contractData.contractDate.split('-')[2]}일
            </div>
            <div className="signatures">
              <div className="signature-field">
                <h3>발주자 (갑)</h3>
                <div className="signature-input-group">
                  <label>성명</label>
                  <input 
                    type="text" 
                    value={contractData.clientName}
                    onChange={(e) => {
                      setContractData(prev => ({ ...prev, clientName: e.target.value }));
                      updateField('clientName', e.target.value);
                    }}
                    placeholder="성명을 입력하세요" 
                  />
                </div>
                <div className="signature-input-group">
                  <label>서명</label>
                  <div className="signature-box"></div>
                </div>
              </div>
              <div className="signature-field">
                <h3>시공자 (을)</h3>
                <div className="signature-input-group">
                  <label>성명</label>
                  <input 
                    type="text" 
                    value="권 경 대"
                    readOnly
                  />
                </div>
                <div className="signature-input-group">
                  <label>서명</label>
                  <div className="signature-box"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 두 번째 페이지 */}
      <div className="contract-page-2">
        <div className="contract-header">
          <h1>계약 일반조건</h1>
        </div>

        <div className="contract-terms-section">
          <div className="terms-article">
            <h3>제1조(공사내역)</h3>
            <p>① "을"은 공사착수 전 "갑"에게 설계도서(도면, 시방서, 공사내역서 등)을 제출하여 승인을 득하여야 한다.</p>
            <p>② 공사에 사용할 재료의 품질 및 규격은 설계서와 일치되어야 한다. 다만, 설계도서에 품질•품명이 명확히 규정되지 아니하거나 해당 재료가 품절인 경우 상호 협의하여 달리 결정할 수 있다.</p>
            <p>③ 전항의 합의가 없는 경우 공사에 사용할 재료는 표준 폼 또는 표준 폼에 상당하는 재료로서 계약의 목적을 달성하는데 가장 적합한 것으로 한다.</p>
          </div>

          <div className="terms-article">
            <h3>제2조(공사의 변경 및 조정)</h3>
            <p>① 공사 진행 중 또는 공사완료 후 "갑"의 요구에 의하여 공사내용을 변경하거나 추가할 경우 "을"은 이에 응할 수 있으며, "갑"은 변경 및 조정에 따르는 추가경비를 "을"에게 지불하여야 한다. 공사완료 후 공사변경은 별도계약에 의하여 처리하여야 한다.</p>
            <p>② "을"은 공사의 원활한 진행 및 계약목적의 효율적 달성을 위해 공사내용 및 공법의 변경을 "갑"에게 요청할 수 있다.</p>
            <p>③ 예정 준공일은 공사내용의 변경에 따라 추가로 소요되는 기간을 고려하여 연장 할 수 있다.</p>
            <p>④ 제1항 및 제2항에 의하여 공사금액이 변경되는 경우 잔금 지급 시 정산된다.</p>
          </div>

          <div className="terms-article">
            <h3>제3조(하자보수)</h3>
            <p>① "을"은 공사 완료 후 12개월 이내에 발생한 일부 또는 전부하자에 대해 보수할 책임이 있다. 다만, "갑"과 "을"은 하자보수를 위한 기간의 연장 또는 단축을 별도로 합의할 수 있다.</p>
            <p>② "갑"의 부주의 또는 천재지변으로 인한 경우 "을"은 면책된다.</p>
          </div>

          <div className="terms-article">
            <h3>제4조(이행지체)</h3>
            <p>① "을"은 준공 기한 내에 공사를 완성하지 못하였을 때에는 매 지체일당 공사금액의 2/1,000에 해당하는 금액을 "갑"에게 지불하여야 한다.</p>
            <p>② "을"은 노사분규, 천재지변 등 기타 특수한 사정으로 인하여 공사가 지연될 경우에는 즉시 "갑"에게 통보하고, 당해 사유의 종료 시까지 기한의 연장을 요청할 수 있다.</p>
            <p>③ "갑"의 요구에 의한 설계변경 및 제2항의 사유에 의한 공사지연의 경우에는 지체보상금을 면제한다.</p>
          </div>

          <div className="terms-article">
            <h3>제5조(계약의 해제 또는 해지)</h3>
            <p>① "갑"또는 "을"은 다음 각 호의 1에 해당하는 경우 서면으로 당해 계약의 일부 또는 전부를 해제•해지할 수 있다.</p>
            <p>1. "을"이 정당한 사유 없이 착공을 지연시킬 경우</p>
            <p>2. "을"의 명백한 귀책사유로 인해</p>
            <p>3. "갑" 또는 "을"이 기타 계약내용의 위반으로 계약의 목적을 달성할 수 없다고 인정될 경우</p>
            <p>② 전항의 규정에 의하여 계약이 해제 또는 해지될 경우 책임이 있는 일방은 상대방의 손해를 배상할 책임이 있다. 단, 천재지변 등 불가항력 사유로 인한 경우에는 배상책임을 면제한다.</p>
          </div>

          <div className="terms-article">
            <h3>제6조(권리 및 의무의 양도)</h3>
            <p>"을"은 계약된 공사의 일부 또는 전부를 제3자에게 양도 또는 하도급 할 수 없다. 다만, 공사의 편의 및 공정의 특수성이 있는 때 또는 "갑"의 동의가 있는 경우에는 예외로 한다.</p>
          </div>

          <div className="terms-article">
            <h3>제7조(기타 사항)</h3>
            <p>① "갑"은 "을"이 당해 공사를 원활하게 진행할 수 있도록 필요한 제반 조치(건축허가 등 주무관청의 인허가, 민원해결 등)에 협조하여야 하며, 이와 관련된 제반비용도 부담하여야 한다.</p>
            <p>② "갑"과 "을"은 상호 협의 하여 본 계약서를 변경 또는 수정할 수 있다.</p>
            <p>③ 본 계약서에 규정하지 않은 사항에 대해서는 "갑"과 "을"이 대등한 지위에서 합의하여 특약으로 정할 수 있다.</p>
          </div>

          <div className="terms-article">
            <h3>제8조(분쟁의 해결)</h3>
            <p>본 계약에서 발생한 분쟁은 합의에 의하여 해결함을 원칙으로 하고, 당사자 사이에 해결되지 않는 분쟁은 사법부에 의하여 최종 해결한다.</p>
          </div>
        </div>

        <div className="contract-footer">
          <div className="date">
            {contractData.contractDate.split('-')[0]}년 {contractData.contractDate.split('-')[1]}월 {contractData.contractDate.split('-')[2]}일
          </div>
          <div className="signatures">
            <div className="signature-field">
              <h3>발주자 (갑)</h3>
              <div className="signature-input-group">
                <label>성명</label>
                <input 
                  type="text" 
                  value={contractData.clientName}
                  onChange={(e) => {
                    setContractData(prev => ({ ...prev, clientName: e.target.value }));
                    updateField('clientName', e.target.value);
                  }}
                  placeholder="성명을 입력하세요" 
                />
              </div>
              <div className="signature-input-group">
                <label>서명</label>
                <div className="signature-box"></div>
              </div>
            </div>
            <div className="signature-field">
              <h3>시공자 (을)</h3>
              <div className="signature-input-group">
                <label>성명</label>
                <input 
                  type="text" 
                  value="권 경 대"
                  readOnly
                />
              </div>
              <div className="signature-input-group">
                <label>서명</label>
                <div className="signature-box"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contract;
