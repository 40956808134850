import React, { useState, useEffect } from 'react';
import MobileSavedItemsModal from './MobileSavedItemsModal';
import MobileSaveItemMemoModal from './MobileSaveItemMemoModal';
import './MobileEditItemModal.css';

const MobileEditItemModal = ({ item, processId, onSave, onClose }) => {
  const [editedItem, setEditedItem] = useState({
    name: item.name || '',
    quantity: item.quantity || '',
    unit: item.unit || '식',
    unitPrice: item.unitPrice || '',
    note: item.note || ''
  });

  const [showSavedItems, setShowSavedItems] = useState(false);
  const [showMemoModal, setShowMemoModal] = useState(false);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        if (showSavedItems) {
          setShowSavedItems(false);
          return;
        }
        if (showMemoModal) {
          setShowMemoModal(false);
          return;
        }
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [showSavedItems, showMemoModal]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!editedItem.name || !editedItem.quantity || !editedItem.unitPrice) {
      alert('품목명, 수량, 단가를 모두 입력해주세요.');
      return;
    }

    onSave({
      ...editedItem,
      quantity: parseInt(editedItem.quantity),
      unitPrice: parseInt(editedItem.unitPrice),
      totalPrice: parseInt(editedItem.quantity) * parseInt(editedItem.unitPrice)
    });
  };

  const handleSaveItem = () => {
    if (!editedItem.name.trim() || !editedItem.unitPrice) {
      alert('품목명과 단가를 입력해주세요.');
      return;
    }
    setShowMemoModal(true);
  };

  const handleSaveWithMemo = (itemWithMemo) => {
    // 여기서 나중에 파이어베이스 저장 기능 추가
    alert('품목이 저장되었습니다.');
    setShowMemoModal(false);
  };

  const handleSelectSavedItem = (item) => {
    setEditedItem({
      ...editedItem,
      name: item.name,
      unit: item.unit,
      unitPrice: item.unitPrice.toString(),
      note: item.note || '',
      quantity: editedItem.quantity // 기존 수량 유지
    });
    setShowSavedItems(false);
  };

  return (
    <div className="mobile-edit-item-modal">
      <div className="mobile-edit-item-content">
        <h3>항목 수정</h3>
        <form onSubmit={handleSubmit}>
          <div className="mobile-edit-item-field">
            <label>품목명</label>
            <input
              type="text"
              value={editedItem.name}
              onChange={(e) => setEditedItem({ ...editedItem, name: e.target.value })}
              placeholder="품목명 입력"
            />
          </div>
          <div className="mobile-edit-item-field">
            <label>수량</label>
            <input
              type="number"
              value={editedItem.quantity}
              onChange={(e) => setEditedItem({ ...editedItem, quantity: e.target.value })}
              placeholder="수량 입력"
            />
          </div>
          <div className="mobile-edit-item-field">
            <label>단위</label>
            <input
              type="text"
              value={editedItem.unit}
              onChange={(e) => setEditedItem({ ...editedItem, unit: e.target.value })}
              placeholder="단위 입력"
            />
          </div>
          <div className="mobile-edit-item-field">
            <label>단가</label>
            <input
              type="number"
              value={editedItem.unitPrice}
              onChange={(e) => setEditedItem({ ...editedItem, unitPrice: e.target.value })}
              placeholder="단가 입력"
            />
          </div>
          <div className="mobile-edit-item-field">
            <label>비고</label>
            <input
              type="text"
              value={editedItem.note}
              onChange={(e) => setEditedItem({ ...editedItem, note: e.target.value })}
              placeholder="비고 입력"
            />
          </div>

          <div className="mobile-total-price">
            <label>합계:</label>
            <span>
              {(parseInt(editedItem.quantity || 0) * parseInt(editedItem.unitPrice || 0)).toLocaleString()}원
            </span>
          </div>

          <div className="mobile-item-management-buttons">
            <button type="button" onClick={() => setShowSavedItems(true)}>
              저장된 품목 검색
            </button>
            <button type="button" onClick={handleSaveItem}>
              현재 품목 저장
            </button>
          </div>

          <div className="mobile-edit-item-buttons">
            <button type="submit">저장</button>
            <button type="button" onClick={onClose}>취소</button>
          </div>
        </form>
      </div>

      {showSavedItems && (
        <MobileSavedItemsModal
          processId={processId}
          onSelect={handleSelectSavedItem}
          onClose={() => setShowSavedItems(false)}
        />
      )}

      {showMemoModal && (
        <MobileSaveItemMemoModal
          item={editedItem}
          onSave={handleSaveWithMemo}
          onClose={() => setShowMemoModal(false)}
        />
      )}
    </div>
  );
};

export default MobileEditItemModal; 