import React, { useState, useCallback, useEffect } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { pdfMessagesCollection } from '../firebase';
import { setDoc, serverTimestamp, getDoc, doc } from 'firebase/firestore';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// 메시지 입력 모달 컴포넌트
const MessageInputModal = ({ defaultMessage, onSave, onClose }) => {
  const [message, setMessage] = useState(defaultMessage);

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        width: '80%',
        maxWidth: '600px'
      }}>
        <h3>상단 메시지 수정</h3>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{
            width: '100%',
            height: '200px',
            marginBottom: '20px',
            padding: '10px'
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
          <button
            onClick={() => onSave(message)}
            style={{
              padding: '10px 20px',
              backgroundColor: '#946A6A',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            저장
          </button>
          <button
            onClick={onClose}
            style={{
              padding: '10px 20px',
              backgroundColor: '#666',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

const PdfExtract = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [extractedText, setExtractedText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [headerMessage, setHeaderMessage] = useState(
    `안녕하세요. 아마레디자인 입니다.
문의하신 견적서 입니다.
자세한 견적서는 추후 상담 시 설명 드리도록 하겠습니다.`
  );

  // 기존 데이터 로드
  useEffect(() => {
    const loadExistingData = async () => {
      try {
        const docRef = doc(pdfMessagesCollection, 'current');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setHeaderMessage(prevMessage => data.message || prevMessage);
          setExtractedText(data.content || '');
        }
      } catch (error) {
        console.error('데이터 로드 중 오류 발생:', error);
      }
    };

    loadExistingData();
  }, []);

  const validateAndSetFile = (file) => {
    if (file && file.type === 'application/pdf') {
      setSelectedFile(file);
      setError('');
    } else {
      alert('PDF 파일만 선택할 수 있습니다.');
      setSelectedFile(null);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    validateAndSetFile(file);
  };

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    validateAndSetFile(file);
  }, []);

  const formatExtractedText = useCallback((text, customMessage = headerMessage) => {
    try {
      console.log('=== 원본 텍스트 ===');
      console.log(text);

      // 정규식 패턴 수정
      const patterns = {
        // 회사 정보
        businessNumber: /712\.17\.01551/,
        address: /수성구\s*들안로\s*215/,
        phone: /010\.\s*8694\.\s*4078/,

        // 공정별 패턴 수정
        constructionItems: {
          demolition: {
            cost: /철거폐기물\s*소계\s*:\s*(\d+[,\d]*)\s*원/,
            details: /철거폐기물[\s\S]*?품목([\s\S]*?)철거폐기물\s*소계/
          },
          plumbing: {
            cost: /배관\s*공사\s*소계\s*:\s*(\d+[,\d]*)\s*원/,
            details: /배관\s*공사[\s\S]*?품목([\s\S]*?)배관\s*공사\s*소계/
          },
          woodwork: {
            cost: /목공사\s*소계\s*:\s*(\d+[,\d]*)\s*원/,
            details: /목공사[\s\S]*?품목([\s\S]*?)목공사\s*소계/
          },
          door: {
            cost: /문공사\s*소계\s*:\s*(\d+[,\d]*)\s*원/,
            details: /문공사[\s\S]*?품목([\s\S]*?)문공사\s*소계/
          },
          tile: {
            cost: /타일\s*공사\s*소계\s*:\s*(\d+[,\d]*)\s*원/,
            details: /타일\s*공사\n\s*품목.*?비고\n([\s\S]*?)소계/
          },
          film: {
            cost: /필름공사\s*소계\s*:\s*(\d+[,\d]*)\s*원/,
            details: /필름공사\n\s*품목.*?비고\n([\s\S]*?)소계/
          },
          floor: {
            cost: /바닥재\s*공사\s*소계\s*:\s*(\d+[,\d]*)\s*원/,
            details: /바닥재\s*공사\n\s*품목.*?비고\n([\s\S]*?)소계/
          },
          painting: {
            cost: /도장공사\s*소계\s*:\s*(\d+[,\d]*)\s*원/,
            details: /도장공사\n\s*품목.*?비고\n([\s\S]*?)소계/
          },
          wallpaper: {
            cost: /도배공사\s*소계\s*:\s*(\d+[,\d]*)\s*원/,
            details: /도배공사\n\s*품목.*?비고\n([\s\S]*?)소계/
          },
          bathroom: {
            cost: /욕실시공\s*소계\s*:\s*(\d+[,\d]*)\s*원/,
            details: /욕실시공\n\s*품목.*?비고\n([\s\S]*?)소계/
          },
          electric: {
            cost: /전기공사\s*소계\s*:\s*(\d+[,\d]*)\s*원/,
            details: /전기공사\n\s*품목.*?비고\n([\s\S]*?)소계/
          },
          furniture: {
            cost: /가구공사\s*소계\s*:\s*(\d+[,\d]*)\s*원/,
            details: /가구공사\n\s*품목.*?비고\n([\s\S]*?)소계/
          },
          aircon: {
            cost: /에어컨공사\s*소계\s*:\s*(\d+[,\d]*)\s*원/,
            details: /에어컨공사\n\s*품목.*?비고\n([\s\S]*?)소계/
          }
        },

        // 상세 내용 패턴
        demolitionDetails: /전체철거\s*1\s*식\s*[\d,]+\s*[\d,]+\s*([^"\n]+)/,
        gasWork: /가스배관철거\s*1\s*식\s*[\d,]+\s*[\d,]+\s*([^"\n]+)/,
        
        // 총 금액
        totalAmount: /총\s*합계\s*:\s*([0-9,]+)\s*원/
      };

      // 회사 정보 추출
      const companyInfo = {
        name: '아마레디자인',
        businessNumber: text.match(patterns.businessNumber)?.[0] || '',
        address: text.match(patterns.address)?.[0] || '',
        phone: text.match(patterns.phone)?.[0] || '',
        representative: '권경대'
      };

      // 정별 금액 추출
      const constructionCosts = {};
      const constructionDetails = {};

      // 상세 내용 추출 함수 추가
      const extractConstructionDetails = (text, pattern) => {
        const match = text.match(pattern);
        if (!match || !match[1]) return [];

        return match[1]
          .split('\n')
          .map(line => line.trim())
          .filter(line => {
            return line && 
              !line.includes('소계') && 
              !line.includes('품목') && 
              !line.includes('수량') && 
              !line.includes('단위') && 
              !line.includes('단가') && 
              !line.includes('금액') && 
              !line.includes('비고');
          })
          .map(line => {
            const parts = line.split(/\s+/).filter(Boolean);
            if (parts.length >= 4) {
              const itemName = parts[0];
              const note = parts[parts.length - 1];
              if (note && note !== itemName) {
                return `${itemName}(${note})`;
              }
              return itemName;
            }
            return parts[0];
          })
          .filter(Boolean);
      };

      // 공정별 정보 추출 부분 수정
      Object.entries(patterns.constructionItems).forEach(([key, patterns]) => {
        // 액 추출
        const costMatch = text.match(patterns.cost);
        if (costMatch) {
          constructionCosts[key] = costMatch[1];
        }
        
        // 상세 내용 추출
        const details = extractConstructionDetails(text, patterns.details);
        if (details.length > 0) {
          constructionDetails[key] = details;
        }
      });

      // 철거 상세 정보 추출
      const demolitionNote = text.match(patterns.demolitionDetails)?.[1] || '';
      const gasWorkNote = text.match(patterns.gasWork)?.[1] || '';
      const demolitionDetails = [];
      if (demolitionNote) {
        demolitionDetails.push(`전체철거(${demolitionNote.trim()})`);
      }
      if (gasWorkNote) {
        demolitionDetails.push(`가스배관 철거(${gasWorkNote.trim()})`);
      }

      // 총 합계 추출 (여기서 한 번만 선언)
      const totalAmount = text.match(patterns.totalAmount)?.[1] || '';

      // 상단 메시지 추가 및 형식 수정
      let result = `${customMessage}\n\n[회사정보]\n`;
      result += `회사명: ${companyInfo.name}\n`;
      result += `사업자번호: ${companyInfo.businessNumber}\n`;
      result += `주소: ${companyInfo.address}\n`;
      result += `연락처: ${companyInfo.phone}\n`;
      result += `대표: ${companyInfo.representative}\n\n`;

      // 각 공정별 정보 추가 (줄바꿈 포함)
      Object.entries(constructionCosts).forEach(([key, amount]) => {
        const title = {
          demolition: '철거폐기물',
          plumbing: '배관 공사',
          woodwork: '목공사',
          door: '문공사',
          tile: '타일 공사',
          film: '필름공사',
          floor: '바닥재 공사',
          painting: '도장공사',
          wallpaper: '도배공사',
          bathroom: '욕실시공',
          electric: '전기공사',
          furniture: '가구공사',
          aircon: '에어컨공사'
        }[key];

        if (amount) {
          result += `[${title}] ${amount}원\n`;
        }
      });

      // 총 합계 추가 (이미 추출된 totalAmount 사용)
      if (totalAmount) {
        result += `\n총 공사금액: ${totalAmount}원`;
      }

      return result.trim();

    } catch (error) {
      console.error('텍스트 형식화 중 오류 발생:', error);
      return '텍스트 형식화 중 오류가 발생했습니다.';
    }
  }, [headerMessage]);

  const extractText = useCallback(async (file) => {
    setIsLoading(true);
    setError('');
    try {
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      let fullText = '';

      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        
        const textItems = textContent.items.sort((a, b) => {
          if (Math.abs(a.transform[5] - b.transform[5]) < 5) {
            return a.transform[4] - b.transform[4];
          }
          return b.transform[5] - a.transform[5];
        });

        let lastY = null;
        const pageText = textItems.reduce((acc, item) => {
          if (lastY !== null && Math.abs(item.transform[5] - lastY) > 5) {
            acc += '\n';
          }
          acc += item.str + ' ';
          lastY = item.transform[5];
          return acc;
        }, '');

        fullText += pageText;
      }

      console.log('PDF에서 추출된 전체 텍스트:', fullText);
      const formattedText = formatExtractedText(fullText);
      setExtractedText(formattedText);
    } catch (err) {
      console.error('PDF 처리 중 오류 발생:', err);
      setError('PDF 파일을 처리하는 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  }, [formatExtractedText]);

  useEffect(() => {
    if (selectedFile) {
      extractText(selectedFile);
    }
  }, [selectedFile, extractText]);

  // 파이어베이스 저장 함수
  const saveToFirebase = async (text) => {
    try {
      const docRef = doc(pdfMessagesCollection, 'current');
      await setDoc(docRef, {
        message: headerMessage,
        content: text,
        updatedAt: serverTimestamp()
      });
      alert('저장되었습니다.');
    } catch (error) {
      console.error('저장 중 오류 발생:', error);
      alert('저장 중 오류가 발생했습니다.');
    }
  };

  // 메시지 저장 핸들러
  const handleMessageSave = async (newMessage) => {
    setHeaderMessage(newMessage);
    setShowMessageModal(false);
    
    // 현재 추출된 텍스트가 있다면 새 메시지로 업데이트
    if (extractedText) {
      const updatedText = formatExtractedText(extractedText, newMessage);
      setExtractedText(updatedText);
      await saveToFirebase(updatedText);
    }
  };

  // 제목 클릭 핸들러 추가
  const handleTitleClick = () => {
    setShowMessageModal(true);
  };

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h2 
        onClick={handleTitleClick} 
        style={{ 
          cursor: 'pointer',
          display: 'inline-block',
          padding: '5px 10px',
          borderRadius: '5px',
          transition: 'background-color 0.3s',
          ':hover': {
            backgroundColor: 'rgba(148, 106, 106, 0.1)'
          }
        }}
      >
        PDF 추출 {/* 클릭 가능한 제목 */}
      </h2>
      <div 
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{ 
          marginTop: '20px',
          border: `2px dashed ${isDragging ? '#946A6A' : '#ccc'}`,
          borderRadius: '10px',
          padding: '40px',
          textAlign: 'center',
          backgroundColor: isDragging ? 'rgba(148, 106, 106, 0.1)' : 'transparent',
          transition: 'all 0.3s ease'
        }}
      >
        <div style={{ marginBottom: '20px' }}>
          {isDragging ? (
            <p>여기에 PDF 파일을 놓으세요</p>
          ) : (
            <p>PDF 파일을 여기에 드래그하거나 아래 버튼을 클릭하세요</p>
          )}
        </div>
        <input
          type="file"
          accept=".pdf"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="pdf-file-input"
        />
        <label 
          htmlFor="pdf-file-input"
          style={{
            padding: '10px 20px',
            backgroundColor: '#946A6A',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            display: 'inline-block',
            marginBottom: '10px'
          }}
        >
          파일 선택
        </label>
      </div>

      {isLoading && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <p>PDF 파일 처리 중...</p>
        </div>
      )}

      {error && (
        <div style={{ 
          marginTop: '20px', 
          padding: '10px', 
          backgroundColor: '#ffebee', 
          color: '#c62828',
          borderRadius: '5px' 
        }}>
          <p>{error}</p>
        </div>
      )}

      {extractedText && !isLoading && (
        <div style={{ 
          marginTop: '20px',
          padding: '20px',
          backgroundColor: '#f5f5f5',
          borderRadius: '5px'
        }}>
          <pre style={{ 
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            fontFamily: 'monospace',
            marginBottom: '20px'
          }}>
            {extractedText}
          </pre>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <CopyToClipboard 
              text={extractedText}
              onCopy={() => alert('텍스트가 클립보드에 복사되었습니다.')}
            >
              <button
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#946A6A',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}
              >
                텍스트 복사
              </button>
            </CopyToClipboard>
            <button
              onClick={() => saveToFirebase(extractedText)}
              style={{
                padding: '10px 20px',
                backgroundColor: '#946A6A',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
            >
              저장
            </button>
          </div>
        </div>
      )}

      {showMessageModal && (
        <MessageInputModal
          defaultMessage={headerMessage}
          onSave={handleMessageSave}
          onClose={() => setShowMessageModal(false)}
        />
      )}
    </div>
  );
};

export default PdfExtract; 