import React from 'react';
import './styles.css';

const gradeOptions = ['등급', '하', '중', '상'];

const GradeFilter = ({
  selectedGrade,
  isEditMode,
  isEditingMode,
  selectedAddress,
  onGradeChange,
  onShowSelectVersionModal,
  setPendingModifications,
  setModificationCount,
  onFetchData,
}) => {
  const handleGradeChange = (value) => {
    const newGrade = value === '등급' ? '전체' : value;
    
    if (isEditMode && selectedAddress && !isEditingMode) {
      onShowSelectVersionModal(true);
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'grade',
          originalValue: selectedGrade,
          modifiedValue: newGrade,
          modifiedAt: new Date().toISOString()
        }
      }));
      return;
    }
    
    onGradeChange(newGrade);
    onFetchData();
    
    if (isEditingMode) {
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'grade',
          originalValue: selectedGrade,
          modifiedValue: newGrade,
          modifiedAt: new Date().toISOString()
        }
      }));
      setModificationCount(prev => prev + 1);
    }
  };

  return (
    <div className="grade-filter">
      <select 
        value={selectedGrade === '전체' ? '등급' : selectedGrade} 
        onChange={(e) => handleGradeChange(e.target.value)} 
        className="grade-select"
      >
        {gradeOptions.map(grade => (
          <option key={grade} value={grade}>{grade}</option>
        ))}
      </select>
    </div>
  );
};

export default GradeFilter; 