import React from 'react';
import './styles.css';

const FurnitureFilter = ({
  selectedFurniture,
  isEditMode,
  isEditingMode,
  selectedAddress,
  onFurnitureChange,
  onShowSelectVersionModal,
  setPendingModifications,
  setModificationCount,
  onFetchData
}) => {
  const handleChange = (value) => {
    if (isEditMode && selectedAddress && !isEditingMode) {
      onShowSelectVersionModal(true);
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'furniture',
          originalValue: selectedFurniture,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      return;
    }
    
    onFurnitureChange(value);
    
    if (isEditingMode) {
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'furniture',
          originalValue: selectedFurniture,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      setModificationCount(prev => prev + 1);
    }

    onFetchData();
  };

  return (
    <div className="furniture-filter">
      <select 
        value={selectedFurniture} 
        onChange={(e) => handleChange(e.target.value)} 
        className="furniture-select"
      >
        <option value="상담필요">가구 상담필요</option>
        <option value="기본">가구 기본</option>
      </select>
    </div>
  );
};

export default FurnitureFilter; 