import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { saveApiKeys, testAiConnection } from '../firebase';
import './ApiSettings.css';

const ApiSettings = () => {
  const [apiKeys, setApiKeys] = useState({
    openai: '',
    claude: '',
    gemini: '',
    perplexity: ''
  });
  const [showMessage, setShowMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [testing, setTesting] = useState(false);

  useEffect(() => {
    loadApiKeys();
  }, []);

  const loadApiKeys = async () => {
    try {
      const docRef = doc(db, 'config', 'apiKeys');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setApiKeys(docSnap.data());
      }
    } catch (error) {
      console.error('Error loading API keys:', error);
      showMessageWithTimeout('API 키 로딩에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (key, value) => {
    setApiKeys(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const showMessageWithTimeout = (message) => {
    setShowMessage(message);
    setTimeout(() => setShowMessage(''), 3000);
  };

  const testApiKey = async (service) => {
    if (!apiKeys[service]) {
      showMessageWithTimeout(`${service.toUpperCase()} API 키를 입력해주세요.`);
      return;
    }

    setTesting(true);
    const result = await testAiConnection(service, apiKeys[service]);
    setTesting(false);

    if (result) {
      showMessageWithTimeout(`${service.toUpperCase()} API 연결 성공!`);
    } else {
      showMessageWithTimeout(`${service.toUpperCase()} API 연결 실패. 키를 확인해주세요.`);
    }
  };

  const handleSave = async () => {
    try {
      const result = await saveApiKeys(apiKeys);
      if (result) {
        showMessageWithTimeout('API 키가 저장되었습니다.');
      } else {
        showMessageWithTimeout('API 키 저장에 실패했습니다.');
      }
    } catch (error) {
      console.error('Error saving API keys:', error);
      showMessageWithTimeout('API 키 저장에 실패했습니다.');
    }
  };

  if (loading) {
    return <div className="api-settings-container">Loading...</div>;
  }

  return (
    <div className="api-settings-container">
      <h1>AI API 설정</h1>

      <div className="api-form">
        <div className="input-group">
          <label>ChatGPT API Key</label>
          <div className="input-with-button">
            <input
              type="password"
              value={apiKeys.openai}
              onChange={(e) => handleInputChange('openai', e.target.value)}
              placeholder="sk-로 시작하는 OpenAI API Key 입력"
            />
            <button 
              onClick={() => testApiKey('openai')}
              disabled={testing}
              className="test-button"
            >
              테스트
            </button>
          </div>
        </div>

        <div className="input-group">
          <label>Claude API Key</label>
          <div className="input-with-button">
            <input
              type="password"
              value={apiKeys.claude}
              onChange={(e) => handleInputChange('claude', e.target.value)}
              placeholder="Claude API Key 입력"
            />
            <button 
              onClick={() => testApiKey('claude')}
              disabled={testing}
              className="test-button"
            >
              테스트
            </button>
          </div>
        </div>

        <div className="input-group">
          <label>Gemini API Key</label>
          <div className="input-with-button">
            <input
              type="password"
              value={apiKeys.gemini}
              onChange={(e) => handleInputChange('gemini', e.target.value)}
              placeholder="Gemini API Key 입력"
            />
            <button 
              onClick={() => testApiKey('gemini')}
              disabled={testing}
              className="test-button"
            >
              테스트
            </button>
          </div>
        </div>

        <div className="input-group">
          <label>Perplexity API Key</label>
          <div className="input-with-button">
            <input
              type="password"
              value={apiKeys.perplexity}
              onChange={(e) => handleInputChange('perplexity', e.target.value)}
              placeholder="Perplexity API Key 입력"
            />
            <button 
              onClick={() => testApiKey('perplexity')}
              disabled={testing}
              className="test-button"
            >
              테스트
            </button>
          </div>
        </div>

        <button 
          onClick={handleSave} 
          className="save-button"
          disabled={testing}
        >
          저장
        </button>
      </div>

      {showMessage && (
        <div className="message-popup">
          {showMessage}
        </div>
      )}
    </div>
  );
};

export default ApiSettings; 