import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebase';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import './SketchupLicenseAdmin.css';

const SketchupLicenseAdmin = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);

  // 사용자 목록 조회
  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'sketchup_ruby_users'));
      const usersData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(usersData);
    } catch (error) {
      console.error('사용자 데이터 로딩 실패:', error);
      setError('사용자 데이터를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // 라이센스 삭제 처리
  const handleDeleteLicense = async (userId, licenseKey) => {
    if (!window.confirm('정말로 이 라이센스를 삭제하시겠습니까?')) {
      return;
    }

    setDeleteLoading(true);
    setError('');

    try {
      // 1. sketchup_ruby_licenses 컬렉션에서 라이센스 삭제
      if (licenseKey) {
        await deleteDoc(doc(db, 'sketchup_ruby_licenses', licenseKey));
      }

      // 2. sketchup_ruby_users 컬렉션의 사용자 문서에서 라이센스 필드 제거
      const userRef = doc(db, 'sketchup_ruby_users', userId);
      await updateDoc(userRef, {
        license: null
      });

      // 3. 사용자 목록 새로고침
      await fetchUsers();
      alert('라이센스가 성공적으로 삭제되었습니다.');
    } catch (err) {
      console.error('라이센스 삭제 실패:', err);
      setError('라이센스 삭제에 실패했습니다. 다시 시도해주세요.');
    } finally {
      setDeleteLoading(false);
    }
  };

  if (loading) {
    return <div className="admin-container">로딩 중...</div>;
  }

  return (
    <div className="admin-container">
      <div className="admin-header">
        <h1>스케치업 루비 라이센스 관리</h1>
        <p>총 사용자 수: {users.length}명</p>
        {error && <p className="error-message">{error}</p>}
      </div>

      <div className="users-table-container">
        <table className="users-table">
          <thead>
            <tr>
              <th>이름</th>
              <th>이메일</th>
              <th>연락처</th>
              <th>라이센스 상태</th>
              <th>발급일</th>
              <th>작업</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>
                  {user.license ? (
                    <span className="license-active">활성화</span>
                  ) : (
                    <span className="license-inactive">미발급</span>
                  )}
                </td>
                <td>
                  {user.license 
                    ? new Date(user.license.issuedAt).toLocaleDateString()
                    : '-'
                  }
                </td>
                <td>
                  {user.license && (
                    <button
                      onClick={() => handleDeleteLicense(user.id, user.license.licenseKey)}
                      disabled={deleteLoading}
                      className="license-delete-btn"
                    >
                      {deleteLoading ? '삭제 중...' : '삭제'}
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SketchupLicenseAdmin; 