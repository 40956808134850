import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import './AddressSelect.css';

const AddressSelect = ({ onAddressSelect }) => {
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);

  // 실시간으로 주소 목록 구독
  useEffect(() => {
    const addressesCollection = collection(db, 'addresses');
    const q = query(addressesCollection, where("isCompleted", "==", true));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const addressList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAddresses(addressList);
    }, (error) => {
      console.error("Error fetching addresses:", error);
    });

    return () => unsubscribe();
  }, []);

  const handleAddressChange = (e) => {
    const selected = addresses.find(addr => addr.id === e.target.value);
    setSelectedAddress(selected);
    if (onAddressSelect) {
      onAddressSelect(selected);
    }
  };

  return (
    <div className="address-select-container">
      <label className="address-select-label">공사 주소:</label>
      <select 
        className="address-select-input"
        onChange={handleAddressChange}
        value={selectedAddress?.id || ''}
      >
        <option value="">주소를 선택하세요</option>
        {addresses.map(address => (
          <option key={address.id} value={address.id}>
            {address.description}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AddressSelect; 