import React from 'react';
import './styles.css';

const sizeOptions = ['평', '20평', '30평', '40평', '50평', '60평', '70평', '80평'];

const SizeFilter = ({
  selectedSize,
  isEditMode,
  isEditingMode,
  selectedAddress,
  onSizeChange,
  onShowSelectVersionModal,
  setPendingModifications,
  setModificationCount,
  onFetchData,
}) => {
  const handleSizeChange = (value) => {
    const newSize = value === '평' ? '전체' : value;
    
    if (isEditMode && selectedAddress && !isEditingMode) {
      onShowSelectVersionModal(true);
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'size',
          originalValue: selectedSize,
          modifiedValue: newSize,
          modifiedAt: new Date().toISOString()
        }
      }));
      return;
    }
    
    onSizeChange(newSize);
    onFetchData();
    
    if (isEditingMode) {
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'size',
          originalValue: selectedSize,
          modifiedValue: newSize,
          modifiedAt: new Date().toISOString()
        }
      }));
      setModificationCount(prev => prev + 1);
    }
  };

  return (
    <div className="size-filter">
      <select 
        value={selectedSize === '전체' ? '평' : selectedSize} 
        onChange={(e) => handleSizeChange(e.target.value)} 
        className="size-select"
      >
        {sizeOptions.map(size => (
          <option key={size} value={size}>{size}</option>
        ))}
      </select>
    </div>
  );
};

export default SizeFilter; 