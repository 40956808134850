import React, { useEffect } from 'react';
import EstimateResult from './EstimateResult';
import './EstimateDetailModal.css';

const EstimateDetailModal = ({ estimate, onClose }) => {
  const prices = estimate.selectedProcesses.reduce((acc, process) => ({
    ...acc,
    [process.name]: process.price
  }), {});

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  return (
    <div className="estimate-modal-overlay" onClick={onClose}>
      <div className="estimate-modal-content" onClick={e => e.stopPropagation()}>
        <button className="estimate-modal-close" onClick={onClose}>×</button>
        <EstimateResult 
          selectedProcesses={estimate.selectedProcesses.map(p => p.name)}
          selectedArea={estimate.selectedArea}
          userInfo={estimate.userInfo}
          isModalView={true}
          prices={prices}
          totalPrice={estimate.totalPrice}
          selectedLineup={estimate.selectedLineup}
        />
      </div>
    </div>
  );
};

export default EstimateDetailModal; 