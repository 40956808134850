import React from 'react';
import './LineupSelection.css';
import { FaLeaf, FaHeart, FaCrown } from 'react-icons/fa';

const LineupSelection = ({ onSelect, selectedLineup }) => {
  const lineups = [
    {
      id: 'refresh',
      name: 'REFRESH',
      icon: <FaLeaf className="lineup-icon" />,
      title: '리프레쉬',
      description: '일상에 활력을 더하는',
      subDescription: '합리적인 가격의 실용적 디자인',
      priceRange: '80-120만원/평',
      color: '#7CB342'
    },
    {
      id: 'harmony',
      name: 'HARMONY',
      icon: <FaHeart className="lineup-icon" />,
      title: '하모니',
      description: '삶의 가치를 높이는',
      subDescription: '프리미엄 디자인',
      priceRange: '130-200만원/평',
      color: '#946A6A'
    },
    {
      id: 'collection',
      name: 'COLLECTION',
      icon: <FaCrown className="lineup-icon" />,
      title: '컬렉션',
      description: '세상에 단 하나뿐인',
      subDescription: '최고급 맞춤 설계',
      priceRange: '200-350만원/평',
      color: '#5D4037'
    }
  ];

  return (
    <div className="lineup-selection">
      <div className="lineup-title-card">
        <h2>서비스를 선택해주세요</h2>
        <p>
          원하시는 스타일과 예산에 맞는<br />
          서비스를 선택해주세요
        </p>
      </div>
      <div className="lineup-list">
        {lineups.map((lineup) => (
          <div
            key={lineup.id}
            className={`lineup-card ${selectedLineup === lineup.id ? 'selected' : ''}`}
            onClick={() => onSelect(lineup.id)}
            style={{
              '--card-color': lineup.color
            }}
          >
            <div className="lineup-content">
              <div className="lineup-header">
                {lineup.icon}
                <span className="lineup-name">{lineup.name}</span>
              </div>
              <div className="lineup-info">
                <h3 className="lineup-title">{lineup.title}</h3>
                <p className="lineup-description">{lineup.description}</p>
                <p className="lineup-sub-description">{lineup.subDescription}</p>
                <div className="lineup-price">{lineup.priceRange}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LineupSelection; 