import React from 'react';
import './styles.css';

const ACFilter = ({
  selectedAC,
  isEditMode,
  isEditingMode,
  selectedAddress,
  onACChange,
  onShowSelectVersionModal,
  setPendingModifications,
  setModificationCount,
  onFetchData
}) => {
  const handleChange = (value) => {
    if (isEditMode && selectedAddress && !isEditingMode) {
      onShowSelectVersionModal(true);
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'ac',
          originalValue: selectedAC,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      return;
    }
    
    onACChange(value);
    
    if (isEditingMode) {
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'ac',
          originalValue: selectedAC,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      setModificationCount(prev => prev + 1);
    }

    onFetchData();
  };

  return (
    <div className="ac-filter">
      <select 
        value={selectedAC} 
        onChange={(e) => handleChange(e.target.value)} 
        className="ac-select"
      >
        <option value="없음">에어컨 없음</option>
        <option value="2대">에어컨 2대</option>
        <option value="3대">에어컨 3대</option>
        <option value="4대">에어컨 4대</option>
        <option value="5대">에어컨 5대</option>
        <option value="6대">에어컨 6대</option>
        <option value="7대">에어컨 7대</option>
      </select>
    </div>
  );
};

export default ACFilter; 