import React from 'react';
import './AISuggestButton.css';

const AISuggestButton = ({ onClick, processId, processName }) => {
  return (
    <button 
      className="ai-suggest-button"
      onClick={() => onClick(processId)}
      title={`${processName} AI 추천`}
    >
      🤖
    </button>
  );
};

export default AISuggestButton; 