import React from 'react';
import './styles.css';

const FloorTileFilter = ({
  selectedFloorTile,
  isEditMode,
  isEditingMode,
  selectedAddress,
  onFloorTileChange,
  onShowSelectVersionModal,
  setPendingModifications,
  setModificationCount,
  onFetchData
}) => {
  const handleChange = (value) => {
    if (isEditMode && selectedAddress && !isEditingMode) {
      onShowSelectVersionModal(true);
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'floor_tile',
          originalValue: selectedFloorTile,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      return;
    }
    
    onFloorTileChange(value);
    
    if (isEditingMode) {
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'floor_tile',
          originalValue: selectedFloorTile,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      setModificationCount(prev => prev + 1);
    }

    onFetchData();
  };

  return (
    <div className="floor-tile-filter">
      <select 
        value={selectedFloorTile} 
        onChange={(e) => handleChange(e.target.value)} 
        className="floor-tile-select"
      >
        <option value="바닥타일미포함">바닥타일미포함</option>
        <option value="바닥타일포함">바닥타일포함</option>
      </select>
    </div>
  );
};

export default FloorTileFilter; 