import React from 'react';
import { useNavigate } from 'react-router-dom';

const AreaCalculation = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  return (
    <div className="page-container">
      <button className="back-button" onClick={handleBack}>
        &#8592; 뒤로가기
      </button>
      <div className="page-content">
        <h1 className="page-title">평수 구하기</h1>
        <p className="page-description">정확한 평수를 쉽게 계산할 수 있습니다.</p>
        <button className="start-button">평수 계산하기</button>
      </div>
    </div>
  );
};

export default AreaCalculation;
