import React, { useState, useEffect } from 'react';
import './MobileSavedItemsModal.css';

const MobileSavedItemsModal = ({ onSelect, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  // 임시 데이터 (나중에 파이어베이스로 대체)
  const [items] = useState([
    { id: 1, name: '벽지', unit: '식', unitPrice: 1000000, note: '실크벽지', memo: '30평형' },
    { id: 2, name: '바닥재', unit: '식', unitPrice: 2000000, note: '강마루', memo: '30평형' },
    { id: 3, name: '주방', unit: '식', unitPrice: 3000000, note: '싱크대', memo: '20평형' }
  ]);

  const filteredItems = items.filter(item => {
    const searchLower = searchTerm.toLowerCase();
    
    // 평수 검색 처리
    const pyungMatch = searchTerm.match(/(\d+)평/);
    if (pyungMatch) {
      const startPyung = parseInt(pyungMatch[1]);
      const endPyung = startPyung + 9;
      const itemPyungMatch = item.memo?.match(/(\d+)평/) || item.name.match(/(\d+)평/);
      
      if (itemPyungMatch) {
        const itemPyung = parseInt(itemPyungMatch[1]);
        return itemPyung >= startPyung && itemPyung <= endPyung;
      }
      return false;
    }

    // 일반 검색 (품목명 + 메모)
    return (
      item.name.toLowerCase().includes(searchLower) ||
      (item.memo && item.memo.toLowerCase().includes(searchLower))
    );
  });

  return (
    <div className="mobile-saved-items-modal">
      <div className="mobile-saved-items-content">
        <h3>저장된 품목 목록</h3>
        
        <div className="mobile-search-box">
          <input
            type="text"
            placeholder="품목명 검색..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="mobile-items-list">
          {filteredItems.length > 0 ? (
            filteredItems.map(item => (
              <div 
                key={item.id} 
                className="mobile-item-row"
                onClick={() => onSelect(item)}
              >
                <div className="mobile-item-content">
                  <div className="mobile-item-name">{item.name}</div>
                  <div className="mobile-item-unit">{item.unit}</div>
                  <div className="mobile-item-price">{item.unitPrice.toLocaleString()}원</div>
                  {item.memo && <div className="mobile-item-memo">{item.memo}</div>}
                </div>
              </div>
            ))
          ) : (
            <div className="mobile-no-items">
              {searchTerm ? '검색 결과가 없습니다.' : '저장된 품목이 없습니다.'}
            </div>
          )}
        </div>

        <div className="mobile-modal-buttons">
          <button onClick={onClose}>닫기</button>
        </div>
      </div>
    </div>
  );
};

export default MobileSavedItemsModal; 