import React from 'react';

const FontSelector = ({ selectedFont, onFontChange }) => {
  return (
    <div style={{ marginBottom: '15px' }}>
      <label>폰트 선택:</label>
      <select
        value={selectedFont}
        onChange={(e) => onFontChange(e.target.value)}
        style={{ 
          width: '100%', 
          padding: '5px',
          marginTop: '5px',
          fontFamily: 'Pretendard'
        }}
      >
        <option value="Pretendard" style={{ fontFamily: 'Pretendard' }}>
          Pretendard
        </option>
        <option value="ABSTER" style={{ fontFamily: 'ABSTER' }}>
          ABSTER
        </option>
      </select>
    </div>
  );
};

export default FontSelector; 