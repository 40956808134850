import React from 'react';
import { FaEyeSlash, FaEye } from 'react-icons/fa';

const HideProcessButton = ({ processId, isHidden, onToggle }) => {
  return (
    <button
      className={`hide-process-button ${isHidden ? 'hidden' : ''}`}
      onClick={() => onToggle(processId)}
      title={isHidden ? '공정 보이기' : '공정 숨기기'}
    >
      {isHidden ? <FaEyeSlash /> : <FaEye />}
    </button>
  );
};

export default HideProcessButton; 