import React from 'react';
import './PageControls.css';

const PageControls = ({ onAddWorkType, onAddMaterial, onDelete, onDuplicate, onBulkImageUpload, hasPages = false }) => {
  return (
    <div className="page-control-buttons">
      <button
        className="control-button add-type"
        onClick={onAddWorkType}
        title="작업 종류 추가"
      >
        +
      </button>
      {hasPages && (
        <>
          <button
            className="control-button bulk-upload"
            onClick={onBulkImageUpload}
            title="이미지 일괄 추가"
          >
            📥
          </button>
          <button
            className="control-button add-material"
            onClick={onAddMaterial}
            title="자재 표시"
          >
            📦
          </button>
          <button
            className="control-button duplicate"
            onClick={onDuplicate}
            title="페이지 복제"
          >
            📋
          </button>
          <button
            className="control-button delete"
            onClick={onDelete}
            title="페이지 삭제"
          >
            🗑
          </button>
        </>
      )}
    </div>
  );
};

export default PageControls; 