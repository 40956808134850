import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, doc, getDoc, addDoc } from 'firebase/firestore';
import { getProcesses } from '../../firebase';
import LandingPage from './LandingPage';
import AreaSelection from './AreaSelection';
import ProcessSelection from './ProcessSelection';
import UserInfoInput from './UserInfoInput';
import EstimateResult from './EstimateResult';
import EmailTemplateModal from './EmailTemplateModal';
import './EstimateApp.css';
import './EstimateResult.css';
import { trackMetaEvent } from '../../utils/metaPixel';
import LineupSelection from './LineupSelection';

// generateEstimateId 함수 추가
const generateEstimateId = async (db) => {
  const now = new Date();
  const year = now.getFullYear().toString().slice(-2);
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  
  return `${year}${month}${day}-${random}`;
};

const EstimateProcess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams(); // URL에서 견적서 ID를 가져옴

  const [step, setStep] = useState(0);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedProcesses, setSelectedProcesses] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [prices, setPrices] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [titleClickCount, setTitleClickCount] = useState(0);
  const [showEmailTemplate, setShowEmailTemplate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isProcessesLoading, setIsProcessesLoading] = useState(true);
  const [processes, setProcesses] = useState([]);
  const [estimateId, setEstimateId] = useState(null);
  const [selectedLineup, setSelectedLineup] = useState(null);
  const [skipLineupSelection, setSkipLineupSelection] = useState(false);
  const [defaultLineup, setDefaultLineup] = useState(null);

  // 견적서 ID가 있는 경우 데이터 로드
  useEffect(() => {
    const loadEstimateData = async () => {
      if (id) {
        try {
          setLoading(true);
          const estimateDoc = await getDoc(doc(db, 'estimates', id));
          
          if (estimateDoc.exists()) {
            const data = estimateDoc.data();
            setSelectedArea(data.selectedArea);
            setSelectedProcesses(data.selectedProcesses.map(p => p.name));
            setUserInfo(data.userInfo);
            setPrices(data.selectedProcesses.reduce((acc, p) => ({
              ...acc,
              [p.name]: p.price
            }), {}));
            setTotalPrice(data.totalPrice);
            setSelectedLineup(data.selectedLineup);
            setStep(4);
          } else {
            console.error('견적서를 찾을 수 없습니다 - ID:', id);
            navigate('/');
          }
        } catch (error) {
          console.error('견적서 로드 중 오류:', error);
          navigate('/');
        } finally {
          setLoading(false);
        }
      } else {
        console.log('ID가 없음 - 일반 모드로 진행');
        setLoading(false);
      }
    };

    loadEstimateData();
  }, [id, navigate]);

  // 각 단계 전환 시 history 상태를 추가하는 함수
  const addHistoryState = (newStep) => {
    window.history.pushState({ step: newStep }, '', window.location.pathname);
  };

  // popstate 이벤트 핸들러 수정
  useEffect(() => {
    const handlePopState = (event) => {
      // 견적서 상세 보기일 경우 처리하지 않음
      if (id) return;

      // returnPath가 있는 경우 해당 경로로 이동
      if (location.state?.returnPath) {
        navigate(location.state.returnPath.replace(':id', location.state.returnParams?.id || ''));
        return;
      }

      // 이전 단계로 이동
      const prevStep = event.state?.step ?? step - 1;
      if (prevStep >= 0) {
        setStep(prevStep);
      } else {
        navigate('/');
      }
    };

    window.addEventListener('popstate', handlePopState);
    
    // 컴포넌트 마운트 시 초기 history 상태 설정
    if (!id && !location.state?.returnPath && step === 0) {
      window.history.replaceState({ step: 0 }, '', window.location.pathname);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [step, id, location.state, navigate]);

  // 가격 계산을 위한 useEffect 추가
  useEffect(() => {
    const calculatePrices = async () => {
      if (selectedArea && selectedProcesses.length > 0) {
        try {
          // settings 컬렉션의 areaPrices 문서에서 가격 데이터 조회
          const settingsDocRef = doc(db, 'settings', 'areaPrices');
          const settingsDocSnap = await getDoc(settingsDocRef);

          if (settingsDocSnap.exists()) {
            const areaPricesData = settingsDocSnap.data();
            // 선택된 평수의 가격 데이터 가져오기
            const areaPrices = areaPricesData[`${selectedArea}평대`] || {};
            
            // 가격 매핑
            const mappedPrices = {};
            selectedProcesses.forEach(process => {
              mappedPrices[process] = areaPrices[process];
            });
            
            setPrices(mappedPrices);
            
            // 총액 계산
            const total = selectedProcesses.reduce((sum, process) => {
              const price = parseInt(areaPrices[process]) || 0;
              return sum + price;
            }, 0);
            
            setTotalPrice(total);
          } else {
            console.error('가격 데이터를 찾을 수 없습니다.');
          }
        } catch (error) {
          console.error('가격 계산 중 오류:', error);
        }
      }
    };

    calculatePrices();
  }, [selectedArea, selectedProcesses]);

  // 공정 데이터 리 로드
  useEffect(() => {
    const preloadProcesses = async () => {
      try {
        setIsProcessesLoading(true);
        const processesDoc = await getDoc(doc(db, 'oneClickProcesses', 'default'));
        if (processesDoc.exists()) {
          setProcesses(processesDoc.data().processList || []);
        }
      } catch (error) {
        console.error('공정 데이터 로드 중 오류:', error);
      } finally {
        setIsProcessesLoading(false);
      }
    };

    preloadProcesses();
  }, []);

  // 각 단계 전환 함수들 수정
  const handleStart = () => {
    if (skipLineupSelection && defaultLineup) {
      setSelectedLineup(defaultLineup);
      setStep(1); // 바로 평수 선택으로
    } else {
      setStep(0.5); // 기존처럼 라인업 선택으로
    }
    addHistoryState(skipLineupSelection ? 1 : 0.5);
  };

  const handleLineupSelect = (lineup) => {
    setSelectedLineup(lineup);
    setStep(1);
    addHistoryState(1);
  };

  const handleAreaSelect = (area) => {
    trackMetaEvent('SelectArea', { area });
    setSelectedArea(area);
    setStep(2);
    addHistoryState(2);
  };

  const handleProcessSelect = (processes) => {
    setSelectedProcesses(processes);
    setShowWarning(false);
  };

  const handleComplete = () => {
    if (selectedProcesses.length >= 4) {
      trackMetaEvent('CompleteProcessSelection', {
        processes: selectedProcesses,
        count: selectedProcesses.length
      });
      setStep(3);
      addHistoryState(3);
      setShowWarning(false);
    } else {
      setShowWarning(true);
    }
  };

  const handleSubmit = async (name, email) => {
    try {
      const processDetails = selectedProcesses.map(process => ({
        name: process,
        price: prices[process] || 0
      }));

      const docRef = await addDoc(collection(db, "estimates"), {
        userInfo: { name, email },
        selectedArea: selectedArea,
        selectedProcesses: processDetails,
        totalPrice: totalPrice,
        selectedLineup: selectedLineup,
        createdAt: new Date()
      });

      setUserInfo({ name, email });
      setEstimateId(docRef.id);
      setStep(4);
      window.history.pushState({ step: 4 }, '', `/estimate/${docRef.id}`);

      trackMetaEvent('CompleteEstimate', {
        area: selectedArea,
        processCount: selectedProcesses.length,
        totalPrice: totalPrice,
        lineup: selectedLineup
      });

    } catch (error) {
      alert('견적서 저장 중 오류가 발생했습니다. 다시 시도해주세요.');
    }
  };

  const handleGoHome = () => {
    setStep(0);
    setSelectedArea(null);
    setSelectedProcesses([]);
    setShowWarning(false);
    setUserInfo(null);
  };

  const handleTitleClick = () => {
    const newCount = titleClickCount + 1;
    setTitleClickCount(newCount);
    
    if (newCount === 4) {
      setShowEmailTemplate(true);
      setTitleClickCount(0);
    }
  };

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'settings', 'lineupSettings'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setSkipLineupSelection(!!data.defaultLineup);
          setDefaultLineup(data.defaultLineup);
        }
      } catch (error) {
        console.error('라인업 설정 로드 중 오류:', error);
      }
    };
    
    loadSettings();
  }, []);

  if (loading) {
    return <div className="estimate-app">로딩 중...</div>;
  }

  return (
    <div className="estimate-app">
      <div className="content">
        {step === 0 && <LandingPage onStart={handleStart} />}
        {step === 0.5 && (
          <>
            <LineupSelection 
              onSelect={handleLineupSelect}
              selectedLineup={selectedLineup}
            />
          </>
        )}
        {step > 0 && step < 4 && (
          <div className="header">
            <h2 className="title" onClick={handleTitleClick}>
              {step === 1 ? '평수를 선택해주세요' : 
               step === 2 ? (
                 <>
                   필요한 공정을 4개 이상 선택해주세요
                   <div style={{ height: '30px' }}></div>
                 </>
               ) :
               step === 3 ? '수고 많으셨습니다.' : ''}
            </h2>
            {step === 1 && (
              <p className="area-info"><br />
                확장된 평수 기준 입니다.<br />
                60평대 이상은 따로 문의주세요!
              </p>
            )}
            {step === 2 && !isProcessesLoading && (
              <>
                <p className="process-info">
                  인테리어 자재는 유명 브랜드 제품 기준 입니다.<br />
                  인터폰, 도어락은 미포함 입니다.<br /><br />
                </p>
                <ProcessSelection 
                  onSelect={handleProcessSelect} 
                  selectedProcesses={selectedProcesses}
                  processes={processes}
                />
                <button 
                  className={`complete-button ${selectedProcesses.length >= 4 ? 'active' : ''}`} 
                  onClick={handleComplete}
                >
                  선택이 끝났으면 클릭하세요
                </button>
                {showWarning && <p className="warning-message">공정을 4개 이상 선택해주세요</p>}
              </>
            )}
            {step === 3 && (
              <p className="user-info-description"><br />
                아래 사항을 입력해주시면<br />
                가 견적서를 즉시 확인할 수 있습니다. 😊
              </p>
            )}
          </div>
        )}
        {step === 1 && (
          <AreaSelection 
            onSelect={handleAreaSelect} 
            selectedArea={selectedArea}
            onBack={() => setStep(0.5)}
          />
        )}
        {step === 3 && (
          <UserInfoInput 
            onSubmit={handleSubmit} 
            selectedProcesses={selectedProcesses}
            selectedArea={selectedArea}
            prices={prices}
            totalPrice={totalPrice}
            selectedLineup={selectedLineup}
          />
        )}
        {step === 4 && (
          <EstimateResult 
            userInfo={userInfo} 
            selectedProcesses={selectedProcesses} 
            selectedArea={selectedArea} 
            onGoHome={handleGoHome}
            prices={prices}
            totalPrice={totalPrice}
            estimateId={id || estimateId}
            selectedLineup={selectedLineup}
          />
        )}
        {showEmailTemplate && (
          <EmailTemplateModal onClose={() => setShowEmailTemplate(false)} />
        )}
      </div>
    </div>
  );
};

export default EstimateProcess; 