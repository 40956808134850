import { collection, query, where, getDocs } from 'firebase/firestore';

export const generateEstimateId = async (db) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const dateStr = `${year}${month}${day}`;
  
  // 오늘 날짜의 견적서 수를 가져와서 순번 생성
  const estimatesRef = collection(db, "estimates");
  const q = query(
    estimatesRef,
    where("createdAt", ">=", new Date(today.setHours(0,0,0,0))),
    where("createdAt", "<=", new Date(today.setHours(23,59,59,999)))
  );
  
  const querySnapshot = await getDocs(q);
  const todayCount = querySnapshot.size + 1;
  const sequence = String(todayCount).padStart(2, '0');
  
  return `AM${dateStr}${sequence}`;
}; 