import React from 'react';
import './styles.css';

const DesignFilter = ({
  selectedDesign,
  isEditMode,
  isEditingMode,
  selectedAddress,
  onDesignChange,
  onShowSelectVersionModal,
  setPendingModifications,
  setModificationCount,
  onFetchData
}) => {
  const handleChange = (value) => {
    if (isEditMode && selectedAddress && !isEditingMode) {
      onShowSelectVersionModal(true);
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'design',
          originalValue: selectedDesign,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      return;
    }
    
    onDesignChange(value);
    
    if (isEditingMode) {
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'design',
          originalValue: selectedDesign,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      setModificationCount(prev => prev + 1);
    }

    onFetchData();
  };

  return (
    <div className="design-filter">
      <select 
        value={selectedDesign} 
        onChange={(e) => handleChange(e.target.value)} 
        className="design-select"
      >
        <option value="디자인포함">디자인포함</option>
        <option value="디자인제외">디자인제외</option>
      </select>
    </div>
  );
};

export default DesignFilter; 