import React, { useState } from 'react';
import './SaveItemMemoModal.css';

const SaveItemMemoModal = ({ item, onSave, onClose }) => {
  const [memo, setMemo] = useState('');

  const handleSave = () => {
    onSave({
      ...item,
      memo: memo.trim()
    });
  };

  return (
    <div className="memo-modal-overlay">
      <div className="memo-modal">
        <h3>품목 저장</h3>
        <div className="item-info">
          <p>품목명: {item.name}</p>
          <p>단가: {item.unitPrice.toLocaleString()}원</p>
        </div>
        <div className="memo-field">
          <label>메모</label>
          <textarea
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            placeholder="이 품목에 대한 메모를 입력하세요. (선택사항)"
          />
        </div>
        <div className="memo-buttons">
          <button onClick={handleSave}>저장</button>
          <button onClick={onClose}>취소</button>
        </div>
      </div>
    </div>
  );
};

export default SaveItemMemoModal; 