import { useEffect } from 'react';

const useKeyboardShortcut = ({ isEditingMode, currentVersion, onSave }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Ctrl+S (Windows) 또는 Command+S (Mac) 감지
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
        // 이벤트 전파 중지 및 기본 동작 방지
        event.stopPropagation();
        event.preventDefault();
        
        // 수정 모드이고 현재 버전이 있을 때만 중간저장 실행
        if (isEditingMode && currentVersion) {
          onSave();
        }
        
        return false;
      }
    };

    // 이벤트 리스너 등록 시 capture 옵션을 true로 설정하여 최우선 처리
    document.addEventListener('keydown', handleKeyDown, { capture: true });
    return () => {
      document.removeEventListener('keydown', handleKeyDown, { capture: true });
    };
  }, [isEditingMode, currentVersion, onSave]);
};

export default useKeyboardShortcut; 