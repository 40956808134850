import React, { useState, useEffect } from 'react';
import './MobileEstimate.css';
import MobileAddItemModal from './MobileAddItemModal';
import MobileEditItemModal from './MobileEditItemModal';
import AITemplateModal from '../estimate-ai/components/AITemplateModal';
import { db } from '../../firebase';
import { doc, getDoc, collection, getDocs, query, orderBy, updateDoc } from 'firebase/firestore';
import MobileSelectVersionModal from './MobileSelectVersionModal';
import MobileVersionManager from './MobileVersionManager';
import HiddenProcessList from '../process-hide/HiddenProcessList';
import HideProcessButton from '../process-hide/HideProcessButton';
import PdfButton from '../pdf/PdfButton';
import EmailPdfModal from '../EmailPdfModal';
import { filterProcessesForPDF } from '../pdf/utils';

const MobileEstimate = () => {
  // 공정 숨김 상태 관리
  const [hiddenProcesses, setHiddenProcesses] = useState({});
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [selectedProcessId, setSelectedProcessId] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [showSelectVersionModal, setShowSelectVersionModal] = useState(false);
  const [pendingModifications, setPendingModifications] = useState({});
  const [modificationCount, setModificationCount] = useState(0);
  const [showHiddenProcessModal, setShowHiddenProcessModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  
  // 예시 데이터
  const [estimateData, setEstimateData] = useState({
    title: '견적서',
    company: 'Amare design',
    businessNumber: '712.17.01551',
    address: '수성구 들안로 215',
    contact: '010.8694.4078',
    representative: '대표 권경대',
    processes: [],
    notes: {}
  });

  useEffect(() => {
    loadAddresses();
  }, []);

  useEffect(() => {
    if (selectedAddress) {
      loadEstimateData(selectedAddress);
    }
  }, [selectedAddress]);

  const loadAddresses = async () => {
    try {
      const addressesCollection = collection(db, 'addresses');
      const q = query(addressesCollection, orderBy('siteNumber', 'desc'));
      const addressesSnapshot = await getDocs(q);
      const addressesList = addressesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAddresses(addressesList);
    } catch (error) {
      console.error('주소 목록 로딩 중 오류:', error);
    }
  };

  // 공정 ID를 한글 이름으로 변환하는 함수
  const getProcessName = (processId) => {
    const processNames = {
      'furniture': '가구공사',
      'misc_additional': '기타잡비/별도공사',
      'wallpaper': '도배공사',
      'painting': '도장공사',
      'design': '디자인',
      'wood_flooring': '마루 공사',
      'carpentry': '목공사',
      'door': '문공사',
      'floor_protection': '바닥 보양 공사',
      'floor_tile': '바닥 타일 공사',
      'plumbing': '배관 공사',
      'silicon': '실리콘 공사',
      'ac': '에어컨공사',
      'bathroom': '욕실시공',
      'electrical': '전기공사',
      'window': '창호금속',
      'demolition': '철거폐기물',
      'tile': '타일 공사',
      'film': '필름공사',
      'hardware': '하드웨어, 악세서리',
      'supervision_utilities': '현장감리/공과잡비'
    };
    return processNames[processId] || processId;
  };

  const loadEstimateData = async (address) => {
    try {
      console.log('주소 정보:', address);
      
      // 기본 견적서 데이터 로드
      const estimateRef = doc(db, 'estimates', address.id);
      const estimateDoc = await getDoc(estimateRef);
      
      if (estimateDoc.exists()) {
        const estimateData = estimateDoc.data();
        console.log('기본 견적서 데이터:', estimateData);
        console.log('공정 데이터:', estimateData.processes);
        
        // 숨김 상태도 함께 적용
        if (estimateData.hiddenProcesses) {
          setHiddenProcesses(estimateData.hiddenProcesses);
        }
        
        setEstimateData(prevData => {
          const newData = {
            ...prevData,
            processes: (estimateData.processes || []).map(process => ({
              ...process,
              name: getProcessName(process.id)
            })),
            notes: estimateData.notes || {}
          };
          console.log('업데이트된 견적서 데이터:', newData);
          return newData;
        });
      } else {
        console.log('견적서 문서가 존재하지 않음');
      }

      // 버전 데이터 로드
      const versions = await MobileVersionManager.getVersionList(address.description);
      console.log('버전 목록:', versions);
      
      if (versions && versions.length > 0) {
        const latestVersion = versions[0];
        console.log('최신 버전:', latestVersion);
        setCurrentVersion(latestVersion);
        
        if (latestVersion.data) {
          console.log('버전 데이터:', latestVersion.data);
          console.log('버전의 공정 데이터:', latestVersion.data.processData);
          
          // 버전의 프로세스 데이터로 업데이트
          setEstimateData(prevData => {
            const newData = {
              ...prevData,
              processes: (latestVersion.data.processData || []).map(process => ({
                ...process,
                name: getProcessName(process.id)
              })),
              notes: latestVersion.data.notes || {}
            };
            console.log('버전 데이터로 업데이트된 견적서:', newData);
            return newData;
          });
          
          // 숨김 처리된 프로세스 상태 복원
          if (latestVersion.data.hiddenProcesses) {
            console.log('숨김 처리된 프로세스:', latestVersion.data.hiddenProcesses);
            setHiddenProcesses(latestVersion.data.hiddenProcesses);
          }
        }
      } else {
        console.log('버전이 존재하지 않음');
      }
    } catch (error) {
      console.error('견적서 데이터 로딩 중 오류:', error);
      alert('견적서 데이터를 불러오는데 실패했습니다.');
    }
  };

  // 공정 숨김 토글 함수
  const toggleProcessVisibility = (processId) => {
    setHiddenProcesses(prev => ({
      ...prev,
      [processId]: {
        hidden: !(prev[processId]?.hidden),
        type: 'manual'
      }
    }));
  };

  // 총 합계 계산 함수
  const calculateTotal = (processes = estimateData.processes) => {
    return processes.reduce((sum, process) => {
      if (hiddenProcesses[process.id]?.hidden) return sum;
      return sum + process.total;
    }, 0);
  };

  // 금액 포맷 함수
  const formatNumber = (number) => {
    return number.toLocaleString();
  };

  // 항목 추가 처리 함수
  const handleAddItem = (processId) => {
    setSelectedProcessId(processId);
    setShowAddItemModal(true);
  };

  // 새 항목 저장 함수
  const saveNewItem = (newItem) => {
    setEstimateData(prevData => {
      const updatedProcesses = prevData.processes.map(process => {
        if (process.id === selectedProcessId) {
          const updatedItems = [...process.items, newItem];
          const newTotal = updatedItems.reduce((sum, item) => 
            sum + (item.totalPrice || 0), 0);
          
          return {
            ...process,
            items: updatedItems,
            total: newTotal
          };
        }
        return process;
      });

      return {
        ...prevData,
        processes: updatedProcesses
      };
    });

    setShowAddItemModal(false);
    setSelectedProcessId(null);
  };

  // 항목 수정 처리 함수
  const handleEditItem = (process, item) => {
    setSelectedProcessId(process.id);
    setEditingItem(item);
    setShowEditItemModal(true);
  };

  // 수정된 항목 저장 함수
  const handleSaveEdit = (editedItem) => {
    setEstimateData(prevData => {
      const updatedProcesses = prevData.processes.map(process => {
        if (process.id === selectedProcessId) {
          const updatedItems = process.items.map(item => 
            item === editingItem ? editedItem : item
          );
          
          return {
            ...process,
            items: updatedItems,
            total: updatedItems.reduce((sum, item) => sum + item.totalPrice, 0)
          };
        }
        return process;
      });

      return {
        ...prevData,
        processes: updatedProcesses
      };
    });

    setShowEditItemModal(false);
    setEditingItem(null);
    setSelectedProcessId(null);
  };

  // 템플릿 모달 열기 핸들러
  const handleOpenTemplate = (process) => {
    setSelectedProcess(process);
    setShowTemplateModal(true);
  };

  // 템플릿 적용 핸들러
  const handleApplyTemplate = (templateData) => {
    setEstimateData(prevData => {
      const updatedProcesses = prevData.processes.map(process => {
        if (process.id === selectedProcess.id) {
          return {
            ...process,
            items: templateData.items,
            total: templateData.items.reduce((sum, item) => 
              sum + (parseInt(item.quantity) * parseInt(item.unitPrice)), 0)
          };
        }
        return process;
      });

      return {
        ...prevData,
        processes: updatedProcesses
      };
    });

    setShowTemplateModal(false);
    setSelectedProcess(null);
  };

  // 버전 선택 핸들러
  const handleVersionSelect = async (version) => {
    try {
      setCurrentVersion(version);
      
      if (version.data) {
        setEstimateData(prevData => ({
          ...prevData,
          processes: (version.data.processData || []).map(process => ({
            ...process,
            name: getProcessName(process.id)
          }))
        }));
        
        if (version.hiddenProcesses) {
          setHiddenProcesses(version.hiddenProcesses);
        }
      }
      
      setIsEditingMode(true);
      setShowSelectVersionModal(false);
    } catch (error) {
      console.error('버전 선택 중 오류:', error);
      alert('버전을 불러오는 중 오류가 발생했습니다.');
    }
  };

  // 새 버전 생성 핸들러
  const handleNewVersion = async (versionName) => {
    try {
      const newVersion = {
        versionName: versionName,
        createdAt: new Date().toISOString(),
        lastModified: new Date().toISOString(),
        address: selectedAddress.description,
        data: {
          processData: estimateData.processes,
          hiddenProcesses: hiddenProcesses
        }
      };

      await MobileVersionManager.saveVersion(selectedAddress.description, newVersion);
      setCurrentVersion(newVersion);
      setIsEditingMode(true);
      setShowSelectVersionModal(false);
    } catch (error) {
      console.error('새 버전 생성 중 오류:', error);
      alert('새 버전을 생성하는 중 오류가 발생했습니다.');
    }
  };

  // 수정 시작 버전 핸들러
  const handleStartEdit = () => {
    if (!selectedAddress) {
      alert('주소를 선택해주세요.');
      return;
    }
    setShowSelectVersionModal(true);
  };

  // 중간 저장 핸들러
  const handleInterimSave = async () => {
    try {
      if (!selectedAddress || !currentVersion) {
        alert('주소와 현재 버전 정보가 필요합니다.');
        return;
      }

      const versionData = {
        ...currentVersion,
        data: {
          processData: estimateData.processes,
          hiddenProcesses: hiddenProcesses
        },
        lastModified: new Date().toISOString()
      };

      // estimates 컬렉션에도 숨김 상태 저장
      const estimateRef = doc(db, 'estimates', selectedAddress.id);
      await updateDoc(estimateRef, {
        processes: estimateData.processes,
        hiddenProcesses: hiddenProcesses
      });

      await MobileVersionManager.saveInterimVersion(selectedAddress.description, versionData);
      alert('중간 저장이 완료되었습니다.');
    } catch (error) {
      console.error('중간 저장 중 오류:', error);
      alert('중간 저장 중 오류가 발생했습니다.');
    }
  };

  // 숨김 공정 관리 버튼 렌더링
  const renderHiddenProcessButton = () => {
    if (!selectedAddress) return null;
    
    return (
      <button 
        className="me__hidden-process-button"
        onClick={() => setShowHiddenProcessModal(true)}
      >
        숨긴 공정 관리
      </button>
    );
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        if (showAddItemModal) {
          setShowAddItemModal(false);
          setSelectedProcessId(null);
          return;
        }
        if (showEditItemModal) {
          setShowEditItemModal(false);
          setEditingItem(null);
          setSelectedProcessId(null);
          return;
        }
        if (showTemplateModal) {
          setShowTemplateModal(false);
          setSelectedProcess(null);
          return;
        }
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [showAddItemModal, showEditItemModal, showTemplateModal]);

  return (
    <div className="me">
      <div className="me__header">
        <h1 className="me__title">{estimateData.title}</h1>
        <select 
          className="me__address-select"
          value={selectedAddress?.id || ''}
          onChange={(e) => {
            const address = addresses.find(addr => addr.id === e.target.value);
            setSelectedAddress(address);
            if (!address) {
              // 주소가 선택되지 않았을 때 초기 상태로 리셋
              setEstimateData({
                title: '견적서',
                company: 'Amare design',
                businessNumber: '712.17.01551',
                address: '수성구 들안로 215',
                contact: '010.8694.4078',
                representative: '대표 권경대',
                processes: [],
                notes: {}
              });
              setHiddenProcesses({});
              setCurrentVersion(null);
              setIsEditingMode(false);
            }
          }}
        >
          <option value="">주소를 선택하세요</option>
          {addresses.map(address => (
            <option key={address.id} value={address.id}>
              {address.siteNumber}번 - {address.description}
            </option>
          ))}
        </select>
        
        <div className="me__logo">
          <img 
            src="/images/logo.png" 
            alt="Amare Design Logo" 
            style={{
              width: '40px',
              height: '40px',
              objectFit: 'cover',
              borderRadius: '50%'
            }}
          />
        </div>

        <div className="me__company">
          <p className="me__company-text">{estimateData.company}</p>
          <p className="me__company-text">사업자번호: {estimateData.businessNumber}</p>
          <p className="me__company-text">{estimateData.address}</p>
          <p className="me__company-text">{estimateData.contact}</p>
          <p className="me__company-text">{estimateData.representative}</p>
        </div>
      </div>

      {isEditingMode ? (
        <div className="me__version-controls">
          <button 
            className="me__version-button"
            onClick={handleInterimSave}
            disabled={!currentVersion}
          >
            중간 저장
          </button>
          <button 
            className="me__version-button me__version-button--cancel"
            onClick={() => {
              if (window.confirm('진행 중인 수정사항이 모두 취소됩니다. 계속하시겠습니까?')) {
                setIsEditingMode(false);
                setPendingModifications({});
                setModificationCount(0);
                loadEstimateData(selectedAddress);
              }
            }}
          >
            수정 취소
          </button>
        </div>
      ) : (
        <div className="me__version-controls">
          <button 
            className="me__version-button"
            onClick={handleStartEdit}
          >
            수정 시작
          </button>
        </div>
      )}

      <div className="me__estimate-buttons">
        <PdfButton
          selectedAddress={selectedAddress?.description}
          processData={estimateData.processes}
          notes={estimateData.notes}
          hiddenProcesses={hiddenProcesses}
          calculateTotal={calculateTotal}
          getProcessName={getProcessName}
          formatNumber={formatNumber}
          filterProcessesForPDF={filterProcessesForPDF}
        />
        <button 
          className="me__email-button"
          onClick={() => setShowEmailModal(true)}
        >
          이메일 전송
        </button>
      </div>

      {renderHiddenProcessButton()}

      <div className="me__summary">
        <h2 className="me__section-title">공정별 합계</h2>
        {estimateData.processes.map(process => !hiddenProcesses[process.id]?.hidden && (
          <div key={process.id} className="me__process-row">
            <span className="me__process-name">{process.name}</span>
            <span className="me__process-amount">
              {formatNumber(process.total)}원
            </span>
          </div>
        ))}
        <div className="me__process-total">
          <span className="me__process-total-name">총 합계</span>
          <span className="me__process-total-amount">
            {formatNumber(calculateTotal())}원
          </span>
        </div>
      </div>

      <div className="me__detailed">
        <h2 className="me__detailed-title">상세 견적서</h2>
        {estimateData.processes.map(process => !hiddenProcesses[process.id]?.hidden && (
          <div key={process.id} className="me__detailed-process">
            <div className="me__detailed-header">
              <HideProcessButton
                processId={process.id}
                isHidden={hiddenProcesses[process.id]?.hidden}
                onToggle={toggleProcessVisibility}
              />
              <span className="me__detailed-process-name">{process.name}</span>
              <div className="me__detailed-buttons">
                <button 
                  className="me__detailed-button"
                  onClick={() => handleOpenTemplate(process)}
                >
                  템플릿
                </button>
                <button 
                  className="me__detailed-button"
                  onClick={() => handleAddItem(process.id)}
                >
                  항목추가
                </button>
              </div>
            </div>
            
            <div className="me__detailed-list">
              {process.items.length > 0 ? (
                process.items.map((item, index) => (
                  <div 
                    key={index} 
                    className="me__detailed-item"
                    onClick={() => handleEditItem(process, item)}
                  >
                    <div className="me__detailed-grid">
                      <div className="me__detailed-cell">
                        <span className="me__detailed-text">{item.name}</span>
                      </div>
                      <div className="me__detailed-cell">
                        <span className="me__detailed-text">{item.quantity} {item.unit}</span>
                      </div>
                      <div className="me__detailed-cell">
                        <span className="me__detailed-text">{formatNumber(item.unitPrice)}원</span>
                      </div>
                      <div className="me__detailed-cell">
                        <span className="me__detailed-text">{formatNumber(item.quantity * item.unitPrice)}원</span>
                      </div>
                    </div>
                    {item.note && (
                      <div className="me__detailed-note">
                        <span className="me__detailed-note-label">비고:</span>
                        <span className="me__detailed-text">{item.note}</span>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="me__detailed-empty">
                  항목이 없습니다
                </div>
              )}
            </div>
            
            <div className="me__detailed-subtotal">
              소계: {formatNumber(process.total)}원
            </div>
          </div>
        ))}
      </div>

      <div className="me__total">
        <div className="me__total-row">
          <span className="me__total-label">총 합계 (VAT 별도)</span>
          <span className="me__total-value">{formatNumber(calculateTotal())}원</span>
        </div>
        <div className="me__total-row me__total-row--vat">
          <span className="me__total-label">총 합계 (VAT 포포함)</span>
          <span className="me__total-value">
            {formatNumber(Math.round(calculateTotal() * 1.1))}원
          </span>
        </div>
      </div>

      {showAddItemModal && (
        <MobileAddItemModal
          processId={selectedProcessId}
          onSave={saveNewItem}
          onClose={() => {
            setShowAddItemModal(false);
            setSelectedProcessId(null);
          }}
        />
      )}

      {showEditItemModal && editingItem && (
        <MobileEditItemModal
          item={editingItem}
          processId={selectedProcessId}
          onSave={handleSaveEdit}
          onClose={() => {
            setShowEditItemModal(false);
            setEditingItem(null);
            setSelectedProcessId(null);
          }}
        />
      )}

      {showTemplateModal && selectedProcess && (
        <AITemplateModal
          processId={selectedProcess.id}
          processName={selectedProcess.name}
          currentItems={selectedProcess.items}
          onSave={handleApplyTemplate}
          onClose={() => {
            setShowTemplateModal(false);
            setSelectedProcess(null);
          }}
        />
      )}

      {showHiddenProcessModal && (
        <HiddenProcessList 
          hiddenProcesses={hiddenProcesses}
          onToggle={toggleProcessVisibility}
          getProcessName={getProcessName}
          showModal={showHiddenProcessModal}
          onShowModal={() => setShowHiddenProcessModal(true)}
          onCloseModal={() => setShowHiddenProcessModal(false)}
        />
      )}

      {showSelectVersionModal && selectedAddress && (
        <MobileSelectVersionModal
          selectedAddress={selectedAddress}
          onSelectVersion={handleVersionSelect}
          onNewVersion={handleNewVersion}
          onClose={() => {
            setShowSelectVersionModal(false);
            setIsEditingMode(false);
          }}
        />
      )}

      {showEmailModal && (
        <EmailPdfModal
          selectedAddress={selectedAddress?.description}
          processData={estimateData.processes}
          notes={estimateData.notes}
          hiddenProcesses={hiddenProcesses}
          onClose={() => setShowEmailModal(false)}
        />
      )}
    </div>
  );
};

export default MobileEstimate;  