import React from 'react';
import './styles.css';

const WindowFilter = ({
  selectedWindow,
  isEditMode,
  isEditingMode,
  selectedAddress,
  onWindowChange,
  onShowSelectVersionModal,
  setPendingModifications,
  setModificationCount,
  onFetchData
}) => {
  const handleChange = (value) => {
    if (isEditMode && selectedAddress && !isEditingMode) {
      onShowSelectVersionModal(true);
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'window',
          originalValue: selectedWindow,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      return;
    }
    
    onWindowChange(value);
    
    if (isEditingMode) {
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'window',
          originalValue: selectedWindow,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      setModificationCount(prev => prev + 1);
    }

    onFetchData();
  };

  return (
    <div className="window-filter">
      <select 
        value={selectedWindow} 
        onChange={(e) => handleChange(e.target.value)} 
        className="window-select"
      >
        <option value="없음">창호 없음</option>
        <option value="전체">창호 전체</option>
        <option value="내창">창호 내창</option>
        <option value="거실확">창호 거실확장</option>
        <option value="거실방확장">창호 거실/주방확장</option>
      </select>
    </div>
  );
};

export default WindowFilter; 