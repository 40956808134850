import React from 'react';
import './styles.css';

const PaintingFilter = ({
  selectedPainting,
  isEditMode,
  isEditingMode,
  selectedAddress,
  onPaintingChange,
  onShowSelectVersionModal,
  setPendingModifications,
  setModificationCount,
  onFetchData
}) => {
  const handleChange = (value) => {
    if (isEditMode && selectedAddress && !isEditingMode) {
      onShowSelectVersionModal(true);
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'painting',
          originalValue: selectedPainting,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      return;
    }
    
    onPaintingChange(value);
    
    if (isEditingMode) {
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'painting',
          originalValue: selectedPainting,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      setModificationCount(prev => prev + 1);
    }

    onFetchData();
  };

  return (
    <div className="painting-filter">
      <select 
        value={selectedPainting} 
        onChange={(e) => handleChange(e.target.value)} 
        className="painting-select"
      >
        <option value="없음">도장 없음</option>
        <option value="3개소">도장 3개소</option>
        <option value="4개소">도장 4개소</option>
        <option value="5개소">도장 5개소</option>
        <option value="6개소">도장 6개소</option>
        <option value="7개소">도장 7개소</option>
      </select>
    </div>
  );
};

export default PaintingFilter; 