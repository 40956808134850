import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './PdfControls.css';

const PdfControls = ({ currentPage, pages, pageRefs }) => {
  const generateSinglePdf = async () => {
    try {
      const pageElement = pageRefs.current[currentPage];
      if (!pageElement) return;

      const canvas = await html2canvas(pageElement, {
        scale: 2,
        useCORS: true,
        logging: false
      });

      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new jsPDF('l', 'mm', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      
      pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth, pageHeight);
      pdf.save(`작업지시서_페이지${currentPage + 1}.pdf`);
    } catch (error) {
      console.error('PDF 생성 중 오류:', error);
      alert('PDF 생성 중 오류가 발생했습니다.');
    }
  };

  const generateAllPdf = async () => {
    try {
      const pdf = new jsPDF('l', 'mm', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      for (let i = 0; i < pages.length; i++) {
        const pageElement = pageRefs.current[i];
        if (!pageElement) continue;

        if (i > 0) pdf.addPage();

        const canvas = await html2canvas(pageElement, {
          scale: 2,
          useCORS: true,
          logging: false
        });

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth, pageHeight);
      }

      pdf.save('작업지시서_전체.pdf');
    } catch (error) {
      console.error('PDF 생성 중 오류:', error);
      alert('PDF 생성 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="pdf-controls">
      <button 
        className="pdf-save-button single"
        onClick={generateSinglePdf}
        title="현재 페이지 PDF 저장"
      >
        <span>📄</span>
      </button>
      <button 
        className="pdf-save-button all"
        onClick={generateAllPdf}
        title="전체 페이지 PDF 저장"
      >
        <span>📑</span>
      </button>
    </div>
  );
};

export default PdfControls; 