import React from 'react';

const TextLayerManager = ({ textLayers, onLayerSelect, onLayerDelete, onLayerAdd }) => {
  return (
    <div style={{ marginBottom: '15px' }}>
      <div style={{ 
        marginBottom: '10px'
      }}>
        <button
          onClick={onLayerAdd}
          style={{
            padding: '5px 10px',
            background: '#946A6A',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontFamily: 'Pretendard',
            width: '100%'
          }}
        >
          + 텍스트 추가
        </button>
      </div>
      <div style={{ 
        maxHeight: '250px', 
        overflowY: 'auto',
        border: '1px solid #ddd',
        borderRadius: '5px'
      }}>
        {textLayers.map((layer, index) => (
          <div
            key={layer.id}
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
              background: layer.isSelected ? '#f0f0f0' : 'white',
              cursor: 'pointer'
            }}
          >
            <div 
              onClick={() => onLayerSelect(layer.id)}
              style={{
                marginBottom: '8px'
              }}
            >
              <span style={{ 
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'block'
              }}>
                {layer.text || '텍스트 없음'}
              </span>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onLayerDelete(layer.id);
                }}
                style={{
                  padding: '2px 5px',
                  background: '#ff4444',
                  color: 'white',
                  border: 'none',
                  borderRadius: '3px',
                  cursor: 'pointer',
                  width: '120px'
                }}
              >
                삭제
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TextLayerManager; 