import React from 'react';
import './styles.css';

const ExtraFilter = ({
  selectedExtra,
  isEditMode,
  isEditingMode,
  selectedAddress,
  onExtraChange,
  onShowSelectVersionModal,
  setPendingModifications,
  setModificationCount,
  onFetchData
}) => {
  const handleChange = (value) => {
    if (isEditMode && selectedAddress && !isEditingMode) {
      onShowSelectVersionModal(true);
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'extra',
          originalValue: selectedExtra,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      return;
    }
    
    onExtraChange(value);
    
    if (isEditingMode) {
      setPendingModifications(prev => ({
        ...prev,
        [`filter_${Date.now()}`]: {
          type: 'filter',
          field: 'extra',
          originalValue: selectedExtra,
          modifiedValue: value,
          modifiedAt: new Date().toISOString()
        }
      }));
      setModificationCount(prev => prev + 1);
    }

    onFetchData();
  };

  return (
    <div className="extra-filter">
      <select 
        value={selectedExtra} 
        onChange={(e) => handleChange(e.target.value)} 
        className="extra-select"
      >
        <option value="별도">별도</option>
        <option value="제외">제외</option>
      </select>
    </div>
  );
};

export default ExtraFilter; 