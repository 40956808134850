import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './LineupSettings.css';

const LineupSettings = ({ onMessage }) => {
  const [defaultLineup, setDefaultLineup] = useState(null);
  
  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    try {
      const settingsDoc = await getDoc(doc(db, 'settings', 'lineupSettings'));
      if (settingsDoc.exists()) {
        setDefaultLineup(settingsDoc.data().defaultLineup);
      }
    } catch (error) {
      console.error('설정 로드 중 오류:', error);
    }
  };

  const handleLineupChange = async (lineup) => {
    try {
      await setDoc(doc(db, 'settings', 'lineupSettings'), {
        defaultLineup: lineup === defaultLineup ? null : lineup
      });
      setDefaultLineup(lineup === defaultLineup ? null : lineup);
      onMessage(`기본 라인업이 ${lineup === defaultLineup ? '해제' : lineup}(으)로 설정되었습니다.`);
    } catch (error) {
      console.error('설정 저장 중 오류:', error);
      onMessage('설정 저장 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="content-card">
      <h2 className="card-title">라인업 설정</h2>
      <div className="lineup-settings">
        <div className="setting-item">
          <label>
            <input
              type="checkbox"
              checked={defaultLineup === 'refresh'}
              onChange={() => handleLineupChange('refresh')}
            />
            리프레쉬 라인업으로 고정
          </label>
        </div>
        <div className="setting-item">
          <label>
            <input
              type="checkbox"
              checked={defaultLineup === 'harmony'}
              onChange={() => handleLineupChange('harmony')}
            />
            하모니 라인업으로 고정
          </label>
        </div>
        <div className="setting-item">
          <label>
            <input
              type="checkbox"
              checked={defaultLineup === 'collection'}
              onChange={() => handleLineupChange('collection')}
            />
            컬렉션 라인업으로 고정
          </label>
        </div>
      </div>
    </div>
  );
};

export default LineupSettings; 