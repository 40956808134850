import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, deleteDoc, doc, setDoc, getDoc, serverTimestamp, writeBatch, query, where } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDHXfsUzyHCTVaWXPn_uW_bkuyoIfOwoFM",
  authDomain: "interior-one-click.firebaseapp.com",
  projectId: "interior-one-click",
  storageBucket: "interior-one-click.firebasestorage.app",
  messagingSenderId: "114412898136",
  appId: "1:114412898136:web:5f8b9b8f8b9b8f8b9b8f8b",
  measurementId: "G-XXXXXXXXXX"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const messaging = getMessaging(app);
const storage = getStorage(app);

export const saveThumbnailImage = async (imageDataUrl, timestamp) => {
  try {
    // base64 데이터를 Blob으로 변환
    const response = await fetch(imageDataUrl);
    const blob = await response.blob();
    
    // 파일 크기 체크
    if (blob.size > 5 * 1024 * 1024) { // 5MB 제한
      throw new Error('이미지 크기가 너무 큽니다 (최대 5MB)');
    }

    // 파일 경로 수정
    const storageRef = ref(storage, `thumbnails/${timestamp}.png`); // .png 확장자 추가
    await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error('이미지 저장 실패:', error);
    throw new Error(`이미지 저장 실패: ${error.message}`);
  }
};

const testAiConnection = async () => {
  // API 연결 테스트 로직
};

const saveApiKeys = async (keys) => {
  await setDoc(doc(db, 'settings', 'apiKeys'), keys);
};

const saveAccessToken = async (token) => {
  await setDoc(doc(db, 'settings', 'instagram'), { accessToken: token });
};

const getAccessToken = async () => {
  const docSnap = await getDoc(doc(db, 'settings', 'instagram'));
  return docSnap.exists() ? docSnap.data().accessToken : null;
};

const processWithAI = async (data) => {
  // AI 처리 로직
};

const getAreaPrices = async (area) => {
  const docSnap = await getDoc(doc(db, 'settings', 'areaPrices'));
  if (docSnap.exists()) {
    const data = docSnap.data();
    return data[area] || {};
  }
  return {};
};

const saveAreaPrices = async (area, prices) => {
  const docRef = doc(db, 'settings', 'areaPrices');
  const currentData = (await getDoc(docRef)).data() || {};
  
  await setDoc(docRef, {
    ...currentData,
    [area]: prices
  });
};

const saveProcesses = async (processes) => {
  try {
    await setDoc(doc(db, 'settings', 'processes'), {
      processList: processes
    });
  } catch (error) {
    console.error('Error saving processes:', error);
    throw error;
  }
};

const getProcesses = async () => {
  try {
    const docSnap = await getDoc(doc(db, 'settings', 'processes'));
    if (docSnap.exists()) {
      return docSnap.data().processList || [];
    }
    return [];
  } catch (error) {
    console.error('Error getting processes:', error);
    return [];
  }
};

const getEstimates = async () => {
  const querySnapshot = await getDocs(collection(db, 'estimates'));
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const deleteEstimates = async (ids) => {
  for (const id of ids) {
    await deleteDoc(doc(db, 'estimates', id));
  }
};

const saveTextLayers = async (timestamp, textLayers, imageDataUrl) => {
  try {
    if (!imageDataUrl) {
      throw new Error('이미지 데이터가 없습니다.');
    }

    const thumbnailRef = doc(db, 'thumbnails', timestamp);
    
    // 이미지 URL 저장 시도
    let imageUrl;
    try {
      imageUrl = await saveThumbnailImage(imageDataUrl, timestamp);
    } catch (error) {
      console.error('이미지 저장 중 오류:', error);
      throw new Error(`이미지 저장 실패: ${error.message}`);
    }
    
    // Firestore 문서 저장
    await setDoc(thumbnailRef, {
      id: timestamp,
      imageUrl,
      textLayers,
      createdAt: serverTimestamp()
    });
  } catch (error) {
    console.error('텍스트 레이어 저장 실패:', error);
    throw new Error(`텍스트 레이어 저장 실패: ${error.message}`);
  }
};

const getTextLayers = async (imageId) => {
  try {
    const docSnap = await getDoc(doc(db, 'thumbnails', imageId));
    if (docSnap.exists()) {
      return docSnap.data().textLayers;
    }
    return [];
  } catch (error) {
    console.error('Error getting text layers:', error);
    return [];
  }
};

const getThumbnails = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'thumbnails'));
    const thumbnails = [];
    
    for (const doc of querySnapshot.docs) {
      const data = doc.data();
      try {
        // 이미지 URL이 이미 저장되어 있으면 그대로 사용
        const imageUrl = data.imageUrl || await getDownloadURL(ref(storage, `thumbnails/${doc.id}.png`));
        
        thumbnails.push({
          id: doc.id,
          imageUrl,
          textLayers: data.textLayers || [],
          createdAt: data.createdAt
        });
      } catch (error) {
        console.error(`썸네일 ${doc.id} 이미지 URL 가져오기 실패:`, error);
      }
    }
    
    return thumbnails.sort((a, b) => {
      if (!a.createdAt || !b.createdAt) return 0;
      return b.createdAt.seconds - a.createdAt.seconds;
    });
  } catch (error) {
    console.error('썸네일 목록 가져오기 실패:', error);
    return [];
  }
};

const deleteThumbnail = async (thumbnailId) => {
  try {
    // Firestore 문서 삭제
    await deleteDoc(doc(db, 'thumbnails', thumbnailId));
    
    // Storage 파일 삭제
    try {
      const storageRef = ref(storage, `thumbnails/${thumbnailId}.png`);
      await deleteObject(storageRef);
    } catch (error) {
      console.error('스토리지 파일 삭제 실패:', error);
    }
    
    return true;
  } catch (error) {
    console.error('썸네일 삭제 실패:', error);
    throw error;
  }
};

export const pdfMessagesCollection = collection(db, 'pdfMessages');

export const addProcess = async (newProcess) => {
  const batch = writeBatch(db);
  
  // processes 목록에 추가
  const processesRef = doc(db, 'settings', 'processes');
  const processesDoc = await getDoc(processesRef);
  const currentProcesses = processesDoc.exists() ? processesDoc.data().processList || [] : [];
  batch.set(processesRef, { processList: [...currentProcesses, newProcess] });

  // 각 평수별 가격 데이터에 새 공정 추가
  const areaPricesRef = doc(db, 'settings', 'areaPrices');
  const areaPricesDoc = await getDoc(areaPricesRef);
  const areaPrices = areaPricesDoc.exists() ? areaPricesDoc.data() : {};

  // 각 평수에 새 공정 필드 추가
  const areas = ['20평대', '30평대', '40평대', '50평대'];
  areas.forEach(area => {
    if (!areaPrices[area]) areaPrices[area] = {};
    areaPrices[area][newProcess] = '';
  });
  
  batch.set(areaPricesRef, areaPrices);

  // 트랜잭션 실행
  await batch.commit();
};

export const updateProcess = async (oldName, newName) => {
  const batch = writeBatch(db);

  try {
    console.log('공정 이름 수정 시작:', { oldName, newName });

    // 1. processes 목록 수정
    const processesRef = doc(db, 'settings', 'processes');
    const processesDoc = await getDoc(processesRef);
    const currentProcesses = processesDoc.exists() ? processesDoc.data().processList || [] : [];
    const updatedProcesses = currentProcesses.map(p => p === oldName ? newName : p);
    console.log('processes 수정:', { 
      before: currentProcesses,
      after: updatedProcesses 
    });
    batch.set(processesRef, { processList: updatedProcesses });

    // 2. lineupPrices 수정
    const lineupPricesRef = doc(db, 'lineupPrices', 'default');
    const lineupPricesDoc = await getDoc(lineupPricesRef);
    if (lineupPricesDoc.exists()) {
      const lineupPrices = lineupPricesDoc.data();
      console.log('기존 lineupPrices:', JSON.stringify(lineupPrices, null, 2));
      
      // 각 라인업에 대해 직접 수정
      ['refresh', 'harmony', 'collection'].forEach(lineup => {
        if (lineupPrices[lineup]) {
          Object.keys(lineupPrices[lineup]).forEach(area => {
            if (lineupPrices[lineup][area][oldName] !== undefined) {
              console.log(`${lineup}/${area}에서 공정명 변경:`, {
                from: oldName,
                to: newName,
                value: lineupPrices[lineup][area][oldName]
              });
              const value = lineupPrices[lineup][area][oldName];
              lineupPrices[lineup][area][newName] = value;
              delete lineupPrices[lineup][area][oldName];
            }
          });
        }
      });

      console.log('수정된 lineupPrices:', JSON.stringify(lineupPrices, null, 2));
      batch.set(lineupPricesRef, lineupPrices);
    }

    // 3. lineupDescriptions 수정
    const lineupDescriptionsRef = doc(db, 'lineupDescriptions', 'default');
    const lineupDescriptionsDoc = await getDoc(lineupDescriptionsRef);
    if (lineupDescriptionsDoc.exists()) {
      const lineupDescriptions = lineupDescriptionsDoc.data();
      console.log('기존 lineupDescriptions:', lineupDescriptions);
      
      // 각 라인업의 설명 직접 수정
      Object.keys(lineupDescriptions).forEach(lineup => {
        if (lineupDescriptions[lineup][oldName] !== undefined) {
          console.log(`${lineup} 라인업 설명 변경:`, {
            from: oldName,
            to: newName,
            description: lineupDescriptions[lineup][oldName]
          });
          const description = lineupDescriptions[lineup][oldName];
          lineupDescriptions[lineup][newName] = description;
          delete lineupDescriptions[lineup][oldName];
        }
      });

      console.log('수정된 lineupDescriptions:', lineupDescriptions);
      batch.set(lineupDescriptionsRef, lineupDescriptions);
    }

    // 4. areaPrices 수정
    const areaPricesRef = doc(db, 'settings', 'areaPrices');
    const areaPricesDoc = await getDoc(areaPricesRef);
    const areaPrices = areaPricesDoc.exists() ? areaPricesDoc.data() : {};
    console.log('기존 areaPrices:', areaPrices);

    Object.keys(areaPrices).forEach(area => {
      if (areaPrices[area][oldName] !== undefined) {
        console.log(`${area} 평수 가격 변경:`, {
          from: oldName,
          to: newName,
          price: areaPrices[area][oldName]
        });
        areaPrices[area][newName] = areaPrices[area][oldName];
        delete areaPrices[area][oldName];
      }
    });

    console.log('수정된 areaPrices:', areaPrices);
    batch.set(areaPricesRef, areaPrices);

    // 트랜잭션 실행
    console.log('트랜잭션 실행 시작');
    await batch.commit();
    console.log('트랜잭션 실행 완료');
    return true;

  } catch (error) {
    console.error('공정 이름 수정 중 오류:', error);
    throw new Error(`공정 이름 수정 실패: ${error.message}`);
  }
};

export const deleteProcess = async (processToDelete) => {
  const batch = writeBatch(db);

  // processes 목록에서 삭제
  const processesRef = doc(db, 'settings', 'processes');
  const processesDoc = await getDoc(processesRef);
  const currentProcesses = processesDoc.exists() ? processesDoc.data().processList || [] : [];
  const updatedProcesses = currentProcesses.filter(p => p !== processToDelete);
  batch.set(processesRef, { processList: updatedProcesses });

  // 각 평수별 가격 데이터에서 삭제
  const areaPricesRef = doc(db, 'settings', 'areaPrices');
  const areaPricesDoc = await getDoc(areaPricesRef);
  const areaPrices = areaPricesDoc.exists() ? areaPricesDoc.data() : {};

  // 각 평수에서 해당 공정 삭제
  Object.keys(areaPrices).forEach(area => {
    if (areaPrices[area][processToDelete]) {
      delete areaPrices[area][processToDelete];
    }
  });

  batch.set(areaPricesRef, areaPrices);

  // 트랜잭션 실행
  await batch.commit();
};

// 공정 설명 저장
export const saveProcessDescriptions = async (area, descriptions) => {
  try {
    const docRef = doc(db, 'settings', 'processDescriptions');
    const docSnap = await getDoc(docRef);
    const currentData = docSnap.exists() ? docSnap.data() : {};
    
    currentData[area] = descriptions;
    await setDoc(docRef, currentData);
    return true;
  } catch (error) {
    console.error('공정 설명 저장 중 오류:', error);
    throw error;
  }
};

// 공정 설명 불러오기
export const getProcessDescriptions = async (area) => {
  try {
    const docSnap = await getDoc(doc(db, 'settings', 'processDescriptions'));
    if (docSnap.exists() && docSnap.data()[area]) {
      return docSnap.data()[area];
    }
    return {};
  } catch (error) {
    console.error('공정 설명 로드 중 오류:', error);
    return {};
  }
};

// 스케줄 저장
export const saveSchedule = async (scheduleData) => {
  try {
    const scheduleRef = doc(db, 'schedules', scheduleData.address);
    const saveData = {
      ...scheduleData,
      updatedAt: serverTimestamp()
    };
    
    await setDoc(scheduleRef, saveData);
    return true;
  } catch (error) {
    console.error('스케줄 저장 실패:', error);
    throw error;
  }
};

// 스케줄 불러오기
export const fetchSchedules = async () => {
  try {
    const schedulesSnapshot = await getDocs(collection(db, 'schedules'));
    return schedulesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('스케줄 불러오기 실패:', error);
    return [];
  }
};

// 스케줄 삭제
export const deleteSchedule = async (address) => {
  try {
    // 1. schedules 컬렉션에서 해당 주소 문서 삭제
    await deleteDoc(doc(db, 'schedules', address));
    
    // 2. 관련된 이벤트 데이터도 삭제 (만약 별도 컬렉션으로 저장된 경우)
    const eventsRef = collection(db, 'scheduleEvents');
    const q = query(eventsRef, where('address', '==', address));
    const snapshot = await getDocs(q);
    
    const batch = writeBatch(db);
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
    
    await batch.commit();
    
    return true;
  } catch (error) {
    console.error('스케줄 삭제 실패:', error);
    throw error;
  }
};

export {
  auth,
  db,
  messaging,
  storage,
  testAiConnection,
  saveApiKeys,
  saveAccessToken,
  getAccessToken,
  processWithAI,
  getAreaPrices,
  saveAreaPrices,
  saveProcesses,
  getProcesses,
  getEstimates,
  deleteEstimates,
  saveTextLayers,
  getTextLayers,
  getThumbnails,
  deleteThumbnail
};
