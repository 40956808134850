import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './EmailTemplateModal.css';

const EmailTemplateModal = ({ onClose }) => {
  const initialTemplate = {
    subject: '',
    content: '',
    signature: {
      representative: '',
      address: '',
      phone: '',
      instagram: '',
      website: '',
      blog: ''
    },
    estimateDescription: ''
  };

  const [template, setTemplate] = useState(initialTemplate);

  useEffect(() => {
    const loadTemplate = async () => {
      try {
        console.log('템플릿 로딩 시작');
        const docRef = doc(db, 'emailTemplates', '1mintemplates');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log('기존 템플릿 데이터:', docSnap.data());
          setTemplate(docSnap.data());
        }
      } catch (error) {
        console.error('템플릿 로딩 중 오류:', error);
      }
    };
    loadTemplate();

    // ESC 키 이벤트 핸들러 추가
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    // 이벤트 리스너 등록
    window.addEventListener('keydown', handleEsc);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  const handleSignatureChange = (field, value) => {
    setTemplate(prev => ({
      ...prev,
      signature: {
        ...prev.signature,
        [field]: value
      }
    }));
  };

  const handleSave = async () => {
    try {
      // Firestore에 템플릿 저장
      const templateData = {
        ...template,
        updatedAt: new Date().toISOString()
      };

      await setDoc(doc(db, 'emailTemplates', '1mintemplates'), templateData);
      alert('템플릿이 저장되었습니다.');
      onClose();
      
    } catch (error) {
      console.error('저장 중 오류:', error);
      alert(error.message || '저장 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="email-template-modal-overlay">
      <div className="email-template-modal">
        <h2>이메일 템플릿 설정</h2>
        <div className="template-form">
          <div className="input-group">
            <label>이메일 제목</label>
            <input
              type="text"
              value={template.subject}
              onChange={e => setTemplate({...template, subject: e.target.value})}
              placeholder="예: {name}님의 아마레디자인 견적서"
            />
          </div>
          <div className="input-group">
            <label>이메일 내용</label>
            <textarea
              value={template.content}
              onChange={e => setTemplate({...template, content: e.target.value})}
              placeholder="예: 안녕하세요, {name}님. {area}평형 견적서를 보내드립니다."
              rows={8}
            />
          </div>
          <div className="description-section">
            <h3>견적서 설명 메시지</h3>
            <div className="input-group">
              <textarea
                value={template.estimateDescription}
                onChange={e => setTemplate({...template, estimateDescription: e.target.value})}
                placeholder="견적서 하단에 표시될 설명 메시지를 입력하세요"
                rows={4}
              />
            </div>
          </div>
          <div className="signature-section">
            <h3>서명 설정</h3>
            <div className="signature-grid">
              <div className="input-group">
                <label>대표자</label>
                <input
                  type="text"
                  value={template.signature.representative}
                  onChange={e => handleSignatureChange('representative', e.target.value)}
                  placeholder="대표자명"
                />
              </div>
              <div className="input-group">
                <label>주소</label>
                <input
                  type="text"
                  value={template.signature.address}
                  onChange={e => handleSignatureChange('address', e.target.value)}
                  placeholder="회사 주소"
                />
              </div>
              <div className="input-group">
                <label>연락처</label>
                <input
                  type="text"
                  value={template.signature.phone}
                  onChange={e => handleSignatureChange('phone', e.target.value)}
                  placeholder="연락처"
                />
              </div>
              <div className="input-group">
                <label>인스타그램</label>
                <input
                  type="text"
                  value={template.signature.instagram}
                  onChange={e => handleSignatureChange('instagram', e.target.value)}
                  placeholder="인스타그램 주소"
                />
              </div>
              <div className="input-group">
                <label>웹사이트</label>
                <input
                  type="text"
                  value={template.signature.website}
                  onChange={e => handleSignatureChange('website', e.target.value)}
                  placeholder="웹사이트 주소"
                />
              </div>
              <div className="input-group">
                <label>블로그</label>
                <input
                  type="text"
                  value={template.signature.blog || ''}
                  onChange={e => handleSignatureChange('blog', e.target.value)}
                  placeholder="블로그 주소"
                />
              </div>
            </div>
          </div>
          <div className="button-group">
            <button onClick={handleSave}>저장</button>
            <button onClick={onClose}>취소</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateModal; 