import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import koLocale from '@fullcalendar/core/locales/ko';
import { addDays, isWeekend } from 'date-fns';
import './AutoSchedule.css';
import { db, saveSchedule, fetchSchedules, deleteSchedule } from '../firebase';
import { collection, getDocs, query, orderBy, getDoc, doc, updateDoc, setDoc } from 'firebase/firestore';
import MessageModal from './MessageModal';
import ProcessManagementModal from './ProcessManagementModal';

const AutoSchedule = () => {
  const [activeTab, setActiveTab] = useState('calendar'); // 'settings' or 'calendar'
  const [processes, setProcesses] = useState({});
  const [scheduleInputs, setScheduleInputs] = useState([{
    address: '',
    startDate: '',
    workOnWeekends: false,
    workOnHolidays: false,
    processDays: {}
  }]);
  const [events, setEvents] = useState([]);
  const [addressColors] = useState(new Map());
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [editedEvent, setEditedEvent] = useState(null);
  const [savedAddresses, setSavedAddresses] = useState([]); // 저장된 주소 목록
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [recentAddresses, setRecentAddresses] = useState(() => {
    const saved = localStorage.getItem('recentAddresses');
    return saved ? JSON.parse(saved) : [];
  });
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [selectedMessageAddress, setSelectedMessageAddress] = useState('');
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(-1); // 초기값을 -1로 변경

  const colorPalette = [
    '#FF9999', '#FFB366', '#FFE5CC', '#CCE5FF', '#99CCFF',
    '#FF99CC', '#FF99FF', '#CC99FF', '#9999FF', '#99FFCC',
    '#66FF99', '#99FF99', '#FFFF99', '#FFCC99'
  ];

  // 2025년 한국 공휴일 데이터
  const holidays = [
    { title: '신정', start: '2025-01-01' },
    { title: '설날', start: '2025-01-28' },
    { title: '설날 연휴', start: '2025-01-29' },
    { title: '설날 연휴', start: '2025-01-30' },
    { title: '3·1절', start: '2025-03-01' },
    { title: '어린이날', start: '2025-05-05' },
    { title: '부처님오신날', start: '2025-05-06' },
    { title: '현충일', start: '2025-06-06' },
    { title: '광복절', start: '2025-08-15' },
    { title: '추석', start: '2025-10-06' },
    { title: '추석 연휴', start: '2025-10-07' },
    { title: '추석 연휴', start: '2025-10-08' },
    { title: '개천절', start: '2025-10-03' },
    { title: '한글날', start: '2025-10-09' },
    { title: '크리스마스', start: '2025-12-25' }
  ].map(holiday => ({
    ...holiday,
    display: 'background',
    backgroundColor: '#FFE6E6',
    classNames: ['holiday-event'],
    allDay: true,
    overlap: false,
    rendering: 'background'
  }));

  const getAddressColor = (addr) => {
    if (!addressColors.has(addr)) {
      const usedColors = Array.from(addressColors.values());
      const availableColors = colorPalette.filter(color => !usedColors.includes(color));
      const newColor = availableColors[0] || colorPalette[addressColors.size % colorPalette.length];
      addressColors.set(addr, newColor);
    }
    return addressColors.get(addr);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleInputChange = (index, field, value) => {
    const newInputs = [...scheduleInputs];
    if (field.includes('.')) {
      const [parent, child, subfield] = field.split('.');
      if (subfield) {
        newInputs[index][parent][child][subfield] = value;
      } else {
        newInputs[index][parent][child] = value;
      }
    } else {
      newInputs[index][field] = value;
    }
    setScheduleInputs(newInputs);
  };

  const addNewSchedule = () => {
    setScheduleInputs([...scheduleInputs, {
      address: '',
      startDate: '',
      workOnWeekends: false,
      workOnHolidays: false,
      processDays: processes
    }]);
  };

  const removeSchedule = async (index) => {
    try {
      const scheduleToRemove = scheduleInputs[index];
      
      // 주소가 있는 경우에만 Firebase에서 삭제
      if (scheduleToRemove.address) {
        await deleteSchedule(scheduleToRemove.address);
        
        // 해당 주소의 이벤트들도 제거
        const newEvents = events.filter(event => 
          event.title.split('\n')[0] !== scheduleToRemove.address
        );
        setEvents(newEvents);
      }
      
      // 입력 폼에서 제거
      const newInputs = scheduleInputs.filter((_, i) => i !== index);
      setScheduleInputs(newInputs);
      
    } catch (error) {
      console.error('스케줄 삭제 실패:', error);
      // 에러가 발생해도 UI에서는 제거
      const newInputs = scheduleInputs.filter((_, i) => i !== index);
      setScheduleInputs(newInputs);
    }
  };

  const getEventStyle = (process) => {
    if (process === '사무실 상담' || process === '현장상담') {
      return {
        backgroundColor: '#000000',
        textColor: '#FFFFFF'
      };
    }
    return null;
  };

  const isHoliday = (date, input, process) => {
    const formattedDate = formatDate(date);
    const isWeekendDay = isWeekend(date);
    const isAnyHoliday = holidays.some(holiday => holiday.start === formattedDate);
    
    // 도배 공사나 필름 공사이고 각각의 주말 체크박스가 체크되어 있으면 주말에도 작업 가능
    if (isWeekendDay) {
      if (process === '도배 공사' && input.wallpaperWeekend) return false;
      if (process === '필름 공사' && input.filmWeekend) return false;
      if (!input.workOnWeekends) return true;
    }
    
    if (!input.workOnHolidays && isAnyHoliday) return true;
    
    return false;
  };

  const generateSchedule = async () => {
    console.log('=== 스케줄 생성 시작 ===');
    console.log('현재 scheduleInputs:', scheduleInputs);

    // 입력 데이터 유효성 검사
    const invalidInputs = scheduleInputs.filter(input => {
      if (!input.address || !input.startDate) {
        return true;
      }
      // 선택된 공정이 하나라도 있는지 확인
      const hasSelectedProcess = Object.values(input.processDays).some(
        config => config.include && config.days && parseInt(config.days) > 0
      );
      return !hasSelectedProcess;
    });

    if (invalidInputs.length > 0) {
      console.log('유효하지 않은 입력:', invalidInputs);
      let errorMessage = '다음 항목을 확인해주세요:\n';
      invalidInputs.forEach((input, index) => {
        if (!input.address) {
          errorMessage += `- 현장 ${index + 1}: 현장 주소를 선택해주세요.\n`;
        }
        if (!input.startDate) {
          errorMessage += `- 현장 ${index + 1}: 공사 시작일을 선택해주세요.\n`;
        }
        const hasSelectedProcess = Object.values(input.processDays).some(
          config => config.include && config.days && parseInt(config.days) > 0
        );
        if (!hasSelectedProcess) {
          errorMessage += `- 현장 ${index + 1}: 최소 하나의 공정을 선택하고 일수를 입력해주세요.\n`;
        }
      });
      alert(errorMessage);
      return;
    }
    
    const newEvents = [];

    scheduleInputs.forEach((input, index) => {
      console.log(`\n[현장 ${index + 1} 처리 시작]`);
      console.log('주소:', input.address);
      console.log('시작일:', input.startDate);
      console.log('공정 데이터:', input.processDays);

      let currentDate = new Date(input.startDate);
      const addressColor = getAddressColor(input.address);
      console.log('선택된 색상:', addressColor);

      Object.entries(input.processDays).forEach(([process, config]) => {
        console.log(`\n[공정 처리: ${process}]`);
        console.log('공정 설정:', config);

        if (!config.include || !config.days || parseInt(config.days) <= 0) {
          console.log('공정이 선택되지 않았거나 일수가 0 이하여서 건너뜀');
          return;
        }

        let remainingDays = parseInt(config.days);
        const startDate = new Date(currentDate);

        while (remainingDays > 0) {
          if (!isHoliday(currentDate, input, process)) {
            remainingDays--;
            if (remainingDays === 0) {
              const eventStyle = getEventStyle(process);
              console.log('처리할 일수:', config.days);
              
              const newEvent = {
                title: `${input.address}\n> ${process}`,
                start: formatDate(startDate),
                end: formatDate(addDays(currentDate, 1)),
                color: eventStyle ? eventStyle.backgroundColor : addressColor,
                textColor: eventStyle ? eventStyle.textColor : '#000000',
                allDay: true,
                className: eventStyle ? 'consultation-event' : '',
                days: config.days
              };
              console.log('생성된 이벤트:', newEvent);

              newEvents.push(newEvent);
            }
          }
          currentDate = addDays(currentDate, 1);
        }
      });
    });

    if (newEvents.length === 0) {
      console.log('생성된 이벤트가 없음');
      alert('생성할 수 있는 일정이 없습니다.\n공정 선택과 일수를 확인해주세요.');
      return;
    }

    console.log('\n=== 생성된 전체 이벤트 ===');
    console.log(newEvents);

    // 먼저 이벤트 상태를 업데이트
    console.log('\n이벤트 상태 업데이트 시작');
    setEvents(newEvents);
    console.log('이벤트 상태 업데이트 완료');

    // Firebase에 저장
    try {
      console.log('\nFirebase 저장 시작');
      for (const input of scheduleInputs) {
        if (!input.address || !input.startDate) continue;
        
        const addressEvents = newEvents.filter(event => 
          event.title.split('\n')[0] === input.address
        );
        console.log(`${input.address}의 이벤트 수:`, addressEvents.length);

        const color = getAddressColor(input.address);

        // undefined 값 처리
        const processedProcessDays = {};
        Object.entries(input.processDays).forEach(([key, value]) => {
          processedProcessDays[key] = {
            days: value.days || '1',
            include: value.include || false
          };
        });

        const scheduleData = {
          address: input.address,
          startDate: input.startDate,
          workOnHolidays: input.workOnHolidays || false,
          wallpaperWeekend: input.wallpaperWeekend || false,
          filmWeekend: input.filmWeekend || false,
          processDays: processedProcessDays,
          events: addressEvents.map(event => ({
            ...event,
            color
          })),
          color,
          updatedAt: new Date()
        };

        console.log('저장할 스케줄 데이터:', scheduleData);
        await saveSchedule(scheduleData);
        console.log(`${input.address} 저장 완료`);
      }

      console.log('\n모든 데이터 저장 완료, 캘린더 뷰로 전환');
      setTimeout(() => {
        setActiveTab('calendar');
      }, 100);

    } catch (error) {
      console.error('\n=== 스케줄 저장 실패 ===');
      console.error('에러 상세:', error);
      console.error('에러 스택:', error.stack);
      alert('스케줄 저장에 실패했습니다.');
    }

    console.log('=== 스케줄 생성 완료 ===');
  };

  const handleEventClick = (clickInfo) => {
    const eventTitle = clickInfo.event.title;
    const titleLines = eventTitle.split('\n');
    const clickedAddress = titleLines[0];
    
    // 모든 공정 추출 (> 제거)
    const selectedProcesses = titleLines.slice(1).map(process => process.replace('> ', ''));

    // end 날짜 처리 수정
    const endDate = clickInfo.event.end || addDays(clickInfo.event.start, 1);
    const adjustedEndDate = new Date(endDate.getTime() - 24*60*60*1000);

    const event = {
      title: eventTitle,
      start: formatDate(clickInfo.event.start),
      end: formatDate(adjustedEndDate),
      address: clickedAddress,
      processes: selectedProcesses,
      originalEvent: clickInfo.event,
      memo: clickInfo.event.extendedProps?.memo || ''
    };

    setSelectedEvent(event);
    setEditedEvent(event);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setEditedEvent(null);
  };

  // 컴포넌트 마운트 시 주소 목록 불러오기
  useEffect(() => {
    fetchSavedAddresses();
  }, []);

  // 저장된 주소 목록 불러오기 함수
  const fetchSavedAddresses = async () => {
    try {
      const addressesCollection = collection(db, 'addresses');
      const q = query(addressesCollection, orderBy('siteNumber', 'desc'));
      const addressesSnapshot = await getDocs(q);
      const addressesList = addressesSnapshot.docs.map(doc => ({
        id: doc.id,
        address: doc.data().description, // description 필요 없음
        siteNumber: doc.data().siteNumber
      }));
      setSavedAddresses(addressesList);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  // 초기화 함수 수정
  const resetSchedule = async (index) => {
    try {
      const currentAddress = scheduleInputs[index].address;
      
      if (currentAddress) {
        await deleteSchedule(currentAddress);
        const filteredEvents = events.filter(event => 
          event.title.split('\n')[0] !== currentAddress
        );
        setEvents(filteredEvents);
        addressColors.delete(currentAddress);
      }
      
      const newInputs = [...scheduleInputs];
      newInputs[index] = {
        ...newInputs[index],
        address: '',
        startDate: '',
        workOnWeekends: false,
        workOnHolidays: false,
        processDays: processes
      };
      setScheduleInputs(newInputs);

    } catch (error) {
      console.error('스케줄 초기화 실패:', error);
      const newInputs = [...scheduleInputs];
      newInputs[index] = {
        ...newInputs[index],
        address: '',
        startDate: '',
        workOnWeekends: false,
        workOnHolidays: false,
        processDays: processes
      };
      setScheduleInputs(newInputs);
    }
  };

  // saveEventChanges 함수 수정
  const saveEventChanges = async () => {
    try {
      console.log('=== 이벤트 저장 시작 ===');
      console.log('editedEvent:', editedEvent);
      console.log('selectedEvent:', selectedEvent);

      if (!editedEvent.start || !editedEvent.end) {
        console.error('시작일 또는 종료일이 없음');
        alert('시작일과 종료일을 입력해주세요.');
        return;
      }

      const addressColor = editedEvent.address ? getAddressColor(editedEvent.address) : '#808080';
      console.log('선택된 색상:', addressColor);

      const endDate = new Date(editedEvent.end);
      endDate.setDate(endDate.getDate() + 1);
      console.log('조정된 종료일:', formatDate(endDate));

      let eventTitle;
      if (editedEvent.memo && !editedEvent.address) {
        eventTitle = `[메모]\n${editedEvent.memo}`;
      } else {
        const processText = editedEvent.processes?.map(process => `> ${process}`).join('\n') || '';
        eventTitle = `${editedEvent.address || ''}\n${processText}${editedEvent.memo ? `\n메모: ${editedEvent.memo}` : ''}`;
      }
      console.log('생성된 이벤트 제목:', eventTitle);

      const newEvent = {
        title: eventTitle,
        start: editedEvent.start,
        end: formatDate(endDate),
        color: addressColor,
        textColor: '#000000',
        allDay: true,
        memo: editedEvent.memo || '',
        address: editedEvent.address || '',
        processes: editedEvent.processes || []
      };
      console.log('새 이벤트 객체:', newEvent);

      // Firebase에 저장
      try {
        console.log('Firebase 저장 시작');
        const scheduleDocRef = doc(db, 'schedules', editedEvent.address);
        const scheduleDoc = await getDoc(scheduleDocRef);
        
        let scheduleData;
        if (scheduleDoc.exists()) {
          console.log('기존 schedule 문서 발견');
          scheduleData = scheduleDoc.data();
          const existingEvents = scheduleData.events || [];
          
          // 기존 이벤트 수정 또는 새 이벤트 추가
          let updated = false;
          const updatedEvents = existingEvents.map(event => {
            if (selectedEvent.originalEvent && 
                event.start === selectedEvent.start && 
                event.title === selectedEvent.originalEvent.title) {
              updated = true;
              return newEvent;
            }
            return event;
          });
          
          if (!updated) {
            updatedEvents.push(newEvent);
          }
          
          await updateDoc(scheduleDocRef, {
            events: updatedEvents,
            address: editedEvent.address,
            updatedAt: new Date()
          });
        } else {
          console.log('새 schedule 문서 생성');
          await setDoc(scheduleDocRef, {
            events: [newEvent],
            address: editedEvent.address,
            createdAt: new Date(),
            updatedAt: new Date()
          });
        }
        console.log('Firebase 저장 완료');
      } catch (error) {
        console.error('Firebase 저장 실패:', error);
        throw error;
      }

      // UI 업데이트
      let updatedEvents;
      if (selectedEvent.originalEvent) {
        console.log('기존 이벤트 업데이트');
        updatedEvents = events.map(event => {
          if (event.start === selectedEvent.start && event.title === selectedEvent.originalEvent.title) {
            return newEvent;
          }
          return event;
        });
      } else {
        console.log('새 이벤트 추가');
        updatedEvents = [...events, newEvent];
      }

      setEvents(updatedEvents);
      closeModal();
      console.log('=== 이벤트 저장 완료 ===');
    } catch (error) {
      console.error('=== 이벤트 저장 실패 ===');
      console.error('에러 상세:', error);
      console.error('에러 스택:', error.stack);
      alert('저장에 실패했습니다. 다시 시도해주세요.');
    }
  };

  // deleteEvent 함수 추가 (saveEventChanges 함수 근처에 추가)
  const deleteEvent = async () => {
    setShowDeleteConfirm(true);
  };

  // 실제 삭제 처리 함수
  const confirmDelete = async () => {
    try {
      // 이벤트 목록에서 제거
      const newEvents = events.filter(event => {
        return !(event.start === selectedEvent.start && event.title === selectedEvent.title);
      });
      setEvents(newEvents);

      // Firebase에서 해당 이벤트 제거
      const address = selectedEvent.address;
      const currentSchedule = await getDoc(doc(db, 'schedules', address));
      
      if (currentSchedule.exists()) {
        const scheduleData = currentSchedule.data();
        const updatedEvents = scheduleData.events.filter(event => {
          return !(event.start === selectedEvent.start && event.title === selectedEvent.title);
        });

        // 업데이트된 이벤트 목록으로 저장
        await saveSchedule({
          ...scheduleData,
          events: updatedEvents
        });
      }

      setShowDeleteConfirm(false);
      closeModal();
    } catch (error) {
      console.error('이벤트 삭제 실패:', error);
      alert('이벤트 삭제에 실패했습니다.');
    }
  };

  // 삭제 취소 처리
  const cancelDelete = () => {
    setShowDeleteConfirm(false);
  };

  // FullCalendar 컴포넌트에 대한 ref 추가
  const calendarRef = useRef(null);

  // 이벤트 수정 핸들러 수정
  const handleEventChange = (field, value) => {
    console.log('handleEventChange called:', { field, value });
    console.log('Previous editedEvent:', editedEvent);
    
    if (field === 'address' && value) {
      // 최근 주소 목록 업데이트
      const newRecentAddresses = [
        value,
        ...recentAddresses.filter(addr => addr !== value)
      ].slice(0, 5); // 최근 5개만 유지
      
      setRecentAddresses(newRecentAddresses);
      localStorage.setItem('recentAddresses', JSON.stringify(newRecentAddresses));
    }
    
    setEditedEvent(prev => {
      const newEvent = { ...prev };
      
      if (field === 'start') {
        newEvent.start = value;
        if (new Date(value) > new Date(newEvent.end)) {
          newEvent.end = value;
        }
      } else if (field === 'end') {
        if (new Date(value) < new Date(newEvent.start)) {
          newEvent.end = newEvent.start;
        } else {
          newEvent.end = value;
        }
      } else {
        newEvent[field] = value;
      }

      if ((field === 'start' || field === 'end') && calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(value);
      }
      
      console.log('Updated editedEvent:', newEvent);
      return newEvent;
    });
  };

  // 스케줄 저장 함수
  const handleSaveSchedule = async () => {
    try {
      for (const input of scheduleInputs) {
        if (!input.address || !input.startDate) continue;
        
        // 해당 주소의 이벤트만 필터링
        const addressEvents = events.filter(event => 
          event.title.split('\n')[0] === input.address
        );

        const color = getAddressColor(input.address);

        // 이벤트 데이터를 포함여 저장
        const scheduleData = {
          address: input.address,
          startDate: input.startDate,
          workOnHolidays: input.workOnHolidays,
          processDays: input.processDays,
          events: addressEvents.map(event => ({
            ...event,
            color // 각 이벤트에 색상 정보 추가
          })),
          color // 스케줄에도 색상 정보 저장
        };

        await saveSchedule(scheduleData);
      }
    } catch (error) {
      console.error('스케줄 저장 실패:', error);
      alert('스케줄 저장에 실패했습니다.');
    }
  };

  // 스케줄 불러오기
  const loadSchedules = async () => {
    try {
      const savedSchedules = await fetchSchedules();

      // 공정 목록 불러오기
      const processesRef = doc(db, 'settings', 'processes');
      const processesDoc = await getDoc(processesRef);
      
      if (processesDoc.exists()) {
        const savedProcessList = processesDoc.data().processList;
        
        // 각 공정에 대한 기본값 설정
        const initialProcesses = savedProcessList.reduce((acc, key) => {
          acc[key] = { days: '1', include: true };
          return acc;
        }, {});
        
        setProcesses(initialProcesses);

        // scheduleInputs 업데이트
        if (savedSchedules.length > 0) {
          const newInputs = savedSchedules.map(schedule => ({
            address: schedule.address,
            startDate: schedule.startDate,
            workOnHolidays: schedule.workOnHolidays,
            processDays: {
              ...initialProcesses,
              ...schedule.processDays
            }
          }));
          setScheduleInputs(newInputs);
        } else {
          setScheduleInputs([{
            address: '',
            startDate: '',
            workOnHolidays: false,
            processDays: initialProcesses
          }]);
        }

        // 이벤트와 색상 정보 복원
        const allEvents = savedSchedules.flatMap(schedule => {
          return (schedule.events || []).map(event => ({
            ...event,
            color: schedule.color || getAddressColor(schedule.address)
          }));
        });
        setEvents(allEvents);

        // 색상 정보 복원
        savedSchedules.forEach(schedule => {
          if (schedule.color) {
            addressColors.set(schedule.address, schedule.color);
          }
        });
      }
    } catch (error) {
      console.error('스케줄 불러오기 실패:', error);
    }
  };

  // 컴포넌트 마운트 시 초기화
  useEffect(() => {
    loadSchedules();
  }, []);

  // dateClick 핸들러 수정
  const handleDateClick = (info) => {
    const clickedDate = info.dateStr;
    const sortedAddresses = getSortedAddresses();
    const initialAddress = sortedAddresses.length > 0 ? sortedAddresses[0].address : '';
    const initialColor = initialAddress ? getAddressColor(initialAddress) : '';
    
    const newEvent = {
      address: initialAddress,
      processes: [],
      start: clickedDate,
      end: clickedDate,
      title: '',
      color: initialColor,
      originalEvent: null,
      memo: ''
    };
    
    setEditedEvent(newEvent);
    setSelectedEvent({
      ...newEvent,
      title: '',
      start: clickedDate,
      originalEvent: null
    });
  };

  // 뒤로가기와 ESC 키 방지를 위한 useEffect 추가
  useEffect(() => {
    // 뒤로가기 방지
    const preventGoBack = (e) => {
      e.preventDefault();
      e.stopPropagation();
      window.history.pushState(null, '', window.location.href);
    };

    // ESC 키 방지
    const preventEsc = (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        
        // 삭제 확인 모달이 열려있으면 먼저 닫기
        if (showDeleteConfirm) {
          setShowDeleteConfirm(false);
          return;
        }
        
        // 이벤트 상세 모달이 열려있으면 닫기
        if (selectedEvent) {
          closeModal();
        }
      }
    };

    // 이벤트 리스너 등록
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', preventGoBack);
    window.addEventListener('keydown', preventEsc);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('popstate', preventGoBack);
      window.removeEventListener('keydown', preventEsc);
    };
  }, [selectedEvent, showDeleteConfirm]); // 의존성 배열에 상태 추가

  // handleShare 함수 수정
  const handleShare = async (index) => {
    const currentAddress = scheduleInputs[index]?.address;
    const addressEvents = events
      .filter(event => event.title.split('\n')[0] === currentAddress)
      .sort((a, b) => new Date(a.start) - new Date(b.start));

    const shareText = `[아마레디자인 일정공유]
현장 주소: ${currentAddress || ''}

[공정 일정]
${addressEvents.map(event => {
  const processes = event.title.split('\n').slice(1).map(process => process.replace('> ', ''));
  const startDate = new Date(event.start);
  const endDate = new Date(event.end);
  endDate.setDate(endDate.getDate() - 1); // exclusive end date 보정

  // 시작일과 종료일이 같은 경우 날짜 하나만 표시
  const dateText = formatDate(startDate) === formatDate(endDate)
    ? `${formatDate(startDate)}`
    : `${formatDate(startDate)} ~ ${formatDate(endDate)}`;

  // 모든 공정을 표시
  return processes.map(process => `${process}: ${dateText}`).join('\n');
}).join('\n')}`;

    // 데스크톱 환경에서는 클립보드 복사만 실행
    if (window.innerWidth >= 768) {
      try {
        await navigator.clipboard.writeText(shareText);
        alert('클립보드에 복사되었습니다!');
      } catch (err) {
        console.error('클립보드 복사 실패:', err);
        alert('클립보드 복사에 실패했습니다.');
      }
      return;
    }

    // 모바일 환경에서는 공유 기능 사용
    try {
      await navigator.share({
        title: '아마레디자인 일정공유',
        text: shareText,  // 중복 제거된 텍스트 사용
        url: ''  // URL 제거
      });
    } catch (err) {
      console.error('공유 실패:', err);
    }
  };

  // addDays 함수가 없다면 추가
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  // 정렬된 주소 목록을 반환하는 함수
  const getSortedAddresses = () => {
    if (!savedAddresses.length) return [];

    // 주소 목록을 최근 선택 순으로 정렬
    return [...savedAddresses].sort((a, b) => {
      const aIndex = recentAddresses.indexOf(a.address);
      const bIndex = recentAddresses.indexOf(b.address);
      
      if (aIndex === -1 && bIndex === -1) {
        // 둘 다 최근 선택 목록에 없으면 현장 번호로 정렬
        return b.siteNumber - a.siteNumber;
      }
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  };

  useEffect(() => {
    if (selectedEvent && editedEvent && !editedEvent.address) {
      const sortedAddresses = getSortedAddresses();
      if (sortedAddresses.length > 0) {
        const topAddress = sortedAddresses[0].address;
        setEditedEvent(prev => ({
          ...prev,
          address: topAddress
        }));
      }
    }
  }, [selectedEvent, editedEvent, savedAddresses, recentAddresses]);

  // 문자 버튼 클릭 핸들러
  const handleMessageClick = (address) => {
    console.log('[AutoSchedule] 문자 버튼 클릭:', {
      clickedAddress: address,
      totalEvents: events.length,
      relevantEvents: events.filter(event => event.title.split('\n')[0] === address)
    });
    
    if (!address) {
      alert('현장 주소를 먼저 선택해주세요.');
      return;
    }
    setSelectedMessageAddress(address);
    setShowMessageModal(true);
  };

  // processes가 변경될 때 scheduleInputs 업데이트
  useEffect(() => {
    setScheduleInputs(prev => 
      prev.map(input => ({
        ...input,
        processDays: processes
      }))
    );
  }, [processes]);

  // 아코디언 토글 함수 추가
  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? -1 : index);
  };

  return (
    <div className="schedule-container">
      <div className="tab-table">
        <div 
          className={`tab-cell ${activeTab === 'calendar' ? 'active' : ''}`}
          onClick={() => setActiveTab('calendar')}
        >
          캘린더
        </div>
        <div 
          className={`tab-cell ${activeTab === 'settings' ? 'active' : ''}`}
          onClick={() => setActiveTab('settings')}
        >
          설정
        </div>
      </div>

      {activeTab === 'calendar' ? (
        <div className="calendar-section">
          <div className="calendar-view">
            <div className="calendar-wrapper">
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                locale={{
                  ...koLocale,
                  dayCellContent: ({ date }) => date.getDate(),
                }}
                events={[...events, ...holidays]}
                height="100%"
                headerToolbar={{
                  left: 'prev',
                  center: 'title',
                  right: 'next'
                }}
                dayHeaderFormat={{ weekday: 'short' }}
                eventClick={handleEventClick}
                dateClick={handleDateClick}
                fixedWeekCount={false}
                selectable={true}
                eventDragStart={false}
                editable={false}
                dayCellClassNames={({ date }) => {
                  const formattedDate = formatDate(date);
                  return holidays.some(holiday => holiday.start === formattedDate) ? 'holiday-cell' : '';
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="settings-section">
          <div className="process-management-button-container">
            <button 
              className="process-management-button"
              onClick={() => setShowProcessModal(true)}
            >
              공정 관리
            </button>
          </div>

          {scheduleInputs.map((input, index) => (
            <div key={index} className="schedule-form">
              <div 
                className={`accordion-header ${activeAccordion === index ? 'active' : ''}`}
                onClick={() => toggleAccordion(index)}
              >
                <div className="accordion-header-content">
                  <h3 className="accordion-title">현장 {index + 1}</h3>
                  <div className="header-buttons-container">
                    <button 
                      className="header-button header-button-share"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShare(index);
                      }}
                      title="이 현장의 일정을 공유합니다"
                    >
                      공유
                    </button>
                    <button 
                      className="header-button header-button-sms"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMessageClick(input.address);
                      }}
                      title="문자로 일정을 전송합니다"
                    >
                      문자
                    </button>
                    <button 
                      className="header-button header-button-reset"
                      onClick={(e) => {
                        e.stopPropagation();
                        resetSchedule(index);
                      }}
                      title="이 현장의 스케줄을 초기화합니다"
                    >
                      초기화
                    </button>
                    {index > 0 && (
                      <button 
                        className="header-button header-button-remove"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeSchedule(index);
                        }}
                      >
                        삭제
                      </button>
                    )}
                  </div>
                </div>
                <div className={`accordion-arrow ${activeAccordion === index ? 'active' : ''}`} />
              </div>
              
              <div className={`accordion-content ${activeAccordion === index ? 'active' : ''}`}>
                <div className="input-section">
                  <div className="input-group">
                    <label className="input-label">현장 주소</label>
                    <select
                      className="input-field"
                      value={input.address}
                      onChange={(e) => handleInputChange(index, 'address', e.target.value)}
                    >
                      <option value="">현장 주소를 선택하세요</option>
                      {getSortedAddresses().map((addr) => (
                        <option key={addr.id} value={addr.address}>
                          {`[${addr.siteNumber}] ${addr.address}`}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="input-group">
                    <label className="input-label">공사 시작일</label>
                    <input
                      type="date"
                      className="input-field"
                      value={input.startDate}
                      onChange={(e) => handleInputChange(index, 'startDate', e.target.value)}
                    />
                  </div>

                  <div className="checkbox-container">
                    <div className="checkbox-group">
                      <input
                        type="checkbox"
                        id={`all-process-${index}`}
                        checked={Object.values(input.processDays).every(config => config.include)}
                        onChange={(e) => {
                          const newInputs = [...scheduleInputs];
                          Object.keys(newInputs[index].processDays).forEach(process => {
                            newInputs[index].processDays[process].include = e.target.checked;
                          });
                          setScheduleInputs(newInputs);
                        }}
                      />
                      <label 
                        htmlFor={`all-process-${index}`} 
                        className="checkbox-label"
                      >
                        전체 선택
                      </label>
                    </div>

                    <div className="checkbox-group">
                      <input
                        type="checkbox"
                        id={`weekend-work-${index}`}
                        checked={input.workOnWeekends}
                        onChange={(e) => handleInputChange(index, 'workOnWeekends', e.target.checked)}
                      />
                      <label 
                        htmlFor={`weekend-work-${index}`}
                        className="checkbox-label"
                      >
                        주말 작업
                      </label>
                    </div>

                    <div className="checkbox-group">
                      <input
                        type="checkbox"
                        id={`holiday-work-${index}`}
                        checked={input.workOnHolidays}
                        onChange={(e) => handleInputChange(index, 'workOnHolidays', e.target.checked)}
                      />
                      <label 
                        htmlFor={`holiday-work-${index}`}
                        className="checkbox-label"
                      >
                        명절/국경일/공휴일
                      </label>
                    </div>

                    <div className="checkbox-group">
                      <input
                        type="checkbox"
                        id={`wallpaper-weekend-${index}`}
                        checked={input.wallpaperWeekend}
                        onChange={(e) => handleInputChange(index, 'wallpaperWeekend', e.target.checked)}
                      />
                      <label 
                        htmlFor={`wallpaper-weekend-${index}`}
                        className="checkbox-label"
                      >
                        도배 주말
                      </label>
                    </div>

                    <div className="checkbox-group">
                      <input
                        type="checkbox"
                        id={`film-weekend-${index}`}
                        checked={input.filmWeekend}
                        onChange={(e) => handleInputChange(index, 'filmWeekend', e.target.checked)}
                      />
                      <label 
                        htmlFor={`film-weekend-${index}`}
                        className="checkbox-label"
                      >
                        필름 주말
                      </label>
                    </div>
                  </div>
                </div>

                <div className="process-grid">
                  {Object.entries(input.processDays).map(([process, config]) => (
                    <div key={process} className="input-group">
                      <div className="process-header">
                        <label 
                          className="input-label" 
                          htmlFor={`process-${index}-${process}`}
                        >
                          {process}
                        </label>
                        <div className="checkbox-group">
                          <input
                            type="checkbox"
                            id={`process-${index}-${process}`}
                            checked={config.include}
                            onChange={(e) => handleInputChange(index, `processDays.${process}.include`, e.target.checked)}
                          />
                        </div>
                      </div>
                      <input
                        type="number"
                        className="input-field"
                        value={config.days}
                        onChange={(e) => handleInputChange(index, `processDays.${process}.days`, e.target.value)}
                        placeholder="일수"
                        disabled={!config.include}
                        style={{ backgroundColor: config.include ? 'white' : '#f5f5f5' }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

          <div className="button-group">
            <button className="button add-button" onClick={addNewSchedule}>
              새 현장 추가
            </button>
            <button className="button generate-button" onClick={() => {
              generateSchedule();
              setActiveTab('calendar');
            }}>
              스케줄 생성
            </button>
          </div>
        </div>
      )}

      {selectedEvent && (
        <div className="event-modal-overlay" onClick={closeModal}>
          <div className="event-modal" onClick={e => e.stopPropagation()}>
            <div className="event-modal-header">
              <h3>공사 상세 정보</h3>
              <button className="close-button" onClick={closeModal}>×</button>
            </div>
            <div className="event-modal-content">
              <div className="event-detail">
                <label>현장 주소 (선택)</label>
                <select
                  className="input-field"
                  value={editedEvent.address || ''}
                  onChange={(e) => handleEventChange('address', e.target.value)}
                >
                  <option value="">현장주소를 선택하세요</option>
                  {getSortedAddresses().map((addr) => (
                    <option key={addr.id} value={addr.address}>
                      {`[${addr.siteNumber}] ${addr.address}`}
                    </option>
                  ))}
                </select>
              </div>
              <div className="event-detail">
                <label>공정 선택 (선택)</label>
                <div className="process-checkboxes">
                  {Object.keys(processes).map(process => (
                    <div key={process} className="process-checkbox-item">
                      <input
                        type="checkbox"
                        id={`process-${process}`}
                        checked={editedEvent.processes?.includes(process)}
                        onChange={(e) => {
                          const processes = editedEvent.processes || [];
                          if (e.target.checked) {
                            handleEventChange('processes', [...processes, process]);
                          } else {
                            handleEventChange('processes', processes.filter(p => p !== process));
                          }
                        }}
                      />
                      <label htmlFor={`process-${process}`}>{process}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="event-detail">
                <label>메모</label>
                <textarea
                  className="input-field memo-input"
                  value={editedEvent.memo || ''}
                  onChange={(e) => handleEventChange('memo', e.target.value)}
                  placeholder="메모를 입력하세요"
                  rows={3}
                />
              </div>
              <div className="event-detail">
                <label>시작일</label>
                <input
                  type="date"
                  className="input-field"
                  value={editedEvent.start}
                  onChange={(e) => handleEventChange('start', e.target.value)}
                />
              </div>
              <div className="event-detail">
                <label>종료일</label>
                <input
                  type="date"
                  className="input-field"
                  value={editedEvent.end}
                  onChange={(e) => handleEventChange('end', e.target.value)}
                />
              </div>
              <div className="modal-button-group">
                <button className="modal-button modal-button-save" onClick={saveEventChanges}>
                  저장
                </button>
                <button className="modal-button modal-button-cancel" onClick={closeModal}>
                  취소
                </button>
                <button className="modal-button modal-button-delete" onClick={deleteEvent}>
                  삭제
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDeleteConfirm && (
        <div className="confirm-modal-overlay">
          <div className="confirm-modal">
            <div className="confirm-modal-message">
              이 일정을 삭제하시겠습니까?
            </div>
            <div className="confirm-modal-buttons">
              <button className="confirm-modal-button" onClick={confirmDelete}>
                확인
              </button>
              <button className="confirm-modal-button" onClick={cancelDelete}>
                취소
              </button>
            </div>
          </div>
        </div>
      )}

      <MessageModal
        isOpen={showMessageModal}
        onClose={() => setShowMessageModal(false)}
        address={selectedMessageAddress}
        events={events}
      />

      <ProcessManagementModal
        isOpen={showProcessModal}
        onClose={() => setShowProcessModal(false)}
        processes={processes}
        setProcesses={setProcesses}
        scheduleInputs={scheduleInputs}
        setScheduleInputs={setScheduleInputs}
      />
    </div>
  );
};

export default AutoSchedule; 