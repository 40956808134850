const PIXEL_ID = '1802257710512092';
const DEBOUNCE_TIME = 1000;

// 이벤트 중복 방지를 위한 디바운스
let lastEventTime = {};

export const trackMetaEvent = async (eventName, params = {}) => {
  // 중복 이벤트 방지
  const now = Date.now();
  if (lastEventTime[eventName] && (now - lastEventTime[eventName]) < DEBOUNCE_TIME) {
    return;
  }
  lastEventTime[eventName] = now;

  // 표준 이벤트 매핑
  const standardEvents = {
    'StartEstimate': 'InitiateCheckout',    // 견적 시작
    'ProcessSelection': 'AddToCart',         // 공정 선택
    'CompleteEstimate': 'Purchase',          // 견적 완료
    'Contact': 'Contact'                     // 문의하기
  };

  // CustomizeProduct 이벤트는 매핑 없이 직접 사용
  const eventNameToUse = standardEvents[eventName] || eventName;

  // 기본 fbq 함수로 이벤트 전송
  if (window.fbq) {
    window.fbq('track', eventNameToUse, {
      ...params,
      currency: 'KRW',
      value: params.totalPrice || 0
    });
  }
};

export const trackPageView = () => {
  if (window.fbq) {
    window.fbq('track', 'PageView');
  }
};

// Facebook 쿠키 가져오기 함수
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}; 