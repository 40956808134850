import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios'; // axios를 설치해야 합니다: npm install axios
import { saveAccessToken, getAccessToken } from '../firebase';

const InstagramAuto = () => {
  const [accessToken, setAccessToken] = useState('');
  const [profileInfo, setProfileInfo] = useState(null);
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [autoUpload, setAutoUpload] = useState(false);
  const [pendingImages, setPendingImages] = useState([]);
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadHour, setUploadHour] = useState('');
  const [uploadMinute, setUploadMinute] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [autoUploadInterval, setAutoUploadInterval] = useState(null);

  const updateAccessToken = async (newToken) => {
    setAccessToken(newToken);
    await saveAccessToken(newToken);
    console.log('New token set:', newToken);
  };

  const fetchInstagramProfile = useCallback(async (token) => {
    const currentToken = token || accessToken;
    console.log('Using token:', currentToken);
    if (!currentToken) {
      setError('액세스 토큰을 입력해주세요.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `https://graph.facebook.com/v21.0/me?fields=id,name&access_token=${currentToken}`
      );
      setProfileInfo(response.data);
    } catch (err) {
      console.error('Detailed error:', err);
      setError(`프로필 정보를 가져오는데 실패했습니다: ${err.response?.data?.error?.message || err.message}`);
    } finally {
      setLoading(false);
    }
  }, [accessToken]);

  useEffect(() => {
    const loadSavedToken = async () => {
      const savedToken = await getAccessToken();
      if (savedToken) {
        setAccessToken(savedToken);
        fetchInstagramProfile(savedToken);
      }
    };
    loadSavedToken();
  }, [fetchInstagramProfile]);

  const saveAccessTokenHandler = async () => {
    await updateAccessToken(accessToken);
    alert('액세스 토큰이 저장되었습니다.');
    fetchInstagramProfile();
  };

  const fetchInstagramPosts = async () => {
    if (!profileInfo) {
      setError('먼저 프로필 정보를 가져와주세요.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // 먼저 Instagram 비즈니스 계정 ID를 가져옵니다.
      const accountResponse = await axios.get(
        `https://graph.facebook.com/v21.0/${profileInfo.id}?fields=instagram_business_account&access_token=${accessToken}`
      );
      const instagramAccountId = accountResponse.data.instagram_business_account.id;

      // 이제 Instagram 비즈니스 계정 ID를 사용하여 미디어를 가져옵니다.
      const mediaResponse = await axios.get(
        `https://graph.facebook.com/v21.0/${instagramAccountId}/media?fields=id,caption,media_type,media_url,permalink,timestamp&access_token=${accessToken}`
      );
      setPosts(mediaResponse.data.data);
    } catch (err) {
      setError(`게시물을 가져오는데 실패했습니다: ${err.response?.data?.error?.message || err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const newImages = acceptedFiles.map(file => ({
      file,
      preview: URL.createObjectURL(file),
      selected: false
    }));
    setImages(prevImages => [...prevImages, ...newImages]);
  }, []);

  const handleDragAreaClick = () => {
    fileInputRef.current.click();
  };

  const toggleImageSelection = (index, isPending = false) => {
    if (isPending) {
      setPendingImages(prevImages => prevImages.map((img, i) => 
        i === index ? { ...img, selected: !img.selected } : img
      ));
    } else {
      setImages(prevImages => prevImages.map((img, i) => 
        i === index ? { ...img, selected: !img.selected } : img
      ));
    }
  };

  const handleUploadHourChange = (e) => {
    setUploadHour(e.target.value);
  };

  const handleUploadMinuteChange = (e) => {
    setUploadMinute(e.target.value);
  };

  const toggleAutoUpload = () => {
    if (!uploadHour || !uploadMinute) {
      alert('업로드 시간을 설정해주세요.');
      return;
    }
    setAutoUpload(prev => !prev);
    if (!autoUpload) {
      const now = new Date();
      const uploadTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), uploadHour, uploadMinute);
      if (uploadTime <= now) {
        uploadTime.setDate(uploadTime.getDate() + 1);
      }
      const timeUntilUpload = uploadTime - now;
      
      const interval = setInterval(() => {
        const currentTime = new Date();
        if (currentTime.getHours() === parseInt(uploadHour) && currentTime.getMinutes() === parseInt(uploadMinute)) {
          uploadImages();
        }
      }, 60000); // 매 분마다 체크

      setAutoUploadInterval(interval);
      setTimeout(() => uploadImages(), timeUntilUpload);
    } else {
      clearInterval(autoUploadInterval);
      setAutoUploadInterval(null);
    }
  };

  const uploadStory = async (image) => {
    if (!profileInfo) {
      setError('프로필 정보가 필요합니다.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Instagram 비즈니스 계정 ID 가져오기
      const accountResponse = await axios.get(
        `https://graph.facebook.com/v21.0/${profileInfo.id}?fields=instagram_business_account&access_token=${accessToken}`
      );
      const instagramAccountId = accountResponse.data.instagram_business_account.id;

      // 이미지 업로드
      const formData = new FormData();
      formData.append('image_url', image.file);
      
      const uploadResponse = await axios.post(
        `https://graph.facebook.com/v21.0/${instagramAccountId}/media`,
        formData,
        {
          params: {
            media_type: 'STORY',
            access_token: accessToken
          }
        }
      );

      const mediaId = uploadResponse.data.id;

      // 스토리 게시
      await axios.post(
        `https://graph.facebook.com/v21.0/${instagramAccountId}/media_publish`,
        null,
        {
          params: {
            creation_id: mediaId,
            access_token: accessToken
          }
        }
      );

      console.log('스토리가 성적으로 업로드되었습니다.');
      return true;
    } catch (err) {
      setError(`스토리 업로드 실패: ${err.response?.data?.error?.message || err.message}`);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const uploadImages = async () => {
    console.log('이미지 업로드 시작');
    setLoading(true);
    for (const image of pendingImages) {
      const success = await uploadStory(image);
      if (success) {
        setUploadedImages(prev => [...prev, image]);
        setPendingImages(prev => prev.filter(img => img !== image));
      }
    }
    setLoading(false);
    console.log('이미지 업로드 완료');
  };

  const removeSelectedPendingImages = () => {
    setPendingImages(prevImages => prevImages.filter(img => !img.selected));
  };

  const addToPendingImages = () => {
    const selectedImages = images.filter(img => img.selected);
    setPendingImages(prevPending => [...prevPending, ...selectedImages]);
    setImages(prevImages => prevImages.filter(img => !img.selected));
  };

  useEffect(() => {
    return () => {
      if (autoUploadInterval) {
        clearInterval(autoUploadInterval);
      }
    };
  }, [autoUploadInterval]);

  return (
    <div className="instagram-auto">
      <h1>INSTAGRAM 자동화</h1>
      <p className="app-description">
        이 앱은 Instagram 게시물을 자동으로 업로드합니다. 액세스 토큰을 입력하고, 이미지를 선택한 후, 업로드 시간을 설정하세요.
        <br />
        This app automatically uploads posts to Instagram. Enter your access token, select images, and set the upload time.
      </p>
      <div>
        <input
          type="password"
          value={accessToken}
          onChange={(e) => setAccessToken(e.target.value)}
          placeholder="액세스 토큰 입력"
        />
        <button onClick={() => fetchInstagramProfile()} disabled={loading}>
          {loading ? '로딩 중...' : '프로필 정보 가져오기'}
        </button>
        <button onClick={saveAccessTokenHandler}>액세스 토큰 저장</button>
      </div>
      {error && <p className="error">{error}</p>}
      {profileInfo && (
        <div className="profile-info">
          <h2>프로필 정보</h2>
          <p>이름: {profileInfo.name}</p>
          <p>ID: {profileInfo.id}</p>
          <button onClick={fetchInstagramPosts} disabled={loading} className="fetch-posts-button">
            {loading ? '로딩 중...' : '최근 게시물 가져오기(TEST)'}
          </button>
        </div>
      )}
      {posts.length > 0 && (
        <div>
          <h2>최근 물</h2>
          {posts.map(post => (
            <div key={post.id}>
              <p>{post.caption}</p>
              {post.media_type === 'IMAGE' && <img src={post.media_url} alt="Instagram post" />}
              <p>게시 시간: {new Date(post.timestamp).toLocaleString()}</p>
            </div>
          ))}
        </div>
      )}
      <div className="image-upload-section">
        <h2>인스타그램 스토리 업로드</h2>
        <div 
          className={`dropzone ${isDragging ? 'dragging' : ''}`}
          onClick={handleDragAreaClick}
          onDrop={(e) => {
            e.preventDefault();
            setIsDragging(false);
            onDrop(Array.from(e.dataTransfer.files));
          }}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDragLeave={() => setIsDragging(false)}
        >
          스토리 이미지를 여기에 드래그하거나 클릭하여 선택하세요
        </div>
        <input 
          ref={fileInputRef}
          type="file" 
          multiple 
          onChange={(e) => onDrop(Array.from(e.target.files))}
          style={{ display: 'none' }}
        />
        <div className="image-preview">
          {images.map((img, index) => (
            <div 
              key={index} 
              className={`image-item ${img.selected ? 'selected' : ''}`}
              onClick={() => toggleImageSelection(index)}
            >
              <img src={img.preview} alt={`preview ${index}`} />
              <input
                type="checkbox"
                checked={img.selected}
                onChange={() => toggleImageSelection(index)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="upload-settings">
        <h2>업로드 설정</h2>
        <p className="upload-description">
          매일 지정된 시간에 선택된 이미지가 자동으로 업로드됩니다.
          <br />
          Selected images will be automatically uploaded at the specified time every day.
        </p>
        <div className="time-picker">
          <select value={uploadHour} onChange={handleUploadHourChange}>
            <option value="" disabled>시</option>
            {[...Array(24).keys()].map(hour => (
              <option key={hour} value={hour.toString().padStart(2, '0')}>
                {hour.toString().padStart(2, '0')}
                <span className="time-unit"> 시</span>
              </option>
            ))}
          </select>
          <span>:</span>
          <select value={uploadMinute} onChange={handleUploadMinuteChange}>
            <option value="" disabled>분</option>
            {[...Array(60).keys()].map(minute => (
              <option key={minute} value={minute.toString().padStart(2, '0')}>
                {minute.toString().padStart(2, '0')}
                <span className="time-unit"> 분</span>
              </option>
            ))}
          </select>
        </div>
        <div className="button-group">
          <button onClick={addToPendingImages} disabled={!images.some(img => img.selected)}>
            대기중 이미지 추가
          </button>
          <button onClick={toggleAutoUpload} disabled={!uploadHour || !uploadMinute}>
            {autoUpload ? '자동 업로드 중지' : '자동 업로드 시작'}
          </button>
        </div>
      </div>

      <div className="pending-uploads">
        <h2>대기 중인 이미지 ({pendingImages.length})</h2>
        <div className="image-preview">
          {pendingImages.map((img, index) => (
            <div 
              key={index} 
              className={`image-item ${img.selected ? 'selected' : ''}`}
              onClick={() => toggleImageSelection(index, true)}
            >
              <img src={img.preview} alt={`preview ${index}`} />
              <input
                type="checkbox"
                checked={img.selected}
                onChange={() => toggleImageSelection(index, true)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          ))}
        </div>
        <button onClick={removeSelectedPendingImages} disabled={!pendingImages.some(img => img.selected)}>
          선택 삭제
        </button>
        <button onClick={() => setPendingImages([])} disabled={pendingImages.length === 0}>
          모두 삭제
        </button>
        <button onClick={uploadImages} disabled={pendingImages.length === 0 || loading}>
          {loading ? '업로드 중...' : '스토리 업로드'}
        </button>
      </div>

      <div className="uploaded-images">
        <h2>업로드된 이미지 ({uploadedImages.length})</h2>
        <div className="image-preview">
          {uploadedImages.map((img, index) => (
            <div key={index} className="image-item uploaded">
              <img src={img.preview} alt={`uploaded ${index}`} />
              <span className="upload-indicator">✓</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InstagramAuto;
