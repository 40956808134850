import React from 'react';
import { pdf } from '@react-pdf/renderer';
import MyDocument from '../pdf/MyDocument';
import './PdfButton.css';

const PdfButton = ({ 
  selectedAddress, 
  processData, 
  notes, 
  hiddenProcesses,
  calculateTotal,
  getProcessName,
  formatNumber,
  filterProcessesForPDF 
}) => {
  const handleGeneratePDF = async () => {
    try {
      const blob = await pdf(
        <MyDocument 
          selectedAddress={selectedAddress}
          processData={processData}
          notes={notes}
          hiddenProcesses={hiddenProcesses}
          calculateTotal={calculateTotal}
          getProcessName={getProcessName}
          formatNumber={formatNumber}
          filterProcessesForPDF={filterProcessesForPDF}
        />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `아마레디자인 (${selectedAddress}) - 견적서.pdf`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('PDF 생성 중 오류:', error);
      alert('PDF 생성 중 오류가 발생했습니다.');
    }
  };

  return (
    <button 
      className="pdf-button"
      onClick={handleGeneratePDF}
    >
      PDF 견적서
    </button>
  );
};

export default PdfButton;