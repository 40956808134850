import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>개인정보처리방침</h1>
      <p>
        AMARE DESIGN(이하 "회사")은 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.
        회사는 개인정보처리방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
      </p>
      {/* 여기에 더 자세한 개인정보처리방침 내용을 추가하세요 */}
      <Link to="/">홈으로 돌아가기</Link>
    </div>
  );
};

export default PrivacyPolicy;
