import React, { useState, useCallback, useRef, useEffect } from 'react';
import './SNSImageEdit.css';  // 새로 만든 CSS 파일 import

const SNSImageEdit = () => {
  const [images, setImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [inputText, setInputText] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [allSelected, setAllSelected] = useState(false);
  const fileInputRef = useRef(null);

  const saveImagesToLocalStorage = useCallback((newImages) => {
    const imagesToSave = newImages.map(img => ({
      preview: img.preview,
      text: img.text,
      textPosition: img.textPosition,
      isChecked: img.isChecked
    }));
    localStorage.setItem('snsImages', JSON.stringify(imagesToSave));
  }, []);

  useEffect(() => {
    const savedImages = localStorage.getItem('snsImages');
    if (savedImages) {
      setImages(JSON.parse(savedImages));
    }
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    const newImages = acceptedFiles.map(file => ({
      file,
      preview: URL.createObjectURL(file),
      text: '',
      textPosition: { x: 0, y: 0 },
      isChecked: false
    }));
    const updatedImages = [...images, ...newImages];
    setImages(updatedImages);
    saveImagesToLocalStorage(updatedImages);
  }, [images, saveImagesToLocalStorage]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    onDrop(files);
  };

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    onDrop(files);
  };

  const handleRandomApply = () => {
    const updatedImages = images.map(img => ({
      ...img,
      text: inputText,
      textPosition: {
        x: Math.random() * 80,
        y: Math.random() * 80
      }
    }));
    setImages(updatedImages);
    saveImagesToLocalStorage(updatedImages);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const handleCheckboxChange = (index) => {
    const updatedImages = images.map((img, i) => 
      i === index ? { ...img, isChecked: !img.isChecked } : img
    );
    setImages(updatedImages);
    saveImagesToLocalStorage(updatedImages);
    setAllSelected(updatedImages.every(img => img.isChecked));
  };

  const handleRemoveChecked = () => {
    const updatedImages = images.filter(img => !img.isChecked);
    setImages(updatedImages);
    saveImagesToLocalStorage(updatedImages);
    setAllSelected(false);
  };

  const handleSelectAll = () => {
    const newAllSelected = !allSelected;
    const updatedImages = images.map(img => ({ ...img, isChecked: newAllSelected }));
    setImages(updatedImages);
    saveImagesToLocalStorage(updatedImages);
    setAllSelected(newAllSelected);
  };

  return (
    <div className="sns-image-edit">
      <h1>SNS 이미지 편집</h1>
      <div className="text-input-section">
        <input
          type="text"
          className="text-input"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="텍스트를 입력하세요"
        />
        <button className="random-apply-button" onClick={handleRandomApply}>
          랜덤 적용
        </button>
      </div>
      <div 
        className={`dropzone ${isDragging ? 'dragging' : ''}`}
        onClick={() => fileInputRef.current.click()}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        이미지를 여기로 드래그하거나 클릭 선택
      </div>
      <input 
        ref={fileInputRef}
        type="file" 
        multiple 
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
      />
      <div className="button-container">
        <div className="image-controls">
          <button className="select-all-button" onClick={handleSelectAll}>
            {allSelected ? '전체 선택 해제' : '전체 선택'}
          </button>
          <button className="remove-checked-button" onClick={handleRemoveChecked}>
            삭제
          </button>
        </div>
      </div>
      <div className="image-preview">
        {images.map((img, index) => (
          <div key={index} className="image-item">
            <img src={img.preview} alt={`preview ${index}`} onClick={() => handleImageClick(img)} />
            <input
              type="checkbox"
              checked={img.isChecked}
              onChange={() => handleCheckboxChange(index)}
            />
            {img.text && (
              <div 
                className="image-text" 
                style={{ 
                  left: `${img.textPosition.x}%`, 
                  top: `${img.textPosition.y}%` 
                }}
              >
                {img.text}
              </div>
            )}
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="lightbox" onClick={closeModal}>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage.preview} alt="Full size" />
            {selectedImage.text && (
              <div 
                className="image-text" 
                style={{ 
                  left: `${selectedImage.textPosition.x}%`, 
                  top: `${selectedImage.textPosition.y}%` 
                }}
              >
                {selectedImage.text}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SNSImageEdit;
