import React, { useState, useEffect } from 'react';
import './MobileSaveItemMemoModal.css';

const MobileSaveItemMemoModal = ({ item, onSave, onClose }) => {
  const [memo, setMemo] = useState('');

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  const handleSave = () => {
    onSave({
      ...item,
      memo: memo.trim()
    });
  };

  return (
    <div className="mobile-memo-modal">
      <div className="mobile-memo-content">
        <h3>품목 저장</h3>
        <div className="mobile-item-info">
          <p>품목명: {item.name}</p>
          <p>단가: {parseInt(item.unitPrice).toLocaleString()}원</p>
        </div>
        <div className="mobile-memo-field">
          <label>메모</label>
          <textarea
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            placeholder="이 품목에 대한 메모를 입력하세요. (선택사항)"
          />
        </div>
        <div className="mobile-memo-buttons">
          <button onClick={handleSave}>저장</button>
          <button onClick={onClose}>취소</button>
        </div>
      </div>
    </div>
  );
};

export default MobileSaveItemMemoModal; 