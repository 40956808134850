import React, { useState, useEffect } from 'react';
import { Document, Page, Text, View, StyleSheet, pdf, Font } from '@react-pdf/renderer';
import './EstimateApp.css';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { sendFCMNotification } from '../../services/fcm';
import { trackMetaEvent } from '../../utils/metaPixel';

// 폰트 등록 수정
Font.register({
  family: 'Pretendard',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff', fontWeight: 400 },
    { src: 'https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff', fontWeight: 500 },
    { src: 'https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff', fontWeight: 700 }
  ]
});

// PDF 스타일 정의
const styles = StyleSheet.create({
  page: {
    padding: '25px 35px',  // 여백 축소
    width: '360px',
    backgroundColor: '#ffffff',
    fontFamily: 'Pretendard'
  },
  header: {
    marginBottom: 25,  // 간격 축소
    textAlign: 'right'
  },
  date: {
    fontSize: 10,
    color: '#666666',
    marginBottom: 4
  },
  contact: {
    fontSize: 11,
    color: '#946A6A',
    marginBottom: 20,
    textAlign: 'right',
    fontWeight: 'bold'
  },
  subTitle: {
    fontSize: 12,
    color: '#333333',
    fontWeight: 500
  },
  titleContainer: {
    marginBottom: 30,  // 간격 축소
    textAlign: 'center'
  },
  mainTitle: {
    fontSize: 24,
    fontWeight: 700,
    letterSpacing: 1
  },
  processList: {
    marginTop: 15  // 간격 축소
  },
  processHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 12,
    borderBottom: '0.5px solid #EEEEEE'  // 더 얇은 선
  },
  processHeaderText: {
    fontSize: 11,
    textAlign: 'center',
    flex: 1,
    color: '#666666',
    fontWeight: 500
  },
  processItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,  // 패딩 축소
    borderBottom: '0.5px dashed #EEEEEE'
  },
  processName: {
    fontSize: 11,
    flex: 1,
    textAlign: 'center',
    color: '#333333'
  },
  processPrice: {
    fontSize: 11,
    flex: 1,
    textAlign: 'center',
    color: '#333333'
  },
  visitRequired: {
    fontSize: 11,
    flex: 1,
    textAlign: 'center',
    color: '#946A6A',
    backgroundColor: 'rgba(148, 106, 106, 0.08)',  // 배경색 추가
    padding: 4,
    borderRadius: 4
  },
  extraChargeItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    alignItems: 'center'
  },
  totalPrice: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 0,
    paddingTop: 15,  // 패딩 축소
    paddingBottom: 15,  // 패딩 축소
    borderTop: '1px solid #333333'
  },
  totalLabel: {
    fontSize: 13,
    fontWeight: 700,
    flex: 1,
    textAlign: 'center',
    color: '#333333'
  },
  totalValue: {
    fontSize: 13,
    fontWeight: 700,
    flex: 1,
    textAlign: 'center',
    color: '#333333'
  },
  footer: {
    marginTop: 20,  // 간격 축소
    alignItems: 'center',
    justifyContent: 'center'
  },
  note: {
    fontSize: 9,
    color: '#999999',
    textAlign: 'center'
  }
});

// PDF 문서 컴포넌트 수정
const EstimatePdfDocument = ({ selectedProcesses, userInfo, selectedArea, prices, totalPrice }) => {
  const formatPrice = (price) => {
    // 가격이 문자열이고 숫자가 아닌 경우 그대로 반환
    if (typeof price === 'string' && isNaN(parseInt(price))) {
      return price;
    }
    // 숫자인 경우 포맷팅
    if (!price) return '0원';
    return parseInt(price).toLocaleString() + '원';
  };

  // 공정 가격 총액 계산 (공과잡비 제외)
  const processTotal = selectedProcesses.reduce((sum, process) => {
    const price = prices[process];
    if (typeof price === 'string' && isNaN(parseInt(price.replace(/,/g, '')))) {
      return sum;
    }
    const parsedPrice = parseInt(price?.toString().replace(/,/g, '')) || 0;
    return sum + parsedPrice;
  }, 0);

  // 공과잡비 계산 (전체 공정 금액의 3%)
  const miscFee = Math.round(processTotal * (3/103));

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleContainer}>
          <Text style={styles.mainTitle}>AMARE DESIGN</Text>
        </View>
        
        <View style={styles.header}>
          <Text style={styles.subTitle}>
            {userInfo?.name}님의 {selectedArea}평 견적서
          </Text>
          <Text style={styles.date}>{new Date().toLocaleDateString()}</Text>
          <Text style={styles.contact}>Tel : 010.8694.4078</Text>
        </View>

        <View style={styles.processList}>
          <View style={styles.processHeader}>
            <Text style={styles.processHeaderText}>공정</Text>
            <Text style={styles.processHeaderText}>금액</Text>
          </View>
          
          {selectedProcesses.map((process, index) => (
            <View key={index} style={styles.processItem}>
              <Text style={styles.processName}>
                {process}
              </Text>
              <Text 
                style={[
                  styles.processPrice,
                  typeof prices[process] === 'string' && isNaN(parseInt(prices[process])) 
                    ? styles.visitRequired 
                    : null
                ]}
              >
                {formatPrice(prices[process])}
              </Text>
            </View>
          ))}

          <View style={styles.extraChargeItem}>
            <Text style={styles.processName}>공과잡비</Text>
            <Text style={styles.visitRequired}>미정</Text>
          </View>
        </View>

        <View style={styles.totalPrice}>
          <Text style={styles.totalLabel}>총 견적 금액</Text>
          <Text style={styles.totalValue}>{totalPrice.toLocaleString()}원</Text>
        </View>

        <View style={styles.footer}>
          <Text style={styles.note}>
            ※ 상기 금액은 예상 견적가로 현장 상황에 따라 변동될 수 있습니다.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

// lineupNames 객체를 컴포넌트 내부에 정의
const lineupNames = {
  'refresh': '리프레쉬',
  'harmony': '하모니',
  'collection': '컬렉션'
};

const UserInfoInput = ({ onSubmit, selectedProcesses, selectedArea, prices, totalPrice, selectedLineup }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [sending, setSending] = useState(false);
  const [emailClickCount, setEmailClickCount] = useState(0);
  const [nameClickCount, setNameClickCount] = useState(0);

  const handleEmailClick = () => {
    setEmailClickCount(prev => {
      if (prev + 1 === 4) {
        setEmail('gncloud86@naver.com');
        return 0;
      }
      return prev + 1;
    });
  };

  const handleNameClick = () => {
    setNameClickCount(prev => {
      if (prev + 1 === 4) {
        setName('아마레');
        return 0;
      }
      return prev + 1;
    });
  };

  const sendEstimateEmail = async (userInfo) => {
    try {
      setSending(true);
      
      // 이메일 템플릿 불러오기
      const templateDoc = await getDoc(doc(db, 'emailTemplates', '1mintemplates'));
      const emailTemplate = templateDoc.exists() ? templateDoc.data() : {
        subject: '{name}님의 아마레디자인 견적서',
        content: '{name}님의 견적서입니다.',
        signature: {},
      };

      // 템플릿 변수 치환
      const subject = emailTemplate.subject.replace('{name}', userInfo.name);
      let content = emailTemplate.content
        .replace('{name}', userInfo.name)
        .replace('{area}', selectedArea);

      // PDF 생성
      const pdfBlob = await pdf(
        <EstimatePdfDocument 
          selectedProcesses={selectedProcesses}
          userInfo={userInfo}
          selectedArea={selectedArea}
          prices={prices}
          totalPrice={totalPrice}
        />
      ).toBlob();
      
      // PDF를 Base64로 변환
      const arrayBuffer = await pdfBlob.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      let binaryString = '';
      uint8Array.forEach(byte => {
        binaryString += String.fromCharCode(byte);
      });
      const base64Pdf = btoa(binaryString);

      // SMTP 이메일 전송 요청
      const response = await fetch('https://us-central1-interior-one-click.cloudfunctions.net/sendEstimatePdfHttp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: userInfo.email,
          subject: subject,
          html: content,
          signature: emailTemplate.signature,
          attachments: [{
            filename: `${userInfo.name}님의 견적서.pdf`,
            content: base64Pdf,
            encoding: 'base64'
          }]
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '이메일 전송에 실패했습니다.');
      }

      alert('견적서가 이메일로 전송되었습니다.');
      return true;

    } catch (error) {
      alert('견적서 전송 중 오류가 발생했습니다. 다시 시도해주세요.');
      return false;
    } finally {
      setSending(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userInfo = { name, email };
    
    try {
      await trackMetaEvent('CompleteEstimate', {
        content_name: '1분견적서',
        status: 'success',
        totalPrice: totalPrice
      });

      // 이메일 전송 시도
      const emailSent = await sendEstimateEmail(userInfo);
      if (emailSent) {
        // FCM 알림 전송 시도 (실패해도 계속 진행)
        try {
          const fcmData = {
            title: '1분견적 문의',
            body: `${userInfo.name}님 ${selectedArea}평 1분견적 문의`,
            data: {
              type: 'estimate_inquiry',
              name: userInfo.name,
              email: userInfo.email,
              area: selectedArea.toString(),
              timestamp: new Date().toISOString(),
              selectedLineup: selectedLineup || 'refresh',
              lineupName: lineupNames[selectedLineup || 'refresh']
            }
          };
          
          await sendFCMNotification(fcmData);
        } catch (fcmError) {
          console.error('FCM 알림 전송 실패:', fcmError);
          // FCM 실패는 무시하고 계속 진행
        }
        
        // 견적서 전송 성공 시 다음 단계로 진행
        onSubmit(name, email, selectedLineup);
      }
    } catch (error) {
      console.error('견적서 처리 중 오류:', error);
      alert('견적서 처리 중 오류가 발생했습니다. 다시 시도해주세요.');
    }
  };

  return (
    <div className="user-info-input">
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="성함(또는 별명)을 입력해주세요"
            required
            style={{ caretColor: 'transparent' }}
            onFocus={(e) => e.target.style.caretColor = '#946A6A'}
            onBlur={(e) => e.target.style.caretColor = 'transparent'}
            onClick={handleNameClick}
          />
        </div>
        <div>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="이메일을 입력해주세요"
            required
            style={{ caretColor: 'transparent' }}
            onFocus={(e) => e.target.style.caretColor = '#946A6A'}
            onBlur={(e) => e.target.style.caretColor = 'transparent'}
            onClick={handleEmailClick}
          />
        </div>
        <div>
          <button 
            type="submit" 
            className="submit-button"
            disabled={sending}
          >
            {sending ? '전송 중...' : '즉시 견적서 확인하기'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserInfoInput;
