const PROJECT_ID = "interior-one-click";
const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDo4yS7o2dDxqYI\nxns+xXvsah4w/r1W/WXMnBlmjSaEhX4aOT6fsA5grE5kqYejoXWBVYOu0aoPizNd\nNxCYPF36R8pMmQ2l8Muw6mnt9JUxUmd59x0sKffyPNtvHAi+CkWpvvrFk1EY9hCn\nE3YuCNezwKWQ3yvSKPdD3m1dK9uZJBPQJRnsmeSSBBsvh8sGjV4MriSihS25ya7Y\nbK8IFZrPNsyDOlK9Kza1YcBD2/Imra3hKIwihZvlZehlBbg3dMdH9dy2TDiNy8Ul\ny44yZDCalKX1nvt0Qo91hcxHKpqkvjY5uPiYrwC0O/Gz29LbWOBCtumkGUL3Dxnp\nUFyhdjbFAgMBAAECggEANbXrSx4+yvYV5Wi3VG0R7V61C1o4+0bSU1v+DLWJmF88\nS9p/e69MRZX/t34U4xNyCQvapLdEyDRzunAgcufbScWEXeGfsPGF3M3Xc/KodPlb\nFZk9QGoGffb0qd22k3PFiMZbyjT1a3MWuJ3HsArIirOny694yQ27J0s1gFtE2H61\no6JZGGJeqTPOUkuVcj/KZ6vy81Ll4fC9ydj0UmmALElO2QYVNvKlYjW431CqbLfl\ndt/bje27BK5hoJF6f8cRlYj3smw0FMnZlV2HngBl8PFdfCvxm9cJQT8dLU6JpqxT\nkay+T4WXnmtmiH47kCdARqiUHPXVK+3tRLHVvwFnAQKBgQD86nxk3AoQq36a4kfI\n3urUz+hW7n07F5iQzeYDO9pzXNHjRgeO8CZ6wCOxBpLwM3sHst0hoAUANaPCU8Fd\nLLEXWMqTP6SETb4Bur2w7TseixoSfQD6HFnJg/yBYqY97dHQJMFNpSWR2HOrzn9Y\nLkY7vFXHL8LhdxUaf6Cu6LuFAQKBgQDruiKXWmQnmAtEB4WH9wem9Vn1btzNH/bz\nlAwEqi2ErkNOPOHEgei4B/BuYgZ8wjTCSrWnu4dsCALGg3At4ib++29WF4rScejG\nX9GW+OJKmE71w9YIW+iAKGoq7fBbqS7/8mtAQpI71MHgN0O5hNRF4/JYLIzqhD9N\nC9PbdFfdxQKBgDCk9Qn9HF56bZ5imUX0QhxuZJSbyDBeaHSpdWz3zRtVJfVdmcSP\nQgdn/zVkO0zByg8AAvIdsfiJWJbevxkpqGICLOxHWGAuB7aTcTqfVjiYrooRyudQ\nHqDYpu9HxwuwOyOafidt9RzpEc5gqDOQqueAYMsvz37IBaIUHBaoIrEBAoGAF5d+\nZMVwJqJJ5loWXCA2DNibUzvwLztWvNQBS+o4OgIyzD69nawevWRWBMYcvhm1rAhM\nG+hhykcW+AUpkf4DRxuk7enXQ+6QjGIEmOZ5x1ts853Q6u8/2quwzHEZ0CKjiIwb\nfcfr2L8XVDhTt9p6YDBLDdYPmFz59N0NK/Z94L0CgYBkmTmUEl/q+tYF3a0F3aF5\nhNATBmazaOTI4D279Cj3r2zNr1PPWaM321waM/uBfDiAcn701+8TuPTpza00McyK\nSPVI8+bOCpnX27HOnaoLMLsv9p7uPxnAbVMU4U4Ffn+UKW5xcfOPcudLo2nbMORn\ntvJUdyijkarkmCX+STId0A==\n-----END PRIVATE KEY-----\n";
const CLIENT_EMAIL = "firebase-adminsdk-mpr08@interior-one-click.iam.gserviceaccount.com";

// JWT 생성을 위한 함수
const createJWT = async () => {
  try {
    console.log('JWT 생성 시작');
    const now = Math.floor(Date.now() / 1000);
    const expiry = now + 3600;

    const header = {
      alg: 'RS256',
      typ: 'JWT'
    };

    const claim = {
      iss: CLIENT_EMAIL,
      sub: CLIENT_EMAIL,
      aud: 'https://oauth2.googleapis.com/token',
      iat: now,
      exp: expiry,
      scope: 'https://www.googleapis.com/auth/firebase.messaging'
    };

    console.log('JWT 페이로드:', { header, claim });

    const encodedHeader = btoa(JSON.stringify(header));
    const encodedClaim = btoa(JSON.stringify(claim));
    const signatureInput = `${encodedHeader}.${encodedClaim}`;

    console.log('서명 입력:', signatureInput);

    const key = await importPrivateKey(PRIVATE_KEY);
    console.log('개인키 임포트 완료');

    const signature = await window.crypto.subtle.sign(
      { name: 'RSASSA-PKCS1-v1_5' },
      key,
      new TextEncoder().encode(signatureInput)
    );

    const encodedSignature = btoa(String.fromCharCode(...new Uint8Array(signature)));
    const jwt = `${signatureInput}.${encodedSignature}`;
    
    console.log('JWT 생성 완료:', jwt.substring(0, 50) + '...');
    return jwt;
  } catch (error) {
    console.error('JWT 생성 실패:', error);
    throw error;
  }
};

// 개인키 import 함수
const importPrivateKey = async (pem) => {
  const pemContents = pem
    .replace('-----BEGIN PRIVATE KEY-----', '')
    .replace('-----END PRIVATE KEY-----', '')
    .replace(/\n/g, '');

  const binaryDer = window.atob(pemContents);
  const der = new Uint8Array(binaryDer.length);
  for (let i = 0; i < binaryDer.length; i++) {
    der[i] = binaryDer.charCodeAt(i);
  }

  return await window.crypto.subtle.importKey(
    'pkcs8',
    der,
    {
      name: 'RSASSA-PKCS1-v1_5',
      hash: { name: 'SHA-256' },
    },
    true,
    ['sign']
  );
};

// 액세스 토큰 획득 함수
const getAccessToken = async () => {
  try {
    console.log('액세스 토큰 요청 시작');
    const jwt = await createJWT();
    
    const response = await fetch('https://oauth2.googleapis.com/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
        assertion: jwt,
      }),
    });

    if (!response.ok) {
      throw new Error(`Token request failed: ${response.status}`);
    }

    const data = await response.json();
    console.log('액세스 토큰 획득 성공');
    return data.access_token;
  } catch (error) {
    console.error('액세스 토큰 획득 실패:', error);
    throw error;
  }
};

// FCM 메시지 전송 함수
export const sendFCMNotification = async (fcmData) => {
  try {
    console.log('FCM 알림 전송 시작:', {
      title: fcmData.title,
      body: fcmData.body,
      data: fcmData.data
    });

    const accessToken = await getAccessToken();
    console.log('액세스 토큰 획득:', accessToken.substring(0, 20) + '...');

    const requestBody = {
      message: {
        topic: 'interior_one_click_updates',
        notification: {
          title: fcmData.title,
          body: fcmData.body
        },
        data: fcmData.data
      }
    };

    console.log('FCM 요청 본문:', JSON.stringify(requestBody, null, 2));

    const response = await fetch(
      `https://fcm.googleapis.com/v1/projects/${PROJECT_ID}/messages:send`,
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
      }
    );

    console.log('FCM 응답 상태:', response.status);
    
    if (!response.ok) {
      const errorText = await response.text();
      console.error('FCM 응답 에러:', {
        status: response.status,
        statusText: response.statusText,
        body: errorText
      });
      throw new Error(`FCM request failed: ${response.status} - ${errorText}`);
    }

    const result = await response.json();
    console.log('FCM 알림 전송 성공:', result);
    return result;
  } catch (error) {
    console.error('FCM 알림 전송 실패:', error);
    throw error;
  }
}; 