import React from 'react';
import './TemplateTable.css';

const TemplateTable = ({ templates, onTemplateClick, onDeleteTemplate, onCloneTemplate, onEditTemplate, onOneMinEstimate }) => {
  const getLineupName = (lineup) => {
    switch(lineup) {
      case 'refresh':
        return '리프레쉬';
      case 'harmony':
        return '하모니';
      case 'collection':
        return '컬렉션';
      default:
        return lineup || '-';
    }
  };

  return (
    <div className="template-table">
      <table>
        <thead>
          <tr>
            <th>라인업</th>
            <th>평수</th>
            <th>공정명</th>
            <th>총액</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {templates.map(template => (
            <tr key={template.id}>
              <td onClick={() => onTemplateClick(template)}>{getLineupName(template.lineup)}</td>
              <td onClick={() => onTemplateClick(template)}>{template.userInput?.size || '-'}</td>
              <td onClick={() => onTemplateClick(template)}>{template.processName || '-'}</td>
              <td onClick={() => onTemplateClick(template)}>
                {template.items?.reduce((sum, item) => 
                  sum + (Number(item.quantity) * Number(item.unitPrice)), 0
                ).toLocaleString()}원
              </td>
              <td className="action-cell">
                <button 
                  className="template-edit-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditTemplate(template);
                  }}
                >
                  수정
                </button>
                <button 
                  className="template-clone-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onCloneTemplate(template);
                  }}
                >
                  복제
                </button>
                <button 
                  className="template-one-min-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onOneMinEstimate(template);
                  }}
                >
                  1분
                </button>
                <button 
                  className="template-delete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteTemplate(template.id);
                  }}
                >
                  삭제
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TemplateTable; 